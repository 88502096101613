import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getCategoryById, getQuestionById, getCategoryMode, getCategoryModeStatus, objToArr, getUserAnswer, getCategoryUserAnswers, getCategoryPerformanceStats, getCurrentTimestampStringUTC, empty, parseUrlParams, isCategoryPurchased, getMediaUrl, getMediaPath, loadProjectCSS, isCategoryInTesting, getAnswerIdWithCorrectness, getAnswerById, finalizeExam, getDefaultPosition, saveBookmark, getCorrectAnswer, updateUserDataSynchronously, getDerivedStateFromPropsValue } from './../utility/Shared'
import { CRITIQUE_ALIAS, CATEGORIES_ALIAS, CATEGORY_ALIAS, PASSING_PERCENTAGE, CONFIRM_FINALIZE_EXAM_ALERT_TITLE, CONFIRM_FINALIZE_EXAM_ALERT_TEXT } from './../utility/Constants'
import PopUpModal from './../common/PopUpModal'
import ModeIndicatorBar from './../common/ModeIndicatorBar'
import BackButton from './../common/BackButton'
import MediaModal from './../common/MediaModal'
import LoadingScreen from './../common/LoadingScreen'
import Tabs from './../common/Tabs'
import { FaChevronLeft, FaCheckCircle, FaRegBookmark, FaPlayCircle } from 'react-icons/fa'
import { confirmAlert } from 'react-confirm-alert' // Import
import './../css/react-confirm-alert.css' // Import css
import ReactDOM from 'react-dom'
import ReactPlayer from 'react-player'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Swipeable } from 'react-swipeable'

class Item extends Component {

	constructor(props) {
		super(props);
		// custom function for when user clicks browser back button since it's tied to page loads from servers, not react rendering item pages
		window.onpopstate = this.onBackButtonEvent.bind(this);

		// instantiate modal ref
		this.popUpModal = React.createRef();

		this.activeSwiping = false;

	}

	// we are tracking user position in state, solves an animation issue (see comment in Shared.js for getDerivedStateFromPropsValue())
	static getDerivedStateFromProps(nextProps, prevState) {
		const updatedState = getDerivedStateFromPropsValue('item',nextProps,prevState);

		return updatedState;
	}

	// NOTE: chose not to reduxify Item UI state since it works the way it is
	state = {
		selectedAnswerId: false,
		pane: 'Item',
		paneToggle: 'Critique', // NOTE: VESAP calls 'Critique' the 'Discussion'
		proceedToCompletion: false,
		isItemMediaShowing: false,
		critiqueHasBeenViewedAfterSelection: false,
		isResponseSubmitted: false,
		wasNextClickedWhileInactive: true,
		preloadedCategoryMedias: [],
		itemOrder: false,
		position: this.props.userData.position,
		loadingMessage: '',
		loading: true,
		slideDirection: 0,
	}

	// when user clicks the back button, bring the user back to the previous item rendered (this doesn't happen by default since item pages aren't actually considered page loads by the browser). the url params are still updated via window.history.pushState when a user clicks the react arrows to navigate to next/prev items. i don't want to use this.props.screenProps.history.push() to navigate to next/prev items since it is a heavier operation (which is what i want to avoid so the navigation between items is lightning fast)
	// TODO: maybe just use this.props.screenProps.history.push() instead of all this anyway (if things are still fast) since it might not be going to the server so won't slow things down much when live? PLUS, the isLoggedIn() function will run.
	onBackButtonEvent(e) {
		e.preventDefault();
		const urlParams = parseUrlParams();
		if(!empty(urlParams.qid)) {
			this.loadItem();
		}
	}

	// make sure that userData has loaded properly. first get userData if we don't already have it. then assign the item in user position by getting url params
	async componentDidMount() {
		// console.log('Item.js version 0.5.0');
		// if user navigated to this url manually, need to reload userData
		if(empty(this.props.userData)) {
			await this.props.screenProps.getUserDataFromDB();
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		// check if user has seen the welcome pages and redirect them there if not
		if(empty(this.props.userData.welcomeScreensViewedTimestamp)) {
			this.props.screenProps.history.push('/welcome');
			return;
		}

		const itemLoaded = await this.loadItem();

		if(itemLoaded) {
			// get preloaded images for category
			const preloadedCategoryMedias = this.props.media.categoryMedia[this.state.position.category.id];

			// show item
			this.setState({preloadedCategoryMedias,loading: false});
		}
	}

	async loadItem() {
		// get item to load from url params and set item in user position
		const urlParams = parseUrlParams();
		let cid = '1';
		let qid = '1';
		if(!empty(urlParams.cid) && !empty(urlParams.qid)) {
			cid = urlParams.cid;
			qid = urlParams.qid;
		}
		let category = getCategoryById(this.props.contentCategories, cid);
		let question = getQuestionById(this.props.contentCategories, cid, qid);

		// make sure user purchased this category. redirect if not.
		let isPurchased = isCategoryPurchased(this.props.userData,category.id);
		if(empty(isPurchased)) {
			this.props.screenProps.history.push('/'+CATEGORIES_ALIAS.toLowerCase());
			return false;
		}

		// make sure user hasn't manually navigated to a URL for a category in 'retake' status but the item is not part of the 'retake' subset
		let currentModeStatus = getCategoryModeStatus(this.props.userData,category.id);
		let userAnswer = getUserAnswer(this.props.userData, category.id, question.id);
		if(currentModeStatus === 'retake' && userAnswer && userAnswer.subset !== 'retake') {
			this.props.screenProps.history.push('/landing?cid='+this.state.position.category.id);
			return false;
		}

		await this.props.screenProps.setUserPosition(this.props.userData,category,question);
		this.determineIfPreviouslyAnswered();
		this.checkShowContinueButton(question);
		this.setItemOrder(question);

		return true;
	}

	setItemOrder(itemToSet=false) {
		if(empty(itemToSet)) {
			itemToSet = this.state.position.item;
		}
		// get item order
		const currentMode = getCategoryMode(this.props.userData,this.state.position.category.id);
		let itemOrder = false;
		if(currentMode === 'Exam' && !empty(itemToSet.shuffleOrder)) {
			itemOrder = itemToSet.shuffleOrder;
		} else {
			itemOrder = itemToSet.number;
		}
		this.setState({itemOrder});
	}

	// check if we are on the last item and all questions are answered so we can show a checkmark on the Next Item button
	// NOTE: this functionality was changed so now there's no checkmark shown in place of the Next arrow. just the destination is changed.
	checkShowContinueButton(checkItem) {
		if(typeof checkItem === 'undefined') {
			checkItem = this.state.position.item;
		}

		// determine if user is on the last item so we can show the Continue/Checkmark button when it's been answered. if the user is in 'retake' mode, we have to check if we are on the last 'retake' item rather than the last item
		let isLastItem = false;
		let modeStatus = getCategoryModeStatus(this.props.userData,this.state.position.category.id);
		let userAnswers = getCategoryUserAnswers(this.props.userData, this.state.position.category.id);
		let userAnswersArray = objToArr(userAnswers);
		userAnswersArray.sort(function(a, b) {
			// in exam mode, question order will be shuffled, so sort by question number
			return a.questionNumber - b.questionNumber;
		});
		if(modeStatus !== 'retake') {
			isLastItem = checkItem.id === userAnswersArray[userAnswersArray.length - 1].questionId;
		} else {
			// get question id of last item in 'retake' (looks similar to what's in navNextItem() but we want last item)
			let questionIdLastRetake = false;
			userAnswersArray.forEach(function(userAnswer,idx) {
				if(userAnswer.subset === 'retake') {
					// questionIdLastRetake will keep being overwritten until it gets to the last 'retake' item
					questionIdLastRetake = userAnswer.questionId;
				}
			});

			// set isLastItem to true if our item to check's id matches the last 'retake' item id we found
			if(questionIdLastRetake === checkItem.id) {
				isLastItem = true;
			}
		}

		// get performance stats to determine if the category is complete
		let performanceStats = getCategoryPerformanceStats(this.props.userData,this.state.position.category.id);
		if(isLastItem && performanceStats['isCompleted']) {
			this.setState({proceedToCompletion: true});
		} else {
			this.setState({proceedToCompletion: false});
		}
	}

	// navigate to landing page
	navToLanding() {
		this.props.screenProps.setUserPosition(this.props.userData,this.state.position.category);
		this.props.screenProps.history.push('/landing?cid='+this.state.position.category.id);
	}

	async navPreviousItem() {
		let currentModeStatus = getCategoryModeStatus(this.props.userData,this.state.position.category.id);
		// get the previous item to pass as props
		let previousItem;
		let lastItem;
		let category = this.state.position.category;
		let currentItemNumber = this.state.itemOrder;

		// in retake mode we get the previous item differently than the other modes since we only want items in the 'retake' subset
		if(currentModeStatus === 'retake') {
			// start by getting all userAnswers so we can loop through them
			let questionIdPreviousRetake = false;
			let lastRetakeQuestionId = false;
			let userAnswers = getCategoryUserAnswers(this.props.userData, category.id);

			// convert to array so we can do a backwards for loop to find the previous item
			let userAnswersArray = objToArr(userAnswers);
			userAnswersArray.sort(function(a, b) {
				// in exam mode, question order will be shuffled, so sort by question number
				return a.questionNumber - b.questionNumber;
			});

			// get question id of previous item in 'retake'
			for (var itemNumber = userAnswersArray.length; itemNumber > 0; itemNumber--) {
				let userAnswer = userAnswersArray[itemNumber-1];

				// get question id of previous 'retake' item (will be successful unless we're on the first 'retake' item)
				if(itemNumber < currentItemNumber && userAnswer.subset === 'retake' && questionIdPreviousRetake === false) {
					questionIdPreviousRetake = userAnswer.questionId;
				}
				// store the question id of the last 'retake' item in case user is on the first 'retake' question (questionIdPreviousRetake won't have been found) so we can 'wheel' to the last
				if(lastRetakeQuestionId === false && userAnswer.subset === 'retake') {
					lastRetakeQuestionId = userAnswer.questionId;
				}
			};

			// if questionIdPreviousRetake is still false, need to assign questionIdPreviousRetake to the lastRetakeQuestionId we just found so we can 'wheel' to the first
			if(questionIdPreviousRetake === false) {
				questionIdPreviousRetake = lastRetakeQuestionId;
			}

			// now that we have the question id of the previous 'retake' question, get the item
			category.questions.forEach(function(question) {
				if(question.id === questionIdPreviousRetake) {
					previousItem = question;
				}
			});

		} else { // we are not in 'retake' mode:
			const currentMode = getCategoryMode(this.props.userData,this.state.position.category.id);
			let previousItemNumber = parseInt(this.state.itemOrder) - 1;
			previousItemNumber = previousItemNumber.toString();
			category.questions.forEach(function(question,index) {
				if(currentMode === 'Exam') {
					if(question.shuffleOrder === previousItemNumber) {
						previousItem = question;
					}
					// also store last item incase user is on the first item and we need to wheel to the last
					if(question.shuffleOrder === category.questions.length.toString()) {
						lastItem = question;
					}
				} else {
					if(question.number === previousItemNumber) {
						previousItem = question;
					}
					// also store last item incase user is on the first item and we need to wheel to the last
					if(empty(category.questions[index+1])) {
						lastItem = question;
					}
				}
			});

			// if 'previousItem' is null then we are on the first question which means we want to navigate to the last question
			if(empty(previousItem)) {
				previousItem = lastItem;
			}
		}
		this.setState({slideDirection: -1});
		this.navigateToItem(previousItem);
	}

	navNextItem() {
		let currentModeStatus = getCategoryModeStatus(this.props.userData,this.state.position.category.id);
		// get the next item to load
		let nextItem;
		let firstItem;
		let currentItemNumber = this.state.itemOrder;

		if(currentModeStatus === 'retake') {
			// get question id of next item in 'retake'
			let questionIdNextRetake = false;
			let firstRetakeQuestionId = false;
			let userAnswers = getCategoryUserAnswers(this.props.userData,this.state.position.category.id);
			let userAnswersArray = objToArr(userAnswers);
			userAnswersArray.sort(function(a, b) {
				// in exam mode, question order will be shuffled, so sort by question number
				return a.questionNumber - b.questionNumber;
			});
			userAnswersArray.forEach(function(userAnswer,index) {
				let itemNumber = index + 1;
				if(itemNumber > currentItemNumber && userAnswer.subset === 'retake' && questionIdNextRetake === false) {
					questionIdNextRetake = userAnswer.questionId;
				}
				// store the question id of the first 'retake' item in case user is on the last 'retake' question (questionIdNextRetake won't have been found) so we can 'wheel' to the first
				if(firstRetakeQuestionId === false && userAnswer.subset === 'retake') {
					firstRetakeQuestionId = userAnswer.questionId;
				}
			});

			// if questionIdNextRetake is still false, need to assign questionIdNextRetake to the firstRetakeQuestionId we just found so we can 'wheel' to the first
			if(questionIdNextRetake === false) {
				questionIdNextRetake = firstRetakeQuestionId;
			}

			// now that we have the question id of the next 'retake' question, get the item
			this.state.position.category.questions.forEach(function(question) {
				if(question.id === questionIdNextRetake) {
					nextItem = question;
				}
			});

		} else {
			const currentMode = getCategoryMode(this.props.userData,this.state.position.category.id);
			let nextItemNumber = parseInt(currentItemNumber) + 1;
			nextItemNumber = nextItemNumber.toString();
			this.state.position.category.questions.forEach(function(question) {
				if(currentMode === 'Exam') {
					if(question.shuffleOrder === nextItemNumber) {
						nextItem = question;
					}
					// also store first item incase user is on the last item and we need to wheel to the first
					if(question.shuffleOrder === '1') {
						firstItem = question;
					}
				} else {
					if(question.number === nextItemNumber) {
						nextItem = question;
					}
					// also store first item incase user is on the last item and we need to wheel to the first
					if(question.number === '1') {
						firstItem = question;
					}
				}
			});

			// if 'nextItem' is null then we are on the last question which means we want to navigate to the first question
			if(empty(nextItem)) {
				nextItem = firstItem;
			}
		}
		this.setState({slideDirection: 1});
		this.navigateToItem(nextItem);
	}

	// this is a pseudo navigation since we're just changing the state of the item page to show the requested item
	async navigateToItem(itemToNav) {
		// "navigate" by setting state of current item to the next item
		this.setItemOrder(itemToNav);
		await this.setState({item: itemToNav, pane: 'Item', paneToggle: 'Critique', critiqueHasBeenViewedAfterSelection: false, wasNextClickedWhileInactive: false});

		// scroll to top
		window.scrollTo(0,0);

		// update url query params so user can see which item they are on
		let cid = this.state.position.category.id;
		let qid = itemToNav.id;
		window.history.pushState({},'','item?cid='+cid+'&qid='+qid);

		this.props.screenProps.setUserPosition(this.props.userData,this.state.position.category,itemToNav);
		this.determineIfPreviouslyAnswered();
		this.checkShowContinueButton(itemToNav);
	}

	// user clicked the button that toggles whether we view the Item or Critique. if there is an answer selected (or already submitted) then mark critiqueHasBeenViewedAfterSelection as true.
	toggleItemCritiquePane() {
		if(this.state.pane === 'Item') {
			// mark that the critique has been viewed if answer selected so that Next arrow will be active
			const critiqueHasBeenViewedAfterSelection = !empty(this.state.selectedAnswerId) ? true : false;

			// in Learning mode, the critique tab submits the selected response if it hasn't already been submitted
			const currentMode = getCategoryMode(this.props.userData,this.state.position.category.id);
			if(currentMode === 'Learning' && empty(this.state.isResponseSubmitted) && !empty(this.state.selectedAnswerId)) {
				this.submitResponse();
			}

			// finally, actually toggle the panes
			this.setState({pane: 'Critique', paneToggle: 'Item', critiqueHasBeenViewedAfterSelection});
		} else {
			this.setState({pane: 'Item', paneToggle: 'Critique'});
		}

		// scroll to top
		//window.scrollTo(0,0);
	}

	// user clicked "Show/Hide Answer" on Critique Pane response box
	toggleCorrectAnswer() {
		let userSetting = {};
		if(!empty(this.props.userData.userInfo.hideCorrectAnswer)) {
			userSetting = {hideCorrectAnswer:'0'};
			this.props.screenProps.setUserSetting(userSetting,this.props.userData);
		} else {
			userSetting = {hideCorrectAnswer:'1'};
			this.props.screenProps.setUserSetting(userSetting,this.props.userData);
		}
	}

	handleNextClicked(isNextButtonInactive) {
		if(isNextButtonInactive) {
			this.setState({wasNextClickedWhileInactive:true});
			return;
		}
		if(!this.state.proceedToCompletion) {
			this.navNextItem()
		} else {
			this.handleContinueClicked();
		}
	}

	// when a user clicks the Continue button, we need to pop dialogs and/or bring user back to Landing page depending on mode/status
	handleContinueClicked() {
		let modeStatus = getCategoryModeStatus(this.props.userData,this.state.position.category.id);
		let performanceStats = getCategoryPerformanceStats(this.props.userData,this.state.position.category.id);

		switch (modeStatus) {
			case 'learn':
				// update mode status to 'completed' if not already
				this.props.screenProps.setCategoryModeStatus(this.props.userData,this.state.position.category.id,'completed');

				// navigate to completion page
				this.props.screenProps.history.push('/completion?cid='+this.state.position.category.id+'&mode=learn');

				break;
			case 'take':
			case 'retake':
				// confirm user wants to finalize
				confirmAlert({
					title: CONFIRM_FINALIZE_EXAM_ALERT_TITLE,
					message: CONFIRM_FINALIZE_EXAM_ALERT_TEXT,
					buttons: [
						{label: 'Return to Exam'},
						{label: 'Finalize Exam', onClick: () => finalizeExam(this.props.screenProps,this.props.userData,this.state.position.category)},
					],
				});
				break;
			case 'completed':
			case 'review':
				this.navToLanding();
				break;
			default:
				console.log('IMPOSSIBLE! Continue clicked with a modeStatus of "'+modeStatus+'".');
				this.navToLanding();
		}
	}

	// determines if this item is bookmarked or not so we can show the bookmark icon if so
	isBookmarked(userData) {
		for (const key of Object.keys(userData.bookmarks)) {
			let bookmark = userData.bookmarks[key];
			if(bookmark.questionId === userData.position.item.id && !bookmark.deleted) {
				return true;
			}
		};

		return false;
	}


	// ITEM PANE FUNCTIONS:

	// determine whether the user has previously answered this question
	async determineIfPreviouslyAnswered() {
		// get the user's answer (if any)
		let userAnswer = getUserAnswer(this.props.userData, this.state.position.category.id, this.state.position.item.id);

		// update the selectedAnswerId in state if we have a user answer
		if(userAnswer.answered) {
			this.setState({selectedAnswerId: userAnswer.id, isResponseSubmitted: true});
		} else {
			this.setState({selectedAnswerId: false, isResponseSubmitted: false});
		}
	}

	// user clicked an answer (or elected to reset the current item if second param is true) so set selectedAnswerId state, which will trigger the styles to update via answerOptionClasses()
	async selectAnswer(answer,isResettingResponse=false) {
		// if user clicked the same answer as the one already selected, or reset an answer that isn't set, then they're a loser so don't do anything
		if(
			(this.state.selectedAnswerId === answer.id && !isResettingResponse) ||
			(this.state.selectedAnswerId === false && isResettingResponse)
		) {
			return;
		}

		// also return if user is in 'review' status, or if in Learning mode and response has been previously submitted, since responses are unalterable then
		const currentMode = getCategoryMode(this.props.userData,this.state.position.category.id);
		const categoryModeStatus = getCategoryModeStatus(this.props.userData,this.state.position.category.id);
		if(categoryModeStatus === 'review' || (currentMode === 'Learning' && this.state.isResponseSubmitted && isResettingResponse === false)) {
			return;
		}

		// colors the answer option so it shows up selected. only actually saved below if in Exam mode though since Learning mode isn't submitted until the Discussion tab is clicked
		await this.setState({selectedAnswerId:!empty(answer.id) ? answer.id : false});

		// submit the response if in Exam mode or if resetting a response. in Learning mode, responses are submitted upon clicking the Discussion tab after a selection
		if(currentMode === 'Exam' || isResettingResponse === true) {
			this.submitResponse(isResettingResponse);
		}
	}

	submitResponse(isResettingResponse=false) {
		// get the userAnswer in userData, update it, and save it (need to deep copy userAnswer and userData or else android bugs out and Learning mode answers show up in Exam mode...)
		let userAnswer = getUserAnswer(this.props.userData, this.state.position.category.id, this.state.position.item.id);
		let updatedUserAnswer = Object.assign({},userAnswer);
		if(isResettingResponse) {
			// reset the updatedUserAnswer for the current item
			updatedUserAnswer.answered = false;
			this.setState({critiqueHasBeenViewedAfterSelection:false,selectedAnswerId:false,isResponseSubmitted:false});
		} else {
			// update the updatedUserAnswer for the user's selection. need to get the content answer
			const contentAnswer = getAnswerById(this.state.position.item,this.state.selectedAnswerId);
			updatedUserAnswer.id = contentAnswer.id;
			updatedUserAnswer.answered = true;
			updatedUserAnswer.correct = contentAnswer.correct;
		}
		updatedUserAnswer.timestamp = getCurrentTimestampStringUTC();

		// put updated userAnswer and updated mode timestamp onto a deep copy of the userData object
		let currentMode = getCategoryMode(this.props.userData,this.state.position.category.id);
		let updatedUserData = Object.assign({},this.props.userData);
		updatedUserData.categories[this.state.position.category.id].modes[currentMode].answers[this.state.position.item.id] = updatedUserAnswer;
		updatedUserData.categories[this.state.position.category.id].modes[currentMode].timestamp = updatedUserAnswer.timestamp;

		// update selected answer and the userData in redux/db
		this.props.screenProps.syncReduxAndDB(updatedUserData, 'syncCategories');

		// mark that a response has been submitted (if we aren't resetting)
		if(!isResettingResponse) {
			this.setState({isResponseSubmitted:true});
		}

		// determine if the last question has been answered and we need to change the Next button to Continue
		this.checkShowContinueButton(this.state.position.item);

		// when resetting an answer, also return to Item pane if not there already
		if(isResettingResponse && this.state.pane === 'Critique') {
			this.toggleItemCritiquePane();
		}

		// also update landing page view incase a filter like 'Unanswered' was selected and the user clicks the back button, we want for the answer just selected to not show up in the 'Unanswered' filter list
		// TODO: make async if possible
		this.props.screenProps.setLandingPage(updatedUserData, this.props.modeDisplayed, this.props.filterSelected);
	}


	answerOptionClasses(answer, currentMode, currentModeStatus, element) {
		let classesArr = [];
		const areAnswersUnalterable = (currentModeStatus === 'review' || (currentMode === 'Learning' && this.state.isResponseSubmitted));

		// define the base style for the element. make answer options inactive if in reviewing Exam mode or already submitted Learning mode
		switch (element) {
			case 'itemAnswerOptionBlock': {
				classesArr.push('item-answer-option-block');
				if(areAnswersUnalterable && answer.id !== this.state.selectedAnswerId) {
					classesArr.push('inactive');
				}
				break;
			}
			case 'itemAnswerOptionText': {
				classesArr.push('item-answer-option-text');
				if(areAnswersUnalterable && answer.id !== this.state.selectedAnswerId) {
					classesArr.push('inactive');
				}
				break;
			}
			case 'itemAnswerText': {
				classesArr.push('item-answer-text');
				if(areAnswersUnalterable && answer.id !== this.state.selectedAnswerId) {
					classesArr.push('inactive');
				}
				break;
			}
		}

		// show which is selected
		// NOTE: used to color answers as correct/incorrect in some cases. to see this code, look at BitBucket 'dev' branches on or before 4/15/20
		if (answer.id === this.state.selectedAnswerId) {
			// answer is selected
			switch (element) {
				case 'itemAnswerOptionBlock': {
					classesArr.push('item-answer-option-block-selected');
					break;
				}
				case 'itemAnswerOptionText': {
					classesArr.push('item-answer-option-text-selected');
					break;
				}
				case 'itemAnswerText': {
					classesArr.push('item-answer-text-selected');
					break;
				}
			}
		}

		return ' ' + classesArr.join(' ') + ' ';
	}

	// test user button function to answer all but 1 correctly/incorrectly (unless it's been answered already, keep that answer)
	async answerRemainingExceptLast(areAnsweredCorrectly) {
		let userAnswers = getCategoryUserAnswers(this.props.userData,this.state.position.category.id);
		let updatedUserAnswers = Object.assign({},userAnswers);
		let userAnswersArray = objToArr(userAnswers);
		userAnswersArray.sort(function(a, b) {
			// in exam mode, question order will be shuffled, so sort by question number
			return a.questionNumber - b.questionNumber;
		});
		let userData = this.props.userData;
		let contentCategories = this.props.contentCategories;
		let position = this.state.position;
		let itemToNav;
		const timestamp = getCurrentTimestampStringUTC();
		userAnswersArray.forEach(function(userAnswer,index) {
			// answer all questions except the last
			if(index + 1 !== userAnswersArray.length) {
				if(userAnswer.answered === true) {
					return;
				}
				let updatedUserAnswer = Object.assign({},userAnswer);
				let selectedAnswerId = getAnswerIdWithCorrectness(contentCategories,position.category.id,userAnswer.questionId,areAnsweredCorrectly);
				updatedUserAnswer.id = selectedAnswerId;
				updatedUserAnswer.answered = true;
				updatedUserAnswer.correct = areAnsweredCorrectly;
				updatedUserAnswer.timestamp = timestamp;
				updatedUserAnswers[userAnswer.questionId] = updatedUserAnswer;
			} else {
				// we are on the last question so reset it. if in 'retake' mode, need to make sure we go back to the last question in the 'retake' subset and reset that
				const categoryModeStatus = getCategoryModeStatus(userData,position.category.id);
				if(categoryModeStatus !== 'retake') {
					const currentMode = getCategoryMode(userData,position.category.id);
					if(currentMode === 'Exam') {
						// exam mode questions are shuffled
						position.category.questions.forEach(function(question,qIdx) {
							if(userAnswer.questionId === question.id) {
								itemToNav = question;
							}
						});
					} else {
						// learning mode questions aren't shuffled
						itemToNav = position.category.questions[index];
					}
				} else {
					// we are in retake mode so need to unanswer the last retake mode question and then assign that as the itemToNav
					for (var i = 1; i < userAnswersArray.length; i++) {
						let userAnswer = userAnswersArray[userAnswersArray.length - i];
						if(userAnswer.subset === 'retake') {
							userAnswer.answered = false;
							updatedUserAnswers[userAnswer.questionId] = userAnswer;
							position.category.questions.forEach(function(question,idx) {
								if(question.id === userAnswer.questionId) {
									itemToNav = question;
								}
							});
							break;
						}
					}
				}
			}
		});

		// put updated userAnswers onto a deep copy of the userData object
		let currentMode = getCategoryMode(this.props.userData,this.state.position.category.id);
		let updatedUserData = Object.assign({},this.props.userData);
		updatedUserData.categories[this.state.position.category.id].modes[currentMode].answers = updatedUserAnswers;
		updatedUserData.categories[this.state.position.category.id].modes[currentMode].timestamp = timestamp;

		// update selected answer and the userData in AsyncStorage/redux store
		this.setState({loading:true,loadingMessage:'Answering all but 1 remaining questions '+(areAnsweredCorrectly ? 'correctly' : 'incorrectly')+'...'});
		let syncedUserData = await updateUserDataSynchronously(updatedUserData);
		this.props.screenProps.setUserPosition(syncedUserData,this.state.position.category,this.state.position.item);
		this.props.screenProps.setUserDataToRedux(syncedUserData);
		this.setState({loading:false,loadingMessage:''});

		// navigate to last item
		this.navigateToItem(itemToNav);
	}

	// opens the add bookmark modal, passing the default category and question
	openBookmarkModal() {
		let userPosition = getDefaultPosition(this.props.contentCategories,this.state.position);
		this.popUpModal.current.openModal('bookmark',userPosition.category,userPosition.question);
	}

	onSwiping = (e) => {
		/*console.log(this.activeSwiping + ": " + e.deltaX);*/
		if (this.activeSwiping) {
			if (e.deltaX>=50) {
				const currentMode = getCategoryMode(this.props.userData,this.state.position.category.id);
				const isCritiqueHighlighted = currentMode === 'Learning' && !empty(this.state.selectedAnswerId) && empty(this.state.isResponseSubmitted) && empty(this.state.critiqueHasBeenViewedAfterSelection);
				this.activeSwiping = false;
				if (!isCritiqueHighlighted) {
					this.navNextItem();
				}
			} else if (e.deltaX<=-50) {
				this.activeSwiping = false;
				this.navPreviousItem();
			}
		}
	}
	onSwipedLeft = (e) => {
		this.activeSwiping = true;
	}
	onSwipedRight = (e) => {
		this.activeSwiping = true;
	}



	render() {
		// console.log('Item.js render() this.props',this.props);
		// console.log('Item.js render() this.state',this.state);

		const swipeHandlers = {onSwiping: this.onSwiping, onSwipedRight: this.onSwipedRight, onSwipedLeft: this.onSwipedLeft};
		const swipeConfig = {trackMouse: true};

		if(this.state.loading || empty(this.props.userData)) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText={empty(this.state.loadingMessage) ? "Loading your question..." : this.state.loadingMessage} />
			)
		}

		// init vars
		const letterMap = ['A','B','C','D','E','F'];
		const currentMode = getCategoryMode(this.props.userData,this.state.position.category.id);
		const currentModeStatus = getCategoryModeStatus(this.props.userData,this.state.position.category.id);
		let userAnswer;
		let correctAnswer = {};
		const selectedAnswerId = this.state.selectedAnswerId;
		if(selectedAnswerId) {
			// user has answered this question so build userAnswer variable for Response Box
			userAnswer = getUserAnswer(this.props.userData, this.state.position.category.id, this.state.position.item.id);
		}
		correctAnswer = getCorrectAnswer(this.state.position.item.answers);
		const isBookmarked = this.isBookmarked(this.props.userData);
		const selectAnswer = this.selectAnswer.bind(this);
		const answerOptionClasses = this.answerOptionClasses.bind(this);
		const categoryTestingInProgress = isCategoryInTesting(this.props.userData, this.state.position.category.id);
		const isCeEligible = this.props.userData.userInfo.isCeEligible;
		const isCritiqueHighlighted = currentMode === 'Learning' && !empty(this.state.selectedAnswerId) && empty(this.state.isResponseSubmitted) && empty(this.state.critiqueHasBeenViewedAfterSelection);
		const isNextButtonInactive = isCritiqueHighlighted;

		const areAnswersUnalterable = (currentModeStatus === 'review' || (currentMode === 'Learning' && this.state.isResponseSubmitted));

		const preloadedQuestionMedias = objToArr(this.state.preloadedCategoryMedias[this.state.position.item.id]);
		let slideDirection = "";
		if (this.state.slideDirection === 1) {
			slideDirection = " item-slide-right";
		} else if (this.state.slideDirection === -1) {
			slideDirection = " item-slide-left";
		}
		return (
			<div className={"container item-page" + slideDirection}>
				{!empty(isCeEligible) &&
				<ModeIndicatorBar isItemPage={true} />
				}
				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="back-button-container">
							<BackButton history={this.props.screenProps.history} label={CATEGORY_ALIAS} position={this.state.position} />
						</div>
						<div className="prev-arrow-container flex-centered-axes" onClick={() => this.navPreviousItem()}>
							<div className="item-nav-arrow-container">
								<img src={require("./../images/arrow-left.png")} width="25" height="25" alt="" />
							</div>
						</div>
						<div className={"next-arrow-container flex-centered-axes "+(isNextButtonInactive ? " inactive " : " active ")+(this.state.wasNextClickedWhileInactive ? " clicked " : "")} onClick={()=> {this.handleNextClicked(isNextButtonInactive)}}>
							<div className="item-nav-arrow-container"><img src={require("./../images/arrow-right.png")} width="25" height="25" alt=""/></div>
							<div className="tooltiptext">Click the "Discussion" tab to activate the "Next" arrow.</div>
						</div>

						<Swipeable {...swipeHandlers} {...swipeConfig}>

							<div className="page-content-inner-layer">
								<TransitionGroup component={null}>
									<CSSTransition
										key={this.state.itemOrder}
										timeout={300}
										classNames="itemContent">

										<div>
											{/* location info */}
											<div className="header-text">{this.state.position.category.name}</div>
											<div className="flex-centered-axes">
												<h6 className="module-text">{this.state.position.category.questions.length} Items</h6>
											</div>

											<div className="item-info-container flex-row">
												{(empty(categoryTestingInProgress) && isBookmarked) &&
												<div className="item-bookmark clickable"
													 onClick={() => this.props.screenProps.history.push('/bookmarks')}>
													<img src={require('../images/bookmark-filled.png')}/>
													<span className="item-bookmark-text">Bookmarked</span>
												</div>
												}
												{(empty(categoryTestingInProgress) && !isBookmarked) &&
												<div className="item-bookmark" onClick={() => this.openBookmarkModal()}>
													<img src={require('../images/bookmark-outline.png')}/>
													<span className="item-bookmark-text">Add bookmark</span>
												</div>
												}

												<div className="item-info-circle">
													<div className="item-info-position">{this.state.itemOrder}</div>
												</div>
											</div>

											{empty(categoryTestingInProgress) ? (
												<Tabs activeTab={this.state.pane === 'Critique' ? CRITIQUE_ALIAS : 'Question'}
													  onClickFunction={this.toggleItemCritiquePane.bind(this)}>
													<div label='Question'></div>
													<div label={CRITIQUE_ALIAS} isHighlighted={isCritiqueHighlighted}></div>
												</Tabs>
											) : (
												<br/>
											)}

											<TransitionGroup className="flex-row item-content">

												<CSSTransition
													key={this.state.pane}
													timeout={300}
													classNames="questionFade">

													{this.state.pane === 'Item' ? (

														// ITEM PANE

														<div className="flex-item-1 item-critique-pane">
															<div className="item-text"><p
																dangerouslySetInnerHTML={{__html: this.state.position.item.text}}></p>
															</div>

															<MediaList media={preloadedQuestionMedias} pane={'item'}/>

															{/* answer options */}
															<div className="list-items answer-options">
																{objToArr(this.state.position.item.answers).map(function (answer, idx) {
																	return (
																		// style is determined by the answerOptionClasses() function that takes into account mode and answer status. it is called upon each render() function call
																		<div
																			key={answer.id}
																			className={"item-answer-option " + (areAnswersUnalterable ? ' unalterable ' : '') + (selectedAnswerId === answer.id ? ' selected ' : '')}
																			onClick={() => selectAnswer(answer)}
																		>
																			<div
																				className={answerOptionClasses(answer, currentMode, currentModeStatus, 'itemAnswerOptionBlock')}>
																				<div
																					className={answerOptionClasses(answer, currentMode, currentModeStatus, 'itemAnswerOptionText')}>{letterMap[idx]}</div>
																			</div>
																			<div>
																				<div><p
																					className={answerOptionClasses(answer, currentMode, currentModeStatus, 'itemAnswerText')} dangerouslySetInnerHTML={{__html: answer.text}}></p>
																				</div>
																			</div>
																		</div>
																	)
																})}
															</div>


														</div>

														// end ITEM PANE

													) : (

														// CRITIQUE PANE:

														<div className="flex-item-1">
															{/*<div className="item-text" dangerouslySetInnerHTML={{ __html: this.state.position.item.text }}></div>*/}
															<div className="item-text"><p
																dangerouslySetInnerHTML={{__html: this.state.position.item.text}}></p></div>

															{/* response box
										- shown if user has answered question
										- if correct, CorrectAnswer component (containing correct answer and letter) shown automatically
										- if incorrect, correct answer and letter hidden until user clicks 'Show'
								 	*/}
															{selectedAnswerId &&
															<div className="response-box-container">
																<div>
																	<div className="flex-col flex-centered-axes">
																		<div
																			className="correctness-text">{userAnswer.correct ? 'Correct!' : 'Incorrect.'}</div>
																		<div className="flex-row flex-centered-axes">
																			<div className="preferred-response">The preferred response is:</div>
																			<div
																				className="btn5 small x-small mgn-left-15"
																				onClick={() => this.toggleCorrectAnswer()}
																			>
																				<div>{!empty(this.props.userData.userInfo.hideCorrectAnswer) ? 'Show' : 'Hide'}</div>
																			</div>
																		</div>
																	</div>
																	{empty(this.props.userData.userInfo.hideCorrectAnswer) &&
																	<div className="flex-row flex-centered-axes">
																		<CorrectAnswer correctAnswer={correctAnswer}/>
																	</div>
																	}
																</div>
															</div>
															}

															<MediaList media={preloadedQuestionMedias} pane={'critique'}/>

															<div className="header-text-secondary">{CRITIQUE_ALIAS}</div>
															{/*<div className="item-text" dangerouslySetInnerHTML={{ __html: this.state.position.item.critique.text }}></div>*/}
															<div className="item-text"><p
																dangerouslySetInnerHTML={{__html: this.state.position.item.critique.text}}></p>
															</div>

															{/* references */}
															<div className="header-text-secondary">References</div>
															<div>
																{objToArr(this.state.position.item.references).map(function (reference, idx) {
																	const refNum = idx + 1;
																	const referenceText = refNum + '. ' + reference.text;
																	return (
																		<div key={reference.id} className="item-reference">
																			<p dangerouslySetInnerHTML={{__html: referenceText}}></p>
																		</div>
																	)
																})}
															</div>
														</div>

														// end CRITIQUE PANE

													)}

												</CSSTransition>

											</TransitionGroup>

											{/* reset response button (hidden in Exam mode) */}
											{(currentMode === 'Learning') &&
											<div className="flex-centered-axes reset-button">
												<div className="btn5 small" onClick={() => this.selectAnswer(false, true)}>
													<div className="center-text">Reset Response</div>
												</div>
											</div>
											}

											{/* Test user buttons */}
											{!empty(this.props.userData.userInfo.isTestUser) &&
											<div className="test-user-menu">
												<p className="bold-text">Test User Menu</p>
												<p>Correct answer: {correctAnswer.correctLetter}</p>
												<div className="flex-row">
													<div className="btn4" onClick={() => {
														this.answerRemainingExceptLast(true)
													}}>
														Finish all but 1 correctly
													</div>
													<div className="btn4" onClick={() => {
														this.answerRemainingExceptLast(false)
													}}>
														Finish all but 1 incorrectly
													</div>
												</div>
											</div>
											}


										</div>

									</CSSTransition>
								</TransitionGroup>

							</div>

						</Swipeable>

					</div>
				</div>

				<PopUpModal
					ref={this.popUpModal}
					screenProps={this.props.screenProps}
					saveBookmark={saveBookmark}
					contentCategories={this.props.contentCategories}
					userData={this.props.userData}
				/>
			</div>
		);
	}
}


// the part of response box that shows the correct answer letter and text
class CorrectAnswer extends Component {
	render() {
		return (
			<div className="item-answer-option">
				<div className="item-answer-option-block item-answer-option-block-inactive">
					<div className="item-answer-option-text">{this.props.correctAnswer.correctLetter}</div>
				</div>
				<div>
					<div><p className="item-answer-text-inactive no-margin" dangerouslySetInnerHTML={{ __html: this.props.correctAnswer.correctAnswerText }}></p></div>
				</div>
			</div>
		)
	}
}

// renders the array of media for the item or critique
class MediaList extends Component {
	constructor(props) {
		super(props);
		this.mediaModal = React.createRef();
		this.onOpenModal = this.onOpenModal.bind(this);
	}

	onOpenModal(media) {
		this.mediaModal.current.openModal(media);
	}


	render() {
		// console.log('MediaList render() this.props',this.props);

		const pane = this.props.pane;
		const onOpenModal = this.onOpenModal;

		return (
			<div className={"flex-centered-axes " + (this.props.media.length > 0 ? "includes-media" : "")}>
				{this.props.media.map(function(media, idx) {
					if(media.pane !== pane) {
						return null;
					}

					return(
						<div key={media.id} className="media-thumbnail-container" onClick={()=> onOpenModal(media)}>
							{media.type !== 'mp4' ? (
								<img
									src={media.preloadedThumbnailImage.src}
									className="media-thumbnail"
								/>
							) : (
								<div className="relative">
									<ReactPlayer
										url={media.url}
										width={128}
										height={128}
									/>
									<FaPlayCircle
										className="play-video-icon"
									/>
								</div>
							)}
						</div>
					)
				})}
				<MediaModal
					ref={this.mediaModal}
				/>
			</div>
		)
	}
}


const mapStateToProps = (state) => {
	// console.log('Item.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers,
		modeDisplayed: state.landingReducers.modeDisplayed,
		media: state.mediaReducers,
	}
}

export default connect(mapStateToProps)(Item);
