import React, { Component } from 'react'
import { connect } from 'react-redux'
import { empty, loadProjectCSS } from './../utility/Shared'
import HeaderSecondaryAdmin from './../common/HeaderSecondaryAdmin'
import BackButton from './../common/BackButton'
import LoadingScreen from './../common/LoadingScreen'

class UserSummary extends Component {

	state = {
		loading: true,
	}

	async componentWillMount() {
		// if user navigated to this url manually, need to reload licensing data
		if(empty(this.props.licensing) || empty(this.props.licensing.logoImageName)) {
			await this.props.screenProps.getLicensingData(true);
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		// if user navigated to this url manually, need to reload admin data
		if(empty(this.props.adminData) || empty(this.props.adminData.data.staticStats)) {
			await this.props.screenProps.getAdminData();
		}

		this.setState({loading: false});

	}



  render() {
		// console.log('UserSummary.js render() this.props',this.props);

		if(this.state.loading) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText="Loading user summary..."/>
			)
		}

		const userSummary = this.props.adminData.data.userSummary;

    return (<>
			<div className="container container-admin user-summary-page">
				<HeaderSecondaryAdmin history={this.props.screenProps.history} />

				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">
						
						<div className="back-button-container">
							<BackButton history={this.props.screenProps.history} />
						</div>

						<h1>User Summary</h1>
							{/* <p className="info-text"></p> */}

							<div className="table-wrapper">
								<div className="table data-table">
									<div className="row row-header">
										<div className="cell">User Status</div>
										<div className="cell">CME</div>
										<div className="cell">Non-CME</div>
										<div className="cell">Total</div>
									</div>
									<div className="row">
										<div className="cell">Active</div>
										<div className="cell">{userSummary.CeActiveCount}</div>
										<div className="cell">{userSummary.NonCeActiveCount}</div>
										<div className="cell">{parseInt(userSummary.CeActiveCount) + parseInt(userSummary.NonCeActiveCount)}</div>
									</div>
									<div className="row">
										<div className="cell">Inactive</div>
										<div className="cell">{userSummary.CeInactiveCount}</div>
										<div className="cell">{userSummary.NonCeInactiveCount}</div>
										<div className="cell">{parseInt(userSummary.CeInactiveCount) + parseInt(userSummary.NonCeInactiveCount)}</div>
									</div>
									<div className="row">
										<div className="cell">Test</div>
										<div className="cell">{userSummary.CeTestCount}</div>
										<div className="cell">{userSummary.NonCeTestCount}</div>
										<div className="cell">{parseInt(userSummary.CeTestCount) + parseInt(userSummary.NonCeTestCount)}</div>
									</div>
									<div className="row">
										<div className="cell">Total</div>
										<div className="cell">{parseInt(userSummary.CeActiveCount) + parseInt(userSummary.CeInactiveCount) + parseInt(userSummary.CeTestCount)}</div>
										<div className="cell">{parseInt(userSummary.NonCeActiveCount) + parseInt(userSummary.NonCeInactiveCount) + parseInt(userSummary.NonCeTestCount)}</div>
										<div className="cell">{parseInt(userSummary.CeActiveCount) + parseInt(userSummary.NonCeActiveCount)+ parseInt(userSummary.CeInactiveCount) + parseInt(userSummary.NonCeInactiveCount) + parseInt(userSummary.CeTestCount) + parseInt(userSummary.NonCeTestCount)}</div>
									</div>
								</div>
							</div/*  className="table-wrapper" */>
						</div/* className="page-content"*/>
					</div/* className="page-content-outer-layer"*/>
				</div/* className="page-content-inner-layer"*/>

			</div>
    </>);
  }
}

const mapStateToProps = (state) => {
	// console.log('UserSummary.js mapStateToProps() state',state);
	return {
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers,
		adminData: state.adminReducers
	}
}

export default connect(mapStateToProps)(UserSummary);
