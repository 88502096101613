import React, { Component } from 'react'
import { connect } from 'react-redux'
import { empty } from './../utility/Shared'
import Levels from 'react-activity/lib/Levels'
import 'react-activity/lib/Levels/Levels.css';
import Sentry from 'react-activity/lib/Sentry'
import 'react-activity/lib/Sentry/Sentry.css';
import { ORG_STYLES, DEFAULT_CSS_THEME_PREFIX } from './../utility/Constants';


class LoadingScreen extends Component {

	renderLoadingIndicator() {
		let loadingIndicatorColor = ORG_STYLES[DEFAULT_CSS_THEME_PREFIX].primary;
		if(!empty(this.props.licensing)) {
			loadingIndicatorColor = ORG_STYLES[this.props.licensing.cssThemePrefix].primary;
		}

		switch (this.props.type) {
			case 'csvImport':
				return (
					<Levels size={48} speed={0.7} color={loadingIndicatorColor} />
				)
				break;
			default:
				return (
					<Sentry size={48} speed={0.7} color={loadingIndicatorColor} />
				)
		}
	}

	renderLoadingText() {
		if(empty(this.props.loadingText)) {
			return (
				<p>Loading...</p>
			)
		} else {
			return (
				<p>{this.props.loadingText}</p>
			)
		}
	}


  render() {
		// console.log('LoadingScreen.js render() this.props',this.props);

		return (
			<div className="flex-item-1 flex-col flex-centered-axes loading-container">
				{this.renderLoadingIndicator()}
				{this.renderLoadingText()}
			</div>
		)
  }
}


const mapStateToProps = (state) => {
	// console.log('LoadingScreen.js mapStateToProps() state',state);
	return {
		licensing: state.licensingReducers
	}
}

export default connect(mapStateToProps)(LoadingScreen);
