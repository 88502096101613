import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routeUser, getCurrentTimestampStringUTC, setLoginSession, getLoginTokenPacket, empty, getProjectBasename, objToArr, loadProjectCSS, getLicensingOrgs, getLicensingDataFromOrgs, isProjectLive, handleCacheBusting, parseUrlParams, getBaseUrl, checkParamsForAlerts } from './../utility/Shared'
import { PARENT_PROJECT_UUID, GUEST_LOGIN_PASSWORD_HASH, SESSION_NAME_USER, DEFAULT_LOGIN_LOGO_FILENAME, PROJECT_BASENAME } from './../utility/Constants'
import { confirmAlert } from 'react-confirm-alert' // Import
import './../css/react-confirm-alert.css' // Import css
import LoadingScreen from './../common/LoadingScreen'
import Footer from "../common/Footer";



class Login extends Component {
	constructor(props) {
		super(props)
		this.md5 = require('md5');

		this.handleUsernameTextChange = this.handleUsernameTextChange.bind(this);
		this.handlePasswordTextChange = this.handlePasswordTextChange.bind(this);
		this.handleOrganizationChange = this.handleOrganizationChange.bind(this);
		this.handleLogin = this.handleLogin.bind(this);
	}

	state = {
		licensingOrgsDropdown: [],
		username: '',
		password: '',
		projectUuid: PARENT_PROJECT_UUID, // initialize to parent
		loading: true,
	}

	async componentDidMount() {
		// console.log('Login.js version 0.5.0');
		await this.logoutIfNotAlready();

		await handleCacheBusting();

		checkParamsForAlerts();

		// populate licensing state obj for dropdown
		let licensingOrgsDropdown = await getLicensingOrgs();

		// initialize projectUuid in state with parent if none already selected
		let projectUuid = PARENT_PROJECT_UUID;
		if(!empty(this.props.licensing.projectUuid)) {
			projectUuid = this.props.licensing.projectUuid;
		}

		// show login screen
		this.setState({licensingOrgsDropdown, projectUuid, loading:false});
	}

	async logoutIfNotAlready() {
		let loginPacket = getLoginTokenPacket(false,true);
		if(empty(loginPacket)) {
			// user is already logged out
			return false;
		}
		await this.props.screenProps.logoutUser(loginPacket);
		this.logoutLocalStorageSession();
	}

	logoutLocalStorageSession() {
		// remove login "session"
		localStorage.removeItem(SESSION_NAME_USER);
	}

	handleUsernameTextChange(e) {
		this.setState({username: e.target.value});
	}

	handlePasswordTextChange(e) {
		this.setState({password: e.target.value});
	}

	handleOrganizationChange(e) {
		// set licensing org to redux so styles/logo update upon org selection
		let licensingOrg = {};
		this.state.licensingOrgsDropdown.forEach(function(org,idx) {
			if(org.projectUuid === e.target.value) {
				licensingOrg = org;
			}
		});
		this.props.screenProps.setLicensingDataToRedux(licensingOrg);

		// load styles (needed for dev, live works via setLicensingDataToRedux() above... unfortunate i know, more details in wiki)
		loadProjectCSS(licensingOrg.cssFilename);

		// update dropdown
		this.setState({projectUuid: e.target.value});
	}

	// send username, passwordHash, and projectUuid to hypix api to validate credentials
	handleLogin(e, isGuestLogin) {
		e.preventDefault();

		// get login vars
		let username = this.state.username;
		let passwordHash = this.md5(this.state.password);
		let projectUuid = this.state.projectUuid;

		// guest login overwrites username to be the VESAP5 guest user unless a different org is selected in the dropdown
		if(isGuestLogin) {
			this.state.licensingOrgsDropdown.forEach(function(org,idx) {
				if(org.projectUuid === projectUuid) {
					username = org.guestUn;
				}
			})
			passwordHash = GUEST_LOGIN_PASSWORD_HASH;
		}

		// build the login packet based off the final values for username, passwordHash, and projectUuid
		let loginPacket = {
      "userInfo": {
        "username": username,
        "passwordHash": passwordHash,
				"projectUuid": projectUuid
      }
    }

		// validate login form and call API if
    if(empty(username)) {
      this.setState({Error: 'Please enter your username'});
    } else if(passwordHash === 'd41d8cd98f00b204e9800998ecf8427e') {
      this.setState({Error: 'Please enter your password'});
    } else {
			// show loading screen
			this.setState({loading: true});

// console.log('loginPacket',JSON.stringify(loginPacket));
			// call API login service
      return fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action=login', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginPacket)
      })
      .then((response) => response.json())
      .then((loginResponse) => {
// console.log('loginResponse',loginResponse);
// console.log('loginResponse',JSON.stringify(loginResponse));

				// display errors if any
				if(loginResponse.Error) {
					if(loginResponse.Error === "initiateAction(): VESAP5.class.php __construct(): VESAP5.model.php: __construct(): getUserAndProject(): There were no users matching those credentials.") {
						this.setState({Error: 'Invalid credentials, please try again.', username: '', password: '', loading: false});
					} else {
						this.setState({Error: 'Something went wrong. Please try again.', username: '', password: '', loading: false});
					}
					return;
				}

				// first check to see if there are any messages we want to send to the user. these could include scheduled maintenance, down for maintenance, or update required messages, among others
				if(!empty(loginResponse.interrupt)) {
					// pop alert if needed
					if(!empty(loginResponse.interrupt.isPopupActiveOnWeb)) {
						confirmAlert({
							title: loginResponse.interrupt.title,
							message: loginResponse.interrupt.message,
							buttons: [{label: loginResponse.interrupt.buttonText}]
						});
					}
					// log user out if needed
					if(loginResponse.interrupt.isLoginRestrictedOnWeb) {
						this.setState({Error: loginResponse.interrupt.screenMessage, username: '', password: '', loading: false});
						return;
					}
				}

				// save categories content data to redux
				this.props.screenProps.setContentToRedux(loginResponse.contentCategories);

				// save user data to redux (and preload images if user is in a category)
				this.props.screenProps.setUserDataToRedux(loginResponse.userData);
				if(!empty(loginResponse.userData.position.category)) {
					this.props.screenProps.setCategoryMediaToRedux(Object.assign({},loginResponse.userData.position.category));
				}

				// save licensing data to redux
				let licensingOrg = getLicensingDataFromOrgs(loginResponse.project.UUID,loginResponse.licensingOrgs);
				this.props.screenProps.setLicensingDataToRedux(licensingOrg);

				// load styles
				let cssFilename = loginResponse.project.Token + '-styles.css';
				loadProjectCSS(cssFilename);

				// store login info in browser local storage. before the underscore will be the logout time and after the underscore is the login token
				setLoginSession(loginResponse.loginToken);

				// route user to appropriate page
				routeUser(this.props.screenProps.history, this.props.userData);

      })
      .catch((error) => {
				// remove loading screen
				this.setState({loading: false});
        console.log('ERROR Login.js handleLogin(): ', error)
      });
    }

	}

  render() {
		// console.log('Login.js render() this.props',this.props);
		// console.log('Login.js render() this.state',this.state);

		if(this.state.loading) {
			return (
				<LoadingScreen loadingText="Maneuvering satellites into position..." />
			)
		}

		let loginLogoImageFilename = DEFAULT_LOGIN_LOGO_FILENAME;
		if(!empty(this.props.licensing.loginLogoImageName) && !empty(this.props.licensing.logoImageName)) {
			let useSpecificLoginLogo = false;
			try {
				require('./../images/'+this.props.licensing.loginLogoImageName);
				useSpecificLoginLogo = true;
			} catch (e) {
				useSpecificLoginLogo = false;
			}
			if(useSpecificLoginLogo) {
				loginLogoImageFilename = this.props.licensing.loginLogoImageName;
			} else {
				loginLogoImageFilename = this.props.licensing.logoImageName;
			}
		}
		let currentProjectUuid = !empty(this.props.licensing.projectUuid) ? this.props.licensing.projectUuid : this.state.projectUuid;

    return (<>
			<div className="container login-screen">
				<div className="login-form-container">
					<h2>Please log in</h2>
					<p className="login-form-instructions">Enter the user name and password for VESAP5 below.</p>
					{this.state.Error &&
						<p className="login-form-instructions error-field">{this.state.Error}</p>
					}
					<form onSubmit={(e) => this.handleLogin(e)}>
						<div className="flex-col">
							<div className="flex-row"><label>User Name:</label>
								<input name="username" className="input-box" type="text" value={this.state.username} onChange={this.handleUsernameTextChange}/>
							</div>
							<div className="flex-row"><label>Password:</label>
								<input name="password" className="input-box" type="password" value={this.state.password} onChange={this.handlePasswordTextChange}/>
							</div>
							{this.state.licensingOrgsDropdown.length > 1 && (
							<select
								className="input-box select-menu"
								onChange={this.handleOrganizationChange}
								value={this.state.projectUuid}
							>
								{this.state.licensingOrgsDropdown.map(function (organization, idx) {
									return (
										<option key={organization.projectUuid} value={organization.projectUuid}>{organization.name}</option>
									)
								})}
							</select>
							)}
							<input className="btn5" type="submit" value="Login"/>
						</div>
					</form>

					{empty(isProjectLive()) &&
						<form onSubmit={(e) => this.handleLogin(e, true)}>
							<div className="flex-col">
								<input className="btn5" type="submit" value="Guest login"/>
							</div>
						</form>
					}
				</div>
				{/* <Footer /> */}
			</div>
    </>);
  }
}

// TODO: PropTypes doesn't work. I hate them.
Login.propTypes = {

}

function mapStateToProps(state) {
	// console.log('Login.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		licensing: state.licensingReducers
	}
}

export default connect(mapStateToProps)(Login);
