import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PopUpModal from './../common/PopUpModal'
import { empty, getProjectBasename, loadProjectCSS, getCurrentTimestampStringUTC, getDefaultPosition, viewCertificate } from './../utility/Shared'
import { CATEGORIES_ALIAS, DEFAULT_LOGO_FILENAME } from './../utility/Constants'
import { connect } from 'react-redux'

class Header extends Component {
	constructor(props) {
    super(props);
    this.menuDrawerToggle = this.menuDrawerToggle.bind(this);
    this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.saveFeedback = this.saveFeedback.bind(this);

		// setup ability to close menu (if open) when clicked outside
		this.setDrawerMenuArea = this.setDrawerMenuArea.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

		// instantiate modal ref
		this.popUpModal = React.createRef();
  }

	state = {
		menuSearchTerm: '',
		isMenuDrawerOpen: false,
		hasMenuDrawerOpened: false,
		loading: true,
	}

	async componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

	// set menu area so we can determine if a user clicked outside the menu, so we can close it if open
	setDrawerMenuArea(node) {
    this.drawerMenuArea = node;
  }

	// clicking outside of the menu when it's open will close the menu
	handleClickOutside(event) {
    if (this.drawerMenuArea && !this.drawerMenuArea.contains(event.target)) {
			// a mouseclick happened outside the menu area, so close the menu if it's open
			if(this.state.isMenuDrawerOpen) {
				this.menuDrawerToggle();
			}
    }
  }

	menuDrawerToggle(forceClose=false) {
		if(!this.state.hasMenuDrawerOpened && forceClose === false) {
			this.setState({hasMenuDrawerOpened: true});
		}
		if(this.state.isMenuDrawerOpen || forceClose === false) {
			this.setState({isMenuDrawerOpen:forceClose ? false : !this.state.isMenuDrawerOpen});
		}
	}

	handleSearchTermChange(e) {
		this.setState({menuSearchTerm:e.target.value})
	}

	handleSearchSubmit(e) {
		e.preventDefault();

		// encoding and decoding handles space characters (and others)
		const menuSearchTermEncoded = encodeURIComponent(this.state.menuSearchTerm);

		// navigate to search page with term in url params
		this.props.screenProps.history.push({
		  pathname: '/search',
		  search: '?term='+menuSearchTermEncoded,
		});

		// close menu
		this.setState({menuSearchTerm:''});
		if(this.state.isMenuDrawerOpen) {
			this.menuDrawerToggle();
		}
	}

	// opens the feedback modal, passing the default category and question
	openFeedbackModal() {
		this.menuDrawerToggle();
		let userPosition = getDefaultPosition(this.props.contentCategories,this.props.userData.position);
		this.popUpModal.current.openModal('feedback',userPosition.category,userPosition.question);
	}

	// save the feedback in the modal, called from PopUpModal.js
	saveFeedback(categoryId,questionId,feedbackText) {
		// build feedback object
		if(!feedbackText) {
			alert("You haven't entered any feedback.");
			return;
		}

		// get a feedback id by forming an array of ids and finding the highest one and then incrementing
		let feedbackIds = [];
		let feedbackId;
		let storeFeedback = this.props.userData.feedback;
		if(Object.keys(storeFeedback).length === 0) {
			feedbackId = 1;
		} else {
			Object.keys(storeFeedback).forEach(function(key) {
				let feedback = storeFeedback[key];
				feedbackIds.push(parseInt(feedback.id));
			});
			feedbackId = Math.max.apply(null,feedbackIds) + 1;
		}

		// build new feedback
		let feedback = {
			id: feedbackId.toString(),
			categoryId: categoryId,
			questionId: questionId,
			text: feedbackText,
			deleted: false,
			timestamp: getCurrentTimestampStringUTC()
		}

		// call redux store action to save feedback to userData which also updates db
		let updatedUserData = Object.assign({},this.props.userData);
		updatedUserData.feedback[feedback.id] = feedback;
		this.props.screenProps.syncReduxAndDB(updatedUserData, 'syncFeedback');
	}

	handleNavLinkClicked(specificRoute) {
		// close menu
		this.menuDrawerToggle(true);

		// nav to requested route
		switch (specificRoute) {
			case 'logo':
				if(empty(this.props.isAppLoaded)) {
					// do nothing, app isn't loaded
				} else if(this.props.type === 'admin') {
					this.props.screenProps.history.push('/admin/dashboard');
				} else if(this.props.type === 'login') {
					// do nothing, we are on a login page
				} else if(this.props.type === 'main') {
					this.props.screenProps.history.push('/'+CATEGORIES_ALIAS.toLowerCase());
				}
				break;
			case 'logout':
				this.setState({hasMenuDrawerOpened: false});
				if(this.props.type === 'admin') {
					this.props.screenProps.history.push('/admin/logout');
				} else {
					this.props.screenProps.history.push('/logout');
				}
				break;
			default:

		}
	}

	render() {
		// console.log('Header.js render() this.props',this.props);
		// console.log('Header.js render() this.state',this.state);

		const isExamBuilderShowing = window.location.href.match('exambuilder');

		// if we are on the error page, we may not have this.props.userData or this.props.admin... so check for that so we don't have an error ON the error page
		let signOutNamePref = 'Sign Out';
		let signOutName = '';
		if(!empty(this.props.userData) && !empty(this.props.userData.userInfo) && !empty(this.props.userData.userInfo.firstName) && !empty(this.props.userData.userInfo.lastName)) {
			signOutNamePref = signOutNamePref + ":";
			signOutName = this.props.userData.userInfo.firstName + ' ' + this.props.userData.userInfo.lastName;
		} else if(!empty(this.props.admin) && !empty(this.props.admin.data) && !empty(this.props.admin.data.firstName) && !empty(this.props.admin.data.lastName)) {
			signOutNamePref = signOutNamePref + ":";
			signOutName = this.props.admin.data.firstName + ' ' + this.props.admin.data.lastName;
		}
		let logoImageName = DEFAULT_LOGO_FILENAME;
		if(!empty(this.props.licensing) && !empty(this.props.licensing.logoImageName)) {
			logoImageName = this.props.licensing.logoImageName;
		}

		return (
			<div className="header-container">
				<div className="header-container-2">

					<div className="header">
						<div className="header-contents">
							<div onClick={()=>this.handleNavLinkClicked('logo')}><img src={require('./../images/'+logoImageName)} className={"logo headerLogo"} alt="VESAP5 logo" /></div>
							<ul className="help-logout-container">
								{(!empty(this.props.isAppLoaded) && this.props.type === 'main') &&
									<li><Link to={'/help'}>Help</Link></li>
								}
								{(!empty(this.props.isAppLoaded) && this.props.type !== 'login') &&
									<li><div onClick={()=>this.handleNavLinkClicked('logout')}>{signOutNamePref} {signOutName}</div></li>
								}
							</ul>
						</div>
					</div>

					{!empty(this.props.isAppLoaded) && this.props.type !== 'login' && this.props.type !== 'utility' &&
					<div className={"header-items-container" + (this.state.hasMenuDrawerOpened?"":" initial") + (this.state.isMenuDrawerOpen?" display":"")} ref={this.setDrawerMenuArea}>
						<div className="header-items-container-toggle" onClick={()=>this.menuDrawerToggle()}>
							<div className="toggle-line-top toggle-line-thick"></div>
							<div className="toggle-line-arrow"></div>
							<div className="toggle-line-mid1 toggle-line-thin"></div>
							<div className="toggle-line-mid2 toggle-line-thin"></div>
							<div className="toggle-line-bottom toggle-line-thick"></div>
						</div>

						{this.props.type === 'main' &&
						<>
							<div className="header-items" onClick={()=>this.menuDrawerToggle()}>
								<Link to={'/'+CATEGORIES_ALIAS.toLowerCase()} className="btn3">{CATEGORIES_ALIAS}</Link>
								<Link to={'/bookmarks'} className="btn3">Bookmarks</Link>
								{/* <Link to={'/performance-summary'} className="btn3">Performance Data</Link> */}
								<div className="btn3" onClick={()=>this.openFeedbackModal()}>Feedback</div>
								{!empty(this.props.userData.userInfo.isCeEligible) &&
								<>
									<div className="btn3" onClick={()=>viewCertificate()}>Print Certificate</div>
								</>
								}
								<Link to={'/about'} className="btn3">About</Link>
								<Link to={'/help'} className="btn3">Help</Link>
							</div>
							<div className="search-container">
								<p>Search</p>
								<form onSubmit={(e) => this.handleSearchSubmit(e)}>
									<input name="header-search" className="search-field" type="text" value={this.state.menuSearchTerm} onChange={this.handleSearchTermChange} />
									<img src={require('./../images/magnifying-glass.png')} className="search-mag-glass" alt="search" onClick={(e)=>this.handleSearchSubmit(e)} />
								</form>
							</div>
						</>
						}
						{this.props.type === 'admin' &&
						<div className="header-items" onClick={()=>this.menuDrawerToggle()}>
							<Link to={'/admin/dashboard'} className="btn3" >Admin Pages</Link>
							<Link to={'/admin/exambuilder'} className="btn3" >Exam Builder</Link>
						</div>
						}
						<div onClick={()=>this.handleNavLinkClicked('logout')} className="btn3 sign-out" >{signOutNamePref}<br />{signOutName}<div></div></div>
					</div>
					}

				</div>

				<PopUpModal
					ref={this.popUpModal}
					screenProps={this.props.screenProps}
					saveFeedback={this.saveFeedback}
				/>
			</div>
		);
	}
}


// export default Header;
function mapStateToProps(state) {
	// console.log('Header.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers,
		admin: state.adminReducers
	}
}

export default connect(mapStateToProps)(Header);
