import React, { Component } from 'react'
import { connect } from 'react-redux'
import { empty, loadProjectCSS, parseUrlParams, getProjectBasename, getLoginTokenPacket, checkApiResponseErrors, handleBreakingError, hitApi, restrictionAlert, isReadOnlyAdmin, viewCertificate } from './../utility/Shared'
import HeaderSecondaryAdmin from './../common/HeaderSecondaryAdmin'
import BackButton from './../common/BackButton'
import UserFields from './../common/UserFields'
import { confirmAlert } from 'react-confirm-alert'
import LoadingScreen from './../common/LoadingScreen'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'

class UserDetail extends Component {
	constructor(props) {
		super(props);
		this.fetchPerformanceSummary = this.fetchPerformanceSummary.bind(this);
		this.handleViewCertificate = this.handleViewCertificate.bind(this);
		this.confirmReset = this.confirmReset.bind(this);
		this.confirmDelete = this.confirmDelete.bind(this);
	}

	state = {
		performanceSummary: false,
		isReadOnlyAdminUser: false,
		loading: true,
	}

	async componentWillMount() {
		// if user navigated to this url manually, need to reload licensing data
		if(empty(this.props.licensing) || empty(this.props.licensing.logoImageName)) {
			await this.props.screenProps.getLicensingData(true);
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		// if user navigated to this url manually, need to reload admin data
		if(empty(this.props.adminData) || empty(this.props.adminData.data.staticStats)) {
			await this.props.screenProps.getAdminData();
		}

		// get user to be loaded
		let urlParams = parseUrlParams();
		let foundUser = '';
		if(!empty(urlParams.uuid)) {
			this.props.adminData.data.usersList.map(function(user,idx) {
				if(user.UUID === urlParams.uuid) {
					foundUser = user;
					return;
				}
			})
		}
		if(empty(foundUser)) {
			confirmAlert({
				title: 'Select a user',
				message: "Select the user that you would like to see the details for.",
				buttons: [
					{label: 'Ok'},
				]
			});
			this.props.screenProps.history.push('/admin/user-search');
			return;
		} else {
			this.user = foundUser;
		}
		
		await this.fetchPerformanceSummary();
		
		const isReadOnlyAdminUser = isReadOnlyAdmin(this.props.adminData);

		this.setState({isReadOnlyAdminUser,loading: false});
	}
	
	handleViewCertificate() {
		let infoFromAdmin = {
			userUuid: this.user.UUID,
			projectUuid: this.props.licensing.projectUuid,
		}
		viewCertificate(true,infoFromAdmin);
	}

	confirmReset() {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		confirmAlert({
			title: 'Are you sure?',
			message: "This will clear ALL of the user's exam data and reset them to a brand new user!",
			buttons: [
				{label: 'Cancel'},
				{label: 'Yes, reset user!', onClick: () => this.resetOrDeleteUserApi('adminResetUser')},
			]
		});
	}

	confirmDelete() {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		confirmAlert({
			title: 'Woah, are you sure?!',
			message: "This will delete ALL records attached to this user, as well as the user itself!",
			buttons: [
				{label: 'Cancel'},
				{label: "I'm sure!", onClick: () => this.resetOrDeleteUserApi('adminDeleteUser')},
			]
		});
	}

	resetOrDeleteUserApi(apiOperation='') {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		if(apiOperation != 'adminResetUser' && apiOperation != 'adminDeleteUser') {
			console.log('ERROR: Invalid apiOperation of '+apiOperation+' passed to resetOrDeleteUserApi()');
			confirmAlert({
				title: 'Something went wrong',
				message: 'Invalid apiOperation of '+apiOperation+' passed to resetOrDeleteUserApi()',
				buttons: [
					{label: 'Ok'},
				]
			});
			return;
		}

		// we don't have login creds so use login token stored in localStorage
		let loginPacket = getLoginTokenPacket(true);
		loginPacket.userToUpdate = {
			uuid: this.user.UUID,
			lmsId: this.user.LmsID
		}

		return fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action='+apiOperation, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(loginPacket)
		})
		.then((response) => response.json())
		.then((resetOrDeleteUserApiResponse) => {
// console.log('resetOrDeleteUserApiResponse',resetOrDeleteUserApiResponse);
			// check for errors so we don't assign an error message to redux
			checkApiResponseErrors(resetOrDeleteUserApiResponse);

			// save to redux
			this.props.screenProps.setAdminDataToRedux(resetOrDeleteUserApiResponse.adminData);

			// redirect to the user search page if user is deleted
			if(resetOrDeleteUserApiResponse.deletionStatus === 'success') {
				this.props.screenProps.history.push('/admin/user-search');
				confirmAlert({
					message: 'User has been deleted successfully!',
					buttons: [{label: 'Ok'}]
				});
			} else if(resetOrDeleteUserApiResponse.resetStatus === 'success') {
				confirmAlert({
					title: 'User has been reset successfully!',
					message: 'All of their project data has been erased and they can now login as a brand new user.',
					buttons: [
						{label: 'Ok'},
					]
				});
			}

		})
		.catch((error) => {
			console.log('ERROR UserDetail.js resetOrDeleteUserApi(): ', error);
			handleBreakingError('resetOrDeleteUserApi',error,loginPacket,true);
			throw new Error('Stop script execution with this uncaught exception.');
		});
	}
	
	async fetchPerformanceSummary() {
		// hit API for fellows report
		let payload = getLoginTokenPacket(true);
		payload.userUuid = this.user.UUID;
		let response = await hitApi('getUserPerformanceSummary',payload);
		this.setState({performanceSummary: response.userPerformanceSummary});
	}

  render() {
		// console.log('UserDetail.js render() this.props',this.props);

		if(this.state.loading) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText="Loading user details..."/>
			)
		}

		const fields = this.state.fields;
		let categoryStatsLearning = [];
		let totalQuestionsLearning = 0;
		let totalAnsweredLearning = 0;
		let totalCorrectLearning = 0;
		let categoryStatsExam = [];
		let totalQuestionsExam = 0;
		let totalAnsweredExam = 0;
		let totalCorrectExam = 0;
		if(!empty(this.state.performanceSummary)) {
			// get learning perf stats
			for (const key of Object.keys(this.state.performanceSummary.Learning)) {
				const categoryStats =  this.state.performanceSummary.Learning[key];
				
				const categoryDisplay = {
					id: categoryStats.categoryId,
					displayNum: key,
					name: categoryStats.categoryName,
					questionCount: categoryStats.totalQuestionsCount,
					numberAnswered: categoryStats.numberAnswered,
					numberCorrect: categoryStats.numberCorrect
				}
				categoryStatsLearning.push(categoryDisplay);
				totalQuestionsLearning += categoryStats.totalQuestionsCount;
				totalAnsweredLearning += categoryStats.numberAnswered;
				totalCorrectLearning += categoryStats.numberCorrect;
			}
			
			// get exam perf stats
			for (const key of Object.keys(this.state.performanceSummary.Exam)) {
				const categoryStats =  this.state.performanceSummary.Exam[key];
				
				const categoryDisplay = {
					id: categoryStats.categoryId,
					displayNum: key,
					name: categoryStats.categoryName,
					questionCount: categoryStats.totalQuestionsCount,
					numberAnswered: categoryStats.numberAnswered,
					numberCorrect: categoryStats.numberCorrect,
					creditClaimedDate: categoryStats.creditClaimedDate
				}
				categoryStatsExam.push(categoryDisplay);
				totalQuestionsExam += categoryStats.totalQuestionsCount;
				totalAnsweredExam += categoryStats.numberAnswered;
				totalCorrectExam += categoryStats.numberCorrect;
			}
		}

    return (<>
			<div className="container container-admin user-detail-page">
				<HeaderSecondaryAdmin history={this.props.screenProps.history} />

				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">
							<div className="back-button-container">
								<BackButton history={this.props.screenProps.history} />
							</div>

							<h1>User Detail</h1>

							<UserFields userUuid={this.user.UUID} screenProps={this.props.screenProps} page="user-detail" />

							<hr />
							
							<p className="info-text">Activity Detail</p>

							{!empty(this.state.performanceSummary) ? (<>
								<label className="bold-text mgn-bottom-15">Learning Mode</label>
								<Table className="responsive-table performance-summary">
									<Thead>
										<Tr>
											<Th>Category</Th>
											<Th>Total Questions</Th>
											<Th>Number Answered</Th>
											<Th>Number Correct</Th>
											<Th>Percent Correct</Th>
										</Tr>
									</Thead>
									<Tbody>
										{
											categoryStatsLearning.map(function (category, idx) {
												return (
													<Tr key={category.id}>
														<Td>{category.name}</Td>
														<Td>{category.questionCount}</Td>
														<Td>{String(category.numberAnswered)}</Td>
														<Td>{String(category.numberCorrect)}</Td>
														<Td>{(String(category.numberAnswered) !== "0") ? String(Math.floor(category.numberCorrect/category.numberAnswered*100)) + "%" : "N/A"}</Td>
													</Tr>
												)
											})
										}
										<Tr>
											<Td>All Categories</Td>
											<Td>{totalQuestionsLearning}</Td>
											<Td>{String(totalAnsweredLearning)}</Td>
											<Td>{String(totalCorrectLearning)}</Td>
											<Td>{((totalAnsweredLearning>0) ? (Math.floor(totalCorrectLearning/totalAnsweredLearning*100) + "%") : "N/A")}</Td>
										</Tr>
									</Tbody>
								</Table>
								
								<br /><br />
								
								<label className="bold-text mgn-bottom-15">Exam Mode</label>
								<Table className="responsive-table performance-summary">
									<Thead>
										<Tr>
											<Th>Category</Th>
											<Th>Total Questions</Th>
											<Th>Number Answered</Th>
											<Th>Number Correct</Th>
											<Th>Percent Correct</Th>
										</Tr>
									</Thead>
									<Tbody>
										{
											categoryStatsExam.map(function (category, idx) {
												return (
													<Tr key={category.id}>
														<Td>{category.name}{!empty(category.creditClaimedDate) ? <span className="module-action-text">{'CREDIT CLAIMED: ' + category.creditClaimedDate}</span> : ''}</Td>
														<Td>{category.questionCount}</Td>
														<Td>{String(category.numberAnswered)}</Td>
														<Td>{String(category.numberCorrect)}</Td>
														<Td>{(String(category.numberAnswered) !== "0") ? String(Math.floor(category.numberCorrect/category.numberAnswered*100)) + "%" : "N/A"}</Td>
													</Tr>
												)
											})
										}
										<Tr>
											<Td>All Categories</Td>
											<Td>{totalQuestionsExam}</Td>
											<Td>{String(totalAnsweredExam)}</Td>
											<Td>{String(totalCorrectExam)}</Td>
											<Td>{((totalAnsweredExam>0) ? (Math.floor(totalCorrectExam/totalAnsweredExam*100) + "%") : "N/A")}</Td>
										</Tr>
									</Tbody>
								</Table>
								
							</>):(
								<div className="btn5" onClick={this.fetchPerformanceSummary}>
									<div>Fetch Performance Summary</div>
								</div>
							)}
							
							<hr />
							
							<div className="btn5" onClick={this.handleViewCertificate}><div>View Certificate</div></div>
							<br /><br />
							
							<hr />

							<p className="info-text">Drastic actions:</p>

							<div className="btn5 btn5-warning" onClick={this.confirmReset}><div>CLEAR EXAM DATA</div></div>
							<br /><br />
							<div className="btn5 btn5-danger" onClick={this.confirmDelete}><div>DELETE USER!</div></div>

						</div/* className="page-content"*/>
					</div/* className="page-content-outer-layer"*/>
				</div/* className="page-content-inner-layer"*/>

			</div>
    </>);
  }
}

const mapStateToProps = (state) => {
	// console.log('UserDetail.js mapStateToProps() state',state);
	return {
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers,
		adminData: state.adminReducers
	}
}

export default connect(mapStateToProps)(UserDetail);
