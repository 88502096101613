import React, { Component } from 'react'
import { connect } from 'react-redux'
import { empty } from './../utility/Shared'
import { CATEGORY_ALIAS, CATEGORIES_ALIAS } from './../utility/Constants'

import { FaChevronLeft } from 'react-icons/fa'

class BackButton extends Component {

	state = {
		label: this.props.label,
	}

	componentDidMount() {
		this.setButtonText();
	}

	setButtonText() {
		if(!this.state.label) {
			this.setState({label: 'Back'});
		}
	}

	navigateBack() {
		switch (this.state.label) {
			case CATEGORY_ALIAS:
				// // if the Item screen was navigated to by the bookmarks, we still want the back button to go to the Landing page (for consistency since goBack() sometimes would bring a user back to the bookmarks and sometimes back to the Landing screen depending on which was opened first)
				if(!empty(this.props.userData.position.category)) {
					this.props.history.push('/landing?cid='+this.props.userData.position.category.id)
				} else {
					this.props.history.push('/landing');
				}
				break;
			case CATEGORIES_ALIAS:
				// bookmark nuances require this to be explicit rather than just goBack()
				this.props.history.push('/'+CATEGORIES_ALIAS.toLowerCase());
				break;
			default:
				this.props.history.goBack();
		}
	}


  render() {
		// console.log('BackButton.js render() this.props',this.props);

    return (
			<div className="icon-left-container back-button" onClick={this.navigateBack.bind(this)}>
				<div className="flex-row">
					<FaChevronLeft className="back-button-chevron" />
					<div className="back-button-text">{this.state.label}</div>
				</div>
			</div>
    );
  }
}


const mapStateToProps = (state) => {
	// console.log('BackButton.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		licensing: state.licensingReducers
	}
}

export default connect(mapStateToProps)(BackButton);
