import React, { Component } from 'react'
import { empty } from './../utility/Shared'
import Modal from 'react-modal'
import ReactPlayer from 'react-player'

const customModalStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
		zIndex								:	'10000'
  }
};


class PopUpModal extends Component {
	constructor() {
	  super();
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleBookmarkNameChange = this.handleBookmarkNameChange.bind(this);
    this.handleBookmarkNotesChange = this.handleBookmarkNotesChange.bind(this);
    this.handleFeedbackTextChange = this.handleFeedbackTextChange.bind(this);
  }

	state = {
		contentType: false,
		category: false,
		item: false,
		existingBookmark: false,
		bookmarkName: '',
		bookmarkNotes: '',
		feedbackText: '',
		modalIsOpen: false,
	}

	componentDidMount() {
		Modal.setAppElement('body');
	}

	// set the modal variables to be displayed and open modal
  openModal(contentType,category,item,existingBookmark=false) {
		let bookmarkName = '';
		let bookmarkNotes = '';
		if(contentType === 'bookmark') {
			if(!empty(existingBookmark)) {
				bookmarkName = existingBookmark.name;
				bookmarkNotes = existingBookmark.notes;
			} else {
				// use default. if in a category with parts, will follow the category number with the letter (e.g. 10B)
				let categoryNumber = category.number;
				if(!empty(category.parentCategory)) {
					const letterMap = ['A','B','C','D'];
					const partNumber = category.displayNum.split('.')[1];
					categoryNumber = category.number + letterMap[partNumber-1];
				}
				bookmarkName = "Module " + categoryNumber + ", Item " + item.number;
			}
		}
    this.setState({modalIsOpen:true,contentType,category,item,bookmarkName,bookmarkNotes,existingBookmark});
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  closeModal() {
    this.setState({modalIsOpen: false, feedbackText:''});
  }

	handleBookmarkNameChange(e) {
		this.setState({bookmarkName: e.target.value});
	}

	handleBookmarkNotesChange(e) {
		this.setState({bookmarkNotes: e.target.value});
	}

	handleFeedbackTextChange(e) {
		this.setState({feedbackText: e.target.value});
	}

	handleSaveFeedback(e) {
		e.preventDefault();
		this.closeModal();
		this.props.saveFeedback(this.state.category.id,this.state.item.id,this.state.feedbackText);
	}

	handleSaveBookmark(e) {
		e.preventDefault();
		this.closeModal();
		this.props.saveBookmark(this.props.screenProps,this.props.contentCategories,this.props.userData,this.state.category.id,this.state.item.id,this.state.bookmarkName,this.state.bookmarkNotes,this.state.existingBookmark);
	}

	navToScreen(navScreen) {
		this.closeModal();
		this.props.screenProps.history.push('/'+navScreen);
	}

	renderContent() {
		switch (this.state.contentType) {
			case 'bookmark':
				return (
					<>
						<div className="header-text">{empty(this.state.existingBookmark) ? 'Create a Bookmark' : 'Edit Bookmark'}</div>
						<p>Complete the fields below and click Save Bookmark, then close this window or visit the <span className="link" onClick={()=>this.navToScreen('bookmarks')}>Bookmarks screen</span> to view or edit saved Bookmarks.</p>
						<form onSubmit={(e) => this.handleSaveBookmark(e)}>
							<div className="flex-col">
								<div className="semibold-text">Bookmark name:</div>
								<input className="input-box" type="text" value={this.state.bookmarkName} onChange={this.handleBookmarkNameChange} />
								<div className="semibold-text">Private notes for this item:</div>
								<textarea className="input-box" type="text" value={this.state.bookmarkNotes} onChange={this.handleBookmarkNotesChange} rows="3"/>
								<input className="btn5" type="submit" value="Save Bookmark" />
							</div>
						</form>
					</>
				)
				break;
			case 'feedback':
				return (
					<div className="feedback-modal-container">
						<div className="header-text">Offer feedback on this item</div>
						<p>Please type your comments and feedback below, then click "Send Feedback".</p>
						<div className="semibold-text">Module {this.state.category.number}, Item {this.state.item.number}</div>
						<form onSubmit={(e) => this.handleSaveFeedback(e)}>
							<div className="flex-col">
								<textarea className="input-box" type="text" value={this.state.feedbackText} onChange={this.handleFeedbackTextChange} rows="3"/>
								<input className="btn5" type="submit" value="Send Feedback" />
							</div>
						</form>
					</div>
				)
				break;
			default:

		}
	}

  render() {
		// console.log('PopUpModal.js render() this.props',this.props);

		const modalIsOpen = this.state.modalIsOpen;
		const openModal = this.openModal.bind(this);
		const afterOpenModal = this.afterOpenModal.bind(this);
		const closeModal = this.closeModal.bind(this);

    return (
				<Modal
					isOpen={modalIsOpen}
					onAfterOpen={afterOpenModal}
					onRequestClose={closeModal}
					style={customModalStyles}
				>
					<div className="pop-up-modal-container">
						<img src={require('../images/close-x.png')} className="modal-close-icon" onClick={closeModal} />
						{this.renderContent()}
					</div>
				</Modal>
    );
  }
}


export default PopUpModal;
