import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import allReducers from './reducers';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { getProjectBasename } from './utility/Shared'
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
unregister();


// log redux store changes in development
const loggerMiddleware = createLogger({ predicate: (getState, action) => process.env.NODE_ENV === `development` });

// get project root directory (otherwise, react will assume it's the server root)
const basename = getProjectBasename();
const history = createBrowserHistory({ basename: '/' });

const store = createStore(
	connectRouter(history)(allReducers),
	{},
	compose(
		applyMiddleware(
			routerMiddleware(history),
			// loggerMiddleware, // comment in for redux logging
			thunk,
		)
	)
);

// store.subscribe(() => console.log('index.js scribed store.getState()',store.getState()));

// console.log('index.js store',store);

class AppContainer extends React.Component {
	render() {
		// console.log('index.js render() this.props',this.props);
		return (
			<Provider store={store}>
				<App history={history} />
			</Provider>
		)
	}
}


ReactDOM.render(<AppContainer />, document.getElementById('root'));
// registerServiceWorker();
