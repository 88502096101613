import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { empty, getLoginTokenPacket, hitApi, parseUrlParams, viewCertificateVESAP4, loadProjectCSS } from './../utility/Shared'
import BackButton from './../common/BackButton'
import HeaderSecondaryAdmin from './../common/HeaderSecondaryAdmin'
import LoadingScreen from './../common/LoadingScreen'
import { Tween } from "react-gsap";

class ErrorPage extends Component {
	constructor(props) {
		super(props);
		this.handleCustomerNoChange = this.handleCustomerNoChange.bind(this);
		this.handleGetVESAP4Certificates = this.handleGetVESAP4Certificates.bind(this);
		this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
		this.handleLastNameChange = this.handleLastNameChange.bind(this);
		this.handleSuffixChange = this.handleSuffixChange.bind(this);
		this.getUserById = this.getUserById.bind(this);
	}

	state = {
		cmeUserCustomerNo: '',
		cmeUserFirstName: '',
		cmeUserLastName: '',
		cmeUserSuffix: '',
		foundUser: false,
		loading: true,
	}

	async componentWillMount() {
		// if user navigated to this url manually, need to reload licensing data
		if(empty(this.props.licensing) || empty(this.props.licensing.logoImageName)) {
			await this.props.screenProps.getLicensingData(true);
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		// if user navigated to this url manually, need to reload admin data
		if(empty(this.props.adminData) || empty(this.props.adminData.data.staticStats)) {
			await this.props.screenProps.getAdminData();
		}

		this.setState({loading: false});
	}
	
	handleCustomerNoChange(e) {
		const cmeUserCustomerNo = e.target.value;

		this.setState({cmeUserCustomerNo});
	}
	handleFirstNameChange(e) {
		const cmeUserFirstName = e.target.value;

		this.setState({cmeUserFirstName});
	}
	handleLastNameChange(e) {
		const cmeUserLastName = e.target.value;

		this.setState({cmeUserLastName});
	}
	handleSuffixChange(e) {
		const cmeUserSuffix = e.target.value;

		this.setState({cmeUserSuffix});
	}
	getUserById() {
		let foundUser = false;
		for (const key of Object.keys(this.props.adminData.data.VESAP4Users)) {
			const user =  this.props.adminData.data.VESAP4Users[key];
			if(user.CMEUserCustomerNo === this.state.cmeUserCustomerNo) {
				foundUser = user;
				break;
			}
		} // end for
		
		if(!empty(foundUser)) {
			this.setState({foundUser,cmeUserFirstName:foundUser.CMEUserFname,cmeUserLastName:foundUser.CMEUserLname,cmeUserSuffix:foundUser.CMEUserSuffix});
		} else {
			alert('No user with that Customer ID was found.');
		}
	}
	
	async handleGetVESAP4Certificates() {
		if(empty(this.state.foundUser)) {
			alert('Enter a Customer ID to print a certificate for.');
			return;
		}
		// hit API
		let payload = getLoginTokenPacket(true);
		payload.user = {
			cmeUserCustomerNo: this.state.cmeUserCustomerNo,
			displayFirstName: this.state.cmeUserFirstName,
			displayLastName: this.state.cmeUserLastName,
			displaySuffix: this.state.cmeUserSuffix
		}

		viewCertificateVESAP4(payload);
	}

  render() {
		// console.log('VESAP4Certificates.js render() this.props',this.props);

		if(this.state.loading) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText="Loading VESAP4 Users..."/>
			)
		}

		return (<>
		<div className="container container-admin error-page">
			<HeaderSecondaryAdmin history={this.props.screenProps.history} />

			<div className="page-content">
				<div className="page-content-outer-layer">
					<div className="page-content-inner-layer">
						
						<div className="back-button-container">
							<BackButton history={this.props.screenProps.history} />
						</div>

						<h1>VESAP4 Certificates</h1>
						<div className="form-container vesap4-admin-form flex-start-cross">
							<div className="flex-row">
								<input className="input-box" type="text" value={this.state.cmeUserCustomerNo} onChange={this.handleCustomerNoChange} placeholder="Customer ID"/>
							</div>
							<div className="btn5" onClick={this.getUserById}><div>Get VESAP4 User</div></div>
						</div>
						
						{!empty(this.state.foundUser) &&
						<div>
							<hr />
							
							<p>The certificate will print with the first name, last name, and suffix below. You may change them if desired.</p>
							<div className="form-container vesap4-admin-form flex-start-cross">
								<div className="flex-row">
									<input className="input-box" type="text" value={this.state.cmeUserFirstName} onChange={this.handleFirstNameChange} placeholder="First Name"/>
									<input className="input-box" type="text" value={this.state.cmeUserLastName} onChange={this.handleLastNameChange} placeholder="Last Name"/>
									<input className="input-box" type="text" value={this.state.cmeUserSuffix} onChange={this.handleSuffixChange} placeholder="Suffix (e.g. MD)"/>
								</div>
								<div className={!empty(this.state.foundUser)?"btn5":"btn5 btn5-inactive"} onClick={this.handleGetVESAP4Certificates}><div>Download VESAP4 Certificate</div></div>
							</div>
						</div>
						}
					</div>
				</div>
			</div>
		</div>
		</>);

  }
}

const mapStateToProps = (state) => {
	// console.log('ErrorPage.js mapStateToProps() state',state);
	return {
		licensing: state.licensingReducers,
		adminData: state.adminReducers
	}
}

export default connect(mapStateToProps)(ErrorPage);
