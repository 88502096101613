import React, { Component } from 'react'
import { empty,form_checkbox_qs,form_radio_qs,form_text_qs,form_textarea_qs,form_select_qs,objToArr } from './../../utility/Shared'

class EvalShared extends Component {
	constructor(props) {
		super(props);
	}

	state = {
	}

	componentDidMount() {
	}


  render() {
		// console.log('EvalShared.js render() this.props',this.props);
		// console.log('EvalShared.js render() this.state',this.state);

		if(this.state.loading) {
			return(null);
		}
	
	let $EvalData = this.props.EvalData;
	let $cid = this.props.cid;
	let $qn = this.props.qn;
	
	let _this = this;
	
    return (<>
		<div className="eval-form-section">
		<h3><span>2</span> Evaluate the topic</h3>
		
		<p>Please evaluate the topic area covered in VESAP5 by selecting the appropriate number (1=Not at all to 5=Very).</p>
		<ul className="bullet-none">
		<li>{form_radio_qs($EvalData,$cid,($qn+0),'qs1','q4',this.props.handleFieldChange)}</li>
		<li>{form_radio_qs($EvalData,$cid,($qn+1),'qs2','q5',this.props.handleFieldChange)}</li>
		<li>{form_radio_qs($EvalData,$cid,($qn+2),'qs3','q6',this.props.handleFieldChange)}</li>
		</ul>
		</div/*  className="eval-form-section" */>
		
		<div className="eval-form-section">
		<h3><span>3</span> Overall Effectiveness of the Educational Activity</h3>
		
		<ol>
		
		<li className="form-stack">
		{/* form_checkbox_qs($EvalData,$cid,($qn+3),'qs4','q7')*/}
		<div>
			<input type="hidden" name={'UserEvalResponses[EvalQuestions]['+($qn+3)+'][EvalQuestionsID]'} id="" value={$EvalData['questions']['c'+$cid+'-q7']['EvalQuestions.EvalQuestionsID']} />
			<label>{$EvalData['questions_shared']['qs4']['EvalQuestionsShared.Text']}</label>
			<span>
			{objToArr($EvalData['questions_shared']['qs4']['answers_shared']).map(function($v,$i){
				//console.log('$i:',$i);console.log('$v:',$v);
				let $text = $v['EvalAnswersShared.Text'];
				let $value = $EvalData['questions']['c'+$cid+'-q7']['answers']['c'+$cid+'-q7-a'+($i+1)]['EvalAnswers.EvalAnswersID'];
				let $name = 'UserEvalResponses[EvalQuestions]['+($qn+3)+'][EvalAnswersID]['+$i+']';
				let $classname = 'UserEvalResponses-EvalQuestions-'+($qn+3)+'-EvalAnswersID';
				if ($v['EvalAnswersShared.Value'] !== '') {
					return(<React.Fragment key={$i}>
						<label>
						<input onChange={_this.props.handleFieldChange} type="checkbox" title="" name={$name} className={$classname} id="" value={$value} />
						<hp-innerhtml dangerouslySetInnerHTML={{ __html:$text}} />
						</label>
					</React.Fragment>)
				}// /if ($v['EvalAnswersShared.Value'] !== '') {/
				if ($v['EvalAnswersShared.Value'] === '') {
					$name = 'UserEvalResponses[EvalQuestions]['+($qn+3)+'][EvalAnswersID][EvalAnswersIDValue]['+$value+'][value]';
					return(<React.Fragment key={$i}>
						<label dangerouslySetInnerHTML={{ __html:$text}} />
						<textarea onBlur={_this.props.handleFieldChange} data-validate="checkbox" title="" name={$name} className={$classname} id="" defaultValue="" cols="20" rows="5" />
					</React.Fragment>)
				}// /if ($v['EvalAnswersShared.Value'] !== '') {
			})}
			</span>
		</div>
		</li>
		
		<li>
		{form_textarea_qs($EvalData,$cid,($qn+4),'qs5','q8',this.props.handleFieldChange)}
		</li>
		
		<li className="form-stack">
		{form_checkbox_qs($EvalData,$cid,($qn+5),'qs6','q9',3,this.props.handleFieldChange)}
		</li>
		
		<li>
		{form_radio_qs($EvalData,$cid,($qn+6),'qs7','q10',this.props.handleFieldChange)}
		</li>
		
		<li>
		{form_radio_qs($EvalData,$cid,($qn+7),'qs8','q11',this.props.handleFieldChange)}
		</li>
		
		<li>
		{/* form_radio_qs(($EvalData,$cid,$qn+8),'qs9','q12')*/}
		<div>
			<input type="hidden" name={'UserEvalResponses[EvalQuestions]['+($qn+8)+'][EvalQuestionsID]'} id="" value={$EvalData['questions']['c'+$cid+'-q12']['EvalQuestions.EvalQuestionsID']} />
			<label>{$EvalData['questions_shared']['qs9']['EvalQuestionsShared.Text']}</label>
			<span>
				<label>
				<input onClick={this.props.handleFieldChange} type="radio" title="" name={'UserEvalResponses[EvalQuestions]['+($qn+8)+'][EvalAnswersID][0]'} className={'UserEvalResponses-EvalQuestions-'+($qn+8)+'-EvalAnswersID'} id={'UserEvalResponse-EvalQuestions-'+($qn+8)+'-EvalAnswersID-yes'} value={$EvalData['questions']['c'+$cid+'-q12']['answers']['c'+$cid+'-q12-a1']['EvalAnswers.EvalAnswersID']} />
				<hp-innerhtml dangerouslySetInnerHTML={{ __html:$EvalData['questions_shared']['qs9']['answers_shared']['qs9-as1']['EvalAnswersShared.Text']}} />
				</label>
				<label>
				<input onClick={this.props.handleFieldChange} type="radio" title="" name={'UserEvalResponses[EvalQuestions]['+($qn+8)+'][EvalAnswersID][0]'} className={'UserEvalResponses-EvalQuestions-'+($qn+8)+'-EvalAnswersID'} id={'UserEvalResponse-EvalQuestions-'+($qn+8)+'-EvalAnswersID-no'} value={$EvalData['questions']['c'+$cid+'-q12']['answers']['c'+$cid+'-q12-a2']['EvalAnswers.EvalAnswersID']} />
				<hp-innerhtml dangerouslySetInnerHTML={{ __html:$EvalData['questions_shared']['qs9']['answers_shared']['qs9-as2']['EvalAnswersShared.Text']}} />
				</label>
			</span>
			<label dangerouslySetInnerHTML={{ __html:$EvalData['questions_shared']['qs9']['answers_shared']['qs9-as3']['EvalAnswersShared.Text']}} />
			<br />
			<textarea data-validate="radio" data-dependency={'UserEvalResponse-EvalQuestions-'+($qn+8)+'-EvalAnswersID-yes'} title="" name={'UserEvalResponses[EvalQuestions]['+($qn+8)+'][EvalAnswersID][EvalAnswersIDValue]['+$EvalData['questions']['c'+$cid+'-q12']['answers']['c'+$cid+'-q12-a3']['EvalAnswers.EvalAnswersID']+'][value]'} className={'UserEvalResponses-EvalQuestions-'+($qn+8)+'-EvalAnswersID'} id="" defaultValue="" cols="20" rows="5" />
		</div>
		</li>
		
		<li>
		{form_textarea_qs($EvalData,$cid,($qn+9),'qs10','q13',this.props.handleFieldChange)}
		</li>
		
		<li>
		{form_textarea_qs($EvalData,$cid,($qn+10),'qs11','q14',this.props.handleFieldChange)}
		</li>
		
		<li>
		{/* form_radio_qs($EvalData,$cid,($qn+11),'qs12','q15') */}
		<div>
			<input type="hidden" name={'UserEvalResponses[EvalQuestions]['+($qn+11)+'][EvalQuestionsID]'} id="" value={$EvalData['questions']['c'+$cid+'-q15']['EvalQuestions.EvalQuestionsID']} />
			<label>{$EvalData['questions_shared']['qs12']['EvalQuestionsShared.Text']}</label>
			<span>
				<label>
				<input onClick={this.props.handleFieldChange} type="radio" title="" name={'UserEvalResponses[EvalQuestions]['+($qn+11)+'][EvalAnswersID][0]'} className={'UserEvalResponses-EvalQuestions-'+($qn+11)+'-EvalAnswersID'} id={'UserEvalResponse-EvalQuestions-'+($qn+11)+'-EvalAnswersID-yes'} value={$EvalData['questions']['c'+$cid+'-q15']['answers']['c'+$cid+'-q15-a1']['EvalAnswers.EvalAnswersID']} />
				<hp-innerhtml dangerouslySetInnerHTML={{ __html:$EvalData['questions_shared']['qs12']['answers_shared']['qs12-as1']['EvalAnswersShared.Text']}} />
				</label>
				<label>
				<input onClick={this.props.handleFieldChange} type="radio" title="" name={'UserEvalResponses[EvalQuestions]['+($qn+11)+'][EvalAnswersID][0]'} className={'UserEvalResponses-EvalQuestions-'+($qn+11)+'-EvalAnswersID'} id={'UserEvalResponse-EvalQuestions-'+($qn+11)+'-EvalAnswersID-no'} value={$EvalData['questions']['c'+$cid+'-q15']['answers']['c'+$cid+'-q15-a2']['EvalAnswers.EvalAnswersID']} />
				<hp-innerhtml dangerouslySetInnerHTML={{ __html:$EvalData['questions_shared']['qs12']['answers_shared']['qs12-as2']['EvalAnswersShared.Text']}} />
				</label>
			</span>
			<label dangerouslySetInnerHTML={{ __html:$EvalData['questions_shared']['qs12']['answers_shared']['qs12-as3']['EvalAnswersShared.Text']}} />
			<br />
			<input type="text" data-validate="radio" data-validate-type="email" data-dependency={'UserEvalResponse-EvalQuestions-'+($qn+11)+'-EvalAnswersID-yes'} title="" name={'UserEvalResponses[EvalQuestions]['+($qn+11)+'][EvalAnswersID][EvalAnswersIDValue]['+$EvalData['questions']['c'+$cid+'-q15']['answers']['c'+$cid+'-q15-a3']['EvalAnswers.EvalAnswersID']+'][value]'} className={'UserEvalResponses-EvalQuestions-'+($qn+11)+'-EvalAnswersID'} id="" defaultValue="" size="19" />
		</div>
		</li>
		</ol>
		
		</div/*  className="eval-form-section" */>
    </>);
  }
}

export default EvalShared;
