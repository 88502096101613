/*
A list of all the actions
*/
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_CONTENT_DATA = 'SET_CONTENT_DATA';
export const SET_LICENSING_DATA = 'SET_LICENSING_DATA';
export const SET_LANDING_PAGE = 'SET_LANDING_PAGE';
export const SET_ADMIN_DATA = 'SET_ADMIN_DATA';
export const SET_DASHBOARD_INTERVAL = 'SET_DASHBOARD_INTERVAL';
export const SET_SCREEN_DIMENSIONS = 'SET_SCREEN_DIMENSIONS';
export const SET_CATEGORY_MEDIA = 'SET_CATEGORY_MEDIA';
