import React, { Component } from 'react'
import { connect } from 'react-redux'
import { empty, loadProjectCSS } from './../utility/Shared'
import LoadingScreen from './../common/LoadingScreen'
import { Tween } from "react-gsap";
import { CSSTransition, TransitionGroup } from 'react-transition-group'

class Help extends Component {

	constructor(props) {
		super(props)
		this.onSelectPage = this.onSelectPage.bind(this);
	}

	state = {
		pageCount: 14,
		currentPage: 1,
		loading: true,
	}

	async componentDidMount() {
		// if user navigated to this url manually, need to reload userData
		if(empty(this.props.userData)) {
			await this.props.screenProps.getUserDataFromDB();
        if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		this.setState({loading: false});
	}

	nextPage() {
		this.setState({currentPage:this.state.currentPage+1});
	}

	previousPage() {
		this.setState({currentPage:this.state.currentPage-1});
	}

	setCurrentPage(newCurrentPage) {
		this.setState({currentPage:newCurrentPage});
	}

	onSelectPage(event) {
		this.setState({currentPage: Number(event.target.value) + 1});
	}

	renderHelpMenu () {

		const sections = [
			"Using Help",
			"Bookmarks",
			"Modules Screens",
			"Item Screen",
			"Discussion Screen",
			"Media",
			"Offer Feedback on Individual Items",
			"About",
			"Performance Summary",
			"References",
			"Search",
			"Sign In / Welcome Screen",
			"Technical Support",
			"Username and Password"];
		const currentPage = this.state.currentPage;
		const setCurrentPage = this.setCurrentPage.bind(this);

		return (
			<Tween wrapper={<div className="menu-utility" />}
				   staggerFrom={{opacity: 0}}
				   duration={0.3}
				   stagger={0.03}
			>

				<div className="menu-title"></div>

				{/*  menu for desktop*/}
				{sections.map(function(section, idx) {
					return (
						<div className={"menu-item" + (currentPage==idx+1 ? " current-item" : "")}
							 onClick={() => {if (currentPage!=idx+1) setCurrentPage(idx+1)}}
							 key={idx}>
							<div>{section}</div>
						</div>
					)
				})}

				<select
					className="eb-dropdown"
					onChange={this.onSelectPage}
					value={currentPage-1}
				>
					{sections.map(function(section, idx) {
						return (
							<option
								value={idx}
								key={idx}>
								{section}
							</option>
						)
					})}
				</select>

			</Tween>
		);
	}

	renderHelpPage(currentPage) {
		window.scrollTo(0,0);
		switch (currentPage) {
			case 1:  // Using
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>Using Help</h3>
							<p>Select a topic in the Help Topics list to view help on that topic.</p>
						</div>
						<div className="utility-content-col-right">
							{this.renderHelpMenu()}
						</div>
					</div>
				)
				break;
			case 2:  //Bookmarks
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>Bookmarks</h3>
							<p>You can bookmark any item or discussion separately and add notes by selecting “ADD BOOKMARK” text or icon when you are viewing an item in Learning Mode. You can also view, edit or delete your bookmarks by clicking  the “BOOKMARKED” text or icon when viewing an item or by clicking “BOOKMARKS” in the pop out menu. Bookmarks are handy for keeping track of items to review later and for writing notes about items.</p>

							<p>Once the <strong>Create a Bookmark</strong> popup window opens, you can edit the bookmark name and add private notes for the item, then click the “Save Bookmark” button to add a new bookmark for the current item or discussion.</p>

							<p>From the Bookmarks screen, you can modify an existing bookmark by clicking the bookmark in the Bookmark List. You can then edit its name and notes, remove it, or jump to the item. Bookmarks are listed in order by module and item number.</p>

							<p>After an item or discussion has been bookmarked, a graphic and the word “BOOKMARKED” will appear on the Item screen next to the text that indicates the Item number.</p>
						</div>
						<div className="utility-content-col-right">
							{this.renderHelpMenu()}
						</div>
					</div>
				)
				break;
			case 3:  // Modules
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>Modules Screens</h3>
							<p>From the VESAP5 Modules menu screen, click on the name of a module, or the <img src={require("./../images/get-info-i.svg")} class="vert-align" width="22" height="22" alt=""/> icon next to a module, to launch the module. Once within a module you can click on a question, answer a question, or reset your response to that question or all questions in the module.</p>

							<p>Once you answer a question, click on Discussion to read the rationale and whether you answered correctly or incorrectly. Click next to answer the next question, or click back to retry the question.</p>

							<p>Within a module, you have the ability to view questions you have skipped or answered incorrectly, or bookmark questions you can revisit later by clicking the on the menu > bookmarks link.</p>

						</div>
						<div className="utility-content-col-right">
							{this.renderHelpMenu()}
						</div>
					</div>
				)
				break;
			case 4:  // Item
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>Question Screen</h3>
							<p>In Learning Mode, select the best answer by clicking or tapping on it. Once an answer is selected, click the Discussion tab under the question number to view the Discussion content. Once in the discussion, you have the option of clicking on the “Show” button to see the correct answer. To save your response, click the right arrow and continue to the next question or use the left arrow to go to the previous question. Click the media images to view them in a larger window. By default, images open in a single window.</p>

							<p>In Learning Mode, you can also click on the “Discussion”  tab to be taken to the discussion before selecting an answer. At this point you may return to the item to submit an answer by selecting “Question” or you may skip this item and return to it later by selecting “Next.”</p>

							<p>Click the media images to view them in a larger window. By default, images open in a single window. Click the right arrow to lock in your answer.</p>

							<p>Click on the right arrow to skip this item and return to it later.</p>

							<p>Click on “Reset Response” to change your submitted response.</p>
						</div>
						<div className="utility-content-col-right">
							{this.renderHelpMenu()}
						</div>
					</div>
				)
				break;
			case 5:  // Discussion
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>Discussion Screen</h3>
							<p>In Learning Mode, you can click the “Discussion” tab under the question number to view the discussion screen and supporting references.</p>

							<p>Clicking on the PMID numbers in the references will use your default web browser to connect to the abstract for the reference. This feature is not available if you are not connected to the Internet.</p>

							<p>To return to the corresponding item screen, click on the “Question” to the left of the “Discussion” tab.</p>
						</div>
						<div className="utility-content-col-right">
							{this.renderHelpMenu()}
						</div>
					</div>
				)
				break;
			case 6:  // Media
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>Media</h3>
							<p>Click any of the media images to view the media in a larger window. By default, images open in a single window. Click the close “X” in the upper right corner of the media image to close the media window.</p>
						</div>
						<div className="utility-content-col-right">
							{this.renderHelpMenu()}
						</div>
					</div>
				)
				break;
			case 7:  // Feedback
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>Offer Feedback on Individual Items</h3>
							<p>Select the FEEDBACK link in the pop out menu to open a small window where comments regarding specific items can be typed. The feedback will be transmitted to the SVS and will be used to help improve the program.</p>
						</div>
						<div className="utility-content-col-right">
							{this.renderHelpMenu()}
						</div>
					</div>
				)
				break;
			case 8:  // About
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>About</h3>
							<p>Select the ABOUT link in the pop out menu to access information about the program.</p>
						</div>
						<div className="utility-content-col-right">
							{this.renderHelpMenu()}
						</div>
					</div>
				)
				break;
			case 9:  // Performance
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>Performance Summary</h3>
							<p>The Performance Summary is located on the Modules screen and can be accessed by clicking a module. The Performance Summary displays the total number of questions, the number answered, and the number correct or unanswered.</p>
						</div>
						<div className="utility-content-col-right">
							{this.renderHelpMenu()}
						</div>
					</div>
				)
				break;
			case 10:  // References
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>References</h3>
							<p>Click on the PMID in the reference to open a new web browser tab and automatically link to the abstract.</p>
						</div>
						<div className="utility-content-col-right">
							{this.renderHelpMenu()}
						</div>
					</div>
				)
				break;
			case 11:  // Search
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>Search</h3>
							<p>Located in the pop out menu, you can search for terms used within VESAP5 items, discussions, references, and media elements by entering text in the Search field and clicking your computer’s “Return” or “Enter” key, or by clicking the magnifying glass icon. The Search option is useful when you know the word or phrase you are looking for, or even just part of it.</p>

							<p>From the Search results screen, you can click an item in the results list to jump to that item.</p>

						</div>
						<div className="utility-content-col-right">
							{this.renderHelpMenu()}
						</div>
					</div>
				)
				break;
			case 12:  // Welcome
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>Sign In/Welcome Screen</h3>
							<p>Once you have registered for the VESAP5 program, you will use your SVS username and password to access the program. If you cannot locate your username and password, please contact the SVS office via e-mail at <a href={"mailto:vesap@vascularsociety.org"}>vesap@vascularsociety.org</a>.</p>
						</div>
						<div className="utility-content-col-right">
							{this.renderHelpMenu()}
						</div>
					</div>
				)
				break;
			case 13:  // Support
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>Technical Support</h3>
							<p>For technical support or questions regarding VESAP5, please contact:</p>
							<p><a href={"mailto:vesap@vascularsociety.org"}>vesap@vascularsociety.org</a> </p>
						</div>
						<div className="utility-content-col-right">
							{this.renderHelpMenu()}
						</div>
					</div>
				)
				break;
			case 14:  // Password
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>Username and Password</h3>
							<p>Your Username and Password were sent to you in an email message that confirmed your purchase of VESAP5. If you cannot locate your username and password, please contact the SVS office via e-mail at:</p>
							<p><a href={"mailto:vesap@vascularsociety.org"}>vesap@vascularsociety.org</a>.</p>
						</div>
						<div className="utility-content-col-right">
							{this.renderHelpMenu()}
						</div>
					</div>
				)
				break;
			default:
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
						</div>
						<div className="utility-content-col-right">
							{this.renderHelpMenu()}
						</div>
					</div>
				)
			break;
		}
	}

  render() {
		// console.log('Help.js render() this.props',this.props);

		if(this.state.loading || empty(this.props.userData)) {
			return (
				<LoadingScreen loadingText="Help is on the way!" />
			)
		}

    return (
			<div className="container utility-page">
				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">

							<div className="utility-content">
								<TransitionGroup className={"utility-content-page"}>
									<CSSTransition
										key={this.state.currentPage}
										timeout={300}
										classNames="utilityFade">

										<div>

											{this.renderHelpPage(this.state.currentPage)}

											<div className="flex-row utility-page-buttons">

												{this.state.currentPage !== 1 &&
												<div className="btn5 mgn-right-5"
													 onClick={() => this.previousPage()}>
													<div>Previous</div>
												</div>
												}
												{this.state.currentPage !== this.state.pageCount &&
												<div className="btn5"
													 onClick={() => this.nextPage()}>
													<div>Next</div>
												</div>
												}

											</div>

										</div>

									</CSSTransition>
								</TransitionGroup>



							</div>

						</div>
					</div>
				</div>
			</div>
    );
  }
}


const mapStateToProps = (state) => {
	// console.log('Help.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		licensing: state.licensingReducers,
	}
}

export default connect(mapStateToProps)(Help);
