import { SET_USER_DATA } from './actionTypes'
import { setContentToRedux } from './contentActions'
import { setLicensingDataToRedux } from './licensingActions'
import { setCategoryMediaToRedux } from './mediaActions'
import { getLoginTokenPacket, empty, getCurrentTimestampStringUTC, handleBreakingError, checkApiResponseErrors, isCategoryPurchased, routeUser, getProjectBasename, getItemShuffleOrder, handleCacheBusting } from './../utility/Shared'
import { confirmAlert } from 'react-confirm-alert'

// gets userData object from api db and loads into redux
export const getUserDataFromDB = () => {
	// console.log('actions/userDataActions.js getUserDataFromDB() called');

	// we don't have login creds so use login token stored in localStorage
	const loginPacket = getLoginTokenPacket();

	return (dispatch) => {
		return fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action=getUserData', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(loginPacket)
		})
		.then((response) => response.json())
		.then((getUserDataResponse) => {
// console.log('getUserDataResponse',getUserDataResponse);

			// check for errors so we don't assign an error message to redux
			checkApiResponseErrors(getUserDataResponse);

			// update redux with api syncing update
			dispatch(setUserDataToRedux(getUserDataResponse.userData));
			dispatch(setContentToRedux(getUserDataResponse.contentCategories));
			dispatch(setLicensingDataToRedux(getUserDataResponse.licensing));
			if(!empty(getUserDataResponse.userData.position.category)) {
				dispatch(setCategoryMediaToRedux(Object.assign({},getUserDataResponse.userData.position.category)));
			}
		})
		.catch((error) => {
			console.log('ERROR userDataActions.js getUserDataFromDB(): ', error);
			handleBreakingError('getUserDataFromDB',error,loginPacket);
			throw new Error('Stop script execution with this uncaught exception.');
		});
	}
}

// our syncing function! ok actually it's just a thunk action creator for syncing. first updates the redux state with the passed in userData object. then we return a Promise.resolve() within the thunk function because we want the component to be updated for the new redux state WHILE we are dispatching syncUserDataToDB(). this keeps the user from having to wait for syncing to finish making the UI fast! (they would have to wait if I just dispatched setUserDataToRedux() and syncUserDataToDB() one after another). this took forever to figure out and seems like it's cheating but it works wonderfully.
export const syncReduxAndDB = (userData, syncAction='syncActivity') => {

	return(dispatch) => {
		// update redux state with userData object
		dispatch(setUserDataToRedux(userData));

		// update database but asynchronously so that UI is fast. return a promise so that component updates while we are syncing with db
		return Promise.resolve().then(function() {
			dispatch(syncUserDataToDB(userData,syncAction));
		});
	}

}

// updates redux user position and then updates db asynchronously
export const setUserPosition = (userData, category, question) => {
// console.log('userDataActions.js setUserPosition() called.');

	// category will be false when the user is on the categories page. otherwise they are either on the landing or item pages.
	// first check to see if we need to preload media images for the category into redux (since we do that anytime a user switches to a category so the media thumbnails and modals load fast). so determine if category position is changing so we can load/erase images as needed
	let updateCategoryMedia = false;
	if(!(empty(category) && empty(userData.position.category)) && (((empty(category) && !empty(userData.position.category)) || (!empty(category) && (empty(userData.position.category)) || category.id !== userData.position.category.id)))) {
		// update to category media needed since: previous and updated categories are not both already empty and they are not the same
		updateCategoryMedia = true;
	}

	// assign the category to position
	if(empty(category)) {
		userData.position.category = false;
	} else {
		// make sure user purchased this category. checks for this should have already taken place but if one was missed, bring sly mr.url-entering user to error page.
		let isPurchased = isCategoryPurchased(userData,category.id);
		if(empty(isPurchased)) {
			// we somehow missed checking for the user getting to an unpurchased category so throw error page
			return(dispatch) => {
				let error = 'User tried to navigate to unpurchased category ' + category.name + '. The stringified userData was: ' + JSON.stringify(userData);
				handleBreakingError('setUserPosition',error);
				throw new Error('Stop script execution with this uncaught exception.');
			}
		}

		// set the new category. get the exam mode shuffle order for each question too
		category.questions.forEach(function(question,idx) {
			question.shuffleOrder = getItemShuffleOrder(userData,category.id,question.id);
		})
		userData.position.category = category;
	}
	// question will be false when the user is on the landing (or category) page. otherwise they are on an item page
	if(empty(question)) {
		userData.position.item = false;
	} else {
		question.shuffleOrder = getItemShuffleOrder(userData,category.id,question.id);
		userData.position.item = question;
	}

	// update timestamp to now before returning
	userData.position.timestamp = getCurrentTimestampStringUTC();

	return(dispatch) => {
		// update redux state with userData object
		dispatch(setUserDataToRedux(userData));
		if(updateCategoryMedia) {
			dispatch(setCategoryMediaToRedux(Object.assign({},category)));
		}

		// update database but asynchronously so that UI is fast. return a promise so that component updates while we are syncing with db
		return Promise.resolve().then(function() {
			dispatch(pushDataToDb('syncPosition',userData));
		});
	}

}

// updates redux user setting and then updates db asynchronously
export const setUserSetting = (settingObj,userData) => {
// console.log('userDataActions.js setUserSetting() called. settingObj',settingObj);

	// store the setting in the userInfo (for redux)
	const settingKey = Object.keys(settingObj)[0];
	const settingValue = settingObj[settingKey];
	userData.userInfo[settingKey] = settingValue;

	return(dispatch) => {
		// update redux state with userData object
		dispatch(setUserDataToRedux(userData));

		// update database but asynchronously so that UI is fast. return a promise so that component updates while we are syncing with db
		return Promise.resolve().then(function() {
			dispatch(pushDataToDb('pushUserSetting',settingObj));
		});
	}

}

// updates category current mode in redux and then updates db asynchronously
export const setCategoryMode = (userData, categoryId, mode, modeStatus) => {
// console.log('userDataActions.js setCategoryMode() called.');
	if(mode !== 'Learning' && mode !== 'Exam') {
		console.log('ERROR: mode of "'+mode+'" was sent to setCategoryMode() and is not valid.');
		return;
	}

	// determine if an update is actually happening. if not, we can skip syncing with db
	let updateNeeded = false;
	if(userData.categories[categoryId].currentMode !== mode) {
		updateNeeded = true;
		// update current mode
		userData.categories[categoryId].currentMode = mode;
		userData.categories[categoryId].modes[mode].timestamp = getCurrentTimestampStringUTC();
	}

	return(dispatch) => {
		// update redux state with userData object
		if(updateNeeded) {
			dispatch(setUserDataToRedux(userData));

			// update database but asynchronously so that UI is fast. return a promise so that component updates while we are syncing with db
			return Promise.resolve().then(function() {
				dispatch(syncUserDataToDB(userData));
			});
		} else {
			return Promise.resolve();
		}
	}

}

// updates category mode status in redux and then updates db asynchronously
export const setCategoryModeStatus = (userData, categoryId, updatedStatus) => {
// console.log('userDataActions.js setCategoryModeStatus() called.');

	// update the mode status
	let currentMode = userData.categories[categoryId].currentMode;
	userData.categories[categoryId].modes[currentMode].status = updatedStatus;
	userData.categories[categoryId].modes[currentMode].timestamp = getCurrentTimestampStringUTC();

	return(dispatch) => {
		// update redux state with userData object
		dispatch(setUserDataToRedux(userData));

		// update database but asynchronously so that UI is fast. return a promise so that component updates while we are syncing with db
		return Promise.resolve().then(function() {
			dispatch(syncUserDataToDB(userData));
		});
	}

}


// set userData object in redux store
export const setUserDataToRedux = (userData) => {
	// console.log('actions/userDataActions.js setUserDataToRedux() userData',userData);

	return {
		type: SET_USER_DATA,
		userData
	}
}


// sends userData to api to sync
export const syncUserDataToDB = (userData, syncAction='syncActivity') => {
	// console.log('syncUserDataToDB() called');

	// attach user login token
	let loginPacket = getLoginTokenPacket();
	if(syncAction !== 'syncCreditsClaimed') {
		userData.userInfo = loginPacket.userInfo;
	} else {
		// we are claiming credits, also need to pass email and suffix incase updated
		const email = userData.userInfo.email;
		const suffix = userData.userInfo.suffix;
		userData.userInfo = loginPacket.userInfo;
		userData.userInfo.email = email;
		userData.userInfo.suffix = suffix;
	}

// console.log('SYNC PACKET userData',JSON.stringify(userData));
	return(dispatch, getState) => {

		return fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action='+syncAction, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(userData)
		})
		.then((response) => response.json())
		.then((syncActivityResponse) => {
// console.log('userDataActions.js syncActivityResponse',syncActivityResponse);

			// check for errors so we don't assign an error message to redux
			checkApiResponseErrors(syncActivityResponse);

			// always keep the react positioning for a good user experience, except for welcome screens being viewed. check to see if user is a fast clicker (or syncing was slow) and clicked the Next Item button before the syncActivityResponse returned. if so, we want to set the position of the syncActivityResponse.userData to be where the user is in react to avoid a bad user experience where the user is brought back to the question that was just answered before they navigated to the next one
			let dbPosition = syncActivityResponse.userData.position;
			let reactState = getState().userDataReducers;
			if(syncActivityResponse.userData.welcomeScreensViewedTimestamp !== false && reactState.welcomeScreensViewedTimestamp === false) {
				routeUser(syncActivityResponse.userData);
			} else if(dbPosition.timestamp < reactState.position.timestamp) {
				// set syncActivityResponse.userData.position equal to the current react state position
				syncActivityResponse.userData.position = reactState.position;
			}

			// update redux with api syncing update
			dispatch(setUserDataToRedux(syncActivityResponse.userData));
		})
		.catch((error) => {
			console.log('ERROR: userDataActions.js syncUserDataToDB()', error);
			handleBreakingError('syncUserDataToDB',error,userData);
			throw new Error('Stop script execution with this uncaught exception.');
		});
	}
}

// generic function for pushing data to db. nothing returned. the payload will be different depending on the apiAction. for syncPosition, it will be an object with a category and question. for pushUserSetting it will be a setting name and value for the UserSetting table.
export const pushDataToDb = (apiAction,dataToPush) => {
	// console.log('pushDataToDb() called');

	let payload = {};
	let loginPacket = getLoginTokenPacket();
	payload.userInfo = loginPacket.userInfo;
	payload.data = dataToPush;
// console.log('pushDataToDb() payload',payload);
	return(dispatch, getState) => {

		return fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action='+apiAction, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload)
		})
		.then((response) => response.json())
		.then((pushDataToDbResponse) => {
// console.log('userDataActions.js pushDataToDbResponse',pushDataToDbResponse);

			// check for errors... actually, do we want to just fail silently?
			checkApiResponseErrors(pushDataToDbResponse);

			// check to see if cache needs to be busted
			if(apiAction === 'syncPosition') {
				handleCacheBusting();
			}
		})
		.catch((error) => {
			console.log('ERROR: userDataActions.js pushDataToDb()', error);
			handleBreakingError('pushDataToDb',error,payload);
			throw new Error('Stop script execution with this uncaught exception.');
		});
	}
}


// notify API that user is logged out. then clear out user data and categories content in redux.
export const logoutUser = (loginPacket,adminUser=false) => {
	let action = adminUser ? 'adminLogout' : 'logout';
	let logoutPacket = {
		'userInfo': loginPacket.userInfo
	}

// console.log('logoutPacket',JSON.stringify(logoutPacket));
	return (dispatch) => {
		return fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action='+action, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(logoutPacket)
		})
		.then((response) => response.json())
		.then((logoutResponse) => {
// console.log('logoutResponse',logoutResponse);

			// clear out redux user data and content. not clearing out landing page stuff because it throws errors when i try and nobody cares that much.
			if(empty(adminUser)) {
				dispatch(setUserDataToRedux({}));
			}
			dispatch(setContentToRedux({}));

		})
		.catch((error) => {
			console.log('ERROR Logout.js logoutIfNotAlready(): ', error);
		});
	}
}
