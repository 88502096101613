import { SET_LICENSING_DATA } from './actionTypes';
import { getLoginTokenPacket, handleBreakingError, checkApiResponseErrors, getProjectBasename, getAnonymousAuthPacket } from './../utility/Shared';
import { empty } from './../utility/Shared';


// sets licensingData to redux
export const setLicensingDataToRedux = (licensingData) => {
	// console.log('actions/licensingActions.js setLicensingDataToRedux() licensingData', licensingData);

	return {
		type: SET_LICENSING_DATA,
		licensingData
	}
}

// gets licensingData object from api db and loads into redux
export const getLicensingData = (adminUser=false) => {
	// console.log('actions/licensingActions.js getLicensingData() called');

	// we don't have login creds so use login token stored in localStorage
	const loginPacket = getLoginTokenPacket(adminUser);
// console.log('loginPacket',JSON.stringify(loginPacket));
	return (dispatch) => {
		return fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action=getLicensingData', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(loginPacket)
		})
		.then((response) => response.json())
		.then((getLicensingDataResponse) => {
// console.log('getLicensingDataResponse',getLicensingDataResponse);
			// check for errors so we don't assign an error message to redux
			checkApiResponseErrors(getLicensingDataResponse);

			// update redux with api syncing update
			dispatch(setLicensingDataToRedux(getLicensingDataResponse.licensing));

		})
		.catch((error) => {
			console.log('ERROR licensingActions.js getLicensingData(): ', error);
			handleBreakingError('getLicensingData',error,loginPacket,adminUser);
			throw new Error('Stop script execution with this uncaught exception.');
		});
	}
}
