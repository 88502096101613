/*
Reducer functions related to the landing page
*/
import { SET_LANDING_PAGE } from '../actions/actionTypes.js'
import { getCategoryMode, getCategoryModeStatus, getFilteredLandingItems } from '../utility/Shared'

const initialLandingState = {
	modeDisplayed: 'Learning',
	filterSelected: 'all',
	filteredItems: [],
	isAllTabVisible: true,
	isRetakeTabVisible: false,
	isUnansweredTabVisible: true,
	isIncorrectTabVisible: true
}

const landingReducers = (landingState = initialLandingState, action) => {
	// console.log('landingReducers landingState',landingState);
	// console.log('landingReducers action: ', action);

	switch (action.type) {
		case SET_LANDING_PAGE:
			let updatedLandingState = {};
			let mode = getCategoryMode(action.userData,action.userData.position.category.id);
			let examModeStatus = getCategoryModeStatus(action.userData,action.userData.position.category.id,'Exam');
			// set the filterSelected (to 'all' by default) and get the associated items for the category
			let filterSelected = action.filterSelected;
			if(!filterSelected) {
				if(mode === 'Exam' && examModeStatus === 'retake') {
					filterSelected = 'retake';
				} else {
					filterSelected = 'all';
				}
			}
			let filteredItems = getFilteredLandingItems(action.userData, filterSelected, action.modeDisplayed);

			// set tabs to visible/invisible
			if(action.modeDisplayed === 'Learning' || (action.modeDisplayed === 'Exam' && examModeStatus === 'review')) {
				updatedLandingState = {
					...landingState,
					isAllTabVisible: true,
					isUnansweredTabVisible: true,
					isIncorrectTabVisible: true,
					isRetakeTabVisible: false,
					filteredItems: filteredItems,
					filterSelected: filterSelected,
					modeDisplayed: action.modeDisplayed
				}
			} else if(action.modeDisplayed === 'Exam' && examModeStatus === 'take') {
				updatedLandingState = {
					...landingState,
					isAllTabVisible: true,
					isUnansweredTabVisible: true,
					isIncorrectTabVisible: false,
					isRetakeTabVisible: false,
					filteredItems: filteredItems,
					filterSelected: filterSelected,
					modeDisplayed: action.modeDisplayed
				}
			} else if(action.modeDisplayed === 'Exam' && examModeStatus === 'retake') {
				updatedLandingState = {
					...landingState,
					isAllTabVisible: false,
					isUnansweredTabVisible: true,
					isIncorrectTabVisible: false,
					isRetakeTabVisible: true,
					filteredItems: filteredItems,
					filterSelected: filterSelected,
					modeDisplayed: action.modeDisplayed
				}
			} else {
				console.log('ERROR: Invalid mode displayed and exam status in loadFilterTabs(). modeDisplayed was ' + action.modeDisplayed + ' and examModeStatus was ' + examModeStatus + '.');
			}

			return updatedLandingState;
		default:
			return landingState;
	}
}

export default landingReducers;
