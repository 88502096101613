import 'core-js/es/map'
import 'core-js/es/set'

import React, { Component } from 'react'
import { connect } from 'react-redux'

// embellished from https://alligator.io/react/tabs-component/

// the Tabs component keeps track of which tab is active, displays a list of tabs and the content for the active tab. it utilizes the Tab component found below this one
class Tabs extends Component {

  render() {
		let activeTab = this.props.activeTab;
		activeTab = activeTab.toLowerCase().replace(/\b[a-z]/g, function(letter) {
		    return letter.toUpperCase();
		});

    return (
      <div className="tabs">
        <ol className="tab-list">
          {this.props.children.map((child) => {
						if(!child) {
							return true;
						}
            const { label, isHighlighted } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                isHighlighted={isHighlighted}
                onClick={this.props.onClickFunction}
              />
            );
          })}
        </ol>
		  <div className="tab-underscore"></div>
        <div className="tab-content">
          {this.props.children.map((child) => {
						if (!child) return true;
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

class Tab extends Component {

	constructor(props) {
		super (props);
		this.tab = React.createRef();
	}

	componentDidMount() {
		this.moveHighlight();
	}

	componentDidUpdate() {
		this.moveHighlight();
	}


	moveHighlight() {
		if (this.props.label === this.props.activeTab) {
			const node = this.tab.current;
			const rect = node.getBoundingClientRect();
			const tabListRect = document.getElementsByClassName('tab-list')[0].getBoundingClientRect();
      document.getElementsByClassName('tab-underscore')[0].style.left = (rect.left - tabListRect.left + 5) + 'px';
			document.getElementsByClassName('tab-underscore')[0].style.width = rect.width + 'px';
		};
	}

  onClick = () => {
    const { label, isHighlighted, onClick } = this.props;
    onClick(label);
  }

  render() {
    const {
      onClick,
      props: {
        activeTab,
        label,
        isHighlighted,
      },
    } = this;

    let className = 'tab-list-item';

    if (activeTab === label) {
      className += ' tab-list-active';
    }
    if (isHighlighted) {
      className += ' tab-list-highlighted';
    }

    return (
      <li
        className={className}
        onClick={onClick}
				ref={this.tab}
      >
        {label}
      </li>
    );
  }
}

// export default Tabs;
const mapStateToProps = (state) => {
	// console.log('Tabs.js mapStateToProps() state',state);
	return {
		filterSelected: state.landingReducers.filterSelected,
		screenDimensions: state.housekeepingReducers.screenDimensions,
	}
}

export default connect(mapStateToProps)(Tabs);
