/*
All action functions
*/
import { getUserDataFromDB, setUserDataToRedux, syncReduxAndDB, setUserPosition, setUserSetting, setCategoryMode, setCategoryModeStatus, logoutUser } from './userDataActions'
import { setContentToRedux, getContentCategories, updateReduxAndDBContent } from './contentActions'
import { setLandingPage } from './landingActions'
import { setLicensingDataToRedux, getLicensingData } from './licensingActions'
import { getAdminData, setAdminDataToRedux, setDashboardInterval } from './adminActions'
import { setScreenDimensions } from './housekeepingActions'
import { setCategoryMediaToRedux } from './mediaActions'

export const ActionCreators = Object.assign({}, {
	getUserDataFromDB,
	syncReduxAndDB,
	setUserDataToRedux,
	setContentToRedux,
	getContentCategories,
	updateReduxAndDBContent,
	setLandingPage,
	setUserPosition,
	setUserSetting,
	setCategoryMode,
	setCategoryModeStatus,
	getLicensingData,
	setLicensingDataToRedux,
	getAdminData,
	setAdminDataToRedux,
	setDashboardInterval,
	setScreenDimensions,
	setCategoryMediaToRedux,
	logoutUser
});
