import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routeUser, getCurrentTimestampStringUTC, setAdminLoginSession, getLoginTokenPacket, empty, setLoginSession, getProjectBasename, objToArr, getLicensingOrgs, isProjectLive, handleCacheBusting, checkParamsForAlerts } from './../utility/Shared'
import { PARENT_PROJECT_UUID, SESSION_NAME_ADMIN_USER, DEFAULT_LOGIN_LOGO_FILENAME } from './../utility/Constants'
import Sentry from 'react-activity/lib/Sentry'
import 'react-activity/lib/Sentry/Sentry.css';
import LoadingScreen from './../common/LoadingScreen'
import Footer from "../common/Footer";



class AdminLogin extends Component {
	constructor(props) {
		super(props)

		this.md5 = require('md5');

		this.handleUsernameTextChange = this.handleUsernameTextChange.bind(this);
		this.handlePasswordTextChange = this.handlePasswordTextChange.bind(this);
		this.handleOrganizationChange = this.handleOrganizationChange.bind(this);
		this.handleAdminLogin = this.handleAdminLogin.bind(this);
	}

	state = {
		licensingOrgsDropdown: [],
		username: '',
		password: '',
		projectUuid: PARENT_PROJECT_UUID, // default set to parent
		loading: true,
	}

	async componentWillMount() {

		await this.logoutIfNotAlready();

		await handleCacheBusting(true);

		checkParamsForAlerts(true);

		// populate licensing state obj for dropdown
		let licensingOrgsDropdown = await getLicensingOrgs();

		this.setState({licensingOrgsDropdown,loading: false});
	}

	async logoutIfNotAlready() {
		let loginPacket = getLoginTokenPacket(true,true);
		if(empty(loginPacket)) {
			// user is already logged out
			return false;
		}
		this.props.screenProps.logoutUser(loginPacket);
		this.logoutLocalStorageSession();
	}

	logoutLocalStorageSession() {
		// remove login "session"
		localStorage.removeItem(SESSION_NAME_ADMIN_USER);
	}

	handleUsernameTextChange(e) {
		this.setState({username: e.target.value});
	}

	handlePasswordTextChange(e) {
		this.setState({password: e.target.value});
	}

	handleOrganizationChange(e) {
		this.setState({projectUuid: e.target.value});
	}

	// send username, passwordHash and projectUuid to hypix api to validate credentials
	handleAdminLogin(e, isGuestLogin) {
		e.preventDefault();

		// get login vars
		let username = this.state.username;
		let passwordHash = this.md5(this.state.password);
		let projectUuid = this.state.projectUuid;

		// guest login ("Test admin login" button) overwrites username to be the VESAP5 guest user (unless a different org is selected in the dropdown)
		if(isGuestLogin) {
			this.state.licensingOrgsDropdown.forEach(function(org,idx) {
				if(org.projectUuid === projectUuid) {
					username = org.guestAdminUn;
					passwordHash = org.guestAdminPw;
				}
			})
		}

		const loginPacket = {
      "userInfo": {
				"adminUser": true,
        "username": username,
        "passwordHash": passwordHash,
        "projectUuid": projectUuid
      }
    }

    if(username === '') {
      this.setState({Error: 'Please enter your username'});
    } else if(passwordHash === 'd41d8cd98f00b204e9800998ecf8427e') {
      this.setState({Error: 'Please enter your password'});
    } else {
			// show loading screen
			this.setState({loading: true});

			// call API login service
// console.log('admin loginPacket',JSON.stringify(loginPacket));
      return fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action=adminLogin', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginPacket)
      })
      .then((response) => response.json())
      .then((loginResponse) => {
// console.log('loginPacket',loginPacket);
// console.log('loginResponse',loginResponse);
// console.log('loginResponse',JSON.stringify(loginResponse));

				// display errors if any
				if(loginResponse.Error) {
					// TODO: make this more better?
					if(loginResponse.Error === "initiateAction(): VESAP5.class.php __construct(): VESAP5.model.php: __construct(): getUserAndProject(): There were no users matching those credentials.") {
						this.setState({Error: 'Invalid credentials, please try again.', username: '', password: '', loading: false});
					} else {
						this.setState({Error: 'Something went wrong. Please try again.', username: '', password: '', loading: false});
					}
					return;
				}

				// save admin data to redux
				this.props.screenProps.setAdminDataToRedux(loginResponse.adminData);

				// save categories content data to redux
				this.props.screenProps.setContentToRedux(loginResponse.contentCategories);

				// store ADMIN login info in browser local storage. before the underscore will be the logout time and after the underscore is the login token
				setLoginSession(loginResponse.loginToken,true);

				// go to the admin pages if the project has already been launched, and to the exam builder if not
				const currentTimestamp = getCurrentTimestampStringUTC();
				if(loginResponse.project.StartDate > currentTimestamp || empty(loginResponse.adminData.areAdminPagesUnlocked)) {
					// project has not launched, go to exam builder
					this.props.screenProps.history.push('/admin/exambuilder');
				} else {
					// project has launched, go to admin pages
					this.props.screenProps.history.push('/admin/dashboard');
				}

      })
      .catch((error) => {
				// remove loading screen
				this.setState({loading: false});
        console.log('ERROR Login.js handleAdminLogin(): ', error)
      });
    }

	}


  render() {
		// console.log('AdminLogin.js render() this.props',this.props);

		if(this.state.loading) {
			return (
				<LoadingScreen loadingText="Maneuvering admin satellites into position..."/>
			)
		}

		let loginLogoImageFilename = DEFAULT_LOGIN_LOGO_FILENAME;
		if(!empty(this.props.licensing.loginLogoImageName) && !empty(this.props.licensing.logoImageName)) {
			let useSpecificLoginLogo = false;
			try {
				require('./../images/'+this.props.licensing.loginLogoImageName);
				useSpecificLoginLogo = true;
			} catch (e) {
				useSpecificLoginLogo = false;
			}
			if(useSpecificLoginLogo) {
				loginLogoImageFilename = this.props.licensing.loginLogoImageName;
			} else {
				loginLogoImageFilename = this.props.licensing.logoImageName;
			}
		}

    return (<>
			<div className="container login-screen">
				<div className="login-form-container">
					<h2>Admin login</h2>
					{this.state.Error &&
						<p className="login-form-instructions error-field">{this.state.Error}</p>
					}
					<form onSubmit={(e) => this.handleAdminLogin(e)}>
						<div className="flex-col">
							<div className="flex-row">
								<label>User Name:</label>
								<input name="username" className="input-box" type="text" value={this.state.username} onChange={this.handleUsernameTextChange}/>
							</div>
							<div className="flex-row"><label>Password:</label>
								<input name="password" className="input-box" type="password" value={this.state.password} onChange={this.handlePasswordTextChange}/>
							</div>
							{this.state.licensingOrgsDropdown.length > 1 && (
								<select
									xclassName="input-box select-menu"
									onChange={this.handleOrganizationChange}
									value={this.state.projectUuid}
								>
									{this.state.licensingOrgsDropdown.map(function (organization, idx) {
										return (
											<option key={organization.projectUuid} value={organization.projectUuid}>{organization.name}</option>
										)
									})}
								</select>
							)}
							<input className="btn5" type="submit" value="Login"/>
						</div>
					</form>

					{empty(isProjectLive(true)) &&
						<form onSubmit={(e) => this.handleAdminLogin(e, true)}>
							<div className="flex-col">
								<input className="btn5" type="submit" value="Test admin login"/>
							</div>
						</form>
					}
				</div>
				{/* <Footer type='admin' /> */}
			</div>
    </>);
  }
}

// TODO: PropTypes doesn't work. I hate them.
AdminLogin.propTypes = {

}

function mapStateToProps(state) {
	// console.log('AdminLogin.js mapStateToProps() state',state);
	return {
		// userData: state.userDataReducers,
		licensing: state.licensingReducers
	}
}

export default connect(mapStateToProps)(AdminLogin);
