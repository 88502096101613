import React, { Component } from 'react'
import { connect } from 'react-redux'
import { empty, handleCacheBusting } from './../utility/Shared'

class HeaderSecondaryAdmin extends Component {
	constructor(props) {
		super(props);
		this.handleAdminNavigationChange = this.handleAdminNavigationChange.bind(this);

		// check to see if cache needs to be busted
		handleCacheBusting(true);

		// get pathname
		//console.log('const this.props.history:',this.props.history);
		if(!empty(this.props.history)) {
			// for some reason, only the admin pages have a history... what's up with that?
			this.routeName = this.props.history.location.pathname.split('/admin/')[1];
		}
	}

	state = {
	}

	componentDidMount() {
	}

	handleAdminNavigationChange(e,specificRoute) {
		//console.log('this.props.history:',this.props.history);
		if(!empty(specificRoute)) {
			this.props.history.push('/admin/'+specificRoute);
		} else {
			this.props.history.push('/admin/'+e.target.value);
		}
	}

	render() {
		// console.log('HeaderSecondaryAdmin.js render() this.props',this.props);
		
		const privilegeLevel = this.props.adminData.data.privilegeLevel;

	return (<>
		<div className="page-content header-admin-secondary">
			<div className="page-content-outer-layer">
				<div className="page-content-inner-layer">

					<div className="flex-row flex-space-btwn  flex-center-cross">
						<div>
							<select
								//className="input-box select-menu"
								onChange={this.handleAdminNavigationChange}
								value={this.routeName}
							>
								<option key={1} value="dashboard">Dashboard</option>
								<option key={2} value="user-summary">Summary</option>
								<option key={3} value="user-search">User Search</option>
								<option key={4} value="user-detail" hidden>User Detail</option>
								
								<option key={5} value="question-feedback">Question Feedback</option>
								<option key={6} value="first-responses">First Responses</option>
								{privilegeLevel != 'read' &&
									<option key={7} value="add-user">Add User</option>
								}
								<option key={8} value="vesap4-certificates">VESAP4 Certificates</option>
								<option key={9} value="logout">Log Out</option>
							</select>
						</div>
					</div>

				</div/* className="page-content"*/>
			</div/* className="page-content-outer-layer"*/>
		</div/* className="page-content-inner-layer"*/>
    </>);
  }
}


const mapStateToProps = (state) => {
	// console.log('HeaderSecondaryAdmin.js mapStateToProps() state',state);
	return {
		adminData: state.adminReducers
	}
}

export default connect(mapStateToProps)(HeaderSecondaryAdmin);
