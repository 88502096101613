import React, { Component } from 'react'
import { empty, loadProjectCSS, camelCaseToHumanReadable, isEmailValid, getProjectBasename, getLoginTokenPacket, checkApiResponseErrors, handleBreakingError, objToArr, hitApi, restrictionAlert, isReadOnlyAdmin } from './../utility/Shared'
import { connect } from 'react-redux'
import { confirmAlert } from 'react-confirm-alert'
import LoadingScreen from './../common/LoadingScreen'


class UserFields extends Component {
	constructor(props) {
    super(props);
		this.products = ['VESAP5-COMP','VESAP5-VASLAB','VESAP5-LEARNING','VESAP5-RESTRICTED'];
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleProductChange = this.handleProductChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
  }

	state = {
		fields: {},
		product: '',
		errorFields: [],
		isSavingUser: false,
		isReadOnlyAdminUser: false,
	}

	async componentWillMount() {
		// if user navigated to this url manually, need to reload licensing data
		if(empty(this.props.licensing) || empty(this.props.licensing.logoImageName)) {
			await this.props.screenProps.getLicensingData(true);
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		// if user navigated to this url manually, need to reload admin data
		if(empty(this.props.adminData) || empty(this.props.adminData.data.staticStats)) {
			await this.props.screenProps.getAdminData();
		}

		// when on the "Add User" page, this.props.userUuid will be empty. On the "User Detail" page it won't be
		if(!empty(this.props.userUuid)) {
			// we are on the user detail/edit page. if user matches the props userUuid, load that user's stuff into the form fields!
			for (let i = 0; i < this.props.adminData.data.usersList.length; i++) {
				const user = this.props.adminData.data.usersList[i];
				if(user.UUID === this.props.userUuid) {
					// found the user, so populate fields, product, and user
					const fields = {
						firstName: empty(user.FirstName) ? '' : user.FirstName,
						middleName: empty(user.MiddleName) ? '' : user.MiddleName,
						lastName: empty(user.LastName) ? '' : user.LastName,
						email: empty(user.Email) ? '' : user.Email,
						username: empty(user.Username) ? '' : user.Username,
						password: empty(user.Password) ? '' : user.Password,
						suffix: empty(user.Suffix) ? '' : user.Suffix,
						testUser: user.IsTestUser,
						lmsId: user.LmsID,
					}

					// get product purchased - doing it separately from getAdminData since it slows login down a lot
					let userProductPurchased = await this.fetchUserProductPurchased(user.UUID);
					user.product = userProductPurchased;
					
					// save user, fields, and product
					this.user = user;
					this.setState({fields,product:user.product});
				}
			}
		} else {
			// we don't have a user, so populate fields and product with default values (and as a CE user)
			const defaultFields = {
				firstName: '',
				middleName: '',
				lastName: '',
				email: '',
				username: '',
				password: '',
				suffix: '',
				testUser: 1,
				lmsId: '',
			}
			const defaultProduct = this.products[0];
			this.setState({fields:defaultFields,product:defaultProduct});
		}
		
		const isReadOnlyAdminUser = isReadOnlyAdmin(this.props.adminData);

		this.setState({loading: false, isReadOnlyAdminUser});
	}
	
	async fetchUserProductPurchased(userUuid) {
		// hit API for product user has purchased
		let payload = getLoginTokenPacket(true);
		payload.userUuid = userUuid;
		let response = await hitApi('getUserProductPurchasedData',payload);
		return response.productPurchased;
	}


	handleFieldChange(e) {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		const fieldName = e.target.name;
		const fieldValue = e.target.value;
		let updatedFieldObj = {};
		updatedFieldObj[fieldName] = fieldValue;

		// remove the error field
		let errorFields = JSON.parse(JSON.stringify(this.state.errorFields));
		if(this.state.errorFields.includes(fieldName)) {
			if(fieldName === 'email' && !isEmailValid(fieldValue)) {
				// skip, don't remove error field
			} else {
				const index = errorFields.indexOf(fieldName);
				errorFields.splice(index,1);
			}
		}

		this.setState({fields: Object.assign({}, this.state.fields, updatedFieldObj), errorFields});
	}

	// a product selection radio button was changed
	handleProductChange(e) {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		const fieldValue = e.target.value;

		this.setState({product:fieldValue});
	}

	handleSubmit(e) {
		e.preventDefault();
		
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		

		this.setState({isSavingUser:true});

		// validate required fields
		let errorFields = [];
		for (const fieldName of Object.keys(this.state.fields)) {
			const fieldValue =  this.state.fields[fieldName];
			const fieldElement = document.getElementsByName(fieldName)[0];
			if(!empty(fieldElement) && fieldElement.required && empty(fieldValue)) {
				// a required field is empty so add to error fields... UNLESS it's the password field on the user-detail page since that can't be shown. leave it empty and the SQL will know not to update it to be blank
				if(!(fieldName === 'password' && this.props.page === 'user-detail')) {
					errorFields.push(fieldName);
				}
			} else if(fieldName === 'email' && !isEmailValid(fieldValue)) {
				// the email provided is not empty but is not a valid email
				errorFields.push(fieldName);
			} else if(fieldName === 'username') {
				// check to see if the username is already taken
				for (let i = 0; i < this.props.adminData.data.usersList.length; i++) {
					const user = this.props.adminData.data.usersList[i];
					if(fieldName === 'username' && user.Username === fieldValue && user.UUID != this.props.userUuid) {
						// username is taken, alert user
						errorFields.push(fieldName);
						confirmAlert({
							message: 'Sorry, this username is already taken. Please select a new one.',
							buttons: [{label: 'Ok'}]
						});
					}
				}
			}
		}

		// if we found errors, don't submit, just display errors
		if(!empty(errorFields)) {
			this.setState({errorFields,isSavingUser:false});
			// scroll to top if there are errorFields
			window.scrollTo(0, 0);
			return;
		}


		// if we got here, we didn't find any errors, yay! so submit data to save new user in db
		let payload = getLoginTokenPacket(true);
		payload.newUserInfo = {};
		payload.newUserInfo.fields = this.state.fields;
		payload.newUserInfo.product = this.state.product;
		if(!empty(this.props.userUuid)) {
			// we have an existing user, so populate uuid so api knows
			payload.newUserInfo.fields.uuid = this.props.userUuid;
		}

		return fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action=adminUpsertUser', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload)
		})
		.then((response) => response.json())
		.then((adminUpsertUserResponse) => {
// console.log('adminUpsertUserResponse',adminUpsertUserResponse);
			// check for errors so we don't assign an error message to redux
			checkApiResponseErrors(adminUpsertUserResponse);

			// save to redux
			this.props.screenProps.setAdminDataToRedux(adminUpsertUserResponse.adminData);

			this.setState({isSavingUser:false});

			// redirect to their user detail page
			if(this.props.page != 'user-detail') {
				this.props.screenProps.history.push('/admin/user-detail?uuid='+adminUpsertUserResponse.userUuid);
				confirmAlert({
					message: 'User has been added successfully!',
					buttons: [{label: 'Ok'}]
				});
			} else {
				confirmAlert({
					message: 'User has been updated successfully!',
					buttons: [{label: 'Ok'}]
				});
			}

		})
		.catch((error) => {
			this.setState({isSavingUser:false});
			console.log('ERROR AddUser.js handleSubmit(): ', error);
			handleBreakingError('handleSubmit',error,payload,true);
			throw new Error('Stop script execution with this uncaught exception.');
		});

	}


  render() {
		// console.log('UserFields.js render() this.props',this.props);
		// console.log('UserFields.js render() this.state',this.state);

		if(this.state.isSavingUser) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText="Saving user to database..."/>
			)
		}

		const _this = this;
		const handleFieldChange = this.handleFieldChange;
		const handleProductChange = this.handleProductChange;

    return (<>
		{!empty(this.state.errorFields) &&
			<div style={{color:'red'}}>
				<p>Please provide a valid entry for the following fields:</p>
				<ul>
				{this.state.errorFields.map(function(errorField,idx) {
					return (<React.Fragment key={idx}>
						<li key={idx}>{camelCaseToHumanReadable(errorField)}</li>
					</React.Fragment>)
				})}
				</ul>
			</div>
		}

		<div className="form2">
			<div>
				<label className="required">First Name</label>
				<span><input className={this.state.errorFields.includes('firstName') ? "error-field" : ""} type="text" name="firstName" value={this.state['fields'].firstName} onChange={this.handleFieldChange} required /></span>
			</div>
			<div>
				<label>Middle Name</label>
				<span><input className={this.state.errorFields.includes('middleName') ? "error-field" : ""} type="text" name="middleName" value={this.state['fields'].middleName} onChange={this.handleFieldChange}/></span>
			</div>
			<div>
				<label className="required">Last Name</label>
				<span><input  className={this.state.errorFields.includes('lastName') ? "error-field" : ""} type="text" name="lastName" value={this.state['fields'].lastName} onChange={this.handleFieldChange} required /></span>
			</div>
			<div>
				<label>Suffix</label>
				<span><input className={this.state.errorFields.includes('suffix') ? "error-field" : ""} type="text" name="suffix" value={this.state['fields'].suffix} onChange={this.handleFieldChange}/></span>
			</div>
			<div>
				<label className="required">Email</label>
				<span><input className={this.state.errorFields.includes('email') ? "error-field" : ""} type="text" name="email" value={this.state['fields'].email} onChange={this.handleFieldChange} required /></span>
			</div>
			<div>
				<label className="required">Username</label>
				<span><input className={this.state.errorFields.includes('username') ? "error-field" : ""} type="text" name="username" value={this.state['fields'].username} onChange={this.handleFieldChange} required /></span>
			</div>
			<div>
				<label className="required">Password</label>
				<span><input className={this.state.errorFields.includes('password') ? "error-field" : ""} type="text" name="password" value={this.state['fields'].password} onChange={this.handleFieldChange} required /></span>
			</div>
			<div>
				<label>Test user</label>
				<span>
					<select value={this.state['fields'].testUser} onChange={this.handleFieldChange} name="testUser">
						<option value="1">Yes</option>
						<option value="0">No</option>
					</select>
				</span>
			</div>
			<div>
				<label className="required">Customer ID</label>
				<span><input className={this.state.errorFields.includes('lmsId') ? "error-field" : ""} type="text" name="lmsId" value={this.state['fields'].lmsId} onChange={this.handleFieldChange} /></span>
			</div>
			<br /><br />
		</div/*  className="form2" */>


		<h2>Product Information:</h2>

		<div className="form2 cmeprods">
			{this.products.map(function(productName,idx) {
				return (<React.Fragment key={idx}>
					<div><label><input
					type="radio"
					name="product-purchased"
					value={productName}
					checked={_this.state.product === productName}
					className="form-check-input"
					onChange={(e)=>handleProductChange(e)}
					/>{productName}</label></div>
				</React.Fragment>)
			})}
		<br />
		<button className="bn" onClick={this.handleSubmit}>{this.props.page === 'user-detail' ? 'Submit Updated User' : 'Submit'}</button>
		</div/*  className="form2" */>

    </>);
  }
}


// export default UserFields;
function mapStateToProps(state) {
	// console.log('UserFields.js mapStateToProps() state',state);
	return {
		licensing: state.licensingReducers,
		adminData: state.adminReducers
	}
}

export default connect(mapStateToProps)(UserFields);
