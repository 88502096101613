import React, { Component } from 'react'
import { connect } from 'react-redux'
import HeaderSecondaryAdmin from './../common/HeaderSecondaryAdmin'
import LoadingScreen from './../common/LoadingScreen'
import { CSVLink } from "react-csv"
import { getLoginTokenPacket, handleBreakingError, checkApiResponseErrors, getProjectBasename, empty, loadProjectCSS } from './../utility/Shared'
import { confirmAlert } from 'react-confirm-alert'
import './../css/react-confirm-alert.css'


const hypixCsv = [
	["CategoryOrder","CategoryNumber","CategoryName","QuestionNumber","QuestionText","ResponseA","ResponseB","ResponseC","ResponseD","ResponseE","CorrectAnswer","CritiqueText","Ref01","Ref02","Ref03","Ref04","Ref05","Ref06","Ref07","Ref08","Ref09","Ref10"]
];

class ExamBuilderImporter extends Component {
	constructor(props) {
		super(props);
		this.onUpload = this.onUpload.bind(this);
		this.populateMediaTable = this.populateMediaTable.bind(this);
	}
	state = {
		importingInProgress: false,
		mediaImportingInProgress: false,
		loading: true,
	}

	async componentWillMount() {
		// if user navigated to this url manually, need to reload licensing data
		if(empty(this.props.licensing) || empty(this.props.licensing.logoImageName)) {
			await this.props.screenProps.getLicensingData(true);
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		// if user navigated to this url manually, need to reload admin data
		if(empty(this.props.admin) || this.props.admin.data.needToFetchValuesFromDB) {
			await this.props.screenProps.getAdminData();
		}

		// make sure only super admins can see this page, or at least that the project hasn't launched yet if we remove the super admin restriction in the future (this.props.admin.data.isProjectLaunched)
		if(this.props.admin.data.privilegeLevel === 'super') {
			this.props.screenProps.history.push('/admin/exambuilder');
			return;
		}

		this.setState({loading: false});
	}

	// generate array of database that will be exportable to csv
	getCsvOfDb() {
		const loginPacket = getLoginTokenPacket(true);

		window.open('https://mycmecredit.com/'+getProjectBasename()+'/api/exportcsv.php?loginToken='+encodeURIComponent(loginPacket.userInfo.loginToken)+'&report=db&adminUser=true','_blank');
	}

	// upload a new csv into the db (which wipes the current db!)
	onUpload() {
		let input = document.getElementById('fileinput');
		let data = new FormData();
		data.append('csvFile', input.files[0]);
		data.append('payload', JSON.stringify(getLoginTokenPacket(true)));

		this.setState({importingInProgress:true});
// console.log('data',data);
		return fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action=contentCsvImport', {
			method: 'POST',
			body: data
		})
		.then((response) => response.json())
		.then((contentCsvImportResponse) => {
// console.log('ExamBuilderImporter.js contentCsvImportResponse',contentCsvImportResponse);

			// check for errors so we don't assign an error message to redux
			checkApiResponseErrors(contentCsvImportResponse,true);

			// set updated data to redux
			this.props.screenProps.setContentToRedux(contentCsvImportResponse.contentCategories);

			this.setState({importingInProgress:false});
			alert('Data imported successfully!');

		})
		.catch((error) => {
			this.setState({importingInProgress:false});
			console.log('ERROR: ExamBuilderImporter.js onUpload()', error);
			handleBreakingError('contentCsvImport',error,data,true);
			throw new Error('Stop script execution with this uncaught exception.');
		});
  }

	populateMediaTable() {
		confirmAlert({
			message: "This will delete all records currently found in the database Media table and then re-populate it with the media files found in CLIENT_MEDIA. Are you sure you wish to proceed?",
			buttons: [
				{label: 'Cancel'},
				{label: "Proceed", onClick: async () => {
					this.setState({mediaImportingInProgress:true});

					let payload = {};
					const loginPacket = getLoginTokenPacket(true);
					payload.userInfo = loginPacket.userInfo;

					return fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action=populateMediaTable', {
						method: 'POST',
						body: JSON.stringify(payload)
					})
					.then((response) => response.json())
					.then((populateMediaTableResponse) => {
			// console.log('ExamBuilderImporter.js populateMediaTableResponse',populateMediaTableResponse);

						// check for errors so we don't assign an error message to redux
						checkApiResponseErrors(populateMediaTableResponse,true);

						// set updated data to redux
						this.props.screenProps.setContentToRedux(populateMediaTableResponse.contentCategories);

						this.setState({mediaImportingInProgress:false});
						alert('Media imported successfully!');

					})
					.catch((error) => {
						this.setState({mediaImportingInProgress:false});
						console.log('ERROR: ExamBuilderImporter.js populateMediaTable()', error);
						handleBreakingError('populateMediaTable',error,payload,true);
						throw new Error('Stop script execution with this uncaught exception.');
					});
				}},
			]
		});
	}

  render() {
		// console.log('ExamBuilderImporter.js render() this.props',this.props);
		// console.log('ExamBuilderImporter.js render() this.state',this.state);

		if(this.state.loading) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText="Loading Exam Builder Importer..." />
			)
		} else if(this.state.importingInProgress) {
			return (
				<LoadingScreen loadingText="Importing your data..." type="csvImport" />
			)
		} else if(this.state.mediaImportingInProgress) {
			return (
				<LoadingScreen loadingText="Importing your media..." type="csvImport" />
			)
		}


    return (<>
			<div className="container container-admin exam-builder-importer-page">
				<HeaderSecondaryAdmin history={this.props.screenProps.history} />

				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">

							<h1>Download template .csv file for data import</h1>
							<p>Populate this template with the content for your site. Then use the Import Data process below to import the data into the database.</p>
							<p>Note that CSV files don't save styles so if you make a word bold in the spreadsheet, it won't be bold when it's imported to the database. You can add styles by using the following guidelines:</p>
							<ul>
								<li>&lt;b&gt;Bold&lt;/b&gt;</li>
								<li>&lt;i&gt;Italic&lt;/i&gt;</li>
								<li>&lt;u&gt;Underline&lt;/u&gt;</li>
								<li>If you have multiple paragraphs, requiring a carriage return between them:</li>
								<ul>
									<li>&lt;p&gt;Paragraph 1 text&lt;/p&gt;</li>
									<li>&lt;p&gt;Paragraph 2 text&lt;/p&gt;</li>
								</ul>
							</ul>
							<CSVLink
							data={hypixCsv}
							uFEFF={false}
							filename={"hypix-template.csv"}
							className="btn5"
							>
								Download Blank Template
							</CSVLink>

							<hr />

							<h1>Export CSV of current database</h1>
							<p>If there is already data in the database that you would like to work off of, you can do so by exporting it as a CSV below.</p>

							<div className="btn5" onClick={this.getCsvOfDb}>
								Export CSV of DB
							</div>

							<hr />

							<h1>Import Data</h1>

							<ul>
								<li><strong>IMPORTANT: We recommend using Google Sheets.</strong> In order to prevent character encoding issues (where certain special characters don't show up properly, or even break the entire importing process!), the file needs to have a "UTF-8" encoding. Whether this is already the case or how to switch to that will depend on what program you are using (e.g. Google Sheets, Excel, OpenOffice, etc.) and there can be complications with switching. <strong>Using Google Sheets from the get go handles all of this correctly</strong>.</li>
								<li>If a question has more than 10 references, they will need to be added separately via Exam Builder individual item editing.</li>
								<li>The file should be of type ".csv"</li>
							</ul>

							<input id="fileinput" type="file" />
							<div className="btn5" onClick={this.onUpload}>
								Upload
							</div>

							<hr />

							<h1>Import Media</h1>

							<p>Clicking the "Import Media" button below will populate the DB Media table with all the media found in CLIENT_MEDIA (mapping the old captions to updated media records with same filename), generate the thumbnails for them all, and then update the categories.json file. This is used when we start a project and the client gives us all their media (which must follow the naming convention).</p>

							<div className="btn5" onClick={this.populateMediaTable}>
								Import Media
							</div>

						</div/* className="page-content"*/>
					</div/* className="page-content-outer-layer"*/>
				</div/* className="page-content-inner-layer"*/>

			</div>
    </>);
  }
}

const mapStateToProps = (state) => {
	// console.log('ExamBuilderImporter.js mapStateToProps() state',state);
	return {
		licensing: state.licensingReducers,
		admin: state.adminReducers
	}
}

export default connect(mapStateToProps)(ExamBuilderImporter);
