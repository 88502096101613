import React, { Component } from 'react'
import { connect } from 'react-redux'
import { objToArr, categoryByOrder, itemByCategoryOrderAndItemNumber, isCategoryInTesting, getCategoryById, getQuestionById, empty, getCurrentTimestampStringUTC, parseUrlParams, isCategoryPurchased, loadProjectCSS, saveBookmark } from '../utility/Shared'
import { CATEGORIES_ALIAS, CATEGORY_ALIAS } from '../utility/Constants'
import PopUpModal from './../common/PopUpModal'
import BackButton from './../common/BackButton'
import LoadingScreen from './../common/LoadingScreen'
import { FaChevronRight, FaTrashAlt } from 'react-icons/fa'
import { confirmAlert } from 'react-confirm-alert'


class Bookmarks extends Component {

	constructor(props) {
		super(props);
		// instantiate modal ref
		this.popUpModal = React.createRef();
	}

	state = {
		loading: true,
	}

	// make sure that userData has loaded properly. first get userData if we don't already have it
	async componentWillMount() {
		// if user navigated to this url manually, need to reload userData
		if(empty(this.props.userData)) {
			await this.props.screenProps.getUserDataFromDB();
        if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}

		this.setState({loading: false});
	}

	// bring user to bookmarked item
	bookmarkClicked(bookmark) {
		// get category and item to pass as props to Item page
		let category, item;
		for (const key of Object.keys(this.props.contentCategories)) {
			const categoryData =  this.props.contentCategories[key];
			if(bookmark.categoryId === categoryData.id) {
				category = categoryData;
			}
		}
		category.questions.forEach(function(questionData) {
			if(bookmark.questionId === questionData.id) {
				item = questionData;
			}
		});

		// navigate to Item page after updating user position
		let cid = category.id;
		let qid = item.id;
		this.props.screenProps.history.push('/item?cid='+cid+'&qid='+qid);
	}

	// opens the edit bookmark modal, passing the default category and question
	editBookmarkInModal(e,existingBookmark) {
		e.stopPropagation();
		this.popUpModal.current.openModal('bookmark',false,false,existingBookmark);
	}

	// delete bookmark
	deleteBookmark(e,bookmarkId) {
		e.stopPropagation();
		confirmAlert({
			message: 'Are you sure you want to delete this bookmark?',
			buttons: [
				{label: 'Cancel'},
				{label: 'Yes',
				onClick: () => {
					let updatedUserData = Object.assign({},this.props.userData);
					updatedUserData.bookmarks[bookmarkId].deleted = true;
					updatedUserData.bookmarks[bookmarkId].timestamp = getCurrentTimestampStringUTC();
					this.props.screenProps.syncReduxAndDB(updatedUserData, 'syncBookmarks');
					this.setState({bookmarkNotes:''})
				}}
			]
		});
	}

  render() {
		// console.log('Bookmarks.js render() this.props',this.props);
		// console.log('Bookmarks.js render() this.state',this.state);

		if(this.state.loading || empty(this.props.userData)) {
			// show loading screen
			return (
				<LoadingScreen loadingText="Loading your bookmarks..." />
			)
		}


		// initialize variables
		let bookmarkClicked = this.bookmarkClicked.bind(this);
		let editBookmarkInModal = this.editBookmarkInModal.bind(this);
		let deleteBookmark = this.deleteBookmark.bind(this);

		// first check to see if we are coming from a category that is taking an exam meaning that bookmarks should be restricted
		let hideCreateBookmarkForm = false;
		if(isCategoryInTesting(this.props.userData, this.props.userData.position.category.id)) {
			hideCreateBookmarkForm = true;
		}

		// get the viewable bookmarks (if a bookmarked item is in a category that is currently in an exam that hasn't been passed, it can't be viewed incase the user got smaat with us and put the correct answers in their bookmark notes)
		let viewableBookmarks = [];
		let allNonDeletedBookmarks = [];
		for (const key of Object.keys(this.props.userData.bookmarks)) {
			const bookmark =  this.props.userData.bookmarks[key];
			if(!isCategoryInTesting(this.props.userData, bookmark.categoryId) && !bookmark.deleted) {
				viewableBookmarks.push(bookmark);
			} else if(!bookmark.deleted) {
				allNonDeletedBookmarks.push(bookmark);
			}
		}

		// sort viewable bookmarks (cheating since using category/question ids rather than numbers but, we can count on them to be sequential)
		viewableBookmarks.sort(
			function(a,b) {
				let categoryIdA, categoryIdB;
				if(a.categoryId.length < 2) {
					categoryIdA = '0'+a.categoryId;
				}
				if(b.categoryId.length < 2) {
					categoryIdB = '0'+b.categoryId;
				}
				const catItemNumA = categoryIdA+''+a.questionId;
				const catItemNumB = categoryIdB+''+b.questionId;
				return catItemNumA > catItemNumB ? 1 : -1;
			}
		)

    return (
			<div className="container bookmarks-page">
				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="back-button-container">
							<BackButton history={this.props.screenProps.history} label={CATEGORY_ALIAS} />
						</div>
						<div className="page-content-inner-layer">

							{/* saved bookmarks list */}
							<div className="header-text">Bookmarks</div>
							{objToArr(viewableBookmarks).length !== 0 &&
								<>
									<p>Click the bookmark to jump to the associated item, or click the "Edit" icon to edit the bookmark notes or the "Delete" icon to remove it.</p>
									<div className="dotted-line-divider"></div>
								</>
							}
							{objToArr(viewableBookmarks).length !== 0 ? (
								objToArr(viewableBookmarks).map(function(bookmark,idx) {
									return (
										<Bookmark
											key={idx}
											item={bookmark}
											bookmarkClicked={bookmarkClicked}
											editBookmarkInModal={editBookmarkInModal}
											deleteBookmark={deleteBookmark}
											></Bookmark>
									)
								})
							) : (
								Object.keys(allNonDeletedBookmarks).length !== 0 ? (
									<p>{"You don't have any viewable bookmarks. Bookmarks belonging to an item in a "+CATEGORY_ALIAS +" that is currently in Exam mode are not viewable until the exam has been passed."}</p>
								) : (
									<p>You don't have any bookmarks yet.</p>
								)
							)}
						</div>
					</div>
				</div>
				<PopUpModal
					ref={this.popUpModal}
					screenProps={this.props.screenProps}
					saveBookmark={saveBookmark}
					contentCategories={this.props.contentCategories}
					userData={this.props.userData}
				/>
			</div>
    );
  }
}

class Bookmark extends Component {

	render() {
		// console.log('Bookmark render() this.props',this.props);

    return (
			<div className="flex-row flex-center-cross">
				<div className="bookmark-icon edit" onClick={(e) => {this.props.editBookmarkInModal(e,this.props.item)}}></div>
				<div className="bookmark-icon delete" onClick={(e) => {this.props.deleteBookmark(e,this.props.item.id)}}></div>
				<div className="flex-item-1" onClick={() => {this.props.bookmarkClicked(this.props.item);}}>
					<div className="list-item">
						<div className="bookmark-row list-row flex-item-1">
							<div>
								<div className="bold-text line-height-18">{this.props.item.name}</div>
								<div className="bookmark-row-item-notes">{this.props.item.notes}</div>
							</div>
							<div className="list-item-options">
								<img src={require("./../images/arrow-right.png")} width="12" height="12" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
    );
  }
}

const mapStateToProps = (state) => {
	// console.log('Bookmarks.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers
	}
}

export default connect(mapStateToProps)(Bookmarks);
