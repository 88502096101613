import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { routeUser, getCurrentTimestampStringUTC, empty, loadProjectCSS, renderProgressCircle, parseUrlParams, getCategoryPerformanceStats, navClaimCreditProcess, getFirstUnansweredQuestionId, getCategoryModeStatus } from './../utility/Shared'
import BackButton from './../common/BackButton'
import { Link } from 'react-router-dom'
import LoadingScreen from './../common/LoadingScreen'
import { CATEGORIES_ALIAS, CATEGORY_ALIAS, PASSING_PERCENTAGE } from "../utility/Constants";
import { Tween } from "react-gsap";

class Completion extends Component {

	state = {
		category: {},
		performanceStatsExam: {},
		performanceStatsLearning: {},
		mode: 'Exam',
		loading: true
	}

	async componentWillMount() {
		// if user navigated to this url manually, need to reload userData
		if(empty(this.props.userData)) {
			await this.props.screenProps.getUserDataFromDB();
        if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}

		// get category perf stats
		const urlParams = parseUrlParams();
		const cid = urlParams.cid;
		if(empty(cid)) {
			this.props.screenProps.history.push('/modules');
			return;
		}
		let mode = 'Exam';
		if(urlParams.mode === 'learn') {
			mode = 'Learning';
		}
		const category = this.props.contentCategories[cid - 1];
		let performanceStatsExam = getCategoryPerformanceStats(this.props.userData,category.id,'Exam');
		const modeStatus = getCategoryModeStatus(this.props.userData,category.id,'Exam');
		if(modeStatus === 'retake') {
			// if we are in retake mode, getCategoryPerformanceStats() needs to be tweaked because enterRetakeMode() has already been called which makes numberAnswered the numberCorrect
			performanceStatsExam.numberAnswered = performanceStatsExam.totalQuestionCount;
			performanceStatsExam.percentCorrect = parseFloat((performanceStatsExam.numberCorrect / performanceStatsExam.totalQuestionCount) * 100).toFixed(1);
		}

		const performanceStatsLearning = getCategoryPerformanceStats(this.props.userData,category.id,'Learning');

		this.setState({category,performanceStatsExam,performanceStatsLearning,mode,loading: false});
	}

	navToScreen(navScreen) {
		this.props.screenProps.history.push('/'+navScreen);
	}

	navToRetake(categoryId) {
		// get first unanswered question id
		const firstUnansweredQuestionId = getFirstUnansweredQuestionId(this.props.userData.categories[categoryId],'Exam');

		// switch to correct mode (just in case)
		this.props.screenProps.setCategoryMode(this.props.userData, categoryId, 'Exam');

		// navigate to item page
		this.props.screenProps.history.push('/item?cid='+categoryId+'&qid='+firstUnansweredQuestionId);
	}

	render() {
		// console.log('Completion.js render() this.props',this.props);
		// console.log('Completion.js render() this.state',this.state);

		if(this.state.loading || empty(this.props.userData)) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText="Drumroll please..." />
			)
		}

		const { category, performanceStatsExam, performanceStatsLearning } = this.state;
		const categoryExamModeStatus = getCategoryModeStatus(this.props.userData,category.id,'Exam');
		const progressPerformanceStats = this.state.mode === 'Exam' ? performanceStatsExam : performanceStatsLearning;
		const examMode = this.state.mode === 'Exam';
		const examPass = (performanceStatsExam.percentCorrect >= PASSING_PERCENTAGE && this.state.mode === 'Exam');

		return (
			<div className="container completion-page utility-page">
				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="back-button-container">
							<BackButton history={this.props.screenProps.history} label={CATEGORY_ALIAS} position={this.props.userData.position} />
						</div>
						<div className="page-content-inner-layer">

							<div className="utility-content">

								<h3 className={"centered"}>{category.name}</h3>
								<h6 className={"centered question-total"}>{performanceStatsExam.totalQuestionCount} items</h6>

								<div className="utility-content-page">
									<div className="utility-content-col-left">

										{/* passed in exam mode */}
										{(examPass && examMode) && (
											<div>
												<h3>Congratulations!</h3>
												<h5>You have passed the exam with a total percent correct of {performanceStatsExam.percentCorrect}%.</h5>
												<p>Your answers have been saved for your review and you may now apply for CME credit by clicking the 'Claim CME Credit' button below, or under the “Get Info” button on the <span className="link" onClick={()=>this.navToScreen(CATEGORIES_ALIAS.toLowerCase())}>{CATEGORIES_ALIAS} screen</span> or the <span className="link" onClick={()=>this.navToScreen('landing?cid='+category.id)}>{CATEGORY_ALIAS} landing page</span>.</p>
												<div className="utility-button-row">
													<div className="btn5 small" onClick={()=>navClaimCreditProcess(this.props.screenProps.history,this.props.userData)}>
														<div className="center-text">Claim CME Credit</div>
													</div>
												</div>
											</div>
										)}

										{/* failed in exam mode */}
										{(!examPass && examMode) && (
											<div>
												<h3>Sorry!</h3>
												<h5>You have completed all of the questions with a percent correct of {performanceStatsExam.percentCorrect}%.</h5>
												<p>However, in order to apply for CME credit, you will need to achieve a score of {PASSING_PERCENTAGE}% or higher.</p>
												<p>You may <span className="link" onClick={()=>this.navToRetake(category.id)}>return to your exam</span> and reanswer the incorrect questions in Retake mode.</p>
											</div>
										)}

										{((this.state.mode === 'Learning') && performanceStatsLearning.percentCorrect >= PASSING_PERCENTAGE) &&
											<>
												<h3>Congratulations!</h3>
												<h5>You've completed all the questions in Learning mode with a score of {performanceStatsLearning.percentCorrect}%.</h5>
												{(!empty(this.props.userData.userInfo.isCeEligible) && categoryExamModeStatus !== 'review') &&
													<p>In order to receive CME credit, you must answer all the questions in Exam mode with a score of {PASSING_PERCENTAGE}% or better.</p>
												}
												<div className="btn5 small" onClick={()=>this.navToScreen('landing?cid='+category.id)}>
													<div className="center-text">Continue</div>
												</div>
											</>
										}
										{((this.state.mode === 'Learning') && performanceStatsLearning.percentCorrect < PASSING_PERCENTAGE) &&
										<>
											<h3>Sorry!</h3>
											<h5>You've completed all the questions in Learning mode with a score of {performanceStatsLearning.percentCorrect}%.</h5>
											{(!empty(this.props.userData.userInfo.isCeEligible) && categoryExamModeStatus !== 'review') &&
											<p>In order to receive CME credit, you must answer all the questions in Exam mode with a score of {PASSING_PERCENTAGE}% or better.</p>
											}
											<div className="btn5 small" onClick={()=>this.navToScreen('landing?cid='+category.id)}>
												<div className="center-text">Continue</div>
											</div>
										</>
										}

									</div>

									<div className="utility-content-col-right">
										<div className="progress-indicator-container completion-progress">

											{((this.state.mode === 'Learning') && performanceStatsLearning.percentCorrect < PASSING_PERCENTAGE) && <div><Tween from={{transform: 'scale(0.2)', opacity: 0.8}} duration={1.5} ease={'Back.easeOut'} ref={ref => this.tween = ref}><img src={require("./../images/exam-fail.svg")} width="220" height="220" alt="exam fail" className="exam-fail"/></Tween></div>}

											{((this.state.mode === 'Learning') && performanceStatsLearning.percentCorrect >= PASSING_PERCENTAGE) && <div><Tween from={{transform: 'scale(0.2)', opacity: 0.8}} duration={1.5} ease={'Back.easeOut'} ref={ref => this.tween = ref}><img src={require("./../images/gold-star.svg")} width="220" height="220" alt="gold star" className="gold-star"/></Tween></div>}

											{(examPass && examMode) &&
											<div><Tween from={{transform: 'scale(0.2)', opacity: 0.8}} duration={1.5} ease={'Back.easeOut'} ref={ref => this.tween = ref}><img src={require("./../images/gold-star.svg")} width="220" height="220" alt="gold star" className="gold-star"/></Tween></div>}

											{(!examPass && examMode) && <div><Tween from={{transform: 'scale(0.2)', opacity: 0.8}} duration={1.5} ease={'Back.easeOut'} ref={ref => this.tween = ref}><img src={require("./../images/exam-fail.svg")} width="220" height="220" alt="exam fail" className="exam-fail"/></Tween></div>}

											<div className={"progress-indicator-text-container" + (this.state.mode === 'Learning' ? ( performanceStatsLearning.percentCorrect >= PASSING_PERCENTAGE ? ' exam-pass' : ' exam-fail' ) : (examPass ? ' exam-pass' : ' exam-fail' ))}>
												<div
													className="progress-indicator-text progress-indicator-percent">{String(Math.floor(progressPerformanceStats.numberCorrect / progressPerformanceStats.totalQuestionCount * 100))}%
												</div>
												<div className="completion-progress-text">
													<div>{progressPerformanceStats.numberCorrect}/{progressPerformanceStats.totalQuestionCount} CORRECT</div>
												</div>
											</div>
										</div>

										<div className="completion-performance-grid">
											<div className="th1">Performance Summary</div>
											<div className="th">Total Questions</div>
											<div className="th">Number Correct</div>
											<div className="th">Percent Correct</div>
											<div className="th2">Learning Mode</div>
											<div>{performanceStatsLearning.totalQuestionCount}</div>
											<div>{performanceStatsLearning.numberCorrect}</div>
											<div>{performanceStatsLearning.percentCorrect}%</div>
											{!empty(this.props.userData.userInfo.isCeEligible) &&
												<>
												<div className="th2">Exam Mode</div>
												<div>{performanceStatsExam.totalQuestionCount}</div>
												<div>{performanceStatsExam.numberCorrect}</div>
												<div>{performanceStatsExam.percentCorrect}%</div>
												</>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	// console.log('Completion.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers
	}
}

export default connect(mapStateToProps)(Completion);
