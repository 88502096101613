import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import HeaderSecondaryAdmin from './../common/HeaderSecondaryAdmin'
import { objToArr, empty, getCategoryById, updateDBContentSynchronously, loadProjectCSS, getFormattedQuestionNumber, isReadOnlyAdmin, restrictionAlert } from './../utility/Shared'
import { FaChevronRight, FaDatabase, FaPlus, FaTrash } from 'react-icons/fa'
import { confirmAlert } from 'react-confirm-alert'
import './../css/react-confirm-alert.css'
import Levels from 'react-activity/lib/Levels'
import LoadingScreen from './../common/LoadingScreen'
import { CATEGORIES_ALIAS } from './../utility/Constants'


class ExamBuilder extends Component {
	constructor(props) {
		super(props)

		this.onSelectCategory = this.onSelectCategory.bind(this);
	}

	state = {
		selectedCategory: '',
		loadingNewQuestion: false,
		isReadOnlyAdminUser: false,
		loading: true,
	}

	async componentWillMount() {
		// if user navigated to this url manually, need to load contentCategories and licensing data
		if(empty(this.props.contentCategories)) {
			await this.props.screenProps.getContentCategories(true);
		}
		if(empty(this.props.licensing) || empty(this.props.licensing.logoImageName)) {
			await this.props.screenProps.getLicensingData(true);
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		// if user navigated to this url manually, need to reload admin data
		if(empty(this.props.admin) || this.props.admin.data.needToFetchValuesFromDB) {
			await this.props.screenProps.getAdminData();
		}

		const selectedCategory = this.props.contentCategories[Object.keys(this.props.contentCategories)[0]];
		
		const isReadOnlyAdminUser = isReadOnlyAdmin(this.props.admin);

		this.setState({selectedCategory, isReadOnlyAdminUser, loading: false});
	}

	onSelectCategory(event) {
		// update category and questions
		const selectedCategory = getCategoryById(this.props.contentCategories, event.target.value);
		this.setState({selectedCategory});
	}

	// navigate to exam builder edit page for the requested question
	navExamBuilderEdit(categoryId,questionNum,isNewQuestion=false) {
		let url = "/admin/exambuilder/edit?cid="+categoryId+"&qnum="+questionNum;
		if(isNewQuestion) {
			url += "&isNewQuestion=1";
		}
		this.props.screenProps.history.push(url);
	}

	// add a blank question to our content categories, sync redux and db, and nav to question edit page
	async addQuestion() {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		if(this.props.admin.data.isProjectLaunched) {
			return;
		}

		let updatedContentCategories = Object.assign({},this.props.contentCategories);
		let questions = updatedContentCategories[this.state.selectedCategory.id - 1].questions;
		let questionNum = questions.length + 1;
		let newQuestion = {
			id: null,
			number: questionNum,
			text: '',
			answers: [],
			critique: {},
			references: [],
			media: [],
		}
		questions.push(newQuestion);

		// save to redux and db
		this.setState({loadingNewQuestion:true});
		let dbContent = await updateDBContentSynchronously(updatedContentCategories);
		this.props.screenProps.setContentToRedux(dbContent);

		// nav to question edit page
		this.navExamBuilderEdit(this.state.selectedCategory.id,questionNum,true);
	}

	onDragQuestionStart = (e, index) => {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		if(this.props.admin.data.isProjectLaunched) {
			return;
		}
		this.draggedQuestion = this.state.selectedCategory.questions[index];
		e.dataTransfer.effectAllowed = "move";
		e.dataTransfer.setData("text/html", e.target.parentNode);
		e.dataTransfer.setDragImage(e.target.closest('.drag-image'), 20, 20);
	};

	onDragQuestionOver = index => {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		if(this.props.admin.data.isProjectLaunched) {
			return;
		}
		const draggedOverItem = this.state.selectedCategory.questions[index];

		// if the item is dragged over itself, ignore
		if (this.draggedQuestion === draggedOverItem) {
			return;
		}

		// filter out the currently dragged item
		let items = this.state.selectedCategory.questions.filter(item => item !== this.draggedQuestion);

		// add the dragged item after the dragged over item
		items.splice(index, 0, this.draggedQuestion);
		items.forEach(function(question,idx) {
			question.number = (idx + 1).toString();
		});

		this.setState(prevState => ({selectedCategory: {...prevState.selectedCategory, questions: items}}));
	};

	async onDragQuestionEnd(e,index) {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		// if the item is dragged over itself, ignore
		const draggedOverItem = this.state.selectedCategory.questions[index];
		if (this.draggedQuestion === draggedOverItem || this.props.admin.data.isProjectLaunched) {
			this.draggedQuestion = null;
			return;
		}

		// question order has changed so update db and redux
		this.draggedQuestion = null;
		this.setState({savingQuestions:true});

		let updatedContentCategories = Object.assign({},this.props.contentCategories);
		updatedContentCategories[this.state.selectedCategory.id - 1].questions = this.state.selectedCategory.questions;
		let dbContent = await updateDBContentSynchronously(updatedContentCategories);
		this.props.screenProps.setContentToRedux(dbContent);

		this.setState({savingQuestions:false});
	};


  render() {
		// console.log('ExamBuilder.js render() this.props',this.props);
		// console.log('ExamBuilder.js render() this.state',this.state);

		if(this.state.loading) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText="Loading Exam Builder..."/>
			)
		} else if(this.state.loadingNewQuestion) {
			return (
				<LoadingScreen loadingText="Loading new question..."/>
			)
		} else if(this.state.savingQuestions) {
			return (
				<LoadingScreen loadingText="Updating question order..."/>
			)
		}

		const { selectedCategory } = this.state;
		const isProjectLaunched = this.props.admin.data.isProjectLaunched;
		const navExamBuilderEdit = this.navExamBuilderEdit.bind(this);
		const onDragQuestionStart = this.onDragQuestionStart.bind(this);
		const onDragQuestionOver = this.onDragQuestionOver.bind(this);
		const onDragQuestionEnd = this.onDragQuestionEnd.bind(this);

    return (<>
			<div className="container container-admin exam-builder-page">
				<HeaderSecondaryAdmin history={this.props.screenProps.history} />

				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">

							<div className="eb-header-w-link">
								<p className="eb-header-text center-text no-bottom-margin">Exam Builder</p>
								{(empty(isProjectLaunched) && this.props.admin.data.privilegeLevel === 'super') &&
									<div className="eb-import-export-link" onClick={()=>this.props.screenProps.history.push('/admin/exambuilder/import')}>
										<div className="asset-icon-container">
											<img src={require("./../images/import-export-data.png")} width="20" height="20" alt="" />
										</div>
										<p className="strong-text">Import/Export Data</p>
									</div>
								}
							</div>

							<div className="eb-header-text-secondary center-text">{CATEGORIES_ALIAS}</div>
							<div className="flex-row flex-center-main">
								<select
									className="eb-dropdown"
									onChange={this.onSelectCategory}
									value={selectedCategory.id}
								>
									{	objToArr(this.props.contentCategories).map(function(category, idx) {
										return (
											<option key={idx} value={category.id}>{category.displayNum}. {category.name}</option>
										)
									})}
								</select>
							</div>

							<div className="flex-row flex-start-main">
								<p className="eb-header-text-secondary">Questions</p>
								{empty(isProjectLaunched) &&
									<>
									<p className="exam-builder-add asset-icon-plus strong-text" onClick={() => this.addQuestion() }>Add Question</p>
									</>
								}
							</div>

							<div className="eb-list-items">
							{	this.state.selectedCategory.questions.map(function(question, idx) {
								const listRowClass = idx%2===0?"list-row-dark":"list-row-light";
								const questionNumber = getFormattedQuestionNumber(question.number);
								return(
									<div
										key={idx}
										className="drag-image"
										onDragOver={() => onDragQuestionOver(idx)}
										onClick={() => { navExamBuilderEdit(selectedCategory.id,question.number)} }
									>
										<div
											className={"drag list-row eb-text " + listRowClass}
											onDragStart={e => onDragQuestionStart(e, idx)}
											onDragEnd={e => onDragQuestionEnd(e, idx)}
										>
											{empty(isProjectLaunched) &&
												<div className="flex-centered-axes drag-icon">
												<img src={require("./../images/reorder-icon.png")} width="16" height="16" alt="" />
												</div>
											}
											<div className="item-number">{questionNumber}</div>
											<div className="eb-q"><p dangerouslySetInnerHTML={{ __html: question.text }}></p></div>
												<div className="list-item-options">
													<img src={require("./../images/arrow-right.png")} width="12" height="12" alt="" />
												</div>
										</div>
									</div>
								);
							})}
							</div>

						</div/* className="page-content"*/>
					</div/* className="page-content-outer-layer"*/>
				</div/* className="page-content-inner-layer"*/>

			</div>
    </>);
  }
}

const mapStateToProps = (state) => {
	// console.log('ExamBuilder.js mapStateToProps() state',state);
	return {
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers,
		admin: state.adminReducers
	}
}

export default connect(mapStateToProps)(ExamBuilder);
