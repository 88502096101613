// project setup
export const PROJECT_BASENAME = 'vesap5';
export const PARENT_PROJECT_UUID = 'v3sap5er-7691-fac4-fd2d-6af1d3b462e3';
export const GUEST_LOGIN_PASSWORD_HASH = '27a154d1eedd757f670837da97ed5655';
export const SESSION_NAME_USER = 'vesap5';
export const SESSION_NAME_ADMIN_USER = 'vesap5-admin';

// config options
export const CATEGORIES_ALIAS = 'Modules';
export const CATEGORY_ALIAS = 'Module';
export const CRITIQUE_ALIAS = 'Discussion';
export const PASSING_PERCENTAGE = 75;
export const IS_DYNAMIC_SEARCH_ON = false;
export const SEARCH_BAR_PLACEHOLDER = 'heart';
export const SESSION_TIMEOUT_MINUTES = 60;
export const MODULE_CREDIT_VALUE = 7.5;

// styles
export const DEFAULT_LOGIN_LOGO_FILENAME = 'vesap5-logo-login.png';
export const DEFAULT_LOGO_FILENAME = 'vesap5-logo.png';
export const DEFAULT_CSS_FILENAME = 'vesap5-styles.css';
export const DEFAULT_CSS_THEME_PREFIX = 'v5-styles';
// populate these from the org stylesheets corresponding to the cssThemePrefix. they are used to color the progress bars/circles
export const ORG_STYLES = {
	'v5-styles': {
		'primary': '#2a5aa9',
		'primaryLight': '#d1eaf9',
		'primaryDark': '#011B44',
		'inactive': '#666',
		'examMode': '#67BC04',
		'learningMode': '#2a97fb',
		'progressFill': '#2499dd',
		'progressUnfinished': '#b7b7b7',
		'progressFail': '#980000',
		'progressPassText': '#7f6703',
	}
};

// dialogs in more than once place (for other dialogs, search for "Alert.alert". can't make them all constants since many contain variables)
export const ENTER_EXAM_MODE_ALERT_TITLE = 'Entering Exam mode';
export const ENTER_EXAM_MODE_ALERT_TEXT = 'You are about to enter Exam mode for this '+CATEGORY_ALIAS+'. You will not be able to enter Learning mode for this '+CATEGORY_ALIAS+' until you finalize an exam with a score of '+PASSING_PERCENTAGE+'% or greater in Exam mode. Are you sure you want to enter Exam mode?';
export const LEARNING_MODE_RESTRICTED_ALERT_TITLE = 'Learning mode restricted';
export const LEARNING_MODE_RESTRICTED_ALERT_TEXT = 'Learning mode is restricted because you are currently taking the exam for this '+CATEGORY_ALIAS+'. You must finalize a passing exam in Exam mode in this '+CATEGORY_ALIAS+' before you can visit Learning mode again.';
export const CONFIRM_FINALIZE_EXAM_ALERT_TITLE = 'Exam complete';
export const CONFIRM_FINALIZE_EXAM_ALERT_TEXT = "Congratulations! You've completed all the questions in Exam mode. Would you like to finalize your exam to find out if you achieved a passing score of "+PASSING_PERCENTAGE+"% or would you like to return to your exam to review your answers?";
