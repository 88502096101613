import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getCategoryMode, getCategoryModeStatus, getCategoryPerformanceStats, empty, parseUrlParams, isCategoryPurchased, loadProjectCSS, describeArc, isCategoryInTesting } from './../utility/Shared'
import ModeIndicatorBar from './../common/ModeIndicatorBar'
import BackButton from './../common/BackButton'
import LoadingScreen from './../common/LoadingScreen'
import { ORG_STYLES, CATEGORIES_ALIAS, CATEGORY_ALIAS } from './../utility/Constants'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'



class PerformanceSummary extends Component {

	state = {
		loading: true
	}

	// make sure that userData has loaded properly. first get userData if we don't already have it. then assign the category in user position by getting url params
	async componentWillMount() {
		// if user navigated to this url manually, need to reload userData
		if(empty(this.props.userData)) {
			await this.props.screenProps.getUserDataFromDB();
        if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}

		// get category to load from url params and set category in user position
		let urlParams = parseUrlParams();
		let cid = '1';
		if(!empty(urlParams.cid)) {
			cid = urlParams.cid;
		} else {
			if(!empty(this.props.userData.position.category)) {
				cid = this.props.userData.position.category.id;
			}
		}
		let catIdx = cid - 1;
		let category = this.props.contentCategories[catIdx];

		// make sure user purchased this category. redirect if not.
		let isPurchased = isCategoryPurchased(this.props.userData,category.id);
		if(empty(isPurchased)) {
			this.props.screenProps.history.push('/'+CATEGORIES_ALIAS.toLowerCase());
			return false;
		}

		// load updated userData into redux
		this.props.screenProps.setUserPosition(this.props.userData,category);

		// sets the initial mode displayed toggle to whichever mode the user is in
		let currentMode = getCategoryMode(this.props.userData,this.props.userData.position.category.id);
		this.props.screenProps.setLandingPage(this.props.userData, currentMode);

		this.setState({loading: false});
	}


	render() {
		// console.log('PerformanceSummary.js render() this.props',this.props);

		if(this.state.loading || empty(this.props.userData)) {
			// show loading screen
			return (
				<LoadingScreen loadingText="Loading your performance summary..." />
			)
		}

		const categoryLearningStats = getCategoryPerformanceStats(this.props.userData,this.props.userData.position.category.id,'Learning');
		const categoryExamStats = getCategoryPerformanceStats(this.props.userData,this.props.userData.position.category.id,'Exam');
		const isExamInProgress = isCategoryInTesting(this.props.userData,this.props.userData.position.category.id);
		const isCeEligible = this.props.userData.userInfo.isCeEligible;


		return (
			<div className="container performance-summary-page">
				{!empty(isCeEligible) &&
					<ModeIndicatorBar />
				}
				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="back-button-container">
							<BackButton history={this.props.screenProps.history} label={CATEGORY_ALIAS} />
						</div>
						<div className="page-content-inner-layer">

							{/* the actual performance stats */}
							<div className="flex-col flex-centered-axes">
								<h1>{this.props.userData.position.category.name}</h1>
								<h2>Performance Summary</h2>
								<Table className="responsive-table">
						      <Thead>
						        <Tr>
											{!empty(isCeEligible) &&
							          <Th>SCORING DETAIL</Th>
											}
						          <Th>Total Questions</Th>
						          <Th>Number Answered</Th>
						          <Th>Percent Correct</Th>
						        </Tr>
						      </Thead>
						      <Tbody>
						        <Tr>
											{!empty(isCeEligible) &&
							          <Td>Learning Mode</Td>
											}
						          <Td>{categoryLearningStats['totalQuestionCount']}</Td>
						          <Td>{empty(categoryLearningStats['numberAnswered'])?"0":categoryLearningStats['numberAnswered']}</Td>
						          <Td>{Math.round(categoryLearningStats['percentCorrect'])}%</Td>
						        </Tr>
						        <Tr>
											{!empty(isCeEligible) &&
							          <Td>Exam Mode</Td>
											}
						          <Td>{categoryExamStats['totalQuestionCount']}</Td>
						          <Td>{empty(categoryExamStats['numberAnswered'])?"0":categoryExamStats['numberAnswered']}</Td>
						          <Td>{isExamInProgress ? "n/a" : (Math.round(categoryExamStats['percentCorrect']) + "%")}</Td>
						        </Tr>
						      </Tbody>
						    </Table>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	// console.log('PerformanceSummary.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers,
		modeDisplayed: state.landingReducers.modeDisplayed
	}
}

export default connect(mapStateToProps)(PerformanceSummary);
