import { SET_CATEGORY_MEDIA } from './actionTypes';
import { empty, getMediaUrl } from './../utility/Shared';

// preloads category media so can be accessed in Item.js via this.props.categoryMedia
export const setCategoryMediaToRedux = (category) => {
	// console.log('actions/mediaActions.js setCategoryMediaToRedux() category', category);

	let categoryMedia = {};
	if(!empty(category)) {
		categoryMedia[category.id] = {};
		category.questions.forEach(function(question,cIdx) {
			categoryMedia[category.id][question.id] = {};
			question.media.forEach(function(media,mIdx) {
				// preload thumbnail images (not for mp4s)
				if(media.type !== 'mp4') {
					const thumbMediaUrl = getMediaUrl(media.filename,false,true);
					let imgThumb = new Image();
					imgThumb.src = thumbMediaUrl;
					media.preloadedThumbnailImage = imgThumb;
				}

				// preload modal media
				const modalMediaUrl = getMediaUrl(media.filename,false);
				let imgModal = new Image();
				imgModal.src = modalMediaUrl;
				media.preloadedModalImage = imgModal;

				// put onto categoryMedia obj to be saved to redux
				categoryMedia[category.id][question.id][mIdx] = media;
			})
		});
	}

	return {
		type: SET_CATEGORY_MEDIA,
		categoryMedia
	}
}
