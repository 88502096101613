import React, { Component } from 'react'
import { connect } from 'react-redux'
import {empty,getLoginTokenPacket,hitApi,loadProjectCSS,objToArr,parseUrlParams} from './../utility/Shared'
import LoadingScreen from './../common/LoadingScreen'

class Sandbox extends Component {
	
	constructor(props) {
		super(props);
		
		/*
		// submit to a php file via ajax or redux
		this.payload = {};
		//this.loginPacket = getLoginTokenPacket(true);// for admin pages
		this.loginPacket = getLoginTokenPacket();// for non-admin pages
		this.payload.userInfo = this.loginPacket.userInfo;
		*/
		
		// bind function event handlers =============================
		//this.handleExport = this.handleExport.bind(this);
		// /bind function event handlers =============================
	}
	
	state = {
		loading: true,
	}
	
	async componentDidMount() {
		// for admin pages
		/* 
		// if user navigated to this url manually, need to reload licensing data
		if(empty(this.props.licensing) || empty(this.props.licensing.logoImageName)) {
			await this.props.screenProps.getLicensingData(true);
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		// if user navigated to this url manually, need to reload admin data
		if(empty(this.props.adminData) || empty(this.props.adminData.data.staticStats)) {
			await this.props.screenProps.getAdminData();
		}
		*/
		
		// for non-admin pages
		///*
		// if user navigated to this url manually, need to reload userData
		if(empty(this.props.userData)) {
			await this.props.screenProps.getUserDataFromDB();
		if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		//*/		
		
		await this.setState({loading:false});
	}// /async componentDidMount() {
	
	render() {
		// console.log('Sandbox.js render() this.props',this.props);

		if(this.state.loading) {
			return(
				<LoadingScreen loadingText="Loading your sandbox..." />
			)
		}
		
		let _this = this;
		
		return (<>
			<div className="container sandbox-page">
				
				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">
						
						<h1>Sandbox</h1>
						
						
						</div/* className="page-content-inner-layer"*/>
					</div/* className="page-content-outer-layer"*/>
				</div/* className="page-content"*/>
				
			</div/*  className="container sandbox-page" */>
		</>);
	}
}


const mapStateToProps = (state) => {
	// console.log('Sandbox.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		licensing: state.licensingReducers,
	}
}

export default connect(mapStateToProps)(Sandbox);