import React, { Component } from 'react'
import { connect } from 'react-redux'
import { array_unique, empty, getLoginTokenPacket, hitApi, objToArr, parseUrlParams, loadProjectCSS, isCategoryPurchased, getCategoryModeStatus, getCurrentTimestampStringUTC, navClaimCreditProcess } from './../utility/Shared'
import { CATEGORIES_ALIAS, CATEGORY_ALIAS, PASSING_PERCENTAGE } from './../utility/Constants'
import LoadingScreen from './../common/LoadingScreen'
import BackButton from './../common/BackButton'
import { confirmAlert } from 'react-confirm-alert' // Import
import './../css/react-confirm-alert.css' // Import css

class Evaluation extends Component {

	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		// init member vars
		this.EvalData = {};
		this.cid = false;
	}

	state = {
		loading: true,
	}

	async componentDidMount() {
		// if user navigated to this url manually, need to reload userData
		if(empty(this.props.userData)) {
			await this.props.screenProps.getUserDataFromDB();
      if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}

		// get category
		let urlParams = parseUrlParams();
		if(!empty(urlParams.cid)) {
			this.cid = urlParams.cid;
		} else if(!empty(this.props.userData.position.category)) {
			this.cid = this.props.userData.position.category.id;
		}
		// check to see if the current category is a part/child of a parent category. if so, assign this.cid to that
		this.cidOriginal = this.cid;
		if(!empty(this.props.userData.position.category.parentCategory)) {
			this.cid = this.props.userData.position.category.parentCategory.id;
		}

		// check if this category is eligible to submit an eval
		let redirectWasNecessary = this.redirectIfNecessary();
		if(redirectWasNecessary === true) {
			return; // stop executing component code
		} else {
			// set user position if different from current category
			if(!empty(this.props.userData.position) && !empty(this.props.userData.position.category) && this.props.userData.position.category.id !== this.cidOriginal) {
				let category = this.props.contentCategories[this.cidOriginal - 1];
				this.props.screenProps.setUserPosition(this.props.userData,category);
			}
		}

		// import category specific evaluation include
		try {
			this.Eval = require('../common/Evaluations/Eval-'+this.cid).default;
		}
		catch(error) {
			this.props.screenProps.history.push('/'+CATEGORIES_ALIAS.toLowerCase());
			return;
		}

		// get eval form data =====
		let payload = {};
		const loginPacket = getLoginTokenPacket();
		payload.userInfo = loginPacket.userInfo;
		payload.categoryId = this.cid;
		const apiResponse = await hitApi('getEvalDataSet',payload);//console.log('apiResponse:',apiResponse);
		this.EvalData = apiResponse.EvalData;
		this.EvalData = this.EvalData[this.cid];//console.log('this.EvalData:',this.EvalData);
		// /get eval form data =====

		await this.setState({loading:false});
	}// /async componentDidMount()

	// redirect user if necessary. to landing page if category is not in 'review' mode, or to claim credit screen if eval has already been completed. returns true if redirect was necessary and false if not necessary
	redirectIfNecessary() {
		// first check if user navigated here manually without a specified category id
		if(empty(this.cidOriginal)) {
			this.props.screenProps.history.push('/'+CATEGORIES_ALIAS.toLowerCase());
			return true;
		}

		// make sure user purchased this category. redirect if not.
		let isPurchased = isCategoryPurchased(this.props.userData,this.cidOriginal);
		if(empty(isPurchased)) {
			this.props.screenProps.history.push('/'+CATEGORIES_ALIAS.toLowerCase());
			return true;
		}

		// check if category is not in 'review' mode. redirect user to landing page if so
		let categoryModeStatus = getCategoryModeStatus(this.props.userData,this.cidOriginal,'Exam');
		if(categoryModeStatus !== 'review') {
			this.props.screenProps.history.push('/landing?cid='+this.cidOriginal);
			confirmAlert({
				message: 'You must achieve a score of '+PASSING_PERCENTAGE+'% or greater for this '+CATEGORY_ALIAS+' in Exam mode before you can submit an evaluation.',
				buttons: [{label: 'Ok'}]
			});
			return true;
		}

		// check if evaluation has already been completed for this category. redirect user to claim credit screen if so
		let existingCategoryEvalTimestamp = this.props.userData.evaluations[this.cid].timestamp;
		if(existingCategoryEvalTimestamp !== '0000-00-00 00:00:00') {
			this.props.screenProps.history.push('/claim-credit?cid='+this.cidOriginal);
			return true;
		}

		return false; // redirect was not necessary
	}

	/**
	* validate()
	* validate form fields
	* called by handleSubmit()
	*/
	validate($form) {
		//console.log('$form:',$form);
		let $alerted = false;
		let $validated = true;

		for (let $obj of $form) {// do NOT use forEach for this!!!
			//console.log('$obj:',$obj);
			//console.log('$obj.name:',$obj.name);
			//console.log('$obj.value:',$obj.value);
			//console.log('$obj.type:',$obj.type);

			// this is needed as the classname gets changed by handleFieldChange() below
			let $obj_classname = $obj.className.replace(/alert-validate/gi,'');

			// checkbox ===============
			// NOTE: will also check if a text is part of the array
			if ($obj.type == 'checkbox') {
				let $checks = document.getElementsByClassName($obj_classname);//console.log('$checks:',$checks);
				let $checks_validated = false;
				let $num_answer = 1;
				if ($obj.hasAttribute('data-num_answer')) {$num_answer = $obj.getAttribute('data-num_answer');}
				let $num_answered = 0;
				for(let $check of $checks) {
					//console.log('$check.type:',$check.type);
					//console.log('$check.value:',$check.value);
					if ($check.type == 'checkbox' && $check.checked) {
						$num_answered++;
					}
					if (
					($check.type == 'text' || $check.type == 'textarea')
					&& $check.value != ''
					) {
						$num_answered++;
					}
				}// /for(let $check of $checks) {
				if ($num_answered >= $num_answer) {$checks_validated = true;}
				if ($checks_validated == false) {
					$validated = false;
					for(let $check of $checks) {
						$check.classList.add('alert-validate');
					}// /for(let $rad of $rads) {
					if (!$alerted) {
						$alerted = true;
						$checks[0].focus();
						alert('Please check '+$num_answer+' or more options.');
					}
					//return false;
				}
			}// /if ($obj.type == 'checkbox') {
			// /checkbox ===============

			// radio ===============
			// NOTE: will also check if a text is part of the array
			if ($obj.type == 'radio') {
				let $rads = document.getElementsByClassName($obj_classname);//console.log('$rads:',$rads);
				let $rads_validated = false;
				let $alert = 'Please check an option.';
				let $focus = $rads[0];
				for(let $rad of $rads) {
					if ($rad.type == 'radio' && $rad.checked) {
						$rads_validated = true;
					}
					if ($rad.type == 'text') {
						//console.log('data-dependency:',document.getElementById($rad.getAttribute('data-dependency')));
						//console.log('data-dependency checked:',document.getElementById($rad.getAttribute('data-dependency')).checked);
						if (
							$rad.hasAttribute('data-dependency')
							&& document.getElementById($rad.getAttribute('data-dependency')).checked
							//&& $rad.value == ''
						) {
							let $validate_type = 'blank';
							if ($rad.hasAttribute('data-validate-type')) {$validate_type = $rad.getAttribute('data-validate-type');}
							if (
							$validate_type == 'blank'
							&& $rad.value == '') {
								$rads_validated = false;
								$alert = 'Please complete the field';
							}
							if (
							$validate_type == 'email'
							&& (
								$rad.value.indexOf('@') == -1
								|| $rad.value.search(/^([\w\.-])+@(([\w\.-])+)+\.([a-zA-Z0-9]){2,4}$/)
								)
							) {
								$rads_validated = false;
								$alert = 'There was a problem with your email address, please try again';
							}
							if (!$rads_validated) {
								//$rads_validated = false;
								$focus = $rad;
							}
						}
					}// /if ($rad.type == 'text') {
				}// /for(let $rad of $rads) {
				if ($rads_validated == false) {
					$validated = false;
					for(let $rad of $rads) {
						$rad.classList.add('alert-validate');
					}// /for(let $rad of $rads) {
					if (!$alerted) {
						$alerted = true;
						$focus.focus();
						alert($alert);
					}
					//return false;
				}
			}// /if ($v.type == 'radio') {
			// /radio ===============

			// text ===============
			if ($obj.type == 'text') {
				let $alert = 'Please complete the field.';
				if(
				$obj.hasAttribute('data-validate')
				&& $obj.getAttribute('data-validate') != 'text'
				) {
					continue;
				}
				let $validate_type = 'blank';
				if ($obj.hasAttribute('data-validate-type')) {$validate_type = $obj.getAttribute('data-validate-type');}
				if (
				$validate_type == 'blank'
				&& $obj.value == '') {
					$validated = false;
					$alert = 'Please complete the field.';
				}
				if (
				$validate_type == 'email'
				&& (
					$obj.val().indexOf('@') == -1
					|| $obj.val().search(/^([\w\.-])+@(([\w\.-])+)+\.([a-zA-Z0-9]){2,4}$/)
					)
				) {
					$validated = false;
					$alert = 'There was a problem with your email address, please try again';
				}
				//if ($obj.value == '') {
				if (!$validated) {
					//$validated = false;
					$obj.classList.add('alert-validate');
					if (!$alerted) {
						$alerted = true;
						$obj.focus();
						alert($alert);
					}
					//return false;
				}
			}// /if ($obj.type == 'text') {
			// /text ===============

			// textarea ===============
			if ($obj.type == 'textarea') {
				let $alert = 'Please complete the field.';
				if(
				$obj.hasAttribute('data-validate')
				&& $obj.getAttribute('data-validate') != 'textarea'
				) {
					continue;
				}
				let $validate_type = 'blank';
				if ($obj.hasAttribute('data-validate-type')) {$validate_type = $obj.getAttribute('data-validate-type');}
				if (
				$validate_type == 'blank'
				&& $obj.value == '') {
					$validated = false;
					$alert = 'Please complete the field.';
				}
				//if ($obj.value == '') {
				if (!$validated) {
					//$validated = false;
					$obj.classList.add('alert-validate');
					if (!$alerted) {
						$alerted = true;
						$obj.focus();
						alert($alert);
					}
					//return false;
				}
			}// /if ($obj.type == 'textarea') {
			// /textarea ===============

		}// /for (let $obj of $form) {
		//console.log('$form:',$form);

		// wait till ALL fields have been validated and css marked before returning false
		//alert($validated);
		if (!$validated) {return false;}
		return true;
	}// /validate() {

	/**
	* handleFieldChange()
	* validation function
	* runs on element onChange handler, see ../common/Evaluations/EvalShared.js and ../utility/Shared.js
	* removes the .alert-validate class from elements with the same classname when clicked or blurred
	*/
	handleFieldChange($e) {
		let $obj = $e.target;//console.log('hfc $obj:',$obj);
		let $els = document.getElementsByClassName($obj.className);//console.log('$els:',$els);
		let $els_validated = true;

		if ($obj.hasAttribute('data-num_answer')) {
			let $num_answer = $obj.getAttribute('data-num_answer');
			let $num_answered = 0;
			$els_validated = false;
			for(let $el of $els) {
				//console.log('$check.type:',$check.type);
				if ($el.type == 'checkbox' && $el.checked) {
					$num_answered++;
				}
				if ($el.type == 'text' && $el.value != '') {
					$num_answered++;
				}
			}// /for(let $el of $els) {
			if ($num_answered >= $num_answer) {$els_validated = true;}
		}// /if ($obj.hasAttribute('data-num_answer')) {

		if ($els_validated) {
			for(let $el of $els) {
				setTimeout(function(){$el.classList.remove('alert-validate');},50);
			}// /for(let $check of $checks) {
			return true;
		}// /if ($els_validated) {
	}// /handleFieldChange($e) {

	/**
	* handleSubmit()
	* form submission handler
	*/
	async handleSubmit($e) {
		//see https://medium.com/@everdimension/how-to-handle-forms-with-just-react-ac066c48bd4f
		//let $test = true;
		let $test = false;

		$e.preventDefault();
		let $form = $e.target;//console.log('$form:',$form);

		// validate ==========
		let $validate = this.validate($form);
		if (!$validate) {return false;}
		// /validate ==========

		let $userEvalResponses = {};
		let $fd = new FormData($form);//console.log('$fd:',$fd);//console.log('$fd.keys:',$fd.keys);
		for (let key of $fd.keys()) {
			//console.log('key:',key);
			let $value = $fd.get(key);

			let $new_key = key.replace('UserEvalResponses','').replace(/\[/gi,'').split(']');
			//console.log('$new_key:',$new_key);
			//console.log('$value:',$value);

			// build depth array
			let keysArr = [];
			let $objAtDepth = $userEvalResponses;
			$new_key.forEach(function(nKey,idx) {
				if(!($new_key[idx+1] === null)) {
					keysArr.push(nKey);
					// get to depth
					$objAtDepth = $userEvalResponses;
					keysArr.forEach(function(elem,idx2) {
						if(elem==="") {
							$objAtDepth['END-RESULT'] = $value;
						} else if(!$objAtDepth.hasOwnProperty(elem)) {
							$objAtDepth[elem] = {};
						} else {
							$objAtDepth = $objAtDepth[elem];
						}
					})
				}

			});
			// attach value

			// $userEvalResponses[key] = $fd.get(key);
		}
		if ($test) {
			console.log('$userEvalResponses',$userEvalResponses);
			console.log('$userEvalResponses',JSON.stringify($userEvalResponses));
			console.log('$userEvalResponses:',JSON.stringify($userEvalResponses, null, 2));
		}

// TW ==============================================================
/*
		$userEvalResponses = {};
		for (let key of $fd.keys()) {
			$userEvalResponses[key] = $fd.get(key);
		}

		// save to db
		let payload = {};
		const loginPacket = getLoginTokenPacket();
		payload.userInfo = loginPacket.userInfo;
		payload.userEvalResponses = $userEvalResponses;
		//console.log('payload:',payload);//console.log('payload:',JSON.stringify(payload));
		let apiResponse = '';
		let apiResponse = await hitApi('setUserEvalResponses',payload);//console.log('apiResponse:',apiResponse);
*/
// /TW ==============================================================

///*
		// call update userData in redux and db
		let updatedUserData = Object.assign({},this.props.userData);
		updatedUserData.evaluations[this.cid].responses = $userEvalResponses;
		updatedUserData.evaluations[this.cid].timestamp = getCurrentTimestampStringUTC();
		await this.props.screenProps.syncReduxAndDB(updatedUserData, 'syncEvaluations');

		confirmAlert({
			message: 'Thank you, your evaluation has been submitted.',
			buttons: [
				{
					label: 'Ok',
					onClick: () => {
						// bring user to Claim Credit form
						navClaimCreditProcess(this.props.screenProps.history, this.props.userData);
					}
				}
			]
		});
//*/
	}// /async handleSubmit($e)

	render() {
		// console.log('Evaluation.js render() this.props',this.props);

		if(this.state.loading || empty(this.props.userData)) {
			return(
				<LoadingScreen loadingText="Loading your evaluation..." />
			)
		}

		return (<>
			<div className="container Eval-page">
				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="back-button-container">
							<BackButton history={this.props.screenProps.history} label={CATEGORY_ALIAS} />
						</div>
						<div className="page-content-inner-layer">

						<h1>Evaluation</h1>
						<pre>{/* JSON.stringify(this.EvalData,'',4) */}</pre>

						<form onSubmit={this.handleSubmit} target="_blank" name="eval_common_test" id="eval-common-test" method="post" action="">
						<input type="hidden" name="process" id="" value="SaveEvaluation" />
						<div className="form2 form3">
							<h2>{this.EvalData['Category.CategoryName']}</h2>
							<p>Please complete this evaluation survey immediately after the examination. To receive a CME certificate, this evaluation form must be completed. As a VESAP5 participant, your opinion is valued. Your feedback will remain anonymous. Thank you for completing this evaluation.</p>
							<input type="hidden" name="UserEvalResponses[CategoryID]" id="" value={this.EvalData['Category.CategoryID']} />
							
							{/*
							this is the trick, include form elements file specific to the category id
							ex: ../common/Evaluations/Eval-1.js

							see componentDidMount() above:
							this.Eval = require('../common/Evaluations/Eval-'+this.cid).default;
							*/}
							<this.Eval EvalData={this.EvalData} cid={this.cid} handleFieldChange={this.handleFieldChange} />

							<input type="submit" value="Submit" />
						</div/*  className="form2 form3" */>
						</form>

						</div/* className="page-content-inner-layer"*/>
					</div/* className="page-content-outer-layer"*/>
				</div/* className="page-content"*/>
			</div/*  className="container Eval-page" */>
		</>);
	}
}


const mapStateToProps = (state) => {
	// console.log('Evaluation.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers,
	}
}

export default connect(mapStateToProps)(Evaluation);
