import React, { Component } from 'react'
import { empty } from './../utility/Shared'
import { connect } from 'react-redux'
import { FaRegBookmark } from 'react-icons/fa'

class AdminDashboardNonGraphStats extends Component {
	constructor(props) {
    super(props);
  }


  render() {
		// console.log('AdminDashboardNonGraphStats.js render() this.props',this.props);


    return (
			<div className="flex">
				<div className="flex-item-1 flex-center-cross flex-col">
					<FaRegBookmark style={{paddingLeft: 20, fontSize: 18}} />
					<p>Bookmarks</p>
					<p>{this.props.nonGraphStats.Bookmarks[this.props.interval]}</p>
				</div>
				<div className="flex-item-1 flex-center-cross flex-col">
					<FaRegBookmark style={{paddingLeft: 20, fontSize: 18}} />
					<p>Evaluations</p>
					<p>{this.props.nonGraphStats.Evaluations[this.props.interval]}</p>
				</div>
				<div className="flex-item-1 flex-center-cross flex-col">
					<FaRegBookmark style={{paddingLeft: 20, fontSize: 18}} />
					<p>Feedback</p>
					<p>{this.props.nonGraphStats.Feedback[this.props.interval]}</p>
				</div>
				<div className="flex-item-1 flex-center-cross flex-col">
					<FaRegBookmark style={{paddingLeft: 20, fontSize: 18}} />
					<p>Users Claimed Credit</p>
					<p>{this.props.nonGraphStats.UsersClaimedCredit[this.props.interval]}</p>
				</div>
			</div>
    );
  }
}


// export default AdminDashboardNonGraphStats;
function mapStateToProps(state) {
	// console.log('AdminDashboardNonGraphStats.js mapStateToProps() state',state);
	return {
		interval: state.adminReducers.dashboardInterval,
		nonGraphStats: state.adminReducers.data.intervalStats.nonGraph
	}
}

export default connect(mapStateToProps)(AdminDashboardNonGraphStats);
