import React, { Component } from 'react'
import { connect } from 'react-redux'
import { empty, navClaimCreditProcess } from './../utility/Shared'
import { confirmAlert } from 'react-confirm-alert'
import { IoIosStar, IoIosCheckmarkCircle } from "react-icons/io";
import { CATEGORY_ALIAS, PASSING_PERCENTAGE } from './../utility/Constants';


class ClaimButton extends Component {

	submitClaimCredit(e,categoryClaimInfo) {
		e.preventDefault();
		e.stopPropagation();

		if(categoryClaimInfo.claimStatus === 'ineligible') {
			confirmAlert({
				title: CATEGORY_ALIAS + ' not passed',
				message: 'You must achieve a score of '+PASSING_PERCENTAGE+'% or higher in Exam mode for this '+CATEGORY_ALIAS.toLowerCase()+' before you can claim credit. Good luck!',
				buttons: [{label: 'Ok'}]
			});
			return;
		}
		const category = this.props.contentCategories[parseInt(this.props.categoryId)-1];
		if(this.props.userData.position.category.id !== this.props.categoryId) {
			// need to set the position of the category we are claiming credit for since we're not already on it
			this.props.screenProps.setUserPosition(this.props.userData, category);
		}

		navClaimCreditProcess(this.props.screenProps.history, this.props.userData);
	}

	renderButtonContents(claimStatus) {
		switch (claimStatus) {
			case 'claimable':
				return (
					<div className="btn5 btn5-claim">
						<div>Claim Credit</div>
					</div>
				);
				break;
			case 'claimed':
				return (
					<div className="btn5 btn5-claim">
						<div>Credit Claimed</div>
					</div>
				);
				break;
			case 'ineligible':
				return (
					<div className="btn5 btn5-claim btn5-inactive">
						<span>Claim Credit</span>
					</div>
				);
				break;
			default:
				console.log('ERROR: Invalid claim status of '+claimStatus+' passed to renderButtonContents().');
		}
	}


  render() {
		// console.log('ClaimButton.js render() called. this.props',this.props);

		const isCeEligible = this.props.userData.userInfo.isCeEligible;
		if(empty(isCeEligible)) {
			return(null);
		}
		let categoryClaimInfo = this.props.userData.creditsClaimed[this.props.categoryId];

    return (
			<div onClick={(e) => this.submitClaimCredit(e,categoryClaimInfo)}>
				{this.renderButtonContents(categoryClaimInfo.claimStatus)}
			</div>
    );
  }
}


const mapStateToProps = (state) => {
	// console.log('ClaimButton.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		contentCategories: state.contentReducers,
	}
}

export default connect(mapStateToProps)(ClaimButton);
