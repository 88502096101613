import React, { Component } from 'react'
import { empty } from './../utility/Shared'
import { connect } from 'react-redux'

class Footer extends Component {

  render() {
		// console.log('Footer.js render() this.props',this.props);

    return (
			<div className="footer flex-col">
				<div className="footer-text">Powered by:</div>
				<img src={require('../images/svs-logo.png')} className="footer-logo" />
				<ul className="footer-details flex-row">
					<li className="footer-text footer-text-small">9400 W. Higgins Rd., Suite 315, Rosemont, Ill. 60018-4975</li>
					<li className="footer-text footer-text-small">312-334-2300</li>
					<li className="footer-text footer-text-small">800-258-7188</li>
				</ul>
			</div>
    );
  }
}


function mapStateToProps(state) {
	// console.log('Footer.js mapStateToProps() state',state);
	return {
		licensing: state.licensingReducers
	}
}

export default connect(mapStateToProps)(Footer);
