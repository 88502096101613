import React, { Component } from 'react'
import { connect } from 'react-redux'
import { empty, loadProjectCSS, objToArr, getProjectBasename, getLoginTokenPacket, getDateFromTimestamp, hitApi } from './../utility/Shared'
import { CATEGORY_ALIAS } from './../utility/Constants'
import HeaderSecondaryAdmin from './../common/HeaderSecondaryAdmin'
import BackButton from './../common/BackButton'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingScreen from './../common/LoadingScreen'
import { CSVLink } from "react-csv";


class CreditsClaimed extends Component {

	constructor(props) {
		super(props);
		this.navUser = this.navUser.bind(this);
		this.exportCSV = this.exportCSV.bind(this);
		this.csvRef = React.createRef();
  }

	state = {
		categoryId: 'all',
		creditsClaimedData: [],
		datePickerStart: '',
		datePickerEnd: '',
		rangeStartString: '',
		rangeEndString: '',
		csvData: [],
		loading: true,
	}

	async componentWillMount() {
		// if user navigated to this url manually, need to reload licensing data
		if(empty(this.props.licensing) || empty(this.props.licensing.logoImageName)) {
			await this.props.screenProps.getLicensingData(true);
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		// if user navigated to this url manually, need to reload admin data
		if(empty(this.props.adminData) || empty(this.state.staticStats)) {
			await this.props.screenProps.getAdminData();
		}
		
		// getting credits claimed data separately now since takes a while to load
		await this.fetchCreditsClaimed();

		this.moment = require('moment');
		
		this.updateCreditsClaimedResults();

		this.setState({loading: false});
	}
	
	async fetchCreditsClaimed() {
		// hit API for credits claimed report
		let payload = getLoginTokenPacket(true);
		let response = await hitApi('getCreditsClaimedData',payload);
		this.setState({creditsClaimedData: response.creditsClaimedData});
	}

	// updates results for params
	updateCreditsClaimedResults(categoryId=this.state.categoryId,startDate=this.state.datePickerStart,endDate=this.state.datePickerEnd) {

		// get start/end strings to filter results by
		let rangeStartString, rangeEndString;
		if(typeof startDate === 'object') {
			rangeStartString = this.moment(startDate).format("YYYY-MM-DD");
		}
		if(typeof endDate === 'object') {
			rangeEndString = this.moment(endDate).format("YYYY-MM-DD");
		}

		// build credits claimed records, filter for categoryId, rangeStart, and rangeEnd
		let creditsClaimedRecords = [];
		{	this.state.creditsClaimedData.map(function(claimData, idx) {
			if(
				(categoryId != 'all' && claimData.CategoryID != categoryId) ||
				(!empty(rangeStartString) && claimData.TIMESTAMP.split(' ')[0] < rangeStartString) ||
				(!empty(rangeEndString) && claimData.TIMESTAMP.split(' ')[0] > rangeEndString)
			) {
				return null;
			}
			creditsClaimedRecords.push(claimData);
		})}

		const creditsClaimedRecordsCount = creditsClaimedRecords.length;
		
		this.setState({categoryId, datePickerStart: startDate, datePickerEnd: endDate, creditsClaimedRecords, creditsClaimedRecordsCount, rangeStartString, rangeEndString});
	}
	
	// export credits claimed report to CSV
	async exportCSV() {

		let csvData = [];
		
		// get report data
		csvData.push([this.props.licensing.name + ' Credits Claimed Report']);
		let currentDate = new Date().toLocaleDateString("en-US");
		csvData.push(['Report Date',currentDate]);
		csvData.push([]);
		csvData.push([]);

		// add params & records count
		csvData.push([CATEGORY_ALIAS,this.state.categoryId]);
		csvData.push(['From date',this.state.rangeStartString]);
		csvData.push(['To date',this.state.rangeEndString]);
		csvData.push([]);
		csvData.push(['There are '+this.state.creditsClaimedRecordsCount+' records matching your search parameters.']);
		csvData.push([]);
		
		// add header
		csvData.push([CATEGORY_ALIAS,'SVS ID','First Name','Last Name','Credits','Product','CertType','Date']);
		
		// add category data
		objToArr(this.state.creditsClaimedRecords).map(function(claimData,idx) {
			// add row
			csvData.push([claimData.CategoryID,claimData.LmsID,claimData.FirstName,claimData.LastName,claimData.Credits,claimData.ProductPurchased,claimData.CertType,claimData.TIMESTAMP]);
		});

		await this.setState({csvData});
		
		// programatically click the download CSV button
		this.csvRef.current.link.click();
	}

	navUser(uuid) {
		this.props.screenProps.history.push('/admin/user-detail?uuid='+uuid);
	}
	

  render() {
		// console.log('CreditsClaimed.js render() this.props',this.props);

		if(this.state.loading) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText="Loading credits claimed..."/>
			)
		}

		const {categoryId, datePickerStart, rangeStartString, datePickerEnd, rangeEndString, creditsClaimedRecords, creditsClaimedRecordsCount } = this.state;

		const navUser = this.navUser;

    return (<>
			<div className="container container-admin credits-claimed-page">
				<HeaderSecondaryAdmin history={this.props.screenProps.history} />

				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">
							<div className="back-button-container">
								<BackButton history={this.props.screenProps.history} />
							</div>

							<h1>Credits Claimed</h1>
							<div className="flex">
								<select
									onChange={(e)=>this.updateCreditsClaimedResults(e.target.value)}
								>
									<option key={11} value={'all'}>All Modules</option>
									<option key={1} value={1}>Module 1</option>
									<option key={2} value={2}>Module 2</option>
									<option key={3} value={3}>Module 3</option>
									<option key={4} value={4}>Module 4</option>
									<option key={5} value={5}>Module 5</option>
									<option key={6} value={6}>Module 6</option>
									<option key={7} value={7}>Module 7</option>
									<option key={8} value={8}>Module 8</option>
									<option key={9} value={9}>Module 9</option>
									<option key={10} value={10}>Module 10</option>
								</select>
							</div>

							<DatePicker
								className="input-box"
								selected={datePickerStart}
								onChange={dateStart => this.updateCreditsClaimedResults(this.state.categoryId,dateStart,this.state.datePickerEnd)}
								peekNextMonth
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
								placeholderText="Click to select a start date"
							/>

							<DatePicker
								className="input-box"
								selected={datePickerEnd}
								onChange={dateEnd => this.updateCreditsClaimedResults(this.state.categoryId,this.state.datePickerStart,dateEnd)}
								peekNextMonth
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
								placeholderText="Click to select an end date"
							/>
							
							<br />
							<p><em>(results will include these dates - leave empty for no limit)</em></p>

							<p>There are {creditsClaimedRecordsCount} records matching your search parameters.</p>
							
							{/* Hidden CSVLink programatically triggered by exportCSV() on button click */}
							<CSVLink
								ref={this.csvRef}
								data={this.state.csvData}
								filename={this.props.licensing.name + ' credits claimed.csv'}
								style={{display:'none'}}
							>
								Download me
							</CSVLink>
							<div className="btn5 mgn-bottom-15" onClick={()=>this.exportCSV()}>
								<div>Export Data</div>
							</div>

							<div className="table-wrapper">
							<div className="table data-table">
								<div className="row row-header">
									<div className="cell">{CATEGORY_ALIAS}</div>
									<div className="cell">SVS ID</div>
									<div className="cell">First name</div>
									<div className="cell">Last name</div>
									<div className="cell">Credits</div>
									<div className="cell">Product</div>
									<div className="cell">CertType</div>
									<div className="cell">Date</div>
								</div>
							{	creditsClaimedRecords.map(function(claimData, idx) {
								// console.log('claimData:',claimData);
								//console.log('claimData.TIMESTAMP:',claimData.TIMESTAMP);
								return (
									<div key={idx} className="row">
										<div className="cell">{claimData.CategoryID}</div>
										<div className="cell">{claimData.LmsID}</div>
										<div className="cell link" onClick={()=>navUser(claimData.UserUUID)}>{claimData.FirstName}</div>
										<div className="cell link" onClick={()=>navUser(claimData.UserUUID)}>{claimData.LastName}</div>
										<div className="cell">{claimData.Credits}</div>
										<div className="cell">{claimData.ProductPurchased}</div>
										<div className="cell">{claimData.CertType}</div>
										<div className="cell">{claimData.TIMESTAMP}</div>
									</div>
								)
							})}
							</div>
							</div/*  className="table-wrapper" */>

						</div/* className="page-content"*/>
					</div/* className="page-content-outer-layer"*/>
				</div/* className="page-content-inner-layer"*/>
			</div>
    </>);
  }
}

const mapStateToProps = (state) => {
	// console.log('CreditsClaimed.js mapStateToProps() state',state);
	return {
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers,
		adminData: state.adminReducers
	}
}

export default connect(mapStateToProps)(CreditsClaimed);
