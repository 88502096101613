import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Tween } from "react-gsap";


class PageNotFound extends Component {

  render() {
		// console.log('PageNotFound.js render() this.props',this.props);

		return (
			<div className="container error-page">
				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">

							<div className="utility-content">
								<div className="utility-content-page">
									<div className="utility-content-col-left">
										<h1>Page not found</h1>
										<h2>Erm...</h2>
										<p>The page that you requested doesn't exist.</p>

										<p>Please try the following:</p>
										<p>Visit the VESAP5 page of the SVS website: <a href={"http://vascular.org/vascular-specialists/education-and-meetings/vesap5"}>http://vascular.org/vascular-specialists/education-and-meetings/vesap5</a></p>
										<p>Use the browser's Back button to navigate to the previous page.</p>
									</div>
									<div className="utility-content-col-right">
										<Tween from={{transform: 'scale(0.2)', opacity: 0.8}} duration={1.5} ease={'Back.easeOut'} ref={ref => this.tween = ref}>
											<img src={require("./../images/error-alert.svg")} width="350" height="300" alt="error icon"
												 className={"error-icon"}/>
										</Tween>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
    );
  }
}

const mapStateToProps = (state) => {
	// console.log('PageNotFound.js mapStateToProps() state',state);
	return {
		licensing: state.licensingReducers
	}
}

export default connect(mapStateToProps)(PageNotFound);
