/*
Reducer functions related to contentCategories object
*/
import { SET_CONTENT_DATA } from '../actions/actionTypes.js';

const contentReducers = (contentCategories = {}, action) => {
	// console.log('contentReducers contentCategories',contentCategories);
	// console.log('contentReducers action: ', action);

	switch (action.type) {
		case SET_CONTENT_DATA:
			let contentCategoriesToSet = Object.assign({},action.contentCategories);
			return contentCategoriesToSet;
		default:
			return contentCategories;
	}
}

export default contentReducers;
