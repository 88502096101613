import React, { Component } from 'react'
import { connect } from 'react-redux'
import { empty, parseUrlParams, setLoginSession, loadProjectCSS, routeUser, getAnonymousAuthPacket, hitApi } from './../utility/Shared'
import LoadingScreen from './../common/LoadingScreen'


class Auth extends Component {

	async componentDidMount() {
		// get url params
		let urlParams = parseUrlParams();
		if(empty(urlParams) || empty(urlParams.token)) {
			// redirect to vascular.org
			window.location.href = 'https://vascular.org/';
			return;
		}

		// decode token
		const decodedToken = decodeURIComponent(urlParams.token);

		// check to see if session is still valid
		let anonymousLoginPacket = getAnonymousAuthPacket();
		anonymousLoginPacket.data = decodedToken;
		const sessionResult = await hitApi('checkSessionToken',anonymousLoginPacket);
		if(empty(sessionResult.isValid)) {
			// redirect to vascular.org since user navigated to this page with an invalid token (maybe browser saved URL)
			window.location.href = 'https://vascular.org/';
			return;
		} else {
			// token is valid so set login session
			setLoginSession(decodedToken);
		}

		// get user data
		await this.props.screenProps.getUserDataFromDB();
		loadProjectCSS(this.props.licensing.cssFilename);

		// route user to appropriate page
		routeUser(this.props.screenProps.history, this.props.userData);
	}


  render() {
		// console.log('Auth.js render() this.props',this.props);

    return (<>
			<LoadingScreen loadingText="Maneuvering satellites into position..." />
    </>);
  }
}


const mapStateToProps = (state) => {
	// console.log('Auth.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers,
		screenDimensions: state.housekeepingReducers.screenDimensions
	}
}

export default connect(mapStateToProps)(Auth);
