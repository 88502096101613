import React, { Component } from 'react'
import { empty, getMediaUrl } from './../utility/Shared'
import Modal from 'react-modal'
import ReactPlayer from 'react-player'

const customModalStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
		zIndex								:	'10000'
  }
};


class MediaModal extends Component {
	constructor() {
	  super();
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

	state = {
		modalIsOpen: false,
		modalMedia: {},
	}

	componentDidMount() {
		Modal.setAppElement('body');
	}

	// set the media to be displayed and open modal
  openModal(media,isAdminUser=false) {
		// set modal media url
		if(media.type !== 'mp4') {
			media.url = getMediaUrl(media.filename,isAdminUser);
		} else {
			// do nothing, mp4 media urls are set on the server (since they don't depend on dev/live projects)
		}

		// set modal media in state
		const modalMedia = media;
    this.setState({modalIsOpen:true, modalMedia});
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

	openVideoModal() {
		// console.log('MediaModal.js openVideoModal() called');
	}

  render() {
		// console.log('MediaModal.js render() this.props',this.props);

		const modalIsOpen = this.state.modalIsOpen;
		const modalMedia = this.state.modalMedia;
		const openModal = this.openModal.bind(this);
		const afterOpenModal = this.afterOpenModal.bind(this);
		const closeModal = this.closeModal.bind(this);
		const openVideoModal = this.openVideoModal.bind(this);
		let modalMediaImgModalSrc = '';
		if(!empty(modalMedia) && modalMedia.hasOwnProperty('preloadedModalImage')) {
			// for public site, we have preloaded the media
			modalMediaImgModalSrc = modalMedia.preloadedModalImage.src;
		} else {
			// for exam builder, load normally
			modalMediaImgModalSrc = modalMedia.url;
		}

    return (
				<Modal
					isOpen={modalIsOpen}
					onAfterOpen={afterOpenModal}
					onRequestClose={closeModal}
					style={customModalStyles}
				>
					<div className="media-modal-container">
						<img src={require('../images/close-x.png')} className="modal-close-icon" onClick={closeModal} />
						{modalMedia.type !== 'mp4' ? (
							<img src={modalMediaImgModalSrc} />
						) : (
							<ReactPlayer
								url={modalMedia.url}
								controls={true}
								playing={true}
							/>
						)}
						{(modalMedia.caption != null) &&
						(<div className="media-modal-caption" dangerouslySetInnerHTML={{ __html: modalMedia.caption }}></div>)}
					</div>
				</Modal>
    );
  }
}

export default MediaModal;
