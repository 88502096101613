import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import LoadingScreen from './../common/LoadingScreen'
import BackButton from './../common/BackButton'
import { empty, stripTags, isCategoryPurchased, categoryByOrder, itemByCategoryOrderAndItemNumber, getCategoryModeStatus, getCategoryUserAnswers, loadProjectCSS, kmpSearch, parseUrlParams } from '../utility/Shared'
import { IS_DYNAMIC_SEARCH_ON, SEARCH_BAR_PLACEHOLDER, CATEGORY_ALIAS, CRITIQUE_ALIAS } from '../utility/Constants'
import { confirmAlert } from 'react-confirm-alert'

class Search extends Component {

	constructor(props) {
		super(props);

		this.handleSearchStringTextChange = this.handleSearchStringTextChange.bind(this);
		this.submitSearchFromMenu = this.submitSearchFromMenu.bind(this);

		window.onpopstate = this.onBackButtonEvent.bind(this);
	}

	state = {
		searchString: '',
		manuallySubmittedSearchString: '',
		searchResults: false,
		loading: true,
	}

	async componentWillMount() {
		// if user navigated to this url manually, need to reload userData
		if(empty(this.props.userData) || empty(this.props.contentCategories)) {
			await this.props.screenProps.getUserDataFromDB();
        if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}

		let urlParams = parseUrlParams();
		if(!empty(urlParams.term)) {
			await this.submitSearchFromMenu(urlParams.term);
		} else {
			this.setState({loading:false});
		}
	}

	// hijack the back button to search for previous url terms before actually leaving search page
	onBackButtonEvent(e) {
	 e.preventDefault();
	 const urlParams = parseUrlParams();
	 if(!empty(urlParams.term)) {
		 this.submitSearchFromMenu(urlParams.term);
	 }
	}

	// this function will be called when a search is done from the menu drawer nav
	async submitSearchFromMenu(searchTerm) {
		await this.setState({searchString:searchTerm,manuallySubmittedSearchString:searchTerm});
		this.handleSearchStringTextChange(null,true);
	}

	handleSearchStringTextChange(e,isManuallySubmitted=false) {
		if(!empty(e)) {
			// this function can be called via componentWillMount() when a search term is entered in the drawer nav
			e.preventDefault();
		}

		// scope functions and variables to be used in forEach function below
		let searchString = this.state.searchString;
		let manuallySubmittedSearchString = this.state.manuallySubmittedSearchString;
		if(!isManuallySubmitted) {
			searchString = e.target.value;
		} else {
			manuallySubmittedSearchString = searchString;
		}
		if(empty(searchString)) {
			this.setState({searchString,manuallySubmittedSearchString:"",searchResults:false});
			return;
		} else {
			this.setState({searchString,manuallySubmittedSearchString});
		}
		const getExcerpt = this.getExcerpt.bind(this);

		if(!isManuallySubmitted && !IS_DYNAMIC_SEARCH_ON) {
			// don't perform the search if the user did not manually click the search button and the dynamic search is off
			return;
		}

		// loop through categories and search the question, answers, critique, and references for the search term. if found, build object that contains the search term category/question, indexStart (which is the character index of the search string in the text that we will use later to get the excerpt), and location(s)
		let searchResults = [];
		for (const key of Object.keys(this.props.contentCategories)) {
			const category =  this.props.contentCategories[key];

			// don't search categories that the user hasn't purchased (locked)
			const categoryPurchased = isCategoryPurchased(this.props.userData,category.id);
			if(!categoryPurchased) {
				continue;
			}

			// search questions
			category.questions.forEach(function(question) {
				// build default search result object
				let searchResult = {
					'categoryOrder':category.order,
					'questionNumber':question.number,
					'excerpt': '',
					'locations': [],
				};

				// search question text
				let cleanedQuestionText = stripTags(question.text);
				const indexStart = kmpSearch(searchString,cleanedQuestionText);
				if(indexStart != -1) {
					// found! searchString exists in the question text.
					if(empty(searchResult.excerpt)) {
						searchResult.excerpt = getExcerpt(indexStart,searchString,cleanedQuestionText);
					}
					searchResult.locations.push('Question');
				}

				// search answers text
				const letterArr = ['A','B','C','D','E'];
				question.answers.forEach(function(answer,idx) {
					if(empty(answer.text)) {
						return;
					}
					let cleanedAnswerText = stripTags(answer.text);
					const indexStartAnswer = kmpSearch(searchString,cleanedAnswerText);
					if(indexStartAnswer != -1) {
						// found! searchString exists in the question text.
						if(empty(searchResult.excerpt)) {
							searchResult.excerpt = getExcerpt(indexStartAnswer,searchString,cleanedAnswerText);
						}
						searchResult.locations.push('Answer ' + letterArr[idx]);
					}
				})

				// search critique text
				let cleanedCritiqueText = stripTags(question.critique.text);
				const indexStartCritique = kmpSearch(searchString,cleanedCritiqueText);
				if(indexStartCritique != -1) {
					// found! searchString exists in the question text.
					if(empty(searchResult.excerpt)) {
						searchResult.excerpt = getExcerpt(indexStartCritique,searchString,cleanedCritiqueText);
					}
					searchResult.locations.push(CRITIQUE_ALIAS);
				}

				// search references text
				question.references.forEach(function(reference,idx) {
					if(empty(reference.text)) {
						return;
					}
					let cleanedReferenceText = stripTags(reference.text);
					const indexStartReference = kmpSearch(searchString,cleanedReferenceText);
					if(indexStartReference != -1) {
						// found! searchString exists in the question text.
						if(empty(searchResult.excerpt)) {
							searchResult.excerpt = getExcerpt(indexStartReference,searchString,cleanedReferenceText);
						}
						searchResult.locations.push('Reference ' + (idx+1));
					}
				})

				// search media captions
				question.media.forEach(function(media,idx) {
					if(empty(media.caption)) {
						return;
					}
					let cleanedMediaCaption = stripTags(media.caption);
					const indexStartMedia = kmpSearch(searchString,cleanedMediaCaption);
					if(indexStartMedia != -1) {
						// found! searchString exists in the media caption.
						if(empty(searchResult.excerpt)) {
							searchResult.excerpt = getExcerpt(indexStartMedia,searchString,cleanedMediaCaption);
						}
						searchResult.locations.push('Media caption');
					}
				})

				// if the excerpt is not empty, we found a match somewhere in the item. so push the searchResult object that was built onto the searchResults array that will be displayed
				if(!empty(searchResult.excerpt)) {
					// add on the locations array to the excerpt in brackets
					searchResult.excerpt = searchResult.excerpt + "<div style='margin-top:-1.3em;margin-bottom:1.3em'>[";
					searchResult.locations.forEach(function(location,idx) {
						searchResult.excerpt = searchResult.excerpt + location;
						if(!empty(searchResult.locations[idx+1])) {
							searchResult.excerpt = searchResult.excerpt + ", ";
						}
					})
					searchResult.excerpt = searchResult.excerpt + "]</div>";
					searchResults.push(searchResult);
				}
			});
		}

		this.setState({searchResults,loading:false});

	}

	// Searches for the given pattern string in the given text string using the Knuth-Morris-Pratt string matching algorithm. taken from https://stackoverflow.com/questions/1789945/how-to-check-whether-a-string-contains-a-substring-in-javascript
	// If the pattern is found, this returns the index of the start of the earliest match in 'text'. Otherwise -1 is returned.
	kmpSearch(pattern, text) {
    if (pattern.length == 0)
      return 0;  // Immediate match

    // Compute longest suffix-prefix table
    var lsp = [0];  // Base case
    for (var i = 1; i < pattern.length; i++) {
      var j = lsp[i - 1];  // Start by assuming we're extending the previous LSP
      while (j > 0 && pattern.charAt(i) != pattern.charAt(j))
        j = lsp[j - 1];
      if (pattern.charAt(i) == pattern.charAt(j))
        j++;
      lsp.push(j);
    }

    // Walk through text string
    var j = 0;  // Number of chars matched in pattern
    for (var i = 0; i < text.length; i++) {
      while (j > 0 && text.charAt(i) != pattern.charAt(j))
        j = lsp[j - 1];  // Fall back in the pattern
      if (text.charAt(i).toLowerCase() == pattern.charAt(j).toLowerCase()) {
        j++;  // Next char matched, increment position
        if (j == pattern.length)
          return i - (j - 1);
      }
    }
    return -1;  // Not found
	}

	// get the 50 characters before and after the search string, with elipses where applicable
	getExcerpt(indexOfSearchString,searchString,text) {
		let beforeText, afterText;
		if(indexOfSearchString > 50) {
			const startIndex = indexOfSearchString - 50;
			beforeText = '...' + text.slice(startIndex,indexOfSearchString);
		} else {
			beforeText = text.slice(0,indexOfSearchString);
		}

		afterText = text.slice((indexOfSearchString + searchString.length));
		if(afterText.length > 50) {
			afterText = afterText.slice(0,50) + '...';
		}

		// get actual search string from text (since it may be upper/lower cased there but not when user typed it into the search bar)
		const actualSearchString = text.slice(indexOfSearchString,(indexOfSearchString + (searchString.length)));

		const	excerpt = '<p>' + beforeText + '<span class="highlight">'+actualSearchString+'</span>' + afterText + '</p>';

		return excerpt;
	}

	// nav to requested search result item, as long as the item is not in a category that is in 'retake' mode and the item is not part of the 'retake' subset
	navToItem(categoryOrder,itemNumber) {
		// get category and item
		const category = categoryByOrder(this.props.contentCategories,categoryOrder);
		const item = itemByCategoryOrderAndItemNumber(this.props.contentCategories,categoryOrder,itemNumber);

		// check if we are allowed to nav there (pop alert if item is in 'retake' mode but not in the 'retake' subset)
		const modeStatus = getCategoryModeStatus(this.props.userData,category.id);
		if(modeStatus === 'retake') {
			let isItemRestricted = false;
			const userAnswers = getCategoryUserAnswers(this.props.userData,category.id);
			for (const key of Object.keys(userAnswers)) {
				const userAnswer = userAnswers[key];
				if(userAnswer.questionId === item.id && userAnswer.subset !== 'retake') {
					isItemRestricted = true;
				}
			}
			if(isItemRestricted) {
				confirmAlert({
					message: 'Sorry, you are currently retaking the Exam mode questions for this '+CATEGORY_ALIAS+' and this question is not a part of the available questions. Please finish the Exam and try again.',
					buttons: [{label: 'Ok'}]
				});
				return;
			}
		}

		// navigate to Item page
		this.props.screenProps.history.push('/item?cid='+category.id+'&qid='+item.id);
	}

  render() {
		// console.log('Search.js render() this.props',this.props);
		// console.log('Search.js render() this.state',this.state);

		if(this.state.loading || empty(this.props.userData)) {
			// show loading screen
			return (
				<LoadingScreen loadingText="Loading search page..." />
			)
		}

		const navToItem = this.navToItem.bind(this);

    return (
			<div className="container search-page">
				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="back-button-container">
							<BackButton history={this.props.screenProps.history} />
						</div>
						<div className="page-content-inner-layer">

							<h3>Search</h3>
							<div className="plain-text">Enter a search term and click Search.</div>

							<form onSubmit={(e) => this.handleSearchStringTextChange(e,true)}>
								<div className="flex-row search-input">
									<input className="" type="text" value={this.state.searchString} onChange={this.handleSearchStringTextChange} />
									{empty(IS_DYNAMIC_SEARCH_ON) &&
										<input className="btn5" type="submit" value="Search" />
									}
								</div>
							</form>

							{!empty(this.state.searchResults) &&
								<h4 className="search-results-head">Search results for <span className="highlight">{IS_DYNAMIC_SEARCH_ON ? this.state.searchString : this.state.manuallySubmittedSearchString}</span>: {this.state.searchResults.length}</h4>
							}

							{!empty(this.state.searchResults) &&
								<div className="flex-row flex-item-1 flex-row-sp-btw search-results-header">
									<div className="table-header-label search-left-col">Module / Item</div>
									<div className="table-header-label search-mid-col">Excerpt</div>
								</div>
							}

							{/* If no search results, notify user of no matches */}
							{((IS_DYNAMIC_SEARCH_ON && !empty(this.state.searchString) || !IS_DYNAMIC_SEARCH_ON && !empty(this.state.manuallySubmittedSearchString)) && empty(this.state.searchResults)) && !empty(this.state.searchString) &&
								<p>There are no matches for <span className="highlight">{IS_DYNAMIC_SEARCH_ON ? this.state.searchString : this.state.manuallySubmittedSearchString}</span></p>
							}

							<div className="search-results-list">
							{(!empty(this.state.searchString) && !empty(this.state.searchResults)) &&

								this.state.searchResults.map(function(searchResult, idx) {

									return (
										<div key={idx} onClick={() => navToItem(searchResult.categoryOrder, searchResult.questionNumber)} className="list-item flex-row">
											<div className="search-left-col">
												<p>{searchResult.categoryOrder} / {searchResult.questionNumber}</p>
											</div>
											<div className="search-mid-col" dangerouslySetInnerHTML={{ __html: searchResult.excerpt }}></div>
											<div className="search-right-col">
												<p>
													<img src={require("./../images/search-caret.svg")} width="22" height="22" alt=""/>
												</p>
											</div>
										</div>
									)
								})
							}
							</div>
						</div>
					</div>
				</div>
				{/* <Footer /> */}
			</div>
    );
  }
}

const mapStateToProps = (state) => {
	// console.log('Search.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers
	}
}

export default connect(mapStateToProps)(Search);
