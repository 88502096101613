import React, { Component } from 'react'
import { empty,eval_n_toptext,form_radio } from './../../utility/Shared'

class Eval3 extends Component {
	constructor(props) {
		super(props);
	}

	state = {
		loading: true,
	}

	async componentDidMount() {
		this.EvalShared = require('./EvalShared').default;
		await this.setState({loading:false});
	}


  render() {
		// console.log('Eval3.js render() this.props',this.props);
		// console.log('Eval3.js render() this.state',this.state);

		if(this.state.loading) {
			return(null);
		}
	
	let $EvalData = this.props.EvalData;
	let $cid = this.props.cid;
	let $qn = 3;
	
    return (<>
		<div className="eval-form-section">
		{eval_n_toptext()}
		<ul className="bullet-none">
		<li>{form_radio($EvalData,$cid,0,1,this.props.handleFieldChange)}</li>
		<li>{form_radio($EvalData,$cid,1,2,this.props.handleFieldChange)}</li>
		<li>{form_radio($EvalData,$cid,2,3,this.props.handleFieldChange)}</li>
		</ul>
		</div/*  className="eval-form-section" */>
		
		<this.EvalShared EvalData={$EvalData} cid={this.props.cid} qn={$qn} handleFieldChange={this.props.handleFieldChange} />
    </>);
  }
}

export default Eval3;
