import React, { Component, useRef } from 'react'
import { connect } from 'react-redux'
import { getCategoryMode, getCategoryModeStatus, getCategoryUserAnswers, navClaimCreditProcess, getCurrentTimestampStringUTC, getDateFromTimestamp, empty, parseUrlParams, isCategoryPurchased, loadProjectCSS, getEnterExamInstructions, isCategoryInTesting, getCategoryPerformanceStats, renderProgressCircle, getFormattedQuestionNumber, getBeginningExcerpt, getDerivedStateFromPropsValue } from './../utility/Shared'
import ModeIndicatorBar from './../common/ModeIndicatorBar'
import BackButton from './../common/BackButton'
import EnterMode from './../common/EnterMode'
import LoadingScreen from './../common/LoadingScreen'
import ClaimButton from './../common/ClaimButton'
import Tabs from './../common/Tabs'
import { confirmAlert } from 'react-confirm-alert'
import './../css/react-confirm-alert.css'
import { ENTER_EXAM_MODE_ALERT_TITLE, ENTER_EXAM_MODE_ALERT_TEXT, LEARNING_MODE_RESTRICTED_ALERT_TITLE, LEARNING_MODE_RESTRICTED_ALERT_TEXT, CATEGORIES_ALIAS, CATEGORY_ALIAS } from './../utility/Constants';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Tween } from "react-gsap";


class Landing extends Component {

	constructor(props) {
		super(props);
		this.textContainer = null;
		this.textContainerAnim = null;
		this.resetAllLearningResponses = this.resetAllLearningResponses.bind(this);
	}

	// we are tracking user position in state, solves an animation issue (see comment in Shared.js for getDerivedStateFromPropsValue())
	static getDerivedStateFromProps(nextProps, prevState) {
		const updatedState = getDerivedStateFromPropsValue('landing',nextProps, prevState);

		return updatedState;
	}

	state = {
		position: this.props.userData.position,
		loading: true
	}

	// make sure that userData has loaded properly. first get userData if we don't already have it. then assign the category in user position by getting url params
	async componentDidMount() {
		// console.log('Landing.js version 0.5.0');
		// if user navigated to this url manually, need to reload userData
		if(empty(this.props.userData)) {
			await this.props.screenProps.getUserDataFromDB();
      if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}

		// check if user has seen the welcome pages and redirect them there if not
		if(empty(this.props.userData.welcomeScreensViewedTimestamp)) {
			this.props.screenProps.history.push('/welcome');
			return;
		}

		// get category to load from url params and set category in user position
		let urlParams = parseUrlParams();
		let cid = '1';
		if(!empty(urlParams.cid)) {
			cid = urlParams.cid;
		} else if(!empty(this.state.position.category)) {
			cid = this.state.position.category.id;
		}
		let catIdx = cid - 1;
		let category = this.props.contentCategories[catIdx];

		// make sure user purchased this category. redirect if not.
		let isPurchased = isCategoryPurchased(this.props.userData,category.id);
		if(empty(isPurchased)) {
			this.props.screenProps.history.push('/'+CATEGORIES_ALIAS.toLowerCase());
			return;
		}

		// load updated position into redux
		await this.props.screenProps.setUserPosition(this.props.userData,category);

		// sets the initial mode displayed toggle to whichever mode the user is in
		let currentMode = getCategoryMode(this.props.userData,category.id);
		let currentModeStatus = getCategoryModeStatus(this.props.userData,category.id,'Exam');
		if(currentMode === 'Exam' && currentModeStatus === 'retake') {
			this.props.screenProps.setLandingPage(this.props.userData, currentMode,'retake');
		} else {
			this.props.screenProps.setLandingPage(this.props.userData, currentMode);
		}

		this.setState({loading: false});
	}

	// toggle mode view 'Learning' clicked
	viewLearningMode() {
		this.props.screenProps.setLandingPage(this.props.userData, 'Learning', 'all');
	}

	// toggle mode view 'Exam' clicked
	viewExamMode() {
		// if we are in exam retake mode, show the 'Retake' tab instead of the 'All' tab
		let examModeStatus = getCategoryModeStatus(this.props.userData,this.state.position.category.id,'Exam');
		if(examModeStatus === 'retake') {
			this.props.screenProps.setLandingPage(this.props.userData, 'Exam', 'retake');
		} else {
			this.props.screenProps.setLandingPage(this.props.userData, 'Exam', 'all');
		}
	}

	// when a filter tab of 'All', 'Unanswered', 'Incorrect', 'Retake' is clicked, only show the appropriate items in the list
	filterTabClicked(filterSelected) {
		// lowercase the filterSelected
		filterSelected = filterSelected.toLowerCase();

		// if the same filterSelected is the same as what was already being displayed, return
		if(filterSelected === this.props.filterSelected) {
			return;
		}

		// update redux store to show appropriate tabs and items
		this.props.screenProps.setLandingPage(this.props.userData, this.props.modeDisplayed, filterSelected);
	}

	// switch to Learning or Exam mode (if allowed) and navigate to item page
	navItemPageRequested(itemNumber) {
		let currentMode = getCategoryMode(this.props.userData, this.state.position.category.id);
		if(this.props.modeDisplayed === 'Learning') {
			// check if user is allowed to enter Learning mode. if they are currently in Exam mode and not in 'review' status, they are restricted from Learning mode until passing the exam
			if(isCategoryInTesting(this.props.userData,this.state.position.category.id)) {
				confirmAlert({
					title: LEARNING_MODE_RESTRICTED_ALERT_TITLE,
					message: LEARNING_MODE_RESTRICTED_ALERT_TEXT,
					buttons: [{label: 'Ok'}]
				});
				return;
			}

			// switch to Learning mode
			this.props.screenProps.setCategoryMode(this.props.userData, this.state.position.category.id, this.props.modeDisplayed);

			// navigate to item page
			this.navItemPage(itemNumber);

		} else if(this.props.modeDisplayed === 'Exam') {
			// if the user hasn't entered Exam mode yet, alert them that if they do, Learning mode for this category will be restricted until they submit a passing exam in Exam mode
			let examModeStatus = getCategoryModeStatus(this.props.userData, this.state.position.category.id, 'Exam');
			if(currentMode === 'Learning' && examModeStatus !== 'review') {
				// pop enter exam dialog. first configure how it will look below. we are using the customUI option so that we can style it exactly how we want it
				confirmAlert({
					title: ENTER_EXAM_MODE_ALERT_TITLE,
					message: ENTER_EXAM_MODE_ALERT_TEXT,
					buttons: [
						{
							label: 'Cancel',
						},
						{
							label: 'Continue', // was Yes, enter Exam mode
							onClick: () => {
								// switch to Exam mode
								this.props.screenProps.setCategoryMode(this.props.userData, this.state.position.category.id, this.props.modeDisplayed);

								// navigate to item page
								this.navItemPage(itemNumber);
							}
						}
					]
				});

			} else {
				// switch to Exam mode without alerting user (since in Exam mode review status here)
				this.props.screenProps.setCategoryMode(this.props.userData, this.state.position.category.id, this.props.modeDisplayed);
				this.navItemPage(itemNumber);
			}

		} else {
			console.log('ERROR: navItemPageRequested() mode of "'+this.props.modeDisplayed+'" is not valid.');
		}

	}

	// called from navItemPageRequested() once necessary alerts and confirmations have been made (e.g. to enter Exam mode, user needs to confirm since they won't be able to enter Learning mode until Exam mode has been passed)
	navItemPage(itemNumber) {

		// determine which item to navigate to
		let itemToNav;

		// if no itemNumber was passed, get the first item for the mode ('retake' mode takes some work to find the first 'retake' question)
		if(typeof itemNumber === 'undefined') {
			let currentModeStatus = getCategoryModeStatus(this.props.userData,this.state.position.category.id);

			if(currentModeStatus === 'retake') {
				// user is in 'retake' mode so bring them to the first 'retake' question. first get questionId of first 'retake' item
				let firstQuestionId = false;
				let userAnswers = getCategoryUserAnswers(this.props.userData,this.state.position.category.id);
				Object.keys(userAnswers).forEach(function(userAnswerKey) {
					let userAnswer = userAnswers[userAnswerKey];
					// store first 'retake' item question id
					if(userAnswer.subset === 'retake' && firstQuestionId === false) {
						firstQuestionId = userAnswer.questionId;
					}
				});

				// get 'retake' item to nav to based off of the firstQuestionId we just found
				this.state.position.category.questions.forEach(function(item) {
					if(item.id === firstQuestionId) {
						itemToNav = item;
					}
				});

			} else {
				// when not in retake mode, just navigate to first question if no itemNumber is passed
				itemToNav = this.state.position.category.questions[0];
			}
		} else {
			// we were passed an item number so navigate to that one
			this.state.position.category.questions.forEach(function(item) {
				if(item.number === itemNumber) {
					itemToNav = item;
				}
			});
		}

		// navigate to specified Item page
		let cid = this.state.position.category.id;
		let qid = itemToNav.id;
		this.props.screenProps.history.push('/item?cid='+cid+'&qid='+qid);
	}

	// user clicked the button to reset all learning responses. pop confirm box before doing so
	// NOTE: not being used since we removed the button for it for now
	resetAllLearningResponses() {
		confirmAlert({
			title: 'Reset Learning mode',
			message: 'Are you sure you want to reset all of your Learning mode responses for the ' + this.state.position.category.name + ' '+CATEGORY_ALIAS+'?',
			buttons: [
				{label: 'Cancel'},
				{label: 'Yes, reset responses',
				onClick: () => {
					// reset all learning mode responses for this category
					let updatedUserData = Object.assign({},this.props.userData);
					let userLearningResponses = getCategoryUserAnswers(updatedUserData,this.state.position.category.id,'Learning');
					let userAnswerTimestamp = getCurrentTimestampStringUTC();
					Object.keys(userLearningResponses).forEach(function(key) {
						let userAnswer = userLearningResponses[key];
						userAnswer.answered = false;
						userAnswer.timestamp = userAnswerTimestamp;
					});

					// update mode timestamp so syncing will be triggered on this category mode
					updatedUserData.categories[this.state.position.category.id].modes.Learning.timestamp = userAnswerTimestamp;

					// reset landing page
					this.props.screenProps.setLandingPage(updatedUserData, 'Learning', 'all');

					// update userData in redux/db
					this.props.screenProps.syncReduxAndDB(updatedUserData);
					
					this.alertLearningResponsesDeleted();
				}},
			]
		});
	}
	
	alertLearningResponsesDeleted() {
		// have to set timeout to call this confirmAlert since we need to wait for the other confirmAlert() box to close (which is easier than having to deal with the onClose hook which wouldn't call this function if user didn't confirm deletion)
		setTimeout(function(){
			confirmAlert({
				message: 'All your Learning mode responses for this '+CATEGORY_ALIAS+' have been reset.',
				buttons: [
					{
						label: 'Ok'
					}
				]
			});
		}, 500);
	}

	// nav to performance summary for this category
	navPerformanceSummary() {
		let cid = this.state.position.category.id;
		this.props.screenProps.history.push('/performance-summary?cid='+cid);
	}

	// evaluation/claim credit process
	claimCredit() {
		navClaimCreditProcess(this.props.screenProps.history, this.props.userData);
	}

	render() {
		// console.log('Landing.js render() this.props',this.props);

		if(this.state.loading || empty(this.props.userData)) {
			// show loading screen
			return (
				<LoadingScreen loadingText={"Loading your "+CATEGORY_ALIAS.toLowerCase()+" landing page..."} />
			)
		}

		// init vars

		const _this = this;
	  const landingPageInstructions = getEnterExamInstructions(this.props.userData,this.state.position.category.id).landingPageInstructions;
		const isCeEligible = this.props.userData.userInfo.isCeEligible;
		let categoryCreditsClaimedInfo = this.props.userData.creditsClaimed[this.state.position.category.id];
		let navItemPageRequested = this.navItemPageRequested.bind(this);
		const categoryStats = getCategoryPerformanceStats(this.props.userData,this.state.position.category.id,this.props.modeDisplayed);
		let score;
		if(isCategoryInTesting(this.props.userData,this.state.position.category.id) && this.props.modeDisplayed === 'Exam') {
			score = 'N/A';
		} else {
			score = categoryStats['numberAnswered'] + ' / ' + categoryStats['totalQuestionCount'];
		}

		// constants for size of the progress gauges
		const maxGaugeHeight = 160;

    return (
			<div className="container landing-page">
				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="back-button-container">
							<div className="info-row-item left">
								<BackButton history={this.props.screenProps.history} label={CATEGORIES_ALIAS} />
							</div>
							<div className="header-text">{this.state.position.category.name}</div>
								<div className="info-row-item right">
								{!empty(isCeEligible) &&
									<ClaimButton categoryId={this.state.position.category.id} screenProps={this.props.screenProps} />
								}
								</div>
						</div>
						<div className="page-content-inner-layer">
							{!empty(isCeEligible) &&
								<>
								<div className="plain-text centered">{landingPageInstructions}</div>

								<EnterMode categoryId={this.state.position.category.id} screenProps={this.props.screenProps} isLandingPage={true} />
								</>
							}


							{/* progress circle and score */}
							<div className="flex-col flex-centered-axes">
								<div className={"header-text-secondary "+(!empty(isCeEligible) ? "":"non-ce-user")}>Performance Summary</div>
								<div className="category-progress-indicator">
									<div className="progress-indicator-container" onClick={()=>this.navPerformanceSummary()}>
										{renderProgressCircle("circle1", categoryStats['numberAnswered'],categoryStats['totalQuestionCount'],this.props.licensing.cssThemePrefix, 87, 15, this.props.modeDisplayed)}

										<Tween from={{scale: 0}} duration={3} ease="Elastic.easeOut">
											<div className="progress-indicator-text-container">
												<div
													className="progress-indicator-text progress-indicator-percent">{String(Math.floor(categoryStats['numberAnswered'] / categoryStats['totalQuestionCount'] * 100))}%
												</div>
												<div className="landing-progress-text">
													<div>Answered</div>
												</div>
											</div>
										</Tween>
									</div>
								</div>
							</div>

							{/*buttons for toggling view of Learn/Exam mode*/}
							{!empty(isCeEligible) && (
								<div className="flex-centered-axes">
									<div className="toggle-buttons-container toggle-buttons-container-2">
										<div
										className={"btn-toggle btn-toggle-left " + (this.props.modeDisplayed==='Learning' ? " btn-toggle-selected " : " btn-toggle-unselected ")}
										onClick={() =>
											this.viewLearningMode()
										}
										>
											<div className={"buttonText" + (this.props.modeDisplayed==='Learning' ? "btn-toggle-text-active" : "btn-toggle-text-inactive")}>Learning Mode</div>
										</div>
										<div
										className={"btn-toggle btn-toggle-right " + (this.props.modeDisplayed==='Exam' ? "btn-toggle-selected" : "btn-toggle-unselected")}
										onClick={() =>
											this.viewExamMode()
										}
										>
											<div className={"buttonText" + (this.props.modeDisplayed==='Exam' ? "btn-toggle-text-active" : "btn-toggle-text-inactive")}>Exam Mode</div>
										</div>
									</div>
								</div>
							)}

							{/* show the 'Learning mode' or 'Exam mode' views depending on the toggle state */}
							{/* tabs for filtering all, unanswered, incorrect, retake items */}
							<Tabs activeTab={this.props.filterSelected} onClickFunction={this.filterTabClicked.bind(this)}>
								{this.props.isAllTabVisible && <div label='All'></div>}
								{this.props.isRetakeTabVisible && <div label='Retake'></div>}
								{this.props.isUnansweredTabVisible && <div label='Unanswered'></div>}
								{this.props.isIncorrectTabVisible && <div label='Incorrect'></div>}
							</Tabs>

							{/* list of filtered questions in category (if any) */}
							{this.props.filteredItems.length === 0 && (
								<p className="no-questions-prompt">There are no {this.props.filterSelected} questions.</p>
							)}
							<TransitionGroup className="list-items">
								{this.props.filteredItems.length !== 0 && ( this.props.filteredItems.map(function(item, idx) {
									let itemNumber = item.number;
									if(_this.props.modeDisplayed === 'Exam' && !empty(item.shuffleOrder)) {
										itemNumber = item.shuffleOrder;
									}
									const itemNumberFormatted = getFormattedQuestionNumber(itemNumber);
									const itemExcerpt = getBeginningExcerpt(item.text);
									return(
										<CSSTransition key={item.id} timeout={300} classNames="questionFade">
											<div className="flex-row flex-center-cross list-item" onClick={() => {
												navItemPageRequested(item.number)
											}} key={item.id}>
												<div className="item-number" dangerouslySetInnerHTML={{__html: itemNumberFormatted}}>
												</div>
												<div className="list-row">
													<div className="list-text">
														<div><p dangerouslySetInnerHTML={{__html: itemExcerpt}}></p></div>
													</div>
													<div className="list-item-options">
														<img src={require("./../images/arrow-right.png")} width="12" height="12" alt=""/>
													</div>
												</div>
											</div>
										</CSSTransition>
									);
								}))}
							</TransitionGroup>
							
							{this.props.modeDisplayed === 'Learning' &&
							<div className="mgn-top-15 btn5 btn5-claim" onClick={this.resetAllLearningResponses}>
								<div>Reset Learning Responses</div>
							</div>
							}
						</div>
					</div>
				</div>
			</div>
    );
  }
}

const mapStateToProps = (state) => {
	// console.log('Landing.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers,
		screenDimensions: state.housekeepingReducers.screenDimensions,
		isAllTabVisible: state.landingReducers.isAllTabVisible,
		isUnansweredTabVisible: state.landingReducers.isUnansweredTabVisible,
		isIncorrectTabVisible: state.landingReducers.isIncorrectTabVisible,
		isRetakeTabVisible: state.landingReducers.isRetakeTabVisible,
		filteredItems: state.landingReducers.filteredItems,
		filterSelected: state.landingReducers.filterSelected,
		modeDisplayed: state.landingReducers.modeDisplayed
	}
}

export default connect(mapStateToProps)(Landing);
