import { SET_LANDING_PAGE } from './actionTypes';

// set the mode that is being viewed on the Landing page and load the appropriate landing page item filter tabs (all, unanswered, incorrect, retake) and show the corresponding items
export const setLandingPage = (userData, modeDisplayed, filterSelected) => {
	return {
		type: SET_LANDING_PAGE,
		userData,
		modeDisplayed,
		filterSelected
	}
}
