import React, { Component } from 'react'
import { connect } from 'react-redux'
import { objToArr, navClaimCreditProcess, empty, isCategoryPurchased, loadProjectCSS, describeArc, getCategoryModeStatus, isCategoryInTesting, getCategoryPerformanceStats, getCategoryMode, renderProgressCircle, finalizeExam, getLoginTokenPacket, getProjectBasename, checkApiResponseErrors, handleBreakingError, getParentCategoryClaimStatus }  from './../utility/Shared'
import LoadingScreen from './../common/LoadingScreen'
import EnterMode from './../common/EnterMode'
import ClaimButton from './../common/ClaimButton'
import { FaCheck, FaLock, FaChevronDown, FaChevronUp} from 'react-icons/fa'
import { IoIosStar, IoIosInformationCircleOutline, IoMdCheckmark } from "react-icons/io";
import { confirmAlert } from 'react-confirm-alert'
import { ORG_STYLES, ENTER_EXAM_MODE_ALERT_TITLE, ENTER_EXAM_MODE_ALERT_TEXT, CATEGORIES_ALIAS, CATEGORY_ALIAS, MODULE_CREDIT_VALUE } from './../utility/Constants';
import { Tween } from "react-gsap";


class Categories extends Component {

	state = {
		loading: true,
	}

	async componentDidMount() {
		// console.log('Categories.js version 0.5.0');
		// if user navigated to this url manually, need to reload userData (which also gets this.props.contentCategories)
		if(empty(this.props.userData) || empty(this.props.contentCategories) || empty(this.props.licensing)) {
			await this.props.screenProps.getUserDataFromDB();
      if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}

		// check if user has seen the welcome pages and redirect them there if not
		if(empty(this.props.userData.welcomeScreensViewedTimestamp)) {
			this.props.screenProps.history.push('/welcome');
			return;
		}

		// update user position in redux and db
		if(!empty(this.props.userData.position.category) || !empty(this.props.userData.position.item)) {
			this.props.screenProps.setUserPosition(this.props.userData,false,false);
		}

		// initialize each category's detail display to be hidden
		let categories = {};
		for (const key of Object.keys(this.props.userData.categories)) {
			let userCategory = this.props.userData.categories[key];
			let category = {
				isDetailDisplayed: false,
			}
			categories[userCategory.id] = category;
		}

		this.setState({categories,loading: false});
	}

	countQuestionsAnswered(categories) {
		let answeredQuestionCount = 0;
		const isCeEligible = this.props.userData.userInfo.isCeEligible;
		const modeMeasured = !empty(isCeEligible) ? 'Exam' : 'Learning';
		for (const key of Object.keys(categories)) {
			const category = categories[key];
			for (const answersKey of Object.keys(category.modes[modeMeasured].answers)) {
				answeredQuestionCount +=
					(category.modes[modeMeasured].answers[answersKey].answered ||
						category.modes.Learning.answers[answersKey].answered) ? 1 : 0;
			}
		}
		return (
			answeredQuestionCount
		)
	}

	scaleProgressCircle = () => {
		let browserwidth = Math.min(960,document.body.clientWidth);
		let progressCircleRadius = Math.min(100, browserwidth / 8);
		return (
			progressCircleRadius / 44
		)
	}



	categoryPercentageCorrect = (category) => {
		const categoryExamModeStatus = getCategoryModeStatus(this.props.userData,category.id,'Exam');
		const modeToShowStats = (isCategoryInTesting(this.props.userData,category.id) || categoryExamModeStatus === 'review') ? 'Exam' : 'Learning';
		const categoryStats = getCategoryPerformanceStats(this.props.userData,category.id,modeToShowStats);
		const percentCorrect = categoryStats['numberCorrect'] / categoryStats['totalQuestionCount'];
		return (percentCorrect);
	}

	navPerformanceStats = (e,category) => {
		e.preventDefault();
		this.props.screenProps.history.push('/performance-summary?cid=' + category.id);
		return;
	}

	// evaluation/claim credit process
	claimCredit(e,category) {
		e.stopPropagation();

		this.props.screenProps.setUserPosition(this.props.userData,category);
		navClaimCreditProcess(this.props.screenProps.history, this.props.userData);
	}

	// notify user that they have not purchased the category
	purchaseModules1thru9() {
		confirmAlert({
			message: 'Purchase VESAP5 Modules 1-9 via the Society for Vascular Surgery website',
			buttons: [
				{label: 'Cancel'},
				{
					label: 'Continue',
					onClick: () => {
						window.location.href = 'http://vascular.org/vascular-specialists/education-and-meetings/vesap5';
					}
				}
			]
		});
	}

	toggleCategoryDetails(categoryId) {
		let categories = this.state.categories;
		categories[categoryId].isDetailDisplayed = !categories[categoryId].isDetailDisplayed;
		this.setState({categories});
	}

	navToUrl(url) {
		this.props.screenProps.history.push(url);
	}

	enterExam(e,category) {
		e.stopPropagation();

		confirmAlert({
			title: ENTER_EXAM_MODE_ALERT_TITLE,
			message: ENTER_EXAM_MODE_ALERT_TEXT,
			buttons: [
				{
					label: 'Cancel',
				},
				{
					label: 'Continue',  // was Yes, enter Exam mode
					onClick: () => {
						// switch to Exam mode
						this.props.screenProps.setCategoryMode(this.props.userData, category.id, 'Exam');

						// navigate to first item for category
						let firstQuestionId = false;
						for (var i = 0; i < category.questions.length; i++) {
							if(category.questions[i].number === "1") {
								firstQuestionId = category.questions[i].id;
								break;
							}
						}
						this.props.screenProps.history.push('/item?cid='+category.id+'&qid='+firstQuestionId);
					}
				}
			]
		});
	}

	render() {
		// console.log('Categories.js render() this.props',this.props);

		if(this.state.loading || empty(this.props.userData)) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText={"Loading your "+CATEGORIES_ALIAS.toLowerCase()+"..."} />
			)
		}

		// declare functions/vars to be used outside of 'this' scope below
		const purchaseModules1thru9 = this.purchaseModules1thru9.bind(this);
		const toggleCategoryDetails = this.toggleCategoryDetails.bind(this);
		const claimCredit = this.claimCredit.bind(this);
		const navToUrl = this.navToUrl.bind(this);
		const enterExam = this.enterExam.bind(this);
		const isCeEligible = this.props.userData.userInfo.isCeEligible;
		const screenProps = this.props.screenProps;
		const userData = this.props.userData;
		const cssThemePrefix = this.props.licensing.cssThemePrefix;
		let modules19purchased = true;
		let module10purchased = true;

		// build categoriesToDisplay array from this.props.contentCategories that includes parent categories. also attach claim status onto categories, as well as indicator of whether the user purchased this category or not, and learning mode status (in case user is a Non CE user, we show a checkmark if 'completed')
		let categories = Object.assign({},this.props.contentCategories);
		let categoriesArr = objToArr(categories);

		// constants for size of the progress gauges
		const maxGaugeHeight = 160;

		let totalQuestions = 0;
		let cmeCreditsClaimed = 0;
		let categoriesToDisplay = [];
		let categoryParentNumbersAdded = [];
		for (const key of Object.keys(categories)) {
			const category =  categories[key];

			// check if we have a parent category to add to categoriesToDisplay
			if(!empty(category.parentCategory) && categoryParentNumbersAdded.indexOf(category.parentCategory.number) === -1) {
				let parentCategory = category.parentCategory;
				categoryParentNumbersAdded.push(category.number);

				// add claim status
				const parentClaimObj = getParentCategoryClaimStatus(this.props.userData,this.props.contentCategories,category.number);
				parentCategory.claimStatus = parentClaimObj.claimStatus;
				parentCategory.claimTimestamp = parentClaimObj.timestamp;

				// add purchased status
				parentCategory.locked = !isCategoryPurchased(this.props.userData,category.id);
				if(parentCategory.locked) {
					module10purchased = false;
				}

				// add to categoriesToDisplay
				categoriesToDisplay.push(parentCategory);
			}

			// attach claim status
			category.claimStatus = this.props.userData.creditsClaimed[category.id].claimStatus;
			category.claimTimestamp = this.props.userData.creditsClaimed[category.id].timestamp;
			// attach purchased status
			category.locked = !isCategoryPurchased(this.props.userData,category.id);
			if(category.number < 10 && category.locked) {
				modules19purchased = false;
			}
			// attach learning mode status
			category.learningModeStatus = this.props.userData.categories[category.id].modes.Learning.status;

			// add to categoriesToDisplay
			categoriesToDisplay.push(category);

			// get questions answered and credits claimed numbers for top progress circles
			totalQuestions += category.questions.length;
			cmeCreditsClaimed += category.claimStatus === 'claimed' ? MODULE_CREDIT_VALUE : 0;
		}

		totalQuestions = !empty(isCeEligible) ? totalQuestions*2 : totalQuestions; // measuring both Exam and Learning questions answered
		let allQuestionsAnswered = this.countQuestionsAnswered(this.props.userData.categories);
		let progressFontSize = allQuestionsAnswered.toString().length > 3 ? 28 : 34;
		let ref = this;
		const letterMap = {1:'A',2:'B',3:'C',4:'D',5:'E',6:'F'};

		return (
			<div className="container categories-page">
				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">

							<div className="categories-sub-header">
								<h3>{this.props.licensing.name + ' ' + CATEGORIES_ALIAS}</h3>
								<p>Click on a {CATEGORY_ALIAS} or the {CATEGORY_ALIAS} Progress icon for quick links and performance summary.</p>
							</div>

							<div className="categories-content-container">

								<Tween wrapper={<div className="list-items categories-list-items" />}
									   staggerFrom={{opacity: 0, y: "+50"}}
									   duration={0.25}
									   stagger={0.1}>

									{objToArr(categoriesToDisplay).map(function (category, idx) {
										if(category.isParent) {
											return (
												<div key={category.id}>
													{
														(category.number === "10" && !module10purchased) &&
														<div className="btn5 btn5-purchase">
															<div>Purchase {CATEGORY_ALIAS} 10</div>
														</div>
													}

													<div className={"list-item " + (category.locked ? " list-item-locked" : "")} key={category.id}>

														{category.locked ? (
															<div className="list-item-container flex">
																<div className="category-list-item inactive-link text-inactive"
																	 onClick={() => purchaseModules1thru9()}>
																	<div className={"category-index-number"}>
																		{category.displayNum}
																		{(category.claimStatus === 'claimable' || category.claimStatus === 'claimed') &&
																		<div className="module-completed">
																			<img src={require("./../images/green-check.svg")} width="22" height="22"
																				 alt=""/>
																		</div>
																		}
																	</div>
																	<div className="category-list-name">
																		{category.name}
																	</div>
																</div>
																{/*<FaLock className="base-icon lock-icon"/>
																<div className="module-action-text module-action-text-3"
																	 onClick={(e) => purchaseModules1thru9(e, category)}>Purchase
																	{CATEGORY_ALIAS}
																</div>*/}
															</div>
														) : (
															<div className="list-item-container flex">
																<div className="category-list-item is-parent-category">
																	<div className={"category-index-number"}>
																		{category.number}
																		{(category.claimStatus === 'claimable' || category.claimStatus === 'claimed') &&
																		<div className="module-completed">
																			<img src={require("./../images/green-check.svg")} width="22" height="22"
																				 alt=""/>
																		</div>
																		}
																	</div>
																	<div className="category-list-name">
																		{category.name}
																	</div>
																</div>
															</div>
														)}
													</div>
												</div>
											)
										} /* end return if parent category */

										// if we got here, we have a regular category (not a parent category) so get info and display that
										const url = "/landing?cid=" + category.id;
										let isCompletedNonCME = false;
										if (empty(isCeEligible) && category.learningModeStatus === 'completed') {
											isCompletedNonCME = true;
										}
										const categoryLearningStats = getCategoryPerformanceStats(userData, category.id, 'Learning');
										const categoryExamStats = getCategoryPerformanceStats(userData, category.id, 'Exam');
										const isExamInProgress = isCategoryInTesting(userData, category.id);
										const categoryExamModeStatus = getCategoryModeStatus(userData, category.id, 'Exam');
										const modeToShowStats = (isCategoryInTesting(userData, category.id) || categoryExamModeStatus === 'review') ? 'Exam' : 'Learning';
										const categoryStats = getCategoryPerformanceStats(userData, category.id, modeToShowStats);
										const percentAnswered = Math.round(categoryStats['numberAnswered'] / categoryStats['totalQuestionCount'] * 100);
										const categorySubsection = category.displayNum.indexOf(".") > -1;
										const categorySubsectionLetter = (categorySubsection ? letterMap[category.displayNum.substr(category.displayNum.indexOf(".") + 1)] : "");

										return (
											<div key={category.id}>
												{
													(category.number === "1" && !modules19purchased) &&
													<div className="btn5 btn5-purchase" onClick={()=>purchaseModules1thru9()}>
														<div>Purchase {CATEGORIES_ALIAS} 1–9</div>
													</div>
												}
												<div
													className={"list-item " + (category.locked ? " list-item-locked" : "") + (categorySubsection ? " category-subsection" : "")}
													key={category.id}>


													{(category.locked) ? (
														<div className="list-item-container flex">
															<div className="category-list-item inactive-link text-inactive"
																 onClick={() => purchaseModules1thru9()}>
																<div className={"category-index-number"}>
																	{category.displayNum}
																	{(category.claimStatus === 'claimable' || category.claimStatus === 'claimed') &&
																	<div className="module-completed">
																		<img src={require("./../images/green-check.svg")} width="22" height="22"
																			 alt=""/>
																	</div>
																	}
																</div>
																<div className="category-list-name">
																	{category.name}
																</div>
															</div>
															{/*<FaLock className="base-icon lock-icon"/>
															{empty(category.parentCategory) &&
															<div className="module-action-text module-action-text-3"
																 onClick={(e) => purchaseModules1thru9(e, category)}>
																Purchase&nbsp;{CATEGORY_ALIAS}
															</div>
															}*/}
														</div>
													) : (
														<div className="list-item-container flex">
															<div
																onClick={() => navToUrl(url)}
																className="category-list-item">
																<div className={"category-index-number"}>
																	{categorySubsection ? categorySubsectionLetter : category.displayNum}
																	{(category.claimStatus === 'claimable' || category.claimStatus === 'claimed') &&
																	<div className="module-completed">
																		<img src={require("./../images/green-check.svg")} width="22" height="22"
																			 alt=""/>
																	</div>
																	}
																</div>
																<div className="category-list-name">
																	{category.name}
																</div>
															</div>
															<div className="category-info-icon"
																 onClick={() => toggleCategoryDetails(category.id)}>
																<img src={require("./../images/get-info-i.svg")} width="22" height="22" alt=""/>
															</div>
															{(category.claimStatus === 'claimed') &&
															<div className="module-action-text">Credit Claimed</div>
															}
															{(modeToShowStats === 'Learning' && categoryStats['numberAnswered'] === 0) &&
															<div className="module-action-text module-action-text-3">Not
																Started
															</div>
															}

														</div>
													)}

													<div
														className={"module-detail-container" + (ref.state.categories[category.id].isDetailDisplayed ? " display-module-detail" : "")}
														/*style={ref.state.categories[category.id].isDetailDisplayed ? {display: "inline-block"} : {}}*/>

														<div className="flex-col">
															<div className="flex-col perf-summary">
																<div
																	className={"flex-row perf-summary-header " + (!empty(isCeEligible) ? "" : "non-ce-user")}>
																	{!empty(isCeEligible) &&
																	<div className="table-cell text-align-left">PERFORMANCE SUMMARY</div>
																	}
																	<div className="table-cell">Total Questions</div>
																	<div className="table-cell">Number Answered</div>
																	<div className="table-cell">Percent Correct</div>
																</div>
																<div className="flex-row">
																	{!empty(isCeEligible) &&
																	<div className="table-cell text-align-left">Learning
																		Mode
																	</div>
																	}
																	<div
																		className="table-cell">{categoryLearningStats['totalQuestionCount']}</div>
																	<div
																		className="table-cell">{categoryLearningStats['numberAnswered']}</div>
																	<div
																		className="table-cell">{Math.round(categoryLearningStats['percentCorrect'])}%
																	</div>
																</div>
																{!empty(isCeEligible) &&
																<div className="flex-row">
																	<div className="table-cell text-align-left">Exam Mode
																	</div>
																	<div
																		className="table-cell">{categoryExamStats['totalQuestionCount']}</div>
																	<div
																		className="table-cell">{categoryExamStats['numberAnswered']}</div>
																	<div
																		className="table-cell">{isExamInProgress ? "n/a" : (Math.round(categoryExamStats['percentCorrect']) + "%")}</div>
																</div>
																}
															</div>

															<div className="flex-row buttons-col">
																<EnterMode categoryId={category.id} screenProps={screenProps}/>
																{!empty(isCeEligible) &&
																<ClaimButton categoryId={category.id} screenProps={screenProps}/>
																}
															</div>

														</div>

													</div>

												</div>
											</div>
										)
									})}

								</Tween>

								<div className="completion-tracker">
									<div className="progress-indicator-row-title">Completion Tracker</div>
									<div className={"progress-indicator-row "+(!empty(isCeEligible) ? "" : "non-ce-user")}>
											<div className="progress-indicator-container">
												{renderProgressCircle("circle1",allQuestionsAnswered, totalQuestions, this.props.licensing.cssThemePrefix,80, 15, "Learning")}
												<div className="progress-indicator-text-container">
													<div
														className="progress-indicator-text progress-indicator-number">{allQuestionsAnswered}</div>
													<div
														className="progress-indicator-text">QUESTION{(allQuestionsAnswered == 1) ? '' : 'S'}<br/> ANSWERED
													</div>
												</div>
											</div>
										{!empty(isCeEligible) &&
											<div className="progress-indicator-container">
												{renderProgressCircle("circle2",cmeCreditsClaimed, categoriesArr.length*MODULE_CREDIT_VALUE, this.props.licensing.cssThemePrefix, 80, 15, "Exam")}
												<div className="progress-indicator-text-container">
													<div
														className="progress-indicator-text progress-indicator-number">{cmeCreditsClaimed}</div>
													<div
														className="progress-indicator-text">CREDIT{(cmeCreditsClaimed == 1) ? '' : 'S'}<br/> CLAIMED
													</div>
												</div>
											</div>
										}
									</div>

									<div className="progress-indicator-row-title">KEY</div>
									<div className="categories-key-col">
										<div className="key-items">
											<div className="key-item">
												<img src={require("./../images/get-info-i.svg")} width="22" height="22"
													 alt=""/>
												<div className="key-text">{CATEGORY_ALIAS} Progress</div>
											</div>
											<div className="key-item">
												<FaLock className="base-icon lock-icon"/>
												<div className="key-text">Locked {CATEGORY_ALIAS}; pay to gain access</div>
											</div>
											{!empty(isCeEligible) &&
											<div className="key-item">
												<img src={require("./../images/green-check.svg")} width="22" height="22" alt=""/>
												<div className="key-text">Exam mode complete</div>
											</div>
											}
										</div>
									</div>

								</div>

							</div>

						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	// console.log('Categories.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers,
		screenDimensions: state.housekeepingReducers.screenDimensions
	}
}

export default connect(mapStateToProps)(Categories);
