import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getLoginTokenPacket, empty, loadProjectCSS, isProjectLive, getProjectBasename, parseUrlParams, getBaseUrl } from './../utility/Shared'
import { SESSION_NAME_USER } from './../utility/Constants'
import LoadingScreen from './../common/LoadingScreen'


class Logout extends Component {
	constructor(props) {
		super(props)

		if(isProjectLive(true)) {
			this.samlLogout();
		} else {
			this.redirectToLocalLogin();
		}
	}

	samlLogout() {
		this.logoutIfNotAlready();
		window.location.href = getBaseUrl() + '/api/logout.php';
	}

	// NOTE: this throws an error with animations if we do it when redirecting to local login...
	logoutIfNotAlready() {
		let loginPacket = getLoginTokenPacket(false,true);
		if(empty(loginPacket)) {
			// user is already logged out
			return false;
		}
		this.props.screenProps.logoutUser(loginPacket);
		this.logoutLocalStorageSession();
	}

	logoutLocalStorageSession() {
		// remove login "session"
		localStorage.removeItem(SESSION_NAME_USER);
	}

	redirectToLocalLogin() {
		if(this.props.isAppLoaded) {
			this.props.screenProps.history.push('/login_local');
		} else {
			// NOTE: hard redirect to login page required due to a mother flippen' mystery noted in the wiki
			let urlParams = parseUrlParams();
			if(urlParams.session === 'inactive') {
				window.location.href = getBaseUrl() + '/login_local?session=inactive';
			} else if(urlParams.alert === 'restricted') {
				window.location.href = getBaseUrl() + '/login_local?alert=restricted';
			} else {
				window.location.href = getBaseUrl() + '/login_local';
			}
		}
	}


  render() {
		// console.log('Logout.js render() this.props',this.props);

		return (<>
			<LoadingScreen loadingText="Thanks for visiting! Logging you out..."/>
		</>);
  }
}

// TODO: PropTypes doesn't work. I hate them.
Logout.propTypes = {

}

function mapStateToProps(state) {
	// console.log('Logout.js mapStateToProps() state',state);
	return {
	}
}

export default connect(mapStateToProps)(Logout);
