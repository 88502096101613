/*
Reducer functions related to userData object
*/
import { SET_USER_DATA } from '../actions/actionTypes.js';

const userDataReducers = (userData = {}, action) => {
	// console.log('userDataReducers userData',userData);
	// console.log('userDataReducers action: ', action);

	switch (action.type) {
		case SET_USER_DATA:
			let userDataToSet = Object.assign({},action.userData);
			return userDataToSet;
		default:
			return userData;
	}
}

export default userDataReducers;
