import { SET_CONTENT_DATA } from './actionTypes';
import { getLoginTokenPacket, handleBreakingError, checkApiResponseErrors, getProjectBasename } from './../utility/Shared';

// update redux and db content for an update made in exam builder. if apiAction and updatedContent are NOT false, we are doing an incremental update
// NOTE: if we want to implement incremental syncing, here's how to do it for a specific question: this.props.screenProps.updateReduxAndDBContent(this.props.contentCategories,'updateQuestion',updatedQuestion);
export const updateReduxAndDBContent = (contentCategories, apiAction='setContentCategories', updatedContent=false) => {

	return(dispatch) => {
		// update redux state with contentCategories object
		dispatch(setContentToRedux(contentCategories));

		// update database but asynchronously so that UI is fast. return a promise so that component updates while we are syncing with db
		return Promise.resolve().then(function() {
			dispatch(updateDbContent(contentCategories, apiAction, updatedContent));
		});
	}

}

// sends updatedContent to api to sync with db
export const updateDbContent = (contentCategories, apiAction='setContentCategories', updatedContent) => {
	// console.log('updateDbContent() called');
	let payload = {};
	payload.contentCategories = contentCategories;
	if(apiAction !== 'setContentCategories') {
		payload.updatedContent = updatedContent;
	}

	// attach user login token
	const loginPacket = getLoginTokenPacket(true);
	payload.userInfo = loginPacket.userInfo;
// console.log('SYNC PACKET payload',JSON.stringify(payload));
	return(dispatch, getState) => {

		return fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action='+apiAction, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload)
		})
		.then((response) => response.json())
		.then((updateDbContentResponse) => {
// console.log('updatedContentActions.js updateDbContentResponse',updateDbContentResponse);

			// check for errors so we don't assign an error message to redux
			checkApiResponseErrors(updateDbContentResponse,true);

			// assign updated contentCategories to redux (if ExamBuilder called this function by adding a question/answer/reference, the corresponding ID from the DB will have been assigned)
			dispatch(setContentToRedux(updateDbContentResponse.contentCategories));

		})
		.catch((error) => {
			console.log('ERROR: contentActions.js updateDbContent()', error);
			handleBreakingError('updateDbContent',error,updatedContent,true);
			throw new Error('Stop script execution with this uncaught exception.');
		});
	}
}


// sets contentCategories from login to redux
export const setContentToRedux = (contentCategories) => {
	// console.log('actions/contentActions.js setContentToRedux() contentCategories', contentCategories);

	return {
		type: SET_CONTENT_DATA,
		contentCategories
	}
}

// gets contentCategories object from api db and loads into redux
export const getContentCategories = (adminUser=false) => {
	// console.log('actions/userDataActions.js getContentCategories() called');

	// we don't have login creds so use login token stored in localStorage
	const loginPacket = getLoginTokenPacket(adminUser);

	return (dispatch) => {
		return fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action=getContentCategories', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(loginPacket)
		})
		.then((response) => response.json())
		.then((getContentCategoriesResponse) => {
// console.log('getContentCategoriesResponse',getContentCategoriesResponse);
			// check for errors so we don't assign an error message to redux
			checkApiResponseErrors(getContentCategoriesResponse);

			// update redux with api syncing update
			dispatch(setContentToRedux(getContentCategoriesResponse.contentCategories));

		})
		.catch((error) => {
			console.log('ERROR userDataActions.js getContentCategories(): ', error);
			handleBreakingError('getContentCategories',error,loginPacket,adminUser);
			throw new Error('Stop script execution with this uncaught exception.');
		});
	}
}
