import React, { Component } from 'react'
import { connect } from 'react-redux'
import { empty, loadProjectCSS, objToArr, getLoginTokenPacket, hitApi } from './../utility/Shared'
import HeaderSecondaryAdmin from './../common/HeaderSecondaryAdmin'
import BackButton from './../common/BackButton'
import LoadingScreen from './../common/LoadingScreen'
import { CSVLink } from "react-csv";


class FirstResponses extends Component {
	
	constructor(props) {
		super(props);
		this.fetchFirstResponses = this.fetchFirstResponses.bind(this);
		this.exportCSV = this.exportCSV.bind(this);
		
		this.csvRef = React.createRef();
	}

	state = {
		categoryId: 'all',
		csvData: [],
		loading: true,
	}

	async componentWillMount() {
		// if user navigated to this url manually, need to reload licensing data
		if(empty(this.props.licensing) || empty(this.props.licensing.logoImageName)) {
			await this.props.screenProps.getLicensingData(true);
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		// if user navigated to this url manually, need to reload admin data
		if(empty(this.props.adminData) || empty(this.props.adminData.data.staticStats)) {
			await this.props.screenProps.getAdminData();
		}

		this.setState({loading: false});
	}
	
	async fetchFirstResponses() {
		this.setState({isFetchingFirstResponseData:true});
		// hit API for first responses report
		let payload = getLoginTokenPacket(true);
		let response = await hitApi('getFirstResponsesData',payload);
		this.setState({firstResponsesData: response.firstResponsesData});
		this.setState({isFetchingFirstResponseData:false});
	}
	
	// export first responses report to CSV
	async exportCSV() {

		let csvData = [];
		
		// get report data
		csvData.push([this.props.licensing.name + ' First Responses Report']);
		let currentDate = new Date().toLocaleDateString("en-US");
		csvData.push(['Date',currentDate]);
		csvData.push([]);
		csvData.push([]);

		// add category data
		objToArr(this.state.firstResponsesData).map(function(categoryData,idx) {
			// add category title
			csvData.push([categoryData.CategoryOrder + '. ' + categoryData.CategoryName]);
			
			// add row data
			objToArr(categoryData.rows).map(function(itemStats,idx2) {
				csvData.push(itemStats);
			});
			
			// add blank rows between categories
			csvData.push([]);
			csvData.push([]);
		});

		await this.setState({csvData});
		
		// programatically click the download CSV button
		this.csvRef.current.link.click();
	}
	
	// NOTE: this report takes a while to generate since we're not using the getFirstResponsesData_OLD() function on the API that uses Zac's SQL wizardry. but it makes more sense and a few errors are fixed. left the old code commented out in the render() below incase someone wants to switch back to it someday (would also need to make API use the getFirstResponsesData_OLD() function too).
  render() {
		//console.log('FirstResponses.js render() this.props',this.props);

		if(this.state.loading) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText="Loading first responses page..."/>
			)
		}


    return (<>
			<div className="container container-admin first-responses-page">
				<HeaderSecondaryAdmin history={this.props.screenProps.history} />

				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">
							<div className="back-button-container">
								<BackButton history={this.props.screenProps.history} />
							</div>

							<h1>First Responses</h1>

							<p>The First Responses report below is data collected on how users answered each question the first time they saw it (if they reset the question and reanswered it, or answered it differently in a different exam, that information is recorded elsewhere). But knowing how users answered a question the first time they saw it could be valuable in determining the difficulty of a question, if it was poorly worded, etc.</p>

							{!empty(this.state.isFetchingFirstResponseData) ? (<>
								<div className="flex-row">
									<LoadingScreen loadingText="Gathering first responses data. This may take a minute or so..."/>
								</div>
							</>):(<>
								{!empty(this.state.firstResponsesData) ? (<>
									
									{/* Hidden CSVLink programatically triggered by exportCSV() on button click */}
									<CSVLink
										ref={this.csvRef}
										data={this.state.csvData}
										filename={this.props.licensing.name + ' first responses.csv'}
										style={{display:'none'}}
									>
										Download me
									</CSVLink>
									<button className="btn5" onClick={this.exportCSV}>
										<div>Export Data</div>
									</button>
									<br />
									
									{objToArr(this.state.firstResponsesData).map(function(categoryData,idx) {
										return(<React.Fragment key={idx}>
											<div className="table-wrapper">
											<h2>{categoryData.CategoryOrder}. {categoryData.CategoryName}</h2>
											<div key={idx} className="table data-table">
												{objToArr(categoryData.rows).map(function(itemStats,idx2) {
													let rowclass = 'row';
													if (idx2 === 0) {rowclass += ' row-header';}
													return(<React.Fragment key={idx2}>
														<div key={idx2} className={rowclass}>
														{itemStats.map(function(itemStat,idx3) {
															return(<React.Fragment key={idx3}>
																<div key={idx3} className="cell">
																	{itemStat}
																</div>
															</React.Fragment>)
														})}
														</div>
													</React.Fragment>)
												})}
											</div>
											</div>
										</React.Fragment>)
									})}
									
									
									{/*
									{objToArr(this.state.firstResponsesData).map(function(categoryData,idx) {
										return(<React.Fragment key={idx}>
											<div className="table-wrapper">
											<h2>{categoryData.info.CategoryOrder}. {categoryData.info.CategoryName}</h2>
											<div key={idx} className="table data-table">
												{objToArr(categoryData.row).map(function(itemStats,idx2) {
													let rowclass = 'row';
													if (idx2 === 0) {rowclass += ' row-header';}
													return(<React.Fragment key={idx2}>
														<div key={idx2} className={rowclass}>
														{itemStats.map(function(itemStat,idx3) {
															return(<React.Fragment key={idx3}>
																<div key={idx3} className="cell">
																	{itemStat}
																</div>
															</React.Fragment>)
														})}
														</div>
													</React.Fragment>)
												})}
											</div>
											</div>
										</React.Fragment>)
									})}
									*/}
								</>):(
									<button className="btn5" onClick={this.fetchFirstResponses}>
										<div>Fetch First Responses Data</div>
									</button>
								)}
							</>)}
							

						</div/* className="page-content"*/>
					</div/* className="page-content-outer-layer"*/>
				</div/* className="page-content-inner-layer"*/>

			</div>
    </>);
  }
}

const mapStateToProps = (state) => {
	// console.log('FirstResponses.js mapStateToProps() state',state);
	return {
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers,
		adminData: state.adminReducers
	}
}

export default connect(mapStateToProps)(FirstResponses);
