/*
Combines all reducers into one and exports it so we can pass it to createStore() function
*/

import { combineReducers } from 'redux';
import userDataReducers from './userDataReducers';
import contentReducers from './contentReducers';
import landingReducers from './landingReducers';
import licensingReducers from './licensingReducers';
import adminReducers from './adminReducers';
import housekeepingReducers from './housekeepingReducers';
import mediaReducers from './mediaReducers';

const allReducers = combineReducers({
	userDataReducers,
	contentReducers,
	landingReducers,
	licensingReducers,
	adminReducers,
	housekeepingReducers,
	mediaReducers,
});

export default allReducers;
