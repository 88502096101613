/*
Reducer functions related to the licensingData object
*/
import { SET_LICENSING_DATA } from '../actions/actionTypes.js';

const licensingReducers = (licensingData = {}, action) => {
	// console.log('licensingReducers licensingData',licensingData);
	// console.log('licensingReducers action: ', action);

	switch (action.type) {
		case SET_LICENSING_DATA:
			let licensingDataToSet = Object.assign({},action.licensingData);
			return licensingDataToSet;
		default:
			return licensingData;
	}
}

export default licensingReducers;
