/*
Reducer functions related to housekeeping stuff screen dimensions
*/
import { SET_SCREEN_DIMENSIONS } from '../actions/actionTypes.js';

const housekeepingReducers = (housekeepingStatus = {}, action) => {
	// console.log('housekeepingReducers housekeepingStatus',housekeepingStatus);
	// console.log('housekeepingReducers action: ', action);

	let housekeepingStatusToSet = {};
	switch (action.type) {
		case SET_SCREEN_DIMENSIONS:
			housekeepingStatusToSet = Object.assign({},
				housekeepingStatus,
				{screenDimensions: action.screenDimensions}
			);
			return housekeepingStatusToSet;
		default:
			return housekeepingStatus;
	}
}

export default housekeepingReducers;
