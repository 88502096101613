import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { empty, parseUrlParams } from './../utility/Shared'
import HeaderSecondaryAdmin from './../common/HeaderSecondaryAdmin'
import LoadingScreen from './../common/LoadingScreen'
import { Tween } from "react-gsap";

class ErrorPage extends Component {

	state = {
		isFatal: false,
		loading: true,
	}

	async componentWillMount() {
		// first determine if we have a fatal error, meaning this very error page api calls threw an error. if so, we should not make any api calls
		const urlParams = parseUrlParams();
		if(!empty(urlParams) && !empty(urlParams.fatal)) {
			this.setState({isFatal:true});
		} else {
			// if user navigated to this url manually, need to reload licensing data
			if(empty(this.props.licensing) || empty(this.props.licensing.logoImageName)) {
				await this.props.screenProps.getLicensingData(true);
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			}
		}

		this.setState({loading: false});
	}

  render() {
		// console.log('ErrorPage.js render() this.props',this.props);

		if(this.state.loading) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText="Loading Error page..."/>
			)
		}

		return (<>
		<div className="container container-admin error-page">
			<HeaderSecondaryAdmin history={this.props.screenProps.history} />

			<div className="page-content">
				<div className="page-content-outer-layer">
					<div className="page-content-inner-layer">

						<div className="utility-content">
							<div className="utility-content-page">
								<div className="utility-content-col-left">
									<h1>Oh no!</h1>
									<h2>An error occurred.</h2>
									<p>The page that you requested cannot be found or is temporarily unavailable.</p>

									<p>Please try the following:</p>
									<p>Use the browser's Back button to navigate to the previous page.</p>
									<p><Link to={'/admin/logout'}>Logout</Link> and log back in.</p>
								</div>
								<div className="utility-content-col-right">
									<Tween from={{transform: 'scale(0.2)', opacity: 0.8}} duration={1.5} ease={'Back.easeOut'} ref={ref => this.tween = ref}>
										<img src={require("./../images/error-alert.svg")} width="350" height="300" alt="error icon"
											 className={"error-icon"}/>
									</Tween>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		</>);

  }
}

const mapStateToProps = (state) => {
	// console.log('ErrorPage.js mapStateToProps() state',state);
	return {
		licensing: state.licensingReducers
	}
}

export default connect(mapStateToProps)(ErrorPage);
