import React, { Component } from 'react'
import { connect } from 'react-redux'
import { autop, empty, loadProjectCSS, getCategoryById, getQuestionById } from './../utility/Shared'
import { CATEGORIES_ALIAS, CATEGORY_ALIAS } from './../utility/Constants'
import HeaderSecondaryAdmin from './../common/HeaderSecondaryAdmin'
import BackButton from './../common/BackButton'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingScreen from './../common/LoadingScreen'
import { CSVLink } from "react-csv";


class QuestionFeedback extends Component {

	constructor(props) {
		super(props);
		this.handleModuleSelection = this.handleModuleSelection.bind(this);
		this.navUser = this.navUser.bind(this);
		this.exportCSV = this.exportCSV.bind(this);
		this.csvRef = React.createRef();
	}

	state = {
		categoryId: 'all',
		datePickerStart: '',
		datePickerEnd: '',
		rangeStartString: '',
		rangeEndString: '',
		csvData: [],
		loading: true,
		afqQn: null,
	}

	async UNSAFE_componentWillMount() {
		// if user navigated to this url manually, need to reload licensing data
		if(empty(this.props.licensing) || empty(this.props.licensing.logoImageName)) {
			await this.props.screenProps.getLicensingData(true);
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		// if user navigated to this url manually, need to reload admin data
		if(empty(this.props.adminData) || empty(this.props.adminData.data.staticStats)) {
			await this.props.screenProps.getAdminData();
		}

		this.moment = require('moment');

		this.setState({loading: false});
	}

	navUser(uuid) {
		this.props.screenProps.history.push('/admin/user-detail?uuid='+uuid);
	}

	handleModuleSelection(e) {
		this.setState({categoryId:e.target.value});
	}

	setRangeStart(date) {
		const rangeStartString = this.moment(date).format("YYYY-MM-DD");
		this.setState({datePickerStart:date,rangeStartString});
	}

	setRangeEnd(date) {
		const rangeEndString = this.moment(date).format("YYYY-MM-DD");
		this.setState({datePickerEnd:date,rangeEndString});
	}

	afqShowHideClick(event,qn) {
		//console.log('qn:',qn);
		if (qn == this.state.afqQn) {this.setState({afqQn:null});}
		else {this.setState({afqQn:qn});}
	}// /afqShowHideClick(event,idx) {
		
	// get count of records with filter applied
	getRecordsCount(data) {
		let recordsCount = 0;

		data.forEach(function(category,idx) {
			category.questions.forEach(function(question,idx2) {
				recordsCount += question.feedback.length;
			})
		});

		return recordsCount;
	}
		
	// export question feedback report to CSV
	async exportCSV() {

		let csvData = [];
		
		// get report data
		csvData.push([this.props.licensing.name + ' Question Feedback Report']);
		let currentDate = new Date().toLocaleDateString("en-US");
		csvData.push(['Report Date',currentDate]);
		csvData.push([]);
		csvData.push([]);

		// add params & records count
		csvData.push([CATEGORY_ALIAS,this.state.categoryId]);
		csvData.push(['From date',this.state.rangeStartString]);
		csvData.push(['To date',this.state.rangeEndString]);
		csvData.push([]);
		csvData.push(['There are '+this.feedbackRecordsCount+' records matching your search parameters.']);
		csvData.push([]);
		
		// add header
		csvData.push([CATEGORY_ALIAS,'Question Number','First Name','Last Name','Feedback','Date']);
		
		// add data
		this.data.forEach(function(category,idx) {
			category.questions.forEach(function(question,idx2) {
				question.feedback.forEach(function(feedback,idx3) {
					csvData.push([category.number,question.number,feedback.FirstName,feedback.LastName,feedback.Text.replace(/,/g, ''),feedback.TIMESTAMP]);
				});
			});
		});
		
		
		await this.setState({csvData});

		// programatically click the download CSV button
		this.csvRef.current.link.click();
	}

  render() {
		//console.log('QuestionFeedback.js render() this.props',this.props);

		if(this.state.loading) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText="Loading question feedback..."/>
			)
		}

		const {categoryId, datePickerStart, rangeStartString, datePickerEnd, rangeEndString } = this.state;


		// DATA ===================================================================
		/*
		reorganize this.props.adminData.data.questionFeedback into this structure:
		Category
			Questions
				Answers
				User Feedback
		*/
		const _this = this;// for use inside functions (map, etc.) below
		let qn = 0; // key counter for questions
		let data = [];// holds the reorganized questionFeedback data.
		// get list of category ids
		this.props.adminData.data.questionFeedback.map(function(feedback, idx) {
			if(
				(categoryId !== 'all' && feedback.CategoryID !== categoryId) ||
				(!empty(rangeStartString) && feedback.TIMESTAMP < rangeStartString) ||
				(!empty(rangeEndString) && feedback.TIMESTAMP > rangeEndString)
			) {
				return null;
			}
			//console.log('feedback:',feedback);
			data.push(feedback.CategoryID);
			return null;
		});// /{this.props.adminData.data.questionFeedback.map(function(feedback, idx) {
		// rem duplicates from cat list
		data = data.filter((v, i, a) => a.indexOf(v) === i);// see https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
		//console.log('data:',data);

		// loop cat list and populate
		data.forEach(function($v,$k,$a){
			// set category from passed in id -- $v
			let cat = Object.assign({},getCategoryById(_this.props.contentCategories,$v));
			// rem the questions array from the above
			delete cat['questions'];
			// set new questions array
			cat['questions'] = [];
			// get list of question ids
			_this.props.adminData.data.questionFeedback.map(function(feedback,idx) {
				//console.log('feedback:',feedback);
				if (feedback['CategoryID'] == $v) {
					cat['questions'].push(feedback['QuestionID']);
				}
				return null;
			});// /_this.props.adminData.data.questionFeedback.map(function(feedback,idx) {
			// rem duplicates from questions list
			cat['questions'] = cat['questions'].filter((v, i, a) => a.indexOf(v) === i);
			// set feedbacks per question
			cat['questions'].forEach(function($v2,$k2,$a2){
				// set question from id, i.e. $v2 -- NOTE: answers come in with question, no need to modify them
				cat['questions'][$k2] = Object.assign({},getQuestionById(_this.props.contentCategories,$v,$v2));
				qn++;
				cat['questions'][$k2]['qn'] = qn;
				// set feedback array
				cat['questions'][$k2]['feedback'] = [];
				_this.props.adminData.data.questionFeedback.map(function(feedback,idx) {
					//console.log('feedback:',feedback);
					if (feedback['QuestionID'] == cat['questions'][$k2]['id']) {
						cat['questions'][$k2]['feedback'].push(feedback);
					}
					return null;
				});// /_this.props.adminData.data.questionFeedback.map(function(feedback,idx) {
				return null;
			});// /cat['questions'].forEach(function($v2,$k2,$a2){

			// set organized category into data array
			data[$k] = cat;
			return null;
		});//data.forEach(function($v,$k,$a){
		// console.log('data:',data);
		
		this.data = data;
		this.feedbackRecordsCount = this.getRecordsCount(data);
		
		// /DATA ===================================================================

		const navUser = this.navUser;

    return (<>
			<div className="container container-admin question-feedback-page">
				<HeaderSecondaryAdmin history={this.props.screenProps.history} />

				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">
						
							<div className="back-button-container">
								<BackButton history={this.props.screenProps.history} />
							</div>

							<h1>Question Feedback</h1>
							{/* <p className="info-text"></p> */}

							<div className="flex">
								<select
									onChange={this.handleModuleSelection}
								>
									<option key={11} value={'all'}>All {CATEGORIES_ALIAS}</option>
									<option key={1} value={1}>1</option>
									<option key={2} value={2}>2</option>
									<option key={3} value={3}>3</option>
									<option key={4} value={4}>4</option>
									<option key={5} value={5}>5</option>
									<option key={6} value={6}>6</option>
									<option key={7} value={7}>7</option>
									<option key={8} value={8}>8</option>
									<option key={9} value={9}>9</option>
									<option key={10} value={10}>10</option>
								</select>
							</div>

							<DatePicker
								className="input-box"
								selected={datePickerStart}
								onChange={date => this.setRangeStart(date)}
								peekNextMonth
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
								placeholderText="Click to select a start date"
							/>

							<DatePicker
								className="input-box"
								selected={datePickerEnd}
								onChange={date => this.setRangeEnd(date)}
								peekNextMonth
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
								placeholderText="Click to select an end date"
							/>

							<p>There {this.feedbackRecordsCount === 1 ? 'is' : 'are'} {this.feedbackRecordsCount} record{this.feedbackRecordsCount === 1 ? '' : 's'} matching your search parameters.</p>
							
							{this.feedbackRecordsCount > 0 && <>
								{/* Hidden CSVLink programatically triggered by exportCSV() on button click */}
								<CSVLink
									ref={this.csvRef}
									data={this.state.csvData}
									filename={this.props.licensing.name + ' question feedback.csv'}
									style={{display:'none'}}
								>
									Download me
								</CSVLink>
								<button className="btn5" onClick={this.exportCSV}>
									<div>Export Data</div>
								</button>
								<br />
							</>}

							{data.map(function(cat,idx){
								//console.log('cat:',cat);
								//console.log('cat idx:',idx);
								return(<React.Fragment key={idx}>
									<h2>{CATEGORY_ALIAS + ' ' + cat.number}: {cat.name}</h2>
									{cat['questions'].map(function(question,idx){
										//console.log('question:',question);
										//console.log('q idx:',idx);
										return(<React.Fragment key={idx}>
											<div className={_this.state.afqQn  != question['qn']?'afq-container hide-ellipsis':'afq-container'}>
												<p className="afq-question" dangerouslySetInnerHTML={{ __html: question.number + '. ' + question.text }}></p>
												<p>
													<button className="btn5 small x-small"
														onClick={
														() => _this.afqShowHideClick(_this,question['qn'])}
														>
													{_this.state.afqQn  == question['qn']?'Hide':'Show'}
													</button>
												</p>
											</div>
											<div className={_this.state.afqQn  != question['qn']?'afqa-container hide-ellipsis hide':'afqa-container'}>
												<ul>
												{question.answers.map(function(answer,idx) {
													//console.log('answer:',answer);
													//console.log('a idx:',idx);
													const answerText = answer.text + (answer.correct ? ' - <span class="fao-correct">correct</span>' : '');
													return(<React.Fragment key={idx}>
														<li key={idx} className="feedback-answer-option" dangerouslySetInnerHTML={{ __html: answerText}}></li>
													</React.Fragment>);
												})/*{question.answers.map(function(answer,idx) {*/}
												</ul>
											</div/*  className="afqa-container" */>

											<div className="table-wrapper">
											<div className="table data-table">
												<div className="row row-header">
													<div className="cell nowrap">First name</div>
													<div className="cell nowrap">Last name</div>
													<div className="cell">Text</div>
													<div className="cell">Date</div>
												</div>
												{question.feedback.map(function(feedback,idx) {
													//console.log('feedback:',feedback);
													//console.log('feedback idx:',idx);
													return(<React.Fragment key={idx}>
														<div className="row">
															<div className="cell link" onClick={()=>navUser(feedback.UserUUID)}>{feedback.FirstName}</div>
															<div className="cell link" onClick={()=>navUser(feedback.UserUUID)}>{feedback.LastName}</div>
															<div className="cell autop" dangerouslySetInnerHTML={{ __html:autop(feedback.Text)}}></div>
															<div className="cell">{feedback.TIMESTAMP}</div>
														</div>
													</React.Fragment>);
												})/*{question.feedback.map(function(feedback,idx) {*/}
											</div/*  className="table data-table" */>
											</div/*  className="table-wrapper" */>
										</React.Fragment>);
									})/*{cat['questions'].map(function(question,idx2){*/}
								</React.Fragment>);
							})/*{data.map(function(cat,idx){*/}


						</div/* className="page-content"*/>
					</div/* className="page-content-outer-layer"*/>
				</div/* className="page-content-inner-layer"*/>

			</div>
    </>);
  }
}

const mapStateToProps = (state) => {
	// console.log('QuestionFeedback.js mapStateToProps() state',state);
	return {
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers,
		adminData: state.adminReducers
	}
}

export default connect(mapStateToProps)(QuestionFeedback);
