import React, { Component } from 'react'
import { connect } from 'react-redux'
import { empty, isCategoryInTesting, getCategoryMode, getCategoryModeStatus } from './../utility/Shared'

class ModeIndicatorBar extends Component {

  render() {
		// console.log('ModeIndicatorBar.js render() this.props',this.props);

		const categoryTestingInProgress = isCategoryInTesting(this.props.userData, this.props.userData.position.category.id);
		let modeIndicatorBarText = 'Learning Mode';
		if(!this.props.isItemPage && categoryTestingInProgress) {
			// not on item page, with exam in progress
			return (
				<div class="panel-container">
					<div className="mode-indicator-bar">Exam in progress</div>
				</div>
			);
		} else if(!this.props.isItemPage) {
			// not on item page, but no exam in progress so no need for bar
			return (null);
		}

		// we are on the item page, so show the mode
		const categoryMode = getCategoryMode(this.props.userData,this.props.userData.position.category.id);
		const categoryExamModeStatus = getCategoryModeStatus(this.props.userData,this.props.userData.position.category.id,'Exam');
		let return_bar = '';
		if(categoryMode === 'Learning') {
			return_bar = <div className="mode-indicator-bar learning-bar">Learning mode</div>;
		} else if(categoryExamModeStatus === 'retake') {
			return_bar = <div className="mode-indicator-bar">Exam mode: Retaking</div>;
		} else if(categoryExamModeStatus === 'review') {
			return_bar = <div className="mode-indicator-bar">Exam mode: Reviewing</div>;
		} else {
			return_bar = <div className="mode-indicator-bar">Exam mode</div>;
		}

		if (return_bar != '') {
			return (<>
				<div className="panel-container">
				{return_bar}
				</div>
			</>);
		}




  }
}


const mapStateToProps = (state) => {
	// console.log('ModeIndicatorBar.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
	}
}

export default connect(mapStateToProps)(ModeIndicatorBar);
