import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from './../common/BackButton'
import HeaderSecondaryAdmin from './../common/HeaderSecondaryAdmin'
import LoadingScreen from './../common/LoadingScreen'
import MediaModal from './../common/MediaModal'
import ReactQuill from 'react-quill';
import './../css/react-quill.css';
import { empty, parseUrlParams, getCategoryById, getQuestionByNumber, objToArr, getMediaUrl, getLoginTokenPacket, checkApiResponseErrors, handleBreakingError, getProjectBasename, stripTags, deepCopyObj, loadProjectCSS, getQuestionById, updateDBContentSynchronously, restrictionAlert, isReadOnlyAdmin } from './../utility/Shared'
import { confirmAlert } from 'react-confirm-alert'
import './../css/react-confirm-alert.css'
import ReactPlayer from 'react-player'
import { CATEGORY_ALIAS } from './../utility/Constants'



class ExamBuilderEdit extends Component {

	constructor(props) {
		super(props)

		this.handleQuestionTextChange = this.handleQuestionTextChange.bind(this);
		this.handleAnswerTextChange = this.handleAnswerTextChange.bind(this);
		this.handleCritiqueTextChange = this.handleCritiqueTextChange.bind(this);
		this.handleReferenceTextChange = this.handleReferenceTextChange.bind(this);
		this.handleMediaCaptionChange = this.handleMediaCaptionChange.bind(this);
		this.getCleanString = this.getCleanString.bind(this);
		this.mediaModal = React.createRef();
		this.onOpenModal = this.onOpenModal.bind(this);
	}

	state = {
		category: {},
		question: {},
		isNewQuestion: false,
		savingQuestion: false,
		isReadOnlyAdminUser: false,
		loading: true,
	}

	// strip all html tags before saving to db except:
	allowedTags = '<strong><ins><em><br><sub><sup><u><a>';

	// set editor toolbar buttons
	editorOptions = {
	  toolbar: [
	    ['bold','italic','underline'],
			[{ 'script': 'sub'}, { 'script': 'super' }],
	    ['link'],
	    ['clean']
	  ]
	}

	// set editor allowed formats that it accepts
	editorAllowedFormats = [
	  'bold', 'italic', 'underline', 'strike', 'script', 'link'
	]


	async componentWillMount() {
		// if user navigated to this url manually, need to load redux data
		if(empty(this.props.contentCategories)) {
			await this.props.screenProps.getContentCategories(true);
		}
		if(empty(this.props.licensing) || empty(this.props.licensing.logoImageName)) {
			await this.props.screenProps.getLicensingData(true);
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		if(empty(this.props.admin) || this.props.admin.data.needToFetchValuesFromDB) {
			await this.props.screenProps.getAdminData();
		}

		// get item to load from url params and set item in user position
		const urlParams = parseUrlParams();
		let cid = '1';
		let qnum = '1';
		if(!empty(urlParams.cid) && !empty(urlParams.qnum)) {
			cid = urlParams.cid;
			qnum = urlParams.qnum;
		} else {
			this.props.screenProps.history.push('/admin/exambuilder');
			return;
		}
		const category = getCategoryById(this.props.contentCategories, cid);
		let question = getQuestionByNumber(this.props.contentCategories, cid, qnum);

		// if user just added a new question, put flag in state so that the "Cancel" button deletes the new question (unless "Save Question" has been hit)
		let isNewQuestion = false;
		if(!empty(urlParams.isNewQuestion)) {
			isNewQuestion = true;
		}

		// store the original media filenames so importer.php can handle updates
		question.media.forEach(function(media,idx) {
			media.originalFilename = media.filename;
		});
		
		const isReadOnlyAdminUser = isReadOnlyAdmin(this.props.admin);

		this.setState({category, question, isNewQuestion, isReadOnlyAdminUser, loading: false});
	}

	handleQuestionTextChange(questionText) {
		
		let question = deepCopyObj(this.state.question);
		question.text = this.getCleanString(questionText);
		this.setState({question});
	}

	handleAnswerTextChange(answerText,answerIdx) {
		
		let question = deepCopyObj(this.state.question);
		const getCleanString = this.getCleanString;
		question.answers.forEach(function(answer,aIdx) {
			if(aIdx === answerIdx) {
				answer.text = getCleanString(answerText);
			}
		})
		this.setState({question});
	}

	handleCritiqueTextChange(critiqueText) {
		
		let question = deepCopyObj(this.state.question);
		question.critique.text = this.getCleanString(critiqueText);
		this.setState({question});
	}

	handleReferenceTextChange(referenceText,referenceIdx) {
		let question = deepCopyObj(this.state.question);
		const getCleanString = this.getCleanString;
		question.references.forEach(function(reference,rIdx) {
			if(rIdx === referenceIdx) {
				reference.text = getCleanString(referenceText);
			}
		})
		this.setState({question});
	}

	handleMediaCaptionChange(mediaCaptionText,mediaIdx) {
		
		let question = deepCopyObj(this.state.question);
		const getCleanString = this.getCleanString;
		question.media.forEach(function(media,mIdx) {
			if(mIdx === mediaIdx) {
				media.caption = getCleanString(mediaCaptionText);
			}
		})
		this.setState({question});
	}

	// returns a string with only the allowed html tags (e.g. <p> tags are stripped) and newline chars are replaced with a <br /> tag
	getCleanString(stringToClean) {
		stringToClean = stripTags(stringToClean,this.allowedTags);
		stringToClean = stringToClean.trim().replace(/(?:\r\n|\r|\n)/g, '<br />');
		if(stringToClean === '<br>') {
			// for some reason, react-quill puts a '<br>' in empty fields. so prevent that here.
			stringToClean = '';
		}
		return stringToClean;
	}

	// returns an updated contentCategories object with the updated question in it
	getUpdatedContentCategories(updatedQuestion) {
		let updatedContentCategories = Object.assign({},this.props.contentCategories);
		const cIdx = this.state.category.id - 1;
		const qIdx = updatedQuestion.number - 1;
		updatedContentCategories[cIdx].questions[qIdx] = updatedQuestion;

		return updatedContentCategories;
	}

	// admin pressed the Add Answer button. Just initiates a new answer option object but doesn't save it
	addAnswerOption() {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		// first make sure the user hasn't requested more than 5 answers
		let answers = deepCopyObj(this.state.question.answers);
		if(answers.length >= 6) {
			confirmAlert({
				message: 'Sorry, this question already has the maximum number of answer options.',
				buttons: [{label: 'Ok'}]
			});
			return;
		}

		// first get the number of answer options with an unassigned id so we can make it unique
		let unassignedAnswersCount = 0;
		answers.forEach(function(answer) {
			if(answer.id.match('unassigned')) {
				unassignedAnswersCount++;
			}
		});

		// now add answer to question and editor
		let answer = Object.assign({},answers[0]);
		answer.id = 'unassigned-' + (unassignedAnswersCount+1);
		answer.text = '';
		answer.correct = false;
		answers.push(answer);

		this.setState(prevState => ({question: {...prevState.question, answers: answers}}));
	}

	// delete the answer option with the given index
	deleteAnswerOption(answerIndex) {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		let answers = deepCopyObj(this.state.question.answers);
		answers.splice(answerIndex,1);

		this.setState(prevState => ({question: {...prevState.question, answers: answers}}));
	}

	// admin pressed the Add Reference button. Just initiates a new reference object but doesn't save it
	addReference() {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		let references = deepCopyObj(this.state.question.references);

		// first get the number of references with an unassigned id so we can make it unique
		let unassignedReferencesCount = 0;
		references.forEach(function(reference) {
			if(reference.id.match('unassigned')) {
				unassignedReferencesCount++;
			}
		});

		// now add the reference to the references object
		let reference = Object.assign({},references[0]);
		reference.id = 'unassigned-' + (unassignedReferencesCount+1);
		reference.text = '';
		references.push(reference);

		this.setState(prevState => ({question: {...prevState.question, references: references}}));
	}

	// delete the reference at the specified index
	deleteReference(refIndex) {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		let references = JSON.parse(JSON.stringify(this.state.question.references));
		references.splice(refIndex,1);

		this.setState(prevState => ({question: {...prevState.question, references: references}}));
	}

	// admin pressed the Add Media button. Just initiates a new media object but doesn't save it
	addMedia() {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		let medias = deepCopyObj(this.state.question.media);

		// first get the number of medias with an unassigned id so we can make it unique
		let unassignedMediasCount = 0;
		medias.forEach(function(media) {
			if(media.id.match('unassigned')) {
				unassignedMediasCount++;
			}
		});

		// now add the media to the medias object
		let media = Object.assign({},medias[0]);
		media.id = 'unassigned-' + unassignedMediasCount;
		media.caption = '';
		media.filename = '';
		media.url = '';
		media.videoPreviewUrl = '';
		media.pane = 'item';
		media.type = '';
		media.width = '';
		media.height = '';
		media.originalFilename = '';
		medias.push(media);

		this.setState(prevState => ({question: {...prevState.question, media: medias}}));
	}

	// delete media at given index
	deleteMedia(mediaIndex) {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		let medias = JSON.parse(JSON.stringify(this.state.question.media));
		medias.splice(mediaIndex,1);

		this.setState(prevState => ({question: {...prevState.question, media: medias}}));
	}

	// when admin chooses a media file, show thumbnail. taken from: https://stackoverflow.com/questions/16500848/how-to-generate-a-thumbnail-image-after-adding-an-image-inside-an-input-type-fi
	showMediaThumbnail(idx) {
		let preview = document.getElementById('thumbnail-'+idx);
		let input = document.getElementById('fileinput-'+idx);
		let file    = input.files[0];
		let reader  = new FileReader();

		// gets called when reader.readAsDataURL(file); is finished. populates an image media's src attribute so a thumbnail can be shown when a file has been selected for upload.
		reader.onloadend = () => {
			if(this.state.question.media[idx].type !== 'mp4') {
				preview.src = reader.result;
			}
		}

		let medias = deepCopyObj(this.state.question.media);
		if (file) {
			let mediaFileType = file.name.split('.').pop();
			if(mediaFileType !== 'mp4') {
				// populates image thumbnail src for file to be uploaded
				reader.readAsDataURL(file);
			}

			// update media.type in state, and for mp4 files, populate preview url
			medias.forEach(function(media,mIdx) {
				if(mIdx === idx) {
					media.type = mediaFileType;
					if(mediaFileType === 'mp4') {
						// needed for video thumbnail generation
						media.videoPreviewUrl = URL.createObjectURL(file);
					}
				}
			});
			this.setState(prevState => ({question: {...prevState.question, media: medias}}));
		} else {
			// there is no file uploaded, so for the thumbnail use the media file unless there isn't one
			let useFile = false;
			medias.forEach(function(media,mIdx) {
				if(mIdx === idx && !empty(media.filename)) {
					useFile = true;
				}
			});
			if(empty(useFile)) {
				// there's no file associated with this media
				preview.src = "";
			}
		}
	}

	handleMediaPaneChange(e,mediaIndex) {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		const updatedPane = e.target.value;
		let itemMedia = deepCopyObj(this.state.question.media);
		itemMedia.forEach(function(media,mIdx) {
			if(mIdx === mediaIndex) {
				media.pane = updatedPane;
			}
		});
		this.setState(prevState => ({question: {...prevState.question, media: itemMedia}}));
	}

	// get the media order for the question and pane (item or critique)
	getMediaOrder(medias,media) {
		let mediaOrder = 1;
		let isMediaOrderFound = false;
		medias.forEach(function(questionMedia,idx) {
			if(isMediaOrderFound === true) {
				return;
			}
			if(media.pane === questionMedia.pane) {
				if(media.id === questionMedia.id) {
					isMediaOrderFound = true;
					return;
				} else {
					mediaOrder++;
				}
			}
		})

		return mediaOrder;
	}

	// upload media files to api. also updates the question for media captions/pane assignemnts AS WELL AS all other changes made in other editor fields
	saveQuestion() {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		this.setState({savingQuestion:true});
		let updatedQuestion = deepCopyObj(this.state.question);
		const categoryId = this.state.category.id;
		let data = new FormData();
		data.append('categoryId', categoryId);
		data.append('questionId', updatedQuestion.id);
		const getMediaOrder = this.getMediaOrder;
		updatedQuestion.media.map(function(media,idx) {
			// first, if the user created a new media form but didn't upload an image into it, unset the media
			if(empty(media.type)) {
				updatedQuestion.media.splice(idx,1);
				return false;
			}

			// assign the media filename "categoryId.questionId.orderInPane.pane.filetype" as a prefix
			const mediaOrder = getMediaOrder(updatedQuestion.media,media);
			media.filename = categoryId + '.' + updatedQuestion.id + '.' + mediaOrder + '.' + media.pane + '.' + media.type;

			// append media files to data obj
			let input = document.getElementById('fileinput-'+idx);
			if(input.files.length > 0) {
				// we have a new media file that has been uploaded for this media record. add the file and the file name
				const mediaFile = input.files[0];
				data.append('mediaFiles[]', mediaFile);
				data.append('mediaWithNewMediaFiles[]', JSON.stringify(media));
			} else {
				// this media record doesn't have an updated media file. since the api will delete all media files that are of the same category/question (since it's impossible otherwise to tell if a media file corresponds to one that was deleted/added/replaced) and then re-add the ones with new media files, we want to flag this one so that it DOESN'T get deleted
				data.append('mediaWithoutNewMediaFiles[]', JSON.stringify(media));
			}
		});

		// add updatedContentCategories so api can update categories.json with media updates
		const updatedContentCategories = this.getUpdatedContentCategories(updatedQuestion);
		data.append('updatedContentCategories', JSON.stringify(updatedContentCategories));

		// update the media files by calling the api's mediaUpdate service
		data.append('payload', JSON.stringify(getLoginTokenPacket(true)));
		const showMediaThumbnail = this.showMediaThumbnail.bind(this);

		return fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action=updateContentAndMedia', {
			method: 'POST',
			body: data
		})
		.then((response) => response.json())
		.then((saveQuestionResponse) => {
// console.log('ExamBuilderImporter.js saveQuestionResponse',saveQuestionResponse);

			// check for errors
			checkApiResponseErrors(saveQuestionResponse,true);

			// update redux
			this.props.screenProps.setContentToRedux(saveQuestionResponse.contentCategories);

			// re-attach original filename (incase it gets changed again) and show all thumbnails
			updatedQuestion.media = getQuestionById(saveQuestionResponse.contentCategories,categoryId,updatedQuestion.id).media;
			// store the original media filenames
			updatedQuestion.media.forEach(function(media,idx) {
				media.originalFilename = media.filename;
			});
			this.setState({question:updatedQuestion,isNewQuestion:false,savingQuestion:false}); // needs to be before showMediaThumbnail() below since it searches document for elements
			updatedQuestion.media.forEach(function(media,idx) {
				showMediaThumbnail(idx,media);
			});

			confirmAlert({
				message: 'This question has been saved successfully.',
				buttons: [{label: 'Ok'}]
			});

		})
		.catch((error) => {
			this.setState({savingQuestion:false});
			console.log('ERROR: ExamBuilderImporter.js onUpload()', error);
			handleBreakingError('mediaUpdate',error,data,true);
			throw new Error('Stop script execution with this uncaught exception.');
		});
	}

	// change the correct answer option
	handleCorrectAnswerOptionChange(e) {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		const correctAnswerIndex = parseInt(e.target.value);
		let answers = deepCopyObj(this.state.question.answers);
		answers.forEach(function(answer,aIdx) {
			answer.correct = aIdx === correctAnswerIndex;
		});
		this.setState(prevState => ({question: {...prevState.question, answers: answers}}));
	}


	onDragAnswerStart = (e, index) => {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		this.draggedAnswer = this.state.question.answers[index];
		e.dataTransfer.effectAllowed = "move";
		e.dataTransfer.setData("text/html", e.target.parentNode);
		e.dataTransfer.setDragImage(e.target.closest('.drag-image'), 20, 20);
	};

	onDragReferenceStart = (e, index) => {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		this.draggedReference = this.state.question.references[index];
		e.dataTransfer.effectAllowed = "move";
		e.dataTransfer.setData("text/html", e.target.parentNode);
		e.dataTransfer.setDragImage(e.target.closest('.drag-image'), 20, 20);
	};

	onDragMediaStart = (e, index) => {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		this.draggedMedia = this.state.question.media[index];
		e.dataTransfer.effectAllowed = "move";
		e.dataTransfer.setData("text/html", e.target.parentNode);
		const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
		if(isChrome) {
			e.dataTransfer.setDragImage(e.target.closest('.eb-media-thumbnail-container'), 20, 20);
		} else {
			e.dataTransfer.setDragImage(e.target.closest('.drag-image'), 20, 20);
		}
	};


	onDragAnswerOver = index => {
		if(empty(this.draggedAnswer)) {
			// make sure we are dragging an answer item, not a reference or a media since that will break things
			return;
		}
		const draggedOverItem = this.state.question.answers[index];

		// if the item is dragged over itself, ignore
		if (this.draggedAnswer === draggedOverItem) {
			return;
		}

		// filter out the currently dragged item
		let items = this.state.question.answers.filter(item => item !== this.draggedAnswer);

		// add the dragged item after the dragged over item
		items.splice(index, 0, this.draggedAnswer);

		this.setState(prevState => ({question: {...prevState.question, answers: items}}));
	};

	onDragReferenceOver = index => {
		if(empty(this.draggedReference)) {
			// make sure we are dragging a reference item, not a media or an answer since that will break things
			return;
		}
		const draggedOverItem = this.state.question.references[index];

		// if the item is dragged over itself, ignore
		if (this.draggedReference === draggedOverItem) {
			return;
		}

		// filter out the currently dragged item
		let items = this.state.question.references.filter(item => item !== this.draggedReference);

		// add the dragged item after the dragged over item
		items.splice(index, 0, this.draggedReference);
		items.forEach(function(reference,idx) {
			reference.number = (idx + 1).toString();
		});

		this.setState(prevState => ({question: {...prevState.question, references: items}}));
	};

	onDragMediaOver = index => {
		if(empty(this.draggedMedia)) {
			// make sure we are dragging a media item, not a reference or an answer since that will break things
			return;
		}
		const draggedOverItem = this.state.question.media[index];

		// if the item is dragged over itself, ignore
		if (this.draggedMedia === draggedOverItem) {
			return;
		}

		// filter out the currently dragged item
		let items = this.state.question.media.filter(item => item !== this.draggedMedia);

		// add the dragged item after the dragged over item
		items.splice(index, 0, this.draggedMedia);

		this.setState(prevState => ({question: {...prevState.question, media: items}}));
	};


	// does this actually do anything? all this dragging business taken from: https://medium.freecodecamp.org/how-to-make-and-test-your-own-react-drag-and-drop-list-with-0-dependencies-6fb461603780
	onDragAnswerEnd = () => {
		this.draggedAnswer = null;
	};

	onDragReferenceEnd = () => {
		this.draggedReference = null;
	};

	onDragMediaEnd = () => {
		this.draggedMedia = null;
		this.setState({loading:false}); // triggers render() which fixes the Question/Critique designation disappearing for some reason
	};

	onOpenModal(media) {
		this.mediaModal.current.openModal(media,true);
	}

	navExamBuilder() {
		this.props.screenProps.history.push('/admin/exambuilder');
	}

	// delete a question. note that this will also get triggered if the user got to ExamBuilder via adding a new question and then didn't save any changes and then clicked "Cancel"
	deleteQuestion() {
		if(this.state.isReadOnlyAdminUser) {
			restrictionAlert();
			return;
		}
		
		if(this.props.admin.data.isProjectLaunched) {
			return;
		}

		confirmAlert({
			title: 'Are you sure you want to delete this question?',
			message: "This will delete the question and all associated answer options, critiques, references, and media.",
			buttons: [
				{label: 'Cancel'},
				{label: "Delete", onClick: async () => {
					this.setState({deletingNewQuestion:true});
					let updatedContentCategories = Object.assign({},this.props.contentCategories);
					let questions = updatedContentCategories[this.state.category.id - 1].questions;
					questions.splice(this.state.question.number - 1,1);

					// save to redux and db
					let dbContent = await updateDBContentSynchronously(updatedContentCategories);
					this.props.screenProps.setContentToRedux(dbContent);
					this.setState({deletingNewQuestion:false});

					this.navExamBuilder();
				}},
			]
		});
	}



  render() {
		// console.log('ExamBuilderEdit.js render() this.state',this.state);

		if(this.state.loading) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText="Loading Exam Builder Editor..."/>
			)
		} else if(this.state.savingQuestion) {
			return (
				<LoadingScreen loadingText="Saving question and syncing databases..."/>
			)
		} else if(this.state.deletingNewQuestion) {
			return (
				<LoadingScreen loadingText="Deleting question and syncing databases..."/>
			)
		}

		const question = this.state.question;
		const handleQuestionTextChange = this.handleQuestionTextChange;
		const handleAnswerTextChange = this.handleAnswerTextChange;
		const handleCritiqueTextChange = this.handleCritiqueTextChange;
		const handleReferenceTextChange = this.handleReferenceTextChange;
		const handleMediaCaptionChange = this.handleMediaCaptionChange;
		const editorOptions = this.editorOptions;
		const editorAllowedFormats = this.editorAllowedFormats;
		const answerLetterArray = ['A','B','C','D','E','F'];
		const onDragAnswerStart = this.onDragAnswerStart.bind(this);
		const onDragAnswerOver = this.onDragAnswerOver.bind(this);
		const onDragAnswerEnd = this.onDragAnswerEnd.bind(this);
		const onDragReferenceStart = this.onDragReferenceStart.bind(this);
		const onDragReferenceOver = this.onDragReferenceOver.bind(this);
		const onDragReferenceEnd = this.onDragReferenceEnd.bind(this);
		const onDragMediaStart = this.onDragMediaStart.bind(this);
		const onDragMediaOver = this.onDragMediaOver.bind(this);
		const onDragMediaEnd = this.onDragMediaEnd.bind(this);
		const handleCorrectAnswerOptionChange = this.handleCorrectAnswerOptionChange.bind(this);
		const handleMediaPaneChange = this.handleMediaPaneChange.bind(this);
		const deleteAnswerOption = this.deleteAnswerOption.bind(this);
		const deleteReference = this.deleteReference.bind(this);
		const deleteMedia = this.deleteMedia.bind(this);
		const showMediaThumbnail = this.showMediaThumbnail.bind(this);
		const isProjectLaunched = this.props.admin.data.isProjectLaunched;
		const onOpenModal = this.onOpenModal;

    return (<>
			<div className="container container-admin exam-builder-edit-page">
				<HeaderSecondaryAdmin history={this.props.screenProps.history} />

				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">
							<div className="back-button-container">
								<BackButton history={this.props.screenProps.history} />
							</div>

							<p className="eb-header-text center-text">Exam Builder</p>
							<p className="eb-header-text-secondary center-text">{CATEGORY_ALIAS} {this.state.category.displayNum}. {this.state.category.name}</p>

							<p className="eb-header-text-secondary">Question {question.number}</p>
							<ReactQuill
								defaultValue={question.text}
					onChange={handleQuestionTextChange}
								modules={editorOptions}
								formats={editorAllowedFormats}
							/>

							<hr />

							<div className="flex-row flex-start-main">
								<p className="eb-header-text-secondary">Responses</p>
								{empty(isProjectLaunched) &&
									<>
									<p className="exam-builder-add asset-icon-plus strong-text" onClick={() => this.addAnswerOption() }>Add Response</p>
									</>
								}
							</div>

							<div>
								{	objToArr(question.answers).map(function(answer, idx) {
									const answerLetter = answerLetterArray[idx];
									return(
										<div key={answer.id} className="drag-image" onDragOver={() => onDragAnswerOver(idx)}>
										{empty(isProjectLaunched) ? (
											<div
												className="drag quill-options"
												// draggable
												onDragStart={e => onDragAnswerStart(e, idx)}
												onDragEnd={onDragAnswerEnd}
											>
												<div className="asset-icon-container" style={{marginRight:20}}>
													<img src={require("./../images/reorder-icon.png")} width="20" height="20" alt="" />
												</div>
												<div className="strong-text">Option {answerLetter}</div>
												<input
									  type="radio"
									  name="correct-answer"
									  value={idx}
									  checked={answer.correct}
									  className="form-check-input"
													style={{marginLeft: 60, marginRight: 10}}
													onChange={(e)=>handleCorrectAnswerOptionChange(e)}
									/>
												<div className={"strong-text " + (!answer.correct && 'not-bold')}>Correct</div>
												<div className="asset-icon-container flex-item-1 text-align-right">
													<img src={require("./../images/trashcan-red.png")} onClick={()=>deleteAnswerOption(idx)} width="20" height="20" alt="" />
												</div>
											</div>
										):(
											<div className={"strong-text " + (!answer.correct && 'not-bold')}>
												Option {answerLetter}: {answer.correct ? 'Correct' : 'Incorrect'}
											</div>
										)}
											<ReactQuill
												defaultValue={question.answers[idx].text}
									onChange={(val)=>handleAnswerTextChange(val,idx)}
												modules={editorOptions}
												formats={editorAllowedFormats}
											/>
										</div>
									)
								})}

								{empty(question.answers) &&
									<p className="eb-text">There are no responses assigned to this question yet.</p>
								}
							</div>

							<hr />

							<p className="eb-header-text-secondary">Critique</p>
							<ReactQuill
								defaultValue={question.critique.text}
								onChange={handleCritiqueTextChange}
								modules={editorOptions}
								formats={editorAllowedFormats}
							/>

							<hr />

							<div className="flex-row flex-start-main">
								<p className="eb-header-text-secondary">References</p>
								<p className="exam-builder-add asset-icon-plus strong-text"  onClick={() => this.addReference() }>Add Reference</p>
							</div>

							<div>
								{	question.references.map(function(reference, idx) {
									const refNum = idx + 1;
									return(
										<div key={reference.id} className="drag-image" onDragOver={() => onDragReferenceOver(idx)}>
											<div
												className="drag quill-options"
												draggable
												onDragStart={e => onDragReferenceStart(e, idx)}
												onDragEnd={onDragReferenceEnd}
											>
												<div className="asset-icon-container" style={{marginRight:20}}>
													<img src={require("./../images/reorder-icon.png")} width="20" height="20" alt="" />
												</div>
												<div className="strong-text">Reference {refNum}</div>
												<div className="asset-icon-container flex-item-1 text-align-right">
													<img src={require("./../images/trashcan-red.png")} onClick={()=>deleteReference(idx)} width="20" height="20" alt="" />
												</div>
											</div>
											<ReactQuill
												defaultValue={question.references[idx].text}
									onChange={(val)=>handleReferenceTextChange(val,idx)}
												modules={editorOptions}
												formats={editorAllowedFormats}
											/>
										</div>
									)
								})}

								{empty(question.references) &&
									<p className="eb-text">There are no references assigned to this question yet.</p>
								}

								<MediaModal />
							</div>

							<hr />

							<div className="flex-row flex-start-main">
								<p className="eb-header-text-secondary">Media</p>
								<p className="exam-builder-add asset-icon-plus strong-text" onClick={() => this.addMedia() }>Add Media</p>
							</div>

							<div className="flex-row flex-wrap">
								{	question.media.map(function(media, idx) {
									const mediaUrlThumb = getMediaUrl(media.filename,true,true);
									const mediaVideoPreviewUrl = !empty(media.videoPreviewUrl) ? media.videoPreviewUrl : '';
									const isMediaLoaded = !(empty(mediaUrlThumb) && empty(mediaVideoPreviewUrl));
									const isMediaQueuedForUpload = !empty(media.id.match('unassigned')) && !empty(media.type);

									return(
										<div key={media.id} className="drag-image eb-media-container" onDragOver={() => onDragMediaOver(idx)}>

											<div className="eb-media-thumbnail-container">

												<div className="flex-row">
													<div
														className="drag"
														draggable
														onDragStart={e => onDragMediaStart(e, idx)}
														onDragEnd={onDragMediaEnd}
													>
														<div className="asset-icon-container" style={{margin:10}}>
															<img src={require("./../images/reorder-icon.png")} width="20" height="20" alt="" />
														</div>
													</div>

													<div className="asset-icon-container flex-item-1 text-align-right" style={{margin:10}}>
														<img src={require("./../images/trashcan-red.png")} onClick={()=>deleteMedia(idx)} width="20" height="20" alt="" />
													</div>
												</div>

												{(empty(isMediaLoaded) && empty(isMediaQueuedForUpload)) ? (
													<div className="hover-cursor" style={{margin:'auto'}}>
														<label htmlFor={"fileinput-"+idx}>
															<div className="flex-row flex-center-main hover-cursor">
																<img src={require("./../images/upload-icon.png")} width="20" height="20" alt="" />
															</div>
															<p className="strong-text hover-cursor hoverable-p">Upload Media</p>
														</label>
														<input id={"fileinput-"+idx} type="file" style={{visibility:'hidden', width: 15}} onChange={()=>showMediaThumbnail(idx,media)} />
													</div>
												) : (
													<div style={{visibility:'hidden'}}>
														<label htmlFor={"fileinput-"+idx}>Select Media</label>
														<input id={"fileinput-"+idx} type="file" style={{visibility:'hidden', width: 15}} onChange={()=>showMediaThumbnail(idx,media)} />
													</div>
												)}

												{/* image, already uploaded */}
												{(media.type != 'mp4' && mediaUrlThumb) && (
													<div style={{margin:'auto'}} onClick={()=>onOpenModal(media)}>
														<img id={"thumbnail-"+idx} src={mediaUrlThumb} style={{margin: 10, maxWidth: 200, maxHeight: 200}} />
													</div>
												)}
												{/* image, not uploaded yet */}
												{(media.type != 'mp4' && empty(mediaUrlThumb)) && (
													<div style={isMediaQueuedForUpload ? {margin:'auto'} : {height:0,margin:0}}>
														<img id={"thumbnail-"+idx} style={{margin: 10, maxWidth: 200, maxHeight: 200}} />
													</div>
												)}

												{/* video, already uploaded */}
												{(media.type === 'mp4' && empty(mediaVideoPreviewUrl)) && (
													<div style={{margin:'auto'}} onClick={()=>onOpenModal(media)}>
														<ReactPlayer
															url={media.url}
															width={128}
															height={128}
														/>
													</div>
												)}
												{/* video, not uploaded yet */}
												{(media.type === 'mp4' && !empty(mediaVideoPreviewUrl)) && (
													<div style={isMediaQueuedForUpload ? {margin:'auto'} : {height:0,margin:0}}>
														<ReactPlayer
															url={mediaVideoPreviewUrl}
															width={128}
															height={128}
														/>
													</div>
												)}
											</div>

											<div className="eb-media-location">
												<input
													type="radio"
													name={"media-pane-"+idx}
													value='item'
													checked={media.pane === 'item'}
													className="form-check-input"
													style={{marginLeft: 30, marginRight: 10}}
													onChange={(e)=>handleMediaPaneChange(e,idx)}
												/>
												<label>Question</label>

												<input
													type="radio"
													name={"media-pane-"+idx}
													value='critique'
													checked={media.pane === 'critique'}
													className="form-check-input"
													style={{marginLeft: 30, marginRight: 10}}
													onChange={(e)=>handleMediaPaneChange(e,idx)}
												/>
												<label>Critique</label>
											</div>

											<ReactQuill
												defaultValue={question.media[idx].caption}
									onChange={(val)=>handleMediaCaptionChange(val,idx)}
												modules={editorOptions}
												formats={editorAllowedFormats}
												placeholder="Caption..."
											/>
										</div>
									)
								})}
								<MediaModal
									ref={this.mediaModal}
								/>

								{empty(question.media) &&
									<p className="eb-text">There is no media assigned to this question yet.</p>
								}
							</div>

							<div className="flex-row">
								<button className="eb-btn" onClick={() => this.saveQuestion()}>
									Save Question
								</button>
								<button className="eb-btn eb-btn-cancel" onClick={() => !empty(this.state.isNewQuestion) ? this.deleteQuestion() : this.navExamBuilder()}>
									Cancel
								</button>
							</div>
							{empty(isProjectLaunched) &&
								<div className="flex-row flex-end-main">
								<button className="eb-btn eb-btn-delete" style={{marginTop:-20}} onClick={() => this.deleteQuestion()}>
								<div className="flex-row flex-centered-axes">
								<img src={require("./../images/trashcan-red.png")} onClick={(e)=>this.deleteQuestion()} width="20" height="20" alt="" />
								<div style={{paddingLeft:10}}>Delete Question</div>
								</div>
								</button>
								</div>
							}

						</div/* className="page-content"*/>
					</div/* className="page-content-outer-layer"*/>
				</div/* className="page-content-inner-layer"*/>

			</div>
    </>);
  }
}

const mapStateToProps = (state) => {
	// console.log('ExamBuilderEdit.js mapStateToProps() state',state);
	return {
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers,
		admin: state.adminReducers
	}
}

export default connect(mapStateToProps)(ExamBuilderEdit);
