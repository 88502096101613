/*
Reducer functions related to preloading media
*/
import { SET_CATEGORY_MEDIA } from '../actions/actionTypes.js';

const mediaReducers = (media = {}, action) => {
	// console.log('mediaReducers media',media);
	// console.log('mediaReducers action: ', action);

	let mediaToSet = {};
	switch (action.type) {
		case SET_CATEGORY_MEDIA:
			mediaToSet = Object.assign({},
				media,
				{categoryMedia: action.categoryMedia}
			);
			return mediaToSet;
		default:
			return media;
	}
}

export default mediaReducers;
