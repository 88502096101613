import React, { Component } from 'react'
import { connect } from 'react-redux'
import { empty, loadProjectCSS } from './../utility/Shared'
import Header from './../common/Header'
import Footer from './../common/Footer'
import LoadingScreen from './../common/LoadingScreen'
import { Tween } from "react-gsap";
import { CSSTransition, TransitionGroup } from 'react-transition-group'

class About extends Component {

	constructor(props) {
		super(props)
		this.onSelectPage = this.onSelectPage.bind(this);
	}

	state = {
		pageCount: 5,
		currentPage: 1,
		loading: true,
	}

	async componentDidMount() {
		// if user navigated to this url manually, need to reload userData
		if(empty(this.props.userData)) {
			await this.props.screenProps.getUserDataFromDB();
        if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		this.setState({loading: false});
	}

	nextPage() {
		this.setState({currentPage:this.state.currentPage+1});
	}

	previousPage() {
		this.setState({currentPage:this.state.currentPage-1});
	}

	setCurrentPage(newCurrentPage) {
		this.setState({currentPage:newCurrentPage});
	}

	onSelectPage(event) {
		this.setState({currentPage: Number(event.target.value) + 1});
	}

	renderAboutMenu () {

		/*const sections = ["About","Objectives and Accreditation Information","Faculty and Disclosures","Letter from the Editors","Contact"];*/
		const sections = ["About","Letter from the Editors","Contact"];
		const currentPage = this.state.currentPage;
		const setCurrentPage = this.setCurrentPage.bind(this);

		return (
			<Tween
				wrapper={<div className="menu-utility" />}
				staggerFrom={{opacity: 0}}
				duration={0.3}
				stagger={0.03}
			>
					<div className="menu-title">In This Section</div>

					{/*  menu for desktop*/}
					{sections.map(function (section, idx) {
						return (
							<div className={"menu-item" + (currentPage === idx + 1 ? " current-item" : "")}
								 onClick={() => {
									 if (currentPage !== idx + 1) setCurrentPage(idx + 1)
								 }}
								 key={idx}>
								<div>{section}</div>
							</div>
						)
					})}

					<select
						className="eb-dropdown"
						onChange={this.onSelectPage}
						value={currentPage - 1}
					>
						{sections.map(function (section, idx) {
							return (
								<option
									value={idx}
									key={idx}>
									{section}
								</option>
							)
						})}
					</select>
			</Tween>
		);
	}

	renderAboutPage(currentPage) {
		window.scrollTo(0,0);
		switch (currentPage) {
			case 1:
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>About</h3>

							<p>From its inception, the Vascular Education Self-Assessment Program (VESAP) has had a long history of meeting the needs of vascular surgeons.  In 2008, the Society for Vascular Surgery published the inaugural edition, VESAP1, specifically designed to meet the Maintenance of Certification requirements of the Vascular Surgery Board of the American Board of Surgery.</p>

							<p>Under the direction of the first Editors in Chief, Dr. Kim Hodgson and Dr. John Eidt, the VESAP1 Editorial Board reviewed and adjudicated questions submitted by more than 50 authoritative question writers, yielding a broad representation of current clinical practice. Designed to be largely clinical in focus, VESAP1 offered 45 <em>AMA PRA Category 1 Credits&trade;</em> and comprised 255 multiple-choice questions along with a CD-ROM. VESAP1 content covered the full spectrum of contemporary vascular surgical practice, including the diagnostic evaluation, medical, surgical and endovascular management of patients with vascular disease.  The success of VESAP2 and VESAP3 continued under the leadership of Drs Hodgson and Eidt, with an expanding array of questions and increasing CME credits along with a welcomed online platform.</p>

							<p>The VESAP4 Editors-in-Chief, Dr. Amy Reed and Dr. Greg Modrall continued the tradition of the VESAP, publishing the product in a new online educational software platform in August 2017. The new software platform allowed VESAP4 to be used on smart phones – on or offline - making it more user-friendly for busy clinicians with a few moments of time for learning. Using this software, end-users were able to use the program in learning mode or exam mode and receive a personal performance summary.</p>

							<p>VESAP5 and VESAP5 Learning Mode is a further enhancement of this robust product line, and continues the evolution of the product to parallel advances in clinical practice.</p>

						</div>
						<div className="utility-content-col-right">
							{this.renderAboutMenu()}
						</div>
					</div>
				)
				break;
			case 2:
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>Letter from the Editors</h3>
							<h4>Foreword for VESAP5</h4>

							<p>Welcome to the 5th edition of the Vascular Self-Assessment and Education Program (VESAP5).</p>

							<p>Since its inception, VESAP has evolved to become the educational product of choice for the practicing vascular surgeon in need of a refresher or continuing medical education (CME). Over time, vascular surgery training programs, as well as trainees, have found it useful in preparation for the Vascular Surgery Board Qualifying Exam or the Vascular Surgery In-Training Examination (VSITE).</p>

							<p>VESAP5 builds on the strong foundation of  prior iterations by offering updated questions across all modules, ranging from Cerebrovascular and Renal/Mesenteric to Vascular Laboratory and Vascular Medicine. The modules and their content parallel the published subject areas for VSCORE curriculum. The updated content reflects the current practice of vascular surgery with many questions accompanied by high quality clinical images. VESAP5 was written by an esteemed editorial board consisting of over 50 leaders in vascular surgery education. Each question is accompanied by an informative discussion supported by useful references.</p>

							<p>One of the larger changes to VESAP5 is the vastly expanded Vascular Laboratory section, which includes 650 questions. This section, written by surgeons and ultrasonographer leaders in vascular ultrasound, includes high quality images of vascular laboratory studies throughout.</p>

							<p>VESAP5 continues to improve upon the software platform rolled out on VESAP4, which offers significant advantages over earlier versions of VESAP. The software platform offers easier navigation, along with expanded bookmarking and annotation. The software also includes a companion application that allows users to access the program off-line after the initial download and synchronize with the desktop version once internet connectivity is resumed. This feature will allow users to access and use VESAP5 in nearly any environment, such as the operating room or during air travel. This user-friendly feature is particularly helpful for trainees and graduate surgeons alike who need to make the most of their valuable time.</p>

							<p>The Editors-in-Chief are confident that end-users will be pleased with the educational content and ease of use of VESAP5.</p>

								<p>Amy B. Reed, MD<br />
								J. Gregory Modrall, MD<br />
									<em>Editors-in-Chief, VESAP5</em> </p>
						</div>
						<div className="utility-content-col-right">
							{this.renderAboutMenu()}
						</div>
					</div>
				)
				break;
			case 3:
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>Contact</h3>
							<p> The Society for Vascular Surgery&reg; (SVS) is a not-for-profit professional medical society, composed primarily of vascular surgeons, that seeks to advance excellence and innovation in vascular health through education, advocacy, research and public awareness. SVS is the national advocate for more than 5,800 specialty-trained vascular surgeons and other medical professionals who are dedicated to the prevention and cure of vascular disease.</p>

							<p>For questions about the VESAP5 program, contact us at:</p>

								<p>Society for Vascular Surgery<br />
								633 N. Saint Clair St., 22nd Floor<br />
								Chicago, IL 60611<br />
								Telephone: 312-334-2300 / 800-258-7188<br />
								Fax: 312-334-2320<br />
								Email: <a href={"mailto:vesap@vascularsociety.org"}>vesap@vascularsociety.org</a> </p>
						</div>
						<div className="utility-content-col-right">
							{this.renderAboutMenu()}
						</div>
					</div>
				)
				break;
			default:
		}
	}


  render() {
		// console.log('About.js render() this.props',this.props);

		if(this.state.loading || empty(this.props.userData)) {
			return (
				<LoadingScreen loadingText="It's About time already!" />
			)
		}

    return (
			<div className={"container utility-page"}>
				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">
							<div className="utility-content">

								<TransitionGroup className={"utility-content-page"}>
									<CSSTransition
										key={this.state.currentPage}
										timeout={300}
										classNames="utilityFade">
										<div>
											{this.renderAboutPage(this.state.currentPage)}
											<div className="flex-row utility-page-buttons">

												{this.state.currentPage !== 1 &&
												<div className="btn5 mgn-right-5"
													 onClick={() => this.previousPage()}>
													<div>Previous</div>
												</div>
												}
												{this.state.currentPage !== this.state.pageCount &&
												<div className="btn5"
													 onClick={() => this.nextPage()}>
													<div>Next</div>
												</div>
												}

											</div>
										</div>
									</CSSTransition>
								</TransitionGroup>

							</div>
						</div>
					</div>
				</div>
			</div>
    );
  }
}


const mapStateToProps = (state) => {
	// console.log('About.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		licensing: state.licensingReducers,
	}
}

export default connect(mapStateToProps)(About);
