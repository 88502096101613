import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import $ from 'jquery'
import { SESSION_NAME_USER, SESSION_NAME_ADMIN_USER, SESSION_TIMEOUT_MINUTES, ORG_STYLES, PROJECT_BASENAME, CATEGORIES_ALIAS, CATEGORY_ALIAS, PASSING_PERCENTAGE } from './Constants'


/* this file contains functions that are shared across multiple components */

/*
functions list:

activateEyeBalls
autop
array_unique
camelCaseToHumanReadable
categoryByOrder
checkApiResponseErrors
deepCopyObj
describeArc
empty
enterRetakeMode
enterReviewMode
eval_n_toptext
finalizeExam
form_checkbox_qs
form_radio
form_radio_qs
form_select_qs
form_text_qs
form_textarea_qs
generateToken
getAnonymousAuthPacket
getAnswerById
getAnswerIdWithCorrectness
getBeginningExcerpt
getCategoryById
getCategoryMode
getCategoryModeStatus
getCategoryPerformanceStats
getCategoryUserAnswers
getCorrectAnswer
getCurrentTimestampStringUTC
getDateFromTimestamp
getDefaultPosition
getDerivedStateFromPropsValue
getEnterExamInstructions
getFilteredLandingItems
getFirstUnansweredQuestionId
getFormattedQuestionNumber
getItemShuffleOrder
getLicensingDataFromOrgs
getLicensingOrgs
getLoginTokenPacket
getMediaUrl
getParentCategoryClaimStatus
getProjectBasename
getQuestionById
getQuestionByNumber
getUrlBeforeRoute
getUserAnswer
handleBreakingError
hitApi
isCategoryInTesting
isCategoryPurchased
isEmailValid
isLoggedIn
isProjectLive
isReadyToFinalize
itemByCategoryOrderAndItemNumber
kmpSearch
loadProjectCSS
navClaimCreditProcess
objToArr
parseUrlParams
polarToCartesian
popDialogCreditsAlreadyClaimed
renderProgressCircle
routeUser
saveBookmark
setLoginSession
stripTags
updateDBContentSynchronously
updateUserDataSynchronously
*/



// checks to see if the user is logged in based off of the automatic logout time specified in local storage login 'session' (<-- in quotes since not actually using a session, using local storage). if the user login session is still valid, update the automatic logout time to XX minutes from now. otherwise, log the user out by deleting the local storage entry and bringing user to login page after notifying them
// also scrolls to top since this is called via react router on page navigations
export const isLoggedIn = (isAdminUser=false,scrollToTop=false) => {
	// get logout time from login token in local storage and compare with current time, redirecting to logout if logout time has passed
	const localStorageKey = isAdminUser ? SESSION_NAME_ADMIN_USER : SESSION_NAME_USER;
	let userLoginSession = localStorage.getItem(localStorageKey);
	if(empty(userLoginSession)) {
		// local storage was deleted so user is not logged in
		return false;
	}
	userLoginSession = userLoginSession.split('_');
	let logoutDateTime = userLoginSession[0];
	let currentDateTime = getCurrentTimestampStringUTC();

	if(currentDateTime < logoutDateTime) {
		// update login session so logout is XX minutes in future
		setLoginSession(userLoginSession[1],isAdminUser);

		if(scrollToTop) {
			window.scrollTo(0,0);
		}

		return true;
	} else {
		// user has exceeded inactivity limit so set url params so when they get logged out, they get an alert letting them know
		if(window.history.replaceState) {
			const appendChar = empty(window.location.search) ? '?' : '&';
			const newUrl = getBaseUrl(isAdminUser,true) + window.location.search + appendChar + 'session=inactive';
			window.history.replaceState({path:newUrl},'',newUrl);
		}

		return false;
	}
}


// set the login "session" (in local storage) to expire XX minutes from now
export const setLoginSession = (userLoginToken,admin=false) => {
	// update automatic logout time to XX minutes from now
	let updatedLogoutDateTime = getCurrentTimestampStringUTC(SESSION_TIMEOUT_MINUTES);
	let userLoginSession = updatedLogoutDateTime + '_' + userLoginToken;
	const key = admin ? SESSION_NAME_ADMIN_USER : SESSION_NAME_USER;
	localStorage.setItem(key,userLoginSession);
}

// returns a login object (for API syncing) that contains the login token stored in localStorage when a user logs in
export const getLoginTokenPacket = (adminUser=false,skipLoginCheck=false) => {
	// first check to see if user has exceeded their inactivity limit. skipLoginCheck is true when the logging out process calls this function
	if(!skipLoginCheck) {
		const isUserLoggedIn = isLoggedIn(adminUser,false);
		// TODO: could return false if isUserLoggedIn is empty and then everywhere that calls getLoginTokenPacket() could return (rather than call API with empty $userInfo packet). that way api_caught_exceptions.txt wouldn't be littered with empty $userInfo packet logs. would need to test smooth functionality of this, maybe also handling isLoggedIn() returning false due to empty local session token... so putting it off
	}

	// user is logged in if we got here so put their login token into a packet
	const localStorageKey = adminUser ? SESSION_NAME_ADMIN_USER : SESSION_NAME_USER;
	let userLoginSession = localStorage.getItem(localStorageKey);
	if(empty(userLoginSession)) {
		return false;
	}
	userLoginSession = userLoginSession.split('_');
	let loginToken = userLoginSession[1];
	let loginPacket = {
		"userInfo": {
			"loginToken": loginToken
		}
	}
	if(adminUser) {
		loginPacket.userInfo.adminUser = true;
	}

	return loginPacket;
}

// returns the super secret authentication token that will allow for API calls to be made without a specified user
export const getAnonymousAuthPacket = () => {

	const anonymousAuthPacket = {
		"anonymousAuthToken": "an0nymou5U2er!"
	}

	return anonymousAuthPacket;
}

// check url parameters to see if we need to pop any alerts
export const checkParamsForAlerts = (isAdminUser=false) => {
	// check to see if we need to pop any alerts
	let urlParams = parseUrlParams();
	// if(empty(urlParams)) {
	// 	return;
	// }

	if(urlParams.session === 'inactive') {
		// user has exceeded inactivity limit so alert them (and remove the session=inactive url params)
		confirmAlert({
			message: "Inactivity period exceeded. Please login again.",
			buttons: [{label: 'Ok'}]
		});
	}

	if(urlParams.alert === 'restricted') {
		// API has restricted login (maybe for maintenance). get login interrupt message to be popped
		const anonymousLoginPacket = getAnonymousAuthPacket();

// console.log('anonymousLoginPacket',anonymousLoginPacket);
		fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action=getInterruptInfo', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(anonymousLoginPacket)
		})
		.then((response) => response.json())
		.then((getInterruptInfoResponse) => {
// console.log('getInterruptInfoResponse',getInterruptInfoResponse);

			// pop login interrupt alert
			if(!empty(getInterruptInfoResponse.interruptInfo) && getInterruptInfoResponse.interruptInfo.isPopupActiveOnWeb) {
				confirmAlert({
					title: getInterruptInfoResponse.interruptInfo.title,
					message: getInterruptInfoResponse.interruptInfo.message,
					buttons: [{label: getInterruptInfoResponse.interruptInfo.buttonText}]
				})
			}
		})
		.catch((error) => {
			console.log('ERROR Logout.js getInterruptInfo(): ', error);
		});
	}

	// remove url params so user doesn't see alert if page is reloaded
	if(window.history.replaceState) {
		const newUrl = getBaseUrl(isAdminUser) + '/login';
		window.history.replaceState({path:newUrl},'',newUrl);
	}
}



// when user logs in, bring them to appropriate screen
export const routeUser = (history, userData) => {
	// console.log('routeUser() userData',userData);
	// determine what screen to bring the user to
	if(userData.welcomeScreensViewedTimestamp === false) {
		// if user hasn't viewed the welcome pages, bring user there
		history.push('/welcome');
	} else {
		// otherwise bring the user to the categories, landing, or item screens
		if(userData.position.category === false) {
			history.push('/'+CATEGORIES_ALIAS.toLowerCase());
		} else if(userData.position.item === false) {
			history.push('/landing?cid='+userData.position.category.id);
		} else {
			history.push('/item?cid='+userData.position.category.id+'&qid='+userData.position.item.id);
		}
	}
}


// we want to thoroughly clear the browser cache if it is not using the latest version of the app. synchronously clear all caches and hard reload the app if not. https://dev.to/flexdinesh/cache-busting-a-react-app-22lk
export async function handleCacheBusting(isAdminUser=false) {
	if(!isProjectLive(true)) {
		// we are on dev so no need to bust anybody's chops... i mean caches
		return;
	}

	// get the app version from the environment variable set at build time. this is what the browser is using (since it's the package.json value, right?)
	const appVersionBrowserIsUsing = process.env.REACT_APP_VERSION;
	const latestVersion = await getLatestAppVersion();
	if(appVersionBrowserIsUsing !== latestVersion) {
		await refreshCacheAndReload(isAdminUser);
	}
}

// clear caches and do a hard reload
export async function refreshCacheAndReload(isAdminUser=false) {
	if (caches) {
		// Service worker cache should be cleared with caches.delete()
		caches.keys().then(function(names) {
			for (let name of names) {
				caches.delete(name);
			}
		});
	}

	// delete browser cache via hard reload (unless caches have just been cleared this way, indicated in url params)
	const urlParams = parseUrlParams();
	if(urlParams.caches !== 'cleared') {
		if(window.history.pushState) {
			const appendChar = empty(window.location.search) ? '?' : '&';
			const hardReloadUrl = getBaseUrl(isAdminUser,true) + window.location.search + appendChar + 'caches=cleared';
			window.history.pushState({path:hardReloadUrl},'',hardReloadUrl);
			window.location.reload(true);
		}
	}
}

// a meta.json file is generated with each build of the app that contains the latest app version (to be compared against the version the browser is using, process.env.REACT_APP_VERSION). if on dev, no need to cache bust so set to process.env.REACT_APP_VERSION
export async function getLatestAppVersion() {
	let latestAppVersion = false;
	if(!isProjectLive(true)) {
		// on dev
		return process.env.REACT_APP_VERSION;
	}

	await fetch(getBaseUrl()+'/meta.json', { cache: 'no-store' })
		.then((response) => response.json())
		.then((meta) => {
// console.log('meta',meta);
			latestAppVersion = meta.version;
		})
		.catch((error) => {
			// um, we got an error checking the meta.json file so just set the latestAppVersion to process.env.REACT_APP_VERSION and the user can go on with their life potentially using an outdated version of the app
			console.log('ERROR Shared.js getLatestAppVersion(): ', error);
			latestAppVersion = process.env.REACT_APP_VERSION;
		});

	return latestAppVersion;
}

// compare version numbers (like 2.1.4 vs 2.1.5)
// NOTE: not being used (in cache busting) since we just want to know if versions are different, since we may want to roll back
export const semverGreaterThan = (versionA, versionB) => {
	const versionsA = versionA.split(/\./g);
	const versionsB = versionB.split(/\./g);
	while (versionsA.length || versionsB.length) {
		const a = Number(versionsA.shift());
		const b = Number(versionsB.shift());
		// eslint-disable-next-line no-continue
		if (a === b) continue;
		// eslint-disable-next-line no-restricted-globals
		return a > b || isNaN(b);
	}
	return false;
};


// returns a timestamp string in UTC that is of the current date time plus the number of minutes passed in as a parameter
export const getCurrentTimestampStringUTC = (plusXminutes=0) => {
	// get current date then add specified mins
	let currentDate = new Date();
	currentDate.setMinutes(currentDate.getMinutes() + plusXminutes);

	// convert to utc string time
	let UTCString = currentDate.toISOString();

	// remove the milliseconds
	let UTCArray = UTCString.split('.');
	UTCString = UTCArray[0];

	// remove that random ass 'T' in the middle of it
	UTCString = UTCString.replace('T', ' ');

	return UTCString;
}


// given an object that contains child objects, this will return an array of those child objects
export const objToArr = (parentObject) => {
	if(empty(parentObject)) {
		return [];
	}
  let arrayOfObjects = [];
  Object.keys(parentObject).forEach(function(key) {
    arrayOfObjects.push(parentObject[key]);
  });

  return arrayOfObjects;
}


// get the current mode ('Learning' or 'Exam') for a given category
export const getCategoryMode = (userData, categoryId) => {
	// if userData (or categoryId) is empty, return an empty string since this method may be called in a render() before userData has been loaded into a component's state
	if(typeof userData !== 'object' || typeof categoryId === 'undefined') {
		return '';
	}

	return userData.categories[categoryId].currentMode;
}


// get the current mode status ('learn', 'completed', 'take', 'retake', or 'review') for a given category
export const getCategoryModeStatus = (userData, categoryId, mode=false) => {
	// if userData (or categoryId) is empty, return an empty string since this method may be called in a render() before userData has been loaded into a component's state
	if(typeof userData !== 'object' || typeof categoryId === 'undefined') {
		return '';
	}

	// the optional 'mode' parameter specifies whether to return the 'Learning' mode's status or the 'Exam' mode's status
	let specifiedMode;
	if(empty(userData.userInfo.isCeEligible)) {
		specifiedMode = 'Learning';
	} else if(mode === 'Learning' || mode === 'Exam') {
		specifiedMode = mode;
	} else {
		specifiedMode = userData.categories[categoryId].currentMode;
	}

	return userData.categories[categoryId].modes[specifiedMode].status;
}


// get landing page items to display depending on the filter selected ('All', 'Unanswered', 'Incorrect', 'Retake')
export const getFilteredLandingItems = (userData, filterSelected, modeDisplayed) => {

	// get the list of filtered items to be shown
	let filteredQuestionIds = [];
	let userAnswers = getCategoryUserAnswers(userData,userData.position.category.id, modeDisplayed);
	Object.keys(userAnswers).forEach(function(userAnswerKey) {
		let userAnswer = userAnswers[userAnswerKey];
		switch (filterSelected) {
			case 'all':
				filteredQuestionIds.push(userAnswer.questionId);
				break;
			case 'unanswered':
				if(userAnswer.answered === false) {
					filteredQuestionIds.push(userAnswer.questionId);
				}
				break;
			case 'incorrect':
				if(userAnswer.answered === true && userAnswer.correct === false) {
					filteredQuestionIds.push(userAnswer.questionId);
				}
				break;
			case 'retake':
				if(userAnswer.subset === 'retake') {
					filteredQuestionIds.push(userAnswer.questionId);
				}
				break;
			default:
				console.log('ERROR: Invalid filter tab of ' +filterSelected+ ' selected.');
		}
	}) // for userAnswers

	// filter all category questions for just items with a corresponding filteredQuestionId
	let filteredItems = [];
	for (var i = 0; i < userData.position.category.questions.length; i++) {
		let item = userData.position.category.questions[i];
		if(filteredQuestionIds.includes(item.id)) {
			filteredItems.push(item);
		}
	}

	if(modeDisplayed === 'Exam') {
		filteredItems.sort(function(a, b) {
		   return a.shuffleOrder - b.shuffleOrder;
		})
	}

	return filteredItems;
}

// get all the user answers for a given category's current mode (if not specified)
export const getCategoryUserAnswers = (userData,categoryId,specificMode=false) => {
	// if userData (or categoryId/questionId) is empty, return an empty string since this method may be called in a render() before userData has been loaded into a component's state
	if(typeof userData !== 'object' || typeof categoryId === 'undefined') {
		return '';
	}

	// get which mode we want user answers for (default is the category's current mode)
	let mode;
	if(specificMode) {
		mode = specificMode;
	} else {
		mode = getCategoryMode(userData, categoryId);
	}
	return userData.categories[categoryId]['modes'][mode]['answers'];
}


// return a category from a passed in category id
export const getCategoryById = (categories, categoryId) => {

	let categoryToReturn = false;
	for (const key of Object.keys(categories)) {
		const category =  categories[key];
		if(category.id === categoryId) {
			categoryToReturn = category;
			break;
		}
	};

	return categoryToReturn;
}

// return a question from a passed in category and question ids
export const getQuestionById = (categories, categoryId, questionId) => {

	let questionToReturn = false;
	for (const key of Object.keys(categories)) {
		const category =  categories[key];
		if(category.id === categoryId) {
			for (const key2 of Object.keys(category.questions)) {
				const question =  category.questions[key2];
				if(question.id === questionId) {
					questionToReturn = question;
					break;
				}
			};
			break;
		}
	};

	return questionToReturn;
}

// return a question from a passed in category and question number
export const getQuestionByNumber = (categories, categoryId, questionNumber) => {

	let questionToReturn = false;
	for (const key of Object.keys(categories)) {
		const category =  categories[key];
		if(category.id === categoryId) {
			for (const key2 of Object.keys(category.questions)) {
				const question =  category.questions[key2];
				if(question.number === questionNumber) {
					questionToReturn = question;
					break;
				}
			};
			break;
		}
	};

	return questionToReturn;
}

// get the user answer for a given category, mode, and question
export const getUserAnswer = (userData,categoryId,questionId) => {
	// if userData (or categoryId/questionId) is empty, return an empty string since this method may be called in a render() before userData has been loaded into a component's state
	if(typeof userData !== 'object' || typeof categoryId === 'undefined' || typeof questionId === 'undefined') {
		return '';
	}

	let currentMode = getCategoryMode(userData, categoryId);

	return userData.categories[categoryId]['modes'][currentMode]['answers'][questionId];
}

// get the total number of questions, number answered, number correct, and percent correct for a given category and mode
export const getCategoryPerformanceStats = (userData,categoryId,mode) => {
	// if userData (or categoryId) is empty, return an empty string since this method may be called in a render() before userData has been loaded into a component's state
	if(typeof userData !== 'object' || typeof categoryId === 'undefined') {
		return '';
	}
	if(typeof mode === 'undefined') {
		mode = getCategoryMode(userData, categoryId);
	}

	let answers = userData.categories[categoryId].modes[mode].answers;
	let performanceStats = {};

	// get total number of questions
	performanceStats['totalQuestionCount'] = Object.keys(answers).length;

	// get number answered and number correct
	performanceStats['numberAnswered'] = 0;
	performanceStats['numberCorrect'] = 0;
	Object.keys(answers).forEach(function(answerKey) {
		let answer = answers[answerKey];
		if(answer.answered) {
			performanceStats['numberAnswered']++;
			if(answer.correct) {
				performanceStats['numberCorrect']++;
			}
		}
	});

	// get percent correct
	if(performanceStats['numberAnswered'] === 0) {
		performanceStats['percentCorrect'] = 0;
	} else {
		performanceStats['percentCorrect'] = parseFloat((100 * (performanceStats['numberCorrect'] / performanceStats['numberAnswered'])).toFixed(1));
	}

	// for convenience, get whether mode is completed
	performanceStats['isCompleted'] = performanceStats['numberAnswered'] === performanceStats['totalQuestionCount'] ? true : false;

	return performanceStats;
}

// returns whether a category is taking an exam (in Exam mode but not 'review' status)
export const isCategoryInTesting = (userData, categoryId) => {
	// if userData (or categoryId) is empty, return an empty string since this method may be called in a render() before userData has been loaded into a component's state
	if(typeof userData !== 'object' || typeof categoryId === 'undefined') {
		return '';
	}

	let mode = getCategoryMode(userData, categoryId);
	let modeStatus = getCategoryModeStatus(userData, categoryId);

	if(mode === 'Exam' && modeStatus !== 'review') {
		return true;
	} else {
		return false;
	}

}

// get a category given a category number
export const categoryByOrder = (categories, categoryOrder) => {
	if(typeof categories === 'object') {
		categories = objToArr(categories);
	}

	for (var i = 0; i < categories.length; i++) {
		let category = categories[i];
		if(category.order === categoryOrder) {
			return category;
		}
	}
}

// get an item given a category number and item number
export const itemByCategoryOrderAndItemNumber = (categories, categoryOrder, itemNumber) => {
	if(typeof categories === 'object') {
		categories = objToArr(categories);
	}

	for (var i = 0; i < categories.length; i++) {
		let category = categories[i];
		if(category.order === categoryOrder) {
			for (var j = 0; j < category.questions.length; j++) {
				let item = category.questions[j];
				if(item.number === itemNumber) {
					return item;
				}
			}
		}
	}
}

// recreate PHP's empty() function since lodash's isEmpty() function sucks since it returns true if TRUE is passed as the param
export const empty = (theThing) => {
	let undef;
	let emptyValues = [undef, null, false, 0, '', '0'];

	// check first to see if whatever was passed in is one of the defined empty values
  for (let i = 0, len = emptyValues.length; i < len; i++) {
    if (theThing === emptyValues[i]) {
      return true
    }
  }

	// check to see if whatever was passed in is an empty object (or array)
  if (typeof theThing === 'object') {
    for (const key in theThing) {
      if (theThing.hasOwnProperty(key)) {
        return false
      }
    }
    return true
  }

	// it ain't empty
  return false
}


// brings the user to the correct page in the claiming credit process. if a user hasn't filled out an evaluation for a category, brings them to eval. then to the claim credit page. if they've already claimed credit, we show the details
export const navClaimCreditProcess = (history, userData) => {
	let category = userData.position.category;

	// check if evaluation is completed. if not, do that first
	let evalCategoryId = category.id;
	if(!empty(category.parentCategory)) {
		// user is on a category part, so get parent category id
		evalCategoryId = category.parentCategory.id;
	}
	if(userData.evaluations[evalCategoryId].timestamp === '0000-00-00 00:00:00') {
		history.push('/evaluation?cid='+category.id);
		return;
	}

	// if we got here, evaluation is completed, so now check to see if credit has been claimed
	if(userData.creditsClaimed[category.id].claimStatus !== 'claimed') {
		// credits have not been claimed so navigate to claim credit page
		history.push('/claim-credit?cid='+category.id);
	} else {
		// credits have been claimed, alert user of submission time
		popDialogCreditsAlreadyClaimed(userData.creditsClaimed[category.id]);
	}

	return;
}

export const popDialogCreditsAlreadyClaimed = (creditsClaimedInfo) => {
	let date = getDateFromTimestamp(creditsClaimedInfo.timestamp);

	confirmAlert({
		customUI: ({ onClose }) => {
	    return (
	      <div className="react-confirm-alert-body">
	        <h1>Credit Claimed</h1>
	        <p>You claimed credit for this {CATEGORY_ALIAS} on {date}. <span className="link" onClick={()=>viewCertificate()}>Click here</span> to view your CME certificate.</p>
	        <div className="react-confirm-alert-button-group"><button onClick={()=>{onClose()}}>Ok</button></div>
	      </div>
	    );
  }
	});
}

// open a new tab with the certificate pdf
export const viewCertificate = (isAdminUser=false,infoFromAdmin=false) => {
	const loginPacket = getLoginTokenPacket(isAdminUser);

	if(empty(isAdminUser)) {
		window.open('https://mycmecredit.com/'+getProjectBasename()+'/api/certificate.php?loginToken='+encodeURIComponent(loginPacket.userInfo.loginToken),'_blank');
	} else {
		window.open('https://mycmecredit.com/'+getProjectBasename()+'/api/certificate.php?adminUser='+isAdminUser+'&userUuid='+encodeURIComponent(infoFromAdmin.userUuid)+'&projectUuid='+encodeURIComponent(infoFromAdmin.projectUuid),'_blank');
	}
}

// open a new tab with the vesap4 certificate pdf
export const viewCertificateVESAP4 = (payload=false) => {

	window.open('https://mycmecredit.com/'+getProjectBasename()+'/api/certificate_vesap4.php?loginToken='+encodeURIComponent(payload.userInfo.loginToken)+'&id='+encodeURIComponent(payload.user.cmeUserCustomerNo)+'&firstName='+encodeURIComponent(payload.user.displayFirstName)+'&lastName='+encodeURIComponent(payload.user.displayLastName)+'&suffix='+encodeURIComponent(payload.user.displaySuffix),'_blank');
}

export const getDateFromTimestamp = (timestamp) => {
	let timestampArr = timestamp.split(' ');

	return timestampArr[0];
}

// parses the url after the '?' and returns a key value object (e.g. urlParams.cid = 9). could use queryString package but this is more light weight
export const parseUrlParams = () => {

	let urlSearchString = window.location.search;
	let urlParams = {};
	if(empty(urlSearchString)) {
		return urlParams;
	}

	// remove leading '?'
	urlSearchString = urlSearchString.substr(1);

	// split by '&'
	let urlKeyValuePairsArr = urlSearchString.split('&');

	// add each key/value to the urlParams object
	urlKeyValuePairsArr.forEach(function(paramPair) {
		let paramsArr = paramPair.split('=');
		let key = paramsArr[0];
		let value = decodeURIComponent(paramsArr[1]);
		urlParams[key] = value;
	});

	return urlParams;

}

export const checkApiResponseErrors = (apiResponsePacket,checkEmptyContentCategories=false,checkForceLogout=true) => {
// console.log('apiResponsePacket',apiResponsePacket);

	// check for an error
	if(!empty(apiResponsePacket.Error)) {
		throw new Error(apiResponsePacket.Error);
	} else if(checkEmptyContentCategories && empty(apiResponsePacket.contentCategories)) {
		throw new Error('apiResponsePacket.contentCategories was empty.');
	} else if(checkForceLogout && !empty(apiResponsePacket.interrupt)) {
		// check to see if we have to force logout the user and pop an alert
		if(apiResponsePacket.interrupt.isLoginRestrictedOnWeb) {
			window.location.href = getBaseUrl() + '/logout?alert=restricted';
		}
	} else {
		// no errors, proceed normally
		return;
	}
}


// redirect user to our error page. send error details to API if the error was a react error that didn't come from the API originally (since if it did, it will have already been taken care of there)
export const handleBreakingError = (erroringFunction=false,message=false,packetSentToApi=false,isAdminUser=false) => {
	// set adminUser to true if found in packet but not explicitly set
	if(isAdminUser === false && (packetSentToApi.adminUser === true || (!empty(packetSentToApi.userInfo) && packetSentToApi.userInfo.adminUser === true))) {
		isAdminUser = true;
	}

	// log errors to console if not on live site
	const isLive = isProjectLive();
	if(empty(isLive)) {
		console.log('HANDLE-BREAKING-ERROR erroringFunction',erroringFunction);
		console.log('HANDLE-BREAKING-ERROR message',message);
		console.log('HANDLE-BREAKING-ERROR packetSentToApi',JSON.stringify(packetSentToApi));
		console.log('HANDLE-BREAKING-ERROR isAdminUser',isAdminUser);
	}

	// turns off the error page redir for development purposes
	if (getProjectBasename().indexOf('_twayn') !== -1) {return false;}

	// check to see if we have an error that only occurs because user url navigated before a fetch() call was returned. one way this could happen is if a new version of the app is detected in handleCacheBusting(). in chrome, the error is 'TypeError: Failed to fetch' and in firefox it's 'NetworkError when attempting to fetch resource.'
	if(message.toString() === 'TypeError: Failed to fetch' || message.toString() === 'TypeError: NetworkError when attempting to fetch resource.') {
		const urlParams = parseUrlParams();
		if(urlParams.caches === 'cleared') {
			// don't throw error, return instead
			console.log('redirect occurred.');
			return;
		}
	}

	// check if the error occurred on the API (in which case the packetSentToApi will not be empty) or in react
	let errorPage = getBaseUrl(isAdminUser) + '/error';

	if(window.location.href.includes('error') || erroringFunction === 'getLicensingOrgs') {
		// redirect to errorPage (again!) but pass fatal=true parameter which prevents error page from making api calls (since there was presumably a problem with the getLicensingOrgs() call). this will load the default CSS into the error page (rather than the licensing org specific CSS). a fatal error can also occur if the erroring function is getLicensingOrgs() since that's called upon login, which is where the error page will redirect
		window.location.href = errorPage + '?fatal=true';
	} else if(!empty(packetSentToApi)) {
		// we have an API error but it was already logged/emailed so bring user to error page
		window.location.href = errorPage;
	} else {
		// we have a react error that hasn't been logged (or emailed) on the server so do that now
		let errorPacket = getLoginTokenPacket(isAdminUser);
		errorPacket.message = 'REACT WEB ERROR: ' + message;
		errorPacket.erroringFunction = erroringFunction;
		errorPacket.packetSentToApi = packetSentToApi;
// console.log('ERROR handleBreakingError() errorPacket',errorPacket);
		return fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action=errorReport', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(errorPacket)
		})
		.then((response) => response.json())
		.then((errorReportResponse) => {
// console.log('ERROR errorReportResponse',errorReportResponse);

			// yay errors emailed/logged. redirect user to error page
			if(!window.location.href.includes('error')) {
				window.location.href = errorPage;
			}

		})
		.catch((error) => {
			// oh no, an API error occured as we tried to report a previous react error... so something is wrong with both the API AND react. oh well, redirect to error page with fatal param
			console.log('ERROR Shared.js handleBreakingError(): ', error);
			window.location.href = errorPage + '?fatal=true';
		});

	}

}

export const isCategoryPurchased = (userData,categoryId) => {
	const isCategoryPurchased = userData.categories[categoryId].modes.Learning.status === 'locked' ? false : true;

	return isCategoryPurchased;
}

// get the base site url for wherever the site is being run. this will include the project folder (if not on live site) and will include the admin folder (if in admin site)
export const getBaseUrl = (isAdminUser=false,withPath=false) => {
	let baseUrl = window.location.origin;
	const isLive = isProjectLive();
	if(!empty(isLive)) {
		// live site
		baseUrl = window.location.origin + (isAdminUser ? '/admin' : '');
		if(withPath && empty(isAdminUser)) {
			baseUrl += '/' + getPathname();
		}
	} else {
		// we are not live (including stage branch here)
		baseUrl = window.location.origin + '/' + getProjectBasename() + (isAdminUser ? '/admin' : '');
		if(withPath) {
			baseUrl += '/' + getPathname();
		}
	}
	return baseUrl;
}

export const getPathname = () => {
	let pathArr = window.location.pathname.split('/');
	return pathArr.pop();
}

export const getProjectBasename = () => {
	let projectBasename = PROJECT_BASENAME;
	if(window.location.href.match('_ben')) {
		projectBasename = PROJECT_BASENAME + '_ben';
	} else if(window.location.href.match('_twayn')) {
		projectBasename = PROJECT_BASENAME + '_twayn';
	} else if(window.location.href.match('localhost') || window.location.href.match('_dev')) {
		projectBasename = PROJECT_BASENAME + '_dev';
	} else if(window.location.href.match('_stage')) {
		projectBasename = PROJECT_BASENAME + '_stage';
	}

	return projectBasename;
}

// returns the media url for an image or video
export const getMediaUrl = (filename,adminUser=false,isThumbnail=false) => {

	if(empty(filename)) {
		return false;
	}

	const devOrLiveFolder = isProjectLive(true) ? 'live' : 'dev';
	let url = '';
	if(isThumbnail) {
		url = "https://mycmecredit.com/CLIENT_MEDIA/SVS/VESAP5/"+devOrLiveFolder+"/thumbnails/"+filename;
	} else {
		url = "https://mycmecredit.com/CLIENT_MEDIA/SVS/VESAP5/"+devOrLiveFolder+"/"+filename;
	}

	return url;
}

export const generateToken = () => {
  var ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var rtn = '';
  for (var i = 0; i < 10; i++) {
    rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
  }
  return rtn;
}

// js version of php strip_tags() function taken from http://locutus.io/php/strings/strip_tags/. removes all but the allowable html tags from a string
export const stripTags = (input,allowed) => {
	if(empty(input)) {
		return input;
	}
	
	// making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
	allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');

	let tags = /<\/?([a-z0-9]*)\b[^>]*>?/gi;
	let commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

	let after = input.toString();
	// removes tha '<' char at the end of the string to replicate PHP's behaviour
	after = (after.substring(after.length - 1) === '<') ? after.substring(0, after.length - 1) : after;

	// recursively remove tags to ensure that the returned string doesn't contain forbidden tags after previous passes (e.g. '<bait/>switch/>')
	while (true) {
		let before = after;
		after = before.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
			return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
		})

		// return once no more tags are removed
		if (before === after) {
			return after;
		}
	}
}

export const activateEyeBalls = (activateCrazyEyes=false) => {
	$('body').mousemove(function(event) {

		$('.eye').each(function() {
			let eye = $(this);
			let x = (eye.offset().left) + (eye.width() / 2);
			let y = (eye.offset().top) + (eye.height() / 2);
			let rad = Math.atan2(event.pageX - x, event.pageY - y);
			let rot = (rad * (180 / Math.PI) * -1) + 180;
			eye.css({
				'-webkit-transform': 'rotate(' + rot + 'deg)',
				'-moz-transform': 'rotate(' + rot + 'deg)',
				'-ms-transform': 'rotate(' + rot + 'deg)',
				'transform': 'rotate(' + rot + 'deg)'
			});
		});

		if(activateCrazyEyes) {
			$('.crazy-eye').each(function() {
				let eye = $(this);
				let x = (eye.offset().left) + (eye.width() / 2);
				let y = (eye.offset().top) + (eye.height() / 2);
				let rad = Math.atan2(event.pageX - x, event.pageY - y);
				let rot = (rad * (180 / Math.PI) * -1);
				eye.css({
					'-webkit-transform': 'rotate(' + rot + 'deg)',
					'-moz-transform': 'rotate(' + rot + 'deg)',
					'-ms-transform': 'rotate(' + rot + 'deg)',
					'transform': 'rotate(' + rot + 'deg)'
				});
			});
		}
	});

	return;
}

// load css stylesheet for the user's project. this function only actually does anything in development... in production, Webpack bundles all stylesheets (which is why we have to prefix all our styles with the organization class)
export const loadProjectCSS = (cssFilename) => {
	require('./../css/web/'+cssFilename);
}

// convert a string from camel case to human readable
export const camelCaseToHumanReadable = (camelCasedString) => {
	let stringArr = camelCasedString.split('');
	let humanReadableStringArr = [];
	stringArr.forEach(function(char,idx) {
		if(idx === 0) {
			// first character should always be capitalized
			humanReadableStringArr.push(char.toUpperCase());
		} else if(!empty(stringArr[idx+1]) && stringArr[idx+1] === stringArr[idx+1].toUpperCase()) {
			// if the next character is capitalized, add the letter then a space
			humanReadableStringArr.push(char);
			humanReadableStringArr.push(' ');
		} else {
			humanReadableStringArr.push(char);
		}
	})

	return humanReadableStringArr.join('');
}

// Searches for the given pattern string in the given text string using the Knuth-Morris-Pratt string matching algorithm. taken from https://stackoverflow.com/questions/1789945/how-to-check-whether-a-string-contains-a-substring-in-javascript
// If the pattern is found, this returns the index of the start of the earliest match in 'text'. Otherwise -1 is returned.
export const kmpSearch = (pattern, text) => {
	if (pattern.length == 0)
		return 0;  // Immediate match

	// make case insensitive
	pattern = pattern.toLowerCase();
	text = text.toLowerCase();

	// Compute longest suffix-prefix table
	var lsp = [0];  // Base case
	for (var i = 1; i < pattern.length; i++) {
		var j = lsp[i - 1];  // Start by assuming we're extending the previous LSP
		while (j > 0 && pattern.charAt(i) != pattern.charAt(j))
			j = lsp[j - 1];
		if (pattern.charAt(i) == pattern.charAt(j))
			j++;
		lsp.push(j);
	}

	// Walk through text string
	var j = 0;  // Number of chars matched in pattern
	for (var i = 0; i < text.length; i++) {
		while (j > 0 && text.charAt(i) != pattern.charAt(j))
			j = lsp[j - 1];  // Fall back in the pattern
		if (text.charAt(i).toLowerCase() == pattern.charAt(j).toLowerCase()) {
			j++;  // Next char matched, increment position
			if (j == pattern.length)
				return i - (j - 1);
		}
	}
	return -1;  // Not found
}

// uses a regex to determine if email is valid (found here: https://stackoverflow.com/questions/41348459/regex-in-react-email-validation)
export const isEmailValid = (email) => {
	return (/^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) ? true : false;
}


// used for calculating the position on a circle in gauges
export function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
	var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;

	return {
		x: centerX + (radius * Math.cos(angleInRadians)),
		y: centerY + (radius * Math.sin(angleInRadians))
	};
}

export function describeArc(x, y, radius, startAngle, endAngle){

	var start = polarToCartesian(x, y, radius, endAngle);
	var end = polarToCartesian(x, y, radius, startAngle);

	var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

	var d = [
		"M", start.x, start.y,
		"A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
	].join(" ");

	return d;
}


export const getEnterExamInstructions = (userData,categoryId) => {

	const categoryMode = getCategoryMode(userData,categoryId);
	const categoryExamModeStatus = getCategoryModeStatus(userData,categoryId,'Exam');
	const isExamInProgress = isCategoryInTesting(userData,categoryId);
	const categoryStats = getCategoryPerformanceStats(userData,categoryId);
	const categoryStatsExam = getCategoryPerformanceStats(userData,categoryId,'Exam');

	// get exam button text and exam score
	let enterExamInstructions = {
		examButtonText: 'EXAM MODE',
		landingPageInstructions: 'Enter mode'
	};
	if(categoryMode === 'Learning' && categoryExamModeStatus !== 'review') {
		enterExamInstructions.examButtonText = 'BEGIN EXAM';
		enterExamInstructions.landingPageInstructions = 'Enter Learning Mode or Start Exam';
	} else if(isExamInProgress) {
		enterExamInstructions.examButtonText = 'CONTINUE EXAM';
		enterExamInstructions.landingPageInstructions = 'Enter Exam Mode';
	} else if(categoryExamModeStatus === 'review') {
		enterExamInstructions.examButtonText = 'REVIEW EXAM';
		enterExamInstructions.landingPageInstructions = 'Enter Learning Mode or Review Exam Mode';
	}

	return enterExamInstructions;
}

export const getLicensingDataFromOrgs = (projectUuid,licensingOrgs) => {
	let licensingData;
	licensingOrgs.forEach(function(org) {
		if(org.projectUuid === projectUuid) {
			licensingData = org;
		}
	});

	return licensingData;
}


// gets licensing organizations for this project from api db. this will be called from the login page before we have a user logged in (to populate the dropdown) so we send a special authentication packet rather than the usual loginPacket
export async function getLicensingOrgs() {
	// console.log('getLicensingOrgs() called');

	let licensingOrgs;

	// get special auth packet
	let	loginPacket = getAnonymousAuthPacket();

// console.log('loginPacket',loginPacket);
	await fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action=getLicensingOrgs', {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(loginPacket)
	})
	.then((response) => response.json())
	.then((getLicensingOrgsResponse) => {
// console.log('getLicensingOrgsResponse',getLicensingOrgsResponse);

		// check for errors so we don't assign an error message to redux
		checkApiResponseErrors(getLicensingOrgsResponse);

		// assign licensing orgs to return
		licensingOrgs = getLicensingOrgsResponse.licensingOrgs;

	})
	.catch((error) => {
		console.log('ERROR Shared.js getLicensingOrgs(): ', error);
		handleBreakingError('getLicensingOrgs',error,loginPacket);
		throw new Error('Stop script execution with this uncaught exception.');
	});


	return objToArr(licensingOrgs);
}

// synchronously! sends updatedContent to api to sync with db
export async function updateDBContentSynchronously(contentCategories) {
	try {
		let payload = {};
		payload.contentCategories = contentCategories;

		// attach user login token
		const loginPacket = getLoginTokenPacket(true);
		payload.userInfo = loginPacket.userInfo;
// console.log('SYNC PACKET updateDBContentSynchronously() payload',JSON.stringify(payload));
		let response = await fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action=setContentCategories', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload)
		});
// console.log('updateDBContentSynchronously response before .json()',response);
		let data = await response.json();
// console.log('updateDBContentSynchronously response data',data);
		// check for errors so we don't assign an error message to redux
		checkApiResponseErrors(data,true);

		return data.contentCategories;

	} catch (error) {
		const loginPacket = getLoginTokenPacket(true);
		console.log('ERROR Shared.js updateDBContentSynchronously(): ', error);
		handleBreakingError('setContentCategories(synchronously)',error,loginPacket,true);
		throw new Error('Stop script execution with this uncaught exception.');
	}
}

// synchronously! sends updatedUserData to api to sync with db
// NOTE: only called for test menu buttons right now
export async function updateUserDataSynchronously(userData) {
	try {
		let payload = userData;

		// attach user login token
		const loginPacket = getLoginTokenPacket();
		payload.userInfo = loginPacket.userInfo;
// console.log('SYNC PACKET updateUserDataSynchronously() payload',JSON.stringify(payload));
		let response = await fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action=syncCategories', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload)
		});
// console.log('updateUserDataSynchronously response before .json()',response);
		let syncActivityResponse = await response.json();
// console.log('updateUserDataSynchronously response syncActivityResponse',syncActivityResponse);
		// check for errors so we don't assign an error message to redux
		checkApiResponseErrors(syncActivityResponse);

		return syncActivityResponse.userData;

	} catch (error) {
		const loginPacket = getLoginTokenPacket();
		console.log('ERROR Shared.js updateUserDataSynchronously(): ', error);
		handleBreakingError('syncUserData(synchronously)',error,loginPacket);
		throw new Error('Stop script execution with this uncaught exception.');
	}
}

// synchronously! call api service
// generic api call
export async function hitApi(apiAction,loginPacket) {
	// console.log('hitApi() called');

	let apiActionResult;

// console.log('loginPacket',loginPacket);
//console.log('loginPacket:',JSON.stringify(loginPacket));
	await fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action='+apiAction, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(loginPacket)
	})
	.then((response) => response.json())
	.then((hitApiResponse) => {
// console.log('hitApiResponse',hitApiResponse);

		// check for errors so we don't assign an error message to redux
		checkApiResponseErrors(hitApiResponse);

		apiActionResult = hitApiResponse;

	})
	.catch((error) => {
		console.log('ERROR Shared.js hitApi(): ', error);
		handleBreakingError('hitApi',error,loginPacket);
		throw new Error('Stop script execution with this uncaught exception.');
	});

	return apiActionResult;
}

// deep copy an object. Object.assign() and {...obj} doesn't deep copy properties of an object if they are objects themselves, but this will
// NOTE: this might not work with Date objects or other weird things
export const deepCopyObj = (objToCopy) => {
	return JSON.parse(JSON.stringify(objToCopy));
}

// get a correct/incorrect answer id for a question
export const getAnswerIdWithCorrectness = (contentCategories,categoryId,questionId,isCorrect) => {
	let answerIdToReturn;
	let question = getQuestionById(contentCategories,categoryId,questionId);
	question.answers.forEach(function(answer) {
		if(answer.correct === isCorrect && empty(answerIdToReturn)) {
			answerIdToReturn = answer.id;
		}
	});
	return answerIdToReturn;
}

// get an answer by question and id
export const getAnswerById = (question,answerId) => {
	let answerToReturn;
	question.answers.forEach(function(answer) {
		if(answer.id === answerId) {
			answerToReturn = answer;
		}
	});
	return answerToReturn;
}

// return the correct answer for a questions answers
export const getCorrectAnswer = (answers) => {
	let correctAnswer = {};

	const letterMap = ['A','B','C','D','E','F'];
	for (var i = 0; i < answers.length; i++) {
		const answer = answers[i];
		if(answer.correct) {
			correctAnswer.correctLetter = letterMap[i];
			correctAnswer.correctAnswerText = answer.text;
		}
	}

	return correctAnswer;
}

// get the first unanswered question for a category/mode.
export const getFirstUnansweredQuestionId = (category,mode) => {
	const userAnswers = category.modes[mode].answers;
	let userAnswersArr = objToArr(userAnswers);
	userAnswersArr.sort(function(a, b) {
		// in exam mode, question order will be shuffled, so sort by question number
		return a.questionNumber - b.questionNumber;
	});
	const isInRetakeMode = category.modes[mode].status === 'retake';
	let firstUnansweredQuestionId = false;
	if(isInRetakeMode) {
		userAnswersArr.forEach(function(userAnswer,idx) {
			if(userAnswer.answered === false && userAnswer.subset === 'retake' && empty(firstUnansweredQuestionId)) {
				firstUnansweredQuestionId = userAnswer.questionId;
			}
		});

		if(empty(firstUnansweredQuestionId)) {
			// we didn't find an unanswered question, so that means all questions in retake mode have been answered so we want to return the last question id in retake mode
			userAnswersArr.forEach(function(userAnswer,idx) {
				if(userAnswer.subset === 'retake') {
					firstUnansweredQuestionId = userAnswer.questionId;
				}
			});
		}

	} else {
		// NOT retake mode
		userAnswersArr.forEach(function(userAnswer,idx) {
			if(userAnswer.answered === false && empty(firstUnansweredQuestionId)) {
				firstUnansweredQuestionId = userAnswer.questionId;
			}
		});

		if(empty(firstUnansweredQuestionId)) {
			// we didn't find any unanswered questions so just return the last question id
			firstUnansweredQuestionId = userAnswersArr[userAnswersArr.length-1].questionId;
		}
	}

	return firstUnansweredQuestionId;
}

// progress circles on categories and landing pages
export const renderProgressCircle = (circleName, progress, completion,cssThemePrefix, progressCircleRadius, progressCircleStroke, examMode) => {
	let angle = progress / completion * 359.99;  //can't do a full 360
	const progressCircleCircumference = progressCircleRadius * 2 * Math.PI;
	const progressCirclePathLength = progressCircleCircumference * progress / completion;
	const circleSpeed = Math.round(2000 * progress / completion);
	let viewBoxSize = "0 0 " + String(progressCircleRadius*2) + " " + String(progressCircleRadius*2);
	let progressCircleColor = ORG_STYLES[cssThemePrefix].progressFail;
	switch (examMode) {
		case "Fail": progressCircleColor = ORG_STYLES[cssThemePrefix].progressFail; break;
		case "Exam": progressCircleColor = ORG_STYLES[cssThemePrefix].examMode; break;
		case "Learning": progressCircleColor = ORG_STYLES[cssThemePrefix].learningMode; break;
	}
	// console.log(examMode, progressCircleColor);

	return (
		<div>
			<svg height={progressCircleRadius*2} width={progressCircleRadius*2} viewBox={viewBoxSize}>
				<style>
					{`
					.animatedpath_${circleName} {
						animation: animateddash_${circleName} ${circleSpeed}ms ease;
						animation-fill-mode: forwards;
						animation-delay: 750ms;
						animation-timing-function: linear;
					}
					.browser-ie .animatedpath_${circleName} {
						stroke-dashoffset: 0;
					}
					@keyframes animateddash_${circleName} {
						0% {
							stroke-dashoffset: ${Math.round(progressCirclePathLength)};
						}
						50% {
							stroke-dashoffset: 0;
						}
						80% {
							stroke-dashoffset: ${Math.round(progressCirclePathLength)*0.25};
						}
						100% {
							stroke-dashoffset: 0;
						}
					}
					@keyframes animateddash_${circleName} {
						0% {stroke-dashoffset: ${Math.round(progressCirclePathLength*1.1)};}
						20% {stroke-dashoffset: ${Math.round(progressCirclePathLength*0.4)};}
						60% {stroke-dashoffset: 0;}
						80% {stroke-dashoffset: ${Math.round(progressCirclePathLength*0.05)};}
						100% {stroke-dashoffset: ${Math.round(progressCirclePathLength * 0.1)};}
					}
					`}
				</style>
				<circle cx={progressCircleRadius}
					cy={progressCircleRadius}
					r={progressCircleRadius-progressCircleStroke/2}
					stroke={ORG_STYLES[cssThemePrefix].progressUnfinished}
					strokeWidth={progressCircleStroke}
					fill="none"
				/>
				{(progress) &&
					<circle className={"animatedpath_" + circleName}
						cx={progressCircleRadius}
						cy={progressCircleRadius}
						r={progressCircleRadius-progressCircleStroke/2}
						stroke={progressCircleColor}
						strokeWidth={progressCircleStroke}
						fill="none"
						strokeDasharray={Math.round(progressCirclePathLength * 1.1) + ", " + Math.round(progressCircleCircumference * 2)}
						strokeDashoffset={Math.round(progressCirclePathLength) * 1.1}
						strokeLinecap="round"
						transform={"rotate(-90," + progressCircleRadius + "," + progressCircleRadius + ")"}
				/>}
			</svg>
		</div>
	)
}

export const getFormattedQuestionNumber = (unformattedQuestionNumber) => {
	let numArr = unformattedQuestionNumber.split('');
	if(numArr.length < 2) {
		numArr.unshift('0');
	}
	return numArr.join('');
}

// get the claim status of a parent category
export const getParentCategoryClaimStatus = (userData,contentCategories,categoryNumber) => {
	let claimStatusObj = {
		claimStatus: false,
		timestamp: '0000-00-00',
	}
	let allClaimed = true;
	let allClaimable = true;
	for (const key of Object.keys(userData.categories)) {
		const userCategory =  userData.categories[key];
		const category =  getCategoryById(contentCategories,userCategory.id);
		if(category.number === categoryNumber && (allClaimed === true || allClaimable === true)) {
			if(userData.creditsClaimed[category.id].claimStatus !== 'claimed') {
				allClaimed = false;
			}
			if(userData.creditsClaimed[category.id].claimStatus !== 'claimable') {
				allClaimable = false;
			}

			// keep track of timestamp for last category part claimed
			if(claimStatusObj.timestamp < userData.creditsClaimed[category.id].timestamp) {
				claimStatusObj.timestamp = userData.creditsClaimed[category.id].timestamp;
			}

		}
	}

	if(allClaimed) {
		claimStatusObj.claimStatus = 'claimed';
	} else if(allClaimable) {
		claimStatusObj.claimStatus = 'claimable';
	}
	return claimStatusObj;
}

// returns whether a module's mode is ready to be finalized or not
export const isReadyToFinalize = (userData, categoryId, mode='Exam') => {

	// first determine if the mode has already been finalized
	const modeStatus = getCategoryModeStatus(userData,categoryId,mode)
	if((mode === 'Learning' && modeStatus === 'completed') || (mode === 'Exam' && modeStatus === 'review')) {
		return false
	}

	// determine if the mode is completed and ready to be finalized (since if we got here, it hasn't already been)
	const performanceStats = getCategoryPerformanceStats(userData,categoryId,mode);

	return performanceStats['isCompleted'];
}

// a user answered all the questions in a category's Exam mode and has elected to finalize their exam to determine if they achieved a passing score
export const finalizeExam = (screenProps, userData, category) => {
	// determine if user has a passing score and enter appropriate mode
	let performanceStats = getCategoryPerformanceStats(userData,category.id);
	if(performanceStats['percentCorrect'] >= PASSING_PERCENTAGE) {
		enterReviewMode(screenProps,userData,category);
	} else {
		enterRetakeMode(screenProps,userData,category);
	}
}

// user passed the exam so change mode status to 'review'. also increment finalizedCount for syncing
export const enterReviewMode = (screenProps, userData, category) => {
	// remove 'retake' subset keys from user answers
	let userAnswers = getCategoryUserAnswers(userData,category.id,'Exam');
	let userAnswerTimestamp = getCurrentTimestampStringUTC();
	Object.keys(userAnswers).forEach(function(userAnswerKey) {
		let userAnswer = userAnswers[userAnswerKey];
		userAnswer.subset = null;
		userAnswer.timestamp = userAnswerTimestamp;
	});

	// update claim status to 'claimable', increment finalizedCount, and update mode status to 'review' (which also saves userData to redux/db... assuming the status is infact getting updated - so isn't already 'review' which it shouldn't be)
	let updatedUserData = Object.assign({},userData);
	updatedUserData.creditsClaimed[category.id].claimStatus = 'claimable';
	let performanceStats = getCategoryPerformanceStats(userData,category.id,'Exam');
	updatedUserData.creditsClaimed[category.id].percentCorrect = performanceStats['percentCorrect'].toString();
	updatedUserData.creditsClaimed[category.id].timestamp = getCurrentTimestampStringUTC();
	let previousFinalizedCount = parseInt(updatedUserData.categories[category.id].modes.Exam.finalizedCount, 10);
	let updatedFinalizedCount = previousFinalizedCount + 1;
	updatedUserData.categories[category.id].modes.Exam.finalizedCount = updatedFinalizedCount.toString();
	screenProps.setCategoryModeStatus(updatedUserData,category.id,'review');

	// navigate to Landing page
	screenProps.setUserPosition(userData,category);
	screenProps.history.push('/completion?cid='+category.id);
}

// user failed the exam so change mode status to 'retake' (if not already) and mark incorrect user answer subset keys as 'retake'. also increment finalizedCount for syncing
export const enterRetakeMode = (screenProps, userData, category) => {
	// mark incorrect user answer subset keys as 'retake' (and reset subset keys if correct)
	let updatedUserData = Object.assign({},userData);
	let userAnswers = getCategoryUserAnswers(updatedUserData,category.id,'Exam');
	let userAnswerTimestamp = getCurrentTimestampStringUTC();
	Object.keys(userAnswers).forEach(function(userAnswerKey) {
		let userAnswer = userAnswers[userAnswerKey];
		if(userAnswer.correct) {
			userAnswer.subset = false;
		} else {
			userAnswer.subset = 'retake';
			userAnswer.answered = false;
		}
		userAnswer.timestamp = userAnswerTimestamp;
	});

	// increment finalizedCount and update mode status to 'retake' (which also saves userData to redux/db)
	let previousFinalizedCount = parseInt(updatedUserData.categories[category.id].modes.Exam.finalizedCount, 10);
	let updatedFinalizedCount = previousFinalizedCount + 1;
	updatedUserData.categories[category.id].modes.Exam.finalizedCount = updatedFinalizedCount.toString();
	screenProps.setCategoryModeStatus(updatedUserData,category.id,'retake');

	// navigate to Landing page
	screenProps.setUserPosition(userData,category);
	screenProps.history.push('/completion?cid='+category.id);
}

export const getDefaultPosition = (contentCategories, currentPosition) => {
	let cid = '1';
	let qid = false;
	if(!empty(currentPosition.category) && !empty(currentPosition.item)) {
		cid = currentPosition.category.id;
		qid = currentPosition.item.id;
	} else if(!empty(currentPosition.category)) {
		cid = currentPosition.category.id;
	}
	let category = getCategoryById(contentCategories, cid);
	let question = false;
	if(empty(qid)) {
		// question id wasn't found via url params or stored position, so get it based off of category id
		question = itemByCategoryOrderAndItemNumber(contentCategories,category.order,'1');
	} else {
		question = getQuestionById(contentCategories, cid, qid);
	}

	return {category:category,question:question};
}


// save bookmark to user data (called from Item.js for creating and Bookmarks.js for editing)
export const saveBookmark = (screenProps,contentCategories,userData,categoryId,questionId,bookmarkName,bookmarkNotes,existingBookmark) => {

	let bookmarkToSave = {};
	if(!empty(existingBookmark)) {
		categoryId = existingBookmark.categoryId;
		questionId = existingBookmark.questionId;
	}
	const category = getCategoryById(contentCategories,categoryId);
	const item = getQuestionById(contentCategories,categoryId,questionId);

	// build bookmark
	if(!bookmarkName) {
		bookmarkName = CATEGORY_ALIAS + ' ' + category.displayNum + ', Item ' + item.number;
	}
	if(empty(bookmarkNotes)) {
		// if there is an existing bookmark for this category and number, use those notes
		bookmarkNotes = 'No notes.';
		if(!empty(existingBookmark)) {
			bookmarkNotes = existingBookmark.notes;
		}
	}

	// if a bookmark exists, update the name and notes. otherwise create a new bookmark
	if(existingBookmark) {
		// update existing bookmark
		bookmarkToSave = existingBookmark;
		bookmarkToSave.name = bookmarkName;
		bookmarkToSave.notes = bookmarkNotes;
		bookmarkToSave.deleted = false;
		bookmarkToSave.timestamp = getCurrentTimestampStringUTC();
	} else {
		// create new bookmark
		// get a bookmark id by forming an array of ids and finding the highest one and then incrementing
		let bookmarkIds = [];
		let bookmarkId;
		if(Object.keys(userData.bookmarks).length === 0) {
			bookmarkId = 1;
		} else {
			for (const key of Object.keys(userData.bookmarks)) {
				const bookmark =  userData.bookmarks[key];
				bookmarkIds.push(parseInt(bookmark.id));
			}
			bookmarkId = Math.max.apply(null,bookmarkIds) + 1;
		}

		// build new bookmark
		bookmarkToSave = {
			id: bookmarkId.toString(),
			categoryId: categoryId,
			questionId: questionId,
			name: bookmarkName,
			notes: bookmarkNotes,
			deleted: false,
			timestamp: getCurrentTimestampStringUTC()
		}
	}

	// call redux store action to save bookmark to userData which also updates db
	let updatedUserData = Object.assign({},userData);
	updatedUserData.bookmarks[bookmarkToSave.id] = bookmarkToSave;
	screenProps.syncReduxAndDB(updatedUserData, 'syncBookmarks');
}

// return the first 150 (or specified) characters of a string, followed by elipses
export const getBeginningExcerpt = (originalString,charLimit=150) => {
	let excerpt = '';
	if(originalString.length > charLimit) {
		excerpt = originalString.substring(0,charLimit) + '&hellip;';
	} else {
		excerpt = originalString;
	}

	return excerpt;
}

/**
* autop()
*
* from: https://github.com/mavin/node-wordpress-autop/blob/master/index.js
* Taken from:
* https://github.com/WordPress/WordPress/blob/master/wp-admin/js/editor.js
* with slight changes for style.
*/
//function autop (pee) {
export const autop = (pee) => {
	let preserve_linebreaks = false
	let preserve_br = false
	let blocklist = 'table|thead|tfoot|caption|col|colgroup|tbody|tr|td|th|div|dl|dd|dt|ul|ol|li|pre' +
				'|form|map|area|blockquote|address|math|style|p|h[1-6]|hr|fieldset|legend|section' +
				'|article|aside|hgroup|header|footer|nav|figure|figcaption|details|menu|summary'

	if (pee.indexOf('<object') !== -1) {
		pee = pee.replace(/<object[\s\S]+?<\/object>/g, function (a) {
			return a.replace(/[\r\n]+/g, '')
		})
	}

	pee = pee.replace(/<[^<>]+>/g, function (a) {
		return a.replace(/[\r\n]+/g, ' ')
	})

	// Protect pre|script tags
	if (pee.indexOf('<pre') !== -1 || pee.indexOf('<script') !== -1) {
		preserve_linebreaks = true
		pee = pee.replace(/<(pre|script)[^>]*>[\s\S]+?<\/\1>/g, function (a) {
			return a.replace(/(\r\n|\n)/g, '<wp-line-break>')
		})
	}

	// keep <br> tags inside captions and convert line breaks
	if (pee.indexOf('[caption') !== -1) {
		preserve_br = true
		pee = pee.replace(/\[caption[\s\S]+?\[\/caption\]/g, function (a) {
			// keep existing <br>
			a = a.replace(/<br([^>]*)>/g, '<wp-temp-br$1>')
			// no line breaks inside HTML tags
			a = a.replace(/<[a-zA-Z0-9]+( [^<>]+)?>/g, function (b) {
				return b.replace(/[\r\n\t]+/, ' ')
			})
			// convert remaining line breaks to <br>
			return a.replace(/\s*\n\s*/g, '<wp-temp-br />')
		})
	}

	pee = pee + '\n\n'
	pee = pee.replace(/<br \/>\s*<br \/>/gi, '\n\n')
	pee = pee.replace(new RegExp('(<(?:' + blocklist + ')(?: [^>]*)?>)', 'gi'), '\n$1')
	pee = pee.replace(new RegExp('(</(?:' + blocklist + ')>)', 'gi'), '$1\n\n')
	pee = pee.replace(/<hr( [^>]*)?>/gi, '<hr$1>\n\n') // hr is self closing block element
	pee = pee.replace(/\s*<option/gi, '<option') // No <p> or <br> around <option>
	pee = pee.replace(/<\/option>\s*/gi, '</option>')
	pee = pee.replace(/\r\n|\r/g, '\n')
	pee = pee.replace(/\n\s*\n+/g, '\n\n')
	pee = pee.replace(/([\s\S]+?)\n\n/g, '<p>$1</p>\n')
	pee = pee.replace(/<p>\s*?<\/p>/gi, '')
	pee = pee.replace(new RegExp('<p>\\s*(</?(?:' + blocklist + ')(?: [^>]*)?>)\\s*</p>', 'gi'), '$1')
	pee = pee.replace(/<p>(<li.+?)<\/p>/gi, '$1')
	pee = pee.replace(/<p>\s*<blockquote([^>]*)>/gi, '<blockquote$1><p>')
	pee = pee.replace(/<\/blockquote>\s*<\/p>/gi, '</p></blockquote>')
	pee = pee.replace(new RegExp('<p>\\s*(</?(?:' + blocklist + ')(?: [^>]*)?>)', 'gi'), '$1')
	pee = pee.replace(new RegExp('(</?(?:' + blocklist + ')(?: [^>]*)?>)\\s*</p>', 'gi'), '$1')
	pee = pee.replace(/\s*\n/gi, '<br />\n')
	pee = pee.replace(new RegExp('(</?(?:' + blocklist + ')[^>]*>)\\s*<br />', 'gi'), '$1')
	pee = pee.replace(/<br \/>(\s*<\/?(?:p|li|div|dl|dd|dt|th|pre|td|ul|ol)>)/gi, '$1')
	pee = pee.replace(/(?:<p>|<br ?\/?>)*\s*\[caption([^\[]+)\[\/caption\]\s*(?:<\/p>|<br ?\/?>)*/gi, '[caption$1[/caption]')

	pee = pee.replace(/(<(?:div|th|td|form|fieldset|dd)[^>]*>)(.*?)<\/p>/g, function (a, b, c) {
		if (c.match(/<p( [^>]*)?>/)) {
			return a
		}

		return b + '<p>' + c + '</p>'
	})

	// put back the line breaks in pre|script
	if (preserve_linebreaks) {
		pee = pee.replace(/<wp-line-break>/g, '\n')
	}

	if (preserve_br) {
		pee = pee.replace(/<wp-temp-br([^>]*)>/g, '<br$1>')
	}

	return pee
//}// /function autop (pee) {
}// /export const autop = (pee) => {

/**
* array_unique()
* from: https://locutus.io/php/array/array_unique/
*/
export const array_unique = (inputArr) => {// eslint-disable-line camelcase
  //  discuss at: https://locutus.io/php/array_unique/
  // original by: Carlos R. L. Rodrigues (https://www.jsfromhell.com)
  //    input by: duncan
  //    input by: Brett Zamir (https://brett-zamir.me)
  // bugfixed by: Kevin van Zonneveld (https://kvz.io)
  // bugfixed by: Nate
  // bugfixed by: Kevin van Zonneveld (https://kvz.io)
  // bugfixed by: Brett Zamir (https://brett-zamir.me)
  // improved by: Michael Grier
  //      note 1: The second argument, sort_flags is not implemented;
  //      note 1: also should be sorted (asort?) first according to docs
  //   example 1: array_unique(['Kevin','Kevin','van','Zonneveld','Kevin'])
  //   returns 1: {0: 'Kevin', 2: 'van', 3: 'Zonneveld'}
  //   example 2: array_unique({'a': 'green', 0: 'red', 'b': 'green', 1: 'blue', 2: 'red'})
  //   returns 2: {a: 'green', 0: 'red', 1: 'blue'}

  var key = ''
  var tmpArr2 = {}
  var val = ''

  var _arraySearch = function (needle, haystack) {
    var fkey = ''
    for (fkey in haystack) {
      if (haystack.hasOwnProperty(fkey)) {
        if ((haystack[fkey] + '') === (needle + '')) {
          return fkey
        }
      }
    }
    return false
  }

  for (key in inputArr) {
    if (inputArr.hasOwnProperty(key)) {
      val = inputArr[key]
      if (_arraySearch(val, tmpArr2) === false) {
        tmpArr2[key] = val
      }
    }
  }

  return tmpArr2
}// /export const array_unique = ($array) => {

// determines if project is live. both stage and live folders CAN be considered live (if true param passed) since they both use live dbs
export const isProjectLive = (isStageConsideredLive=false) => {
	let siteIsLive = empty(window.location.href.includes(PROJECT_BASENAME+'_')) && empty(window.location.href.includes('localhost:3001'));

	if(isStageConsideredLive) {
		const siteIsOnStage = !empty(window.location.href.includes(PROJECT_BASENAME+'_stage')) ? true : false;

		if(siteIsLive || siteIsOnStage) {
			siteIsLive = true;
		} else {
			siteIsLive = false;
		}
	}
	return siteIsLive;
}

// user answers in exam mode are shuffled. this function will get the item order given the original item number
export const getItemShuffleOrder = (userData,categoryId,questionId) => {
	let itemOrder = false;
	const userAnswers = getCategoryUserAnswers(userData,categoryId,'Exam');
	Object.keys(userAnswers).forEach(function(userAnswerKey) {
		let userAnswer = userAnswers[userAnswerKey];
		if(userAnswer.questionId === questionId) {
			itemOrder = userAnswer.questionNumber;
		}
	});

	return itemOrder;
}

// EVAL FORMS ================================================================
/**
* form_checkbox_qs()
* questions shared
*
* @param $EvalData
* @param $cid
* @param $eqnum
* @param $qsnum
* @param $qnum
* @param $num_answer=null -- if not null, adds data-num_answer to indicate how many in the cb group need to be answered before passing validation
*/
export const form_checkbox_qs = ($EvalData,$cid,$eqnum,$qsnum,$qnum,$num_answer=null,$handleFieldChange) => {
	//this.form_checkbox_qs(5,'qs6','q9')
	$cid = 'c'+$cid;
	return(<>
		<div>
			<input type="hidden" name={'UserEvalResponses[EvalQuestions]['+$eqnum+'][EvalQuestionsID]'} id="" value={$EvalData['questions'][$cid+'-'+$qnum]['EvalQuestions.EvalQuestionsID']} />
			<label dangerouslySetInnerHTML={{ __html:$EvalData['questions_shared'][$qsnum]['EvalQuestionsShared.Text']}}></label>
			<span>
			{objToArr($EvalData['questions_shared'][$qsnum]['answers_shared']).map(function($v,$i){
				//console.log('$i:',$i);console.log('$v:',$v);
				let $text = $v['EvalAnswersShared.Text'];
				let $value = $EvalData['questions'][$cid+'-'+$qnum]['answers'][$cid+'-'+$qnum+'-a'+($i+1)]['EvalAnswers.EvalAnswersID'];
				let $name = 'UserEvalResponses[EvalQuestions]['+$eqnum+'][EvalAnswersID]['+$i+']';
				let $classname = 'UserEvalResponses-EvalQuestions-'+$eqnum+'-EvalAnswersID';
				return(<React.Fragment key={$i}>
					<label>
					<input onChange={$handleFieldChange} type="checkbox" title="" name={$name} className={$classname} id="" value={$value} data-num_answer={$num_answer} />
					<hp-innerhtml dangerouslySetInnerHTML={{ __html:$text}} />
					</label>
				</React.Fragment>)
			})}
			</span>
		</div>
	</>)
}// /export const form_checkbox_qs = () => {

/**
* form_radio()
*
*/
export const form_radio = ($EvalData,$cid,$eqnum,$qnum,$handleFieldChange) => {
	//{form_radio($EvalData,2,1,2)}
	$cid = 'c'+$cid;
	return(<>
		<div>
			<input type="hidden" name={'UserEvalResponses[EvalQuestions]['+$eqnum+'][EvalQuestionsID]'} id="" value={$EvalData['questions'][$cid+'-q'+$qnum]['EvalQuestions.EvalQuestionsID']} />
			<label dangerouslySetInnerHTML={{ __html:$EvalData['questions'][$cid+'-q'+$qnum]['EvalQuestions.Text']}}></label>
			<span>
			{objToArr($EvalData['questions'][$cid+'-q'+$qnum]['answers']).map(function($v,$i){
				//console.log('$i:',$i);console.log('$v:',$v);
				let $text = $v['EvalAnswers.Text'];
				let $value = $EvalData['questions'][$cid+'-q'+$qnum]['answers'][$cid+'-q'+$qnum+'-a'+($i+1)]['EvalAnswers.EvalAnswersID'];
				let $name = 'UserEvalResponses[EvalQuestions]['+$eqnum+'][EvalAnswersID][0]';
				let $classname = 'UserEvalResponses-EvalQuestions-'+$eqnum+'-EvalAnswersID';
				return(<React.Fragment key={$i}>
					<label>
					<input onClick={$handleFieldChange} type="radio" title="" name={$name} className={$classname} id="" value={$value} />
					<hp-innerhtml dangerouslySetInnerHTML={{ __html:$text}} />
					</label>
				</React.Fragment>)
			})}
			</span>
		</div>
	</>)
}// /export const form_radio = () => {

/**
* form_radio_qs()
* questions shared
*
*/
export const form_radio_qs = ($EvalData,$cid,$eqnum,$qsnum,$qnum,$handleFieldChange) => {
	//{this.form_radio_qs(1,'qs2','q5')}
	$cid = 'c'+$cid;
	return(<>
		<div>
			<input type="hidden" name={'UserEvalResponses[EvalQuestions]['+$eqnum+'][EvalQuestionsID]'} id="" value={$EvalData['questions'][$cid+'-'+$qnum]['EvalQuestions.EvalQuestionsID']} />
			<label dangerouslySetInnerHTML={{ __html:$EvalData['questions_shared'][$qsnum]['EvalQuestionsShared.Text']}}></label>
			<span>
			{objToArr($EvalData['questions_shared'][$qsnum]['answers_shared']).map(function($v,$i){
				//console.log('$i:',$i);console.log('$v:',$v);
				let $text = $v['EvalAnswersShared.Text'];
				let $value = $EvalData['questions'][$cid+'-'+$qnum]['answers'][$cid+'-'+$qnum+'-a'+($i+1)]['EvalAnswers.EvalAnswersID'];
				let $name = 'UserEvalResponses[EvalQuestions]['+$eqnum+'][EvalAnswersID][0]';
				let $classname = 'UserEvalResponses-EvalQuestions-'+$eqnum+'-EvalAnswersID';
				return(<React.Fragment key={$i}>
					<label>
					<input onClick={$handleFieldChange} type="radio" title="" name={$name} className={$classname} id="" value={$value} />
					<hp-innerhtml dangerouslySetInnerHTML={{ __html:$text}} />
					</label>
				</React.Fragment>)
			})}
			</span>
		</div>
	</>)
}// /export const form_radio_qs = () => {

/**
* form_select_qs()
* questions shared
*
*/
export const form_select_qs = ($EvalData,$cid,$eqnum,$qsnum,$qnum) => {
	//{this.form_radio_qs(1,'qs2','q5')}
	$cid = 'c'+$cid;
	return(<>
		<div>
			<input type="hidden" name={'UserEvalResponses[EvalQuestions]['+$eqnum+'][EvalQuestionsID]'} id="" value={$EvalData['questions'][$cid+'-'+$qnum]['EvalQuestions.EvalQuestionsID']} />
			<label dangerouslySetInnerHTML={{ __html:$EvalData['questions_shared'][$qsnum]['EvalQuestionsShared.Text']}}></label>
			<span>
			<select title="" name={'UserEvalResponses[EvalQuestions]['+$eqnum+'][EvalAnswersID]'} className={'UserEvalResponses-EvalQuestions-'+$eqnum+'-EvalAnswersID'} id="">
				<option value=""></option>
			{objToArr($EvalData['questions_shared'][$qsnum]['answers_shared']).map(function($v,$i){
				//console.log('$i:',$i);console.log('$v:',$v);
				let $text = $v['EvalAnswersShared.Text'];
				let $value = $EvalData['questions'][$cid+'-'+$qnum]['answers'][$cid+'-'+$qnum+'-a'+($i+1)]['EvalAnswers.EvalAnswersID'];
				return(<React.Fragment key={$i}>
					<option  value={$value} dangerouslySetInnerHTML={{ __html:$text}} />
				</React.Fragment>)
			})}
			</select>
			</span>
		</div>
	</>)
}// /export const form_select_qs = () => {

/**
* form_text_qs()
* questions shared
*
*/
export const form_text_qs = ($EvalData,$cid,$eqnum,$qsnum,$qnum,$handleFieldChange) => {
	//this.form_text_qs(4,'qs5','q4','q8');
	$cid = 'c'+$cid;
	return(<>
		<div>
			<input type="hidden" name={'UserEvalResponses[EvalQuestions]['+$eqnum+'][EvalQuestionsID]'} id="" value={$EvalData['questions'][$cid+'-'+$qnum]['EvalQuestions.EvalQuestionsID']} />
			<label dangerouslySetInnerHTML={{ __html:$EvalData['questions_shared'][$qsnum]['EvalQuestionsShared.Text']}}></label>
			<span>
			<input onChange={$handleFieldChange} type="text" title="" name={'UserEvalResponses[EvalQuestions]['+$eqnum+'][EvalAnswersID][EvalAnswersIDValue]['+$EvalData['questions'][$cid+'-'+$qnum]['answers'][$cid+'-'+$qnum+'-a1']['EvalAnswers.EvalAnswersID']+'][value]'} className={'UserEvalResponses-EvalQuestions-'+$eqnum+'-EvalAnswersID'} id="" defaultValue="" />
			</span>
		</div>
	</>)
}// /export const form_text_qs = () => {

/**
* form_textarea_qs()
* questions shared
*
*/
export const form_textarea_qs = ($EvalData,$cid,$eqnum,$qsnum,$qnum,$handleFieldChange) => {
	//this.form_textarea_qs(4,'qs5','q4','q8');
	$cid = 'c'+$cid;
	return(<>
		<div>
			<input type="hidden" name={'UserEvalResponses[EvalQuestions]['+$eqnum+'][EvalQuestionsID]'} id="" value={$EvalData['questions'][$cid+'-'+$qnum]['EvalQuestions.EvalQuestionsID']} />
			<label dangerouslySetInnerHTML={{ __html:$EvalData['questions_shared'][$qsnum]['EvalQuestionsShared.Text']}}></label>
			<span>
			<textarea onChange={$handleFieldChange} type="text" title="" name={'UserEvalResponses[EvalQuestions]['+$eqnum+'][EvalAnswersID][EvalAnswersIDValue]['+$EvalData['questions'][$cid+'-'+$qnum]['answers'][$cid+'-'+$qnum+'-a1']['EvalAnswers.EvalAnswersID']+'][value]'} className={'UserEvalResponses-EvalQuestions-'+$eqnum+'-EvalAnswersID'} id="" defaultValue="" cols="20" rows="5" />
			</span>
		</div>
	</>)
}// /export const form_textarea_qs = () => {

/**
* eval_n_toptext()
* used by all of the eval-n include pages
*/
export const eval_n_toptext = () => {
	return (<>
		<h3><span>1</span> Learning Objectives</h3>
		<p><strong>Please indicate the extent to which this activity achieved its stated Learning Objectives.</strong></p>
		<p>At the conclusion of this activity, participants should be able to:</p>
	</>);
}
// /EVAL FORMS ================================================================

// this function is here to solve the problem of animating route transitions. when a route transition happens, the entering route component mounts and can change the value of this.props.userData.position (both category and item properties). this causes problems on this page. so we use component state.position for position values, updating state here when it's updated in props via redux EXCEPT when this component is not the current component. in that case, it will be the exiting component so we DON'T want to update state.position, since this page relies on the old values and still needs to function until it's completely animated off
export const getDerivedStateFromPropsValue = (routeName,nextProps,prevState) => {
	if(nextProps.screenProps.history.location.pathname.match(routeName)) {
		// we are on the item page, so update state.position
		const userPosition = Object.assign({},nextProps.userData.position);
		return {
			position: userPosition,
		};
	} else {
		// we are NOT on the item page, so use old state.position values
		return null;
	}
}

// notifies read-only admin user they don't have permission to complete an action
export const restrictionAlert = () => {
	alert("You don't have permission for this action. Please contact SVS staff if you believe this to be an error.");
}

// determines if admin user is a read only user
export const isReadOnlyAdmin = (adminData) => {
	const privilegeLevel = adminData.data.privilegeLevel;
	if(privilegeLevel == 'read') {
		return true;
	} else {
		return false;
	}
}
