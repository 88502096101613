import React, { Component } from 'react';
import { Route, Redirect, Switch, BrowserRouter as Router } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
//import { Route } from 'react-router' // react-router v4
import { ActionCreators } from './actions';
import { ConnectedRouter } from 'connected-react-router'
import { isLoggedIn, empty, loadProjectCSS, isProjectLive } from './utility/Shared'
import Header from './common/Header'
import Footer from './common/Footer'

import AddUser from './admin/AddUser'
import AdminDashboard from './admin/Dashboard'
import AdminErrorPage from './admin/ErrorPage'
import AdminLogin from './admin/Login'
import AdminLogout from './admin/Logout'
import CreditsClaimed from './admin/CreditsClaimed'
import Evaluations from './admin/Evaluations'
import ExamBuilder from './admin/ExamBuilder'
import ExamBuilderEdit from './admin/ExamBuilderEdit'
import ExamBuilderImporter from './admin/ExamBuilderImporter'
import FirstResponses from './admin/FirstResponses'
import QuestionFeedback from './admin/QuestionFeedback'
import VESAP4Certificates from './admin/VESAP4Certificates'
import SuperAdmin from './admin/SuperAdmin'
import UserDetail from './admin/UserDetail'
import UserSearch from './admin/UserSearch'
import UserSummary from './admin/UserSummary'

import About from './components/About'
import Auth from './components/Auth'
import Bookmarks from './components/Bookmarks'
import Categories from './components/Categories'
import ClaimCredit from './components/ClaimCredit'
import Completion from './components/Completion'
import ErrorPage from './components/ErrorPage'
import Evaluation from './components/Evaluation'
import Help from './components/Help'
import Item from './components/Item'
import Landing from './components/Landing'
import Login from './components/Login'
import Logout from './components/Logout'
import PageNotFound from './components/PageNotFound'
import PerformanceSummary from './components/PerformanceSummary'
import Sandbox from './components/Sandbox'
import Search from './components/Search'
import Welcome from './components/Welcome'

import EvaluationsTest from './components/testsuite/EvaluationsTest'

import { DEFAULT_CSS_THEME_PREFIX, DEFAULT_CSS_FILENAME, CATEGORIES_ALIAS } from './utility/Constants'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

// this is a custom route that checks to see if a user is logged in before navigating to it
const PrivateRoute = ({ path, component: Component, screenProps, location}) => (
	<Route path={path} location={location} render={(props) => (
		// if login token still valid, bring to component. otherwise, log user out
		isLoggedIn() === true ? (
			<Component screenProps={screenProps} />
		) : (
			<Logout screenProps={screenProps} />
		)
	)} />
)


// this is a custom admin route that checks to see if an admin user is logged in before navigating to it
const PrivateAdminRoute = ({ path, component: Component, screenProps, exact }) => (
	<Route path={path} exact={exact} render={(props) => (
		// if login token still valid, bring to component. otherwise, log admin user out
		isLoggedIn(true) === true ? (
			<Component screenProps={screenProps} />
		) : (
			<AdminLogout screenProps={screenProps} />
		)
	)} />
)

class App extends Component {

	constructor(props) {
		super(props);
		// only needed for development since live site bundles all css files (which is why we use the cssThemePrefix below)
		loadProjectCSS(DEFAULT_CSS_FILENAME);

		// detect browser
		const { detect } = require('detect-browser');
		const browser = detect();
		if (browser) {
			if(browser.name === 'ie') {
				this.isBrowserIE = true;
			}
			if(browser.name === 'edge') {
				this.isBrowserEdge = true;
			}
		}

		this.animateRight = false;
	} // end constructor()

	state = {
		animateRoute: true,
		animateRight: false,
	}

	async componentDidMount() {

		this.resizeWindow();
		window.addEventListener('resize', this.resizeWindow);

		// add route change listener for route transition animations. not actually using this.state.animateRoute, but want to run render and scroll to top.
		this.unlistenRoutes = this.props.history.listen((location, action) => {

			window.scrollTo(0,0);

			let animateR = false;

			if ((this.state.animateRoute === '/item') && (location.pathname === '/landing')) {
				animateR = true;
			}
			if ((this.state.animateRoute === '/landing') && (location.pathname === '/modules')) {
				animateR = true;
			}
			this.animateRight = animateR;

			this.setState({animateRoute:location.pathname});

		});

	}

	async componentWillUnmount() {
		window.removeEventListener('resize', this.resizeWindow);

		this.unlistenRoutes();
	}

	resizeWindow = () => {
		let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
		let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;
		let screenDetails = {
			width: windowWidth,
			height: windowHeight,
		}
		this.props.setScreenDimensions(screenDetails);
	}

  render() {
		// console.log('App.js render() this.props',this.props);
		const { ...screenProps } = this.props;

		// populate headerType. also get whether the app is ready (for both main site and admin site)
		let headerType = '';
		let isAppLoaded = false;
		const pathname = this.props.history.location.pathname;
		if(pathname.match('login') || pathname === '/' || pathname === '/admin' || pathname === '/admin/') {
			headerType = 'login';
		} else if(pathname.match('error') || pathname.match('welcome')) {
			headerType = 'utility';
		} else if(pathname.match('admin')) {
			headerType = 'admin';
			if(!empty(this.props.admin) && !empty(this.props.admin.data) && !empty(this.props.admin.data.hasOwnProperty('lastName'))) {
				isAppLoaded = true;
			}
		} else {
			headerType = 'main';
			if(!empty(this.props.userData)) {
				isAppLoaded = true;
			}
		}

		// add the cssThemePrefix classes (e.g. 'uts-styles') to <body> tag. doing in <body> tag (rather than here where app-container is) so that we can add theme specific styles to react-confirm-alert.css (and any other package specific css that is outside of app-container)
		// first, remove any classes that we put on last time App.js was rendered so we don't duplicate theme and browser classes
		document.getElementsByTagName("BODY")[0].classList.forEach(function(className,idx) {
			if(empty(className)) {
				// this makes the app work in IE/Edge and took hours to figure out. thank you user @Hindsight on Reactiflux! (see note in wiki troubleshooting)
				return;
			}

			if(className.match('-styles') || className.match('browser-')) {
				document.getElementsByTagName("BODY")[0].classList.remove(className);
			}
		});
		let cssThemePrefix = this.props.licensing.cssThemePrefix;
		if(empty(cssThemePrefix)) {
			cssThemePrefix = DEFAULT_CSS_THEME_PREFIX;
		}
		document.getElementsByTagName("BODY")[0].classList.add(cssThemePrefix);
		if(!empty(this.isBrowserIE)) {
			document.getElementsByTagName("BODY")[0].classList.add('browser-ie');
		}
		if(!empty(this.isBrowserEdge)) {
			document.getElementsByTagName("BODY")[0].classList.add('browser-edge');
		}

		// location obj for route animations (if it's not set here, then the exiting route doesn't transition out). need to pass to <PrivateRoute /> components (rather than just using screenProps.history.location in those components) since it gets updated here every render (maybe?)

		const location = screenProps.history.location;

		// redirect /login to svs
		const isLive = isProjectLive(true);
		if((location.pathname === '/login' || location.pathname === '/') && !empty(isLive)) {
			window.location.href = 'http://vascular.org/vascular-specialists/education-and-meetings/vesap5';
			return(null);
		}

    return (<>
			<ConnectedRouter history={screenProps.history}>
				<div className={"app-container" + (this.animateRight ? " animateRight" : "")}>
					<Header type={headerType} isAppLoaded={isAppLoaded} screenProps={screenProps} />
					<TransitionGroup className="flex-item-1 flex">
						<CSSTransition
							key={location.key}
							timeout={300}
							classNames="routeTransition">
							<div className="route-container">
								<Switch location={location}>
									{/* main app (not admin pages which are below main app) */}
									{/* non authenticated main app routes */}
									<Route exact path="/" render={() => <Login screenProps={screenProps}/>}/>
									<Route path="/login_local" render={() => <Login screenProps={screenProps}/>}/>
									<Route path="/logout" render={() => <Logout screenProps={screenProps} isAppLoaded={isAppLoaded}/>}/>
									<Route path="/auth" render={() => <Auth screenProps={screenProps}/>}/>
									<Route path='/error' render={() => <ErrorPage screenProps={screenProps}/>}/>

									{/* authenticated main app routes */}
									<PrivateRoute path={'/welcome'} component={Welcome} screenProps={screenProps}/>
									<PrivateRoute path={'/' + CATEGORIES_ALIAS.toLowerCase()} component={Categories} screenProps={screenProps}/>
									<PrivateRoute path={'/landing'} component={Landing} screenProps={screenProps}/>
									<PrivateRoute path={'/item'} component={Item} screenProps={screenProps}/>
									<PrivateRoute path={'/search'} component={Search} screenProps={screenProps}/>
									<PrivateRoute path={'/bookmarks'} component={Bookmarks} screenProps={screenProps}/>
									<PrivateRoute path={'/performance-summary'} component={PerformanceSummary} screenProps={screenProps}/>
									<PrivateRoute path={'/evaluation'} component={Evaluation} screenProps={screenProps}/>
									<PrivateRoute path={'/claim-credit'} component={ClaimCredit} screenProps={screenProps}/>
									<PrivateRoute path={'/about'} component={About} screenProps={screenProps}/>
									<PrivateRoute path={'/help'} component={Help} screenProps={screenProps}/>
									<PrivateRoute path={'/completion'} component={Completion} screenProps={screenProps}/>
									<PrivateRoute path={'/sandbox'} component={Sandbox} screenProps={screenProps}/>

									{/* testsuite pages */}
									<PrivateRoute path={'/evaluations-test'} component={EvaluationsTest} screenProps={screenProps}/>

									{/* admin pages */}
									{/* non authenticated admin pages */}
									<Route exact path="/admin" render={() => <AdminLogin screenProps={screenProps}/>}/>
									<Route path="/admin/login" render={() => <AdminLogin screenProps={screenProps}/>}/>
									<Route path="/admin/logout" render={() => <AdminLogout screenProps={screenProps} isAppLoaded={isAppLoaded}/>}/>
									<Route path="/admin/error" render={() => <AdminErrorPage screenProps={screenProps}/>}/>

									{/* authenticated admin pages */}
									<PrivateAdminRoute path={'/admin/dashboard'} component={AdminDashboard} screenProps={screenProps}/>
									<PrivateAdminRoute path={'/admin/user-summary'} component={UserSummary} screenProps={screenProps}/>
									<PrivateAdminRoute path={'/admin/user-search'} component={UserSearch} screenProps={screenProps}/>
									<PrivateAdminRoute path={'/admin/user-detail'} component={UserDetail} screenProps={screenProps}/>
									<PrivateAdminRoute path={'/admin/credits-claimed'} component={CreditsClaimed} screenProps={screenProps}/>
									<PrivateAdminRoute path={'/admin/evaluations'} component={Evaluations} screenProps={screenProps}/>
									<PrivateAdminRoute path={'/admin/question-feedback'} component={QuestionFeedback} screenProps={screenProps}/>
									<PrivateAdminRoute path={'/admin/vesap4-certificates'} component={VESAP4Certificates} screenProps={screenProps}/>
									<PrivateAdminRoute path={'/admin/first-responses'} component={FirstResponses} screenProps={screenProps}/>
									<PrivateAdminRoute path={'/admin/add-user'} component={AddUser} screenProps={screenProps}/>
									<PrivateAdminRoute path={'/admin/exambuilder'} component={ExamBuilder} screenProps={screenProps} exact={true}/>
									<PrivateAdminRoute path={'/admin/exambuilder/edit'} component={ExamBuilderEdit} screenProps={screenProps}/>
									<PrivateAdminRoute path={'/admin/exambuilder/import'} component={ExamBuilderImporter} screenProps={screenProps}/>
									<PrivateAdminRoute path={'/admin/super-admin'} component={SuperAdmin} screenProps={screenProps}/>
									<Route render={() => <PageNotFound screenProps={screenProps}/>}/>
								</Switch>
							</div>
						</CSSTransition>
					</TransitionGroup>
					<Footer />
				</div>
			</ConnectedRouter>
    </>);
  }
}

// gives us a way of centrally dispatching actions throughout the entire application
const mapDispatchToProps = (dispatch) => {
	// console.log('App.js mapDispatchToProps() called');
	return bindActionCreators(ActionCreators, dispatch);
}

const mapStateToProps = (state) => {
	// console.log('App.js mapStateToProps() called');
	return {
		userData: state.userDataReducers,
		admin: state.adminReducers,
		licensing: state.licensingReducers
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
