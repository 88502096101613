/*
Reducer functions related to adminData object
*/
import { SET_ADMIN_DATA, SET_DASHBOARD_INTERVAL } from '../actions/actionTypes.js';

const initialAdminState = {
	data: {
		needToFetchValuesFromDB: true,
		staticStats: {},
		intervalStats: {},
		usersList: {},
		creditsClaimed: [],
		evaluations: {},
		questionFeedback: {},
		firstResponses: {},
	},
	dashboardInterval: 'Day',
	evalRangeStart: '',
	evalRangeEnd: '',
	creditsClaimedRangeStart: '',
	creditsClaimedRangeEnd: '',
}

const adminDataReducers = (adminState = initialAdminState, action) => {
	// console.log('adminDataReducers adminState',adminState);
	// console.log('adminDataReducers action: ', action);

	switch (action.type) {
		case SET_ADMIN_DATA:
			return Object.assign({},adminState, {
				data: action.adminData
			});
		case SET_DASHBOARD_INTERVAL:
			return Object.assign({},adminState, {
				dashboardInterval: action.dashboardInterval
			});
		default:
			return adminState;
	}
}

export default adminDataReducers;
