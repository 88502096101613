import React, { Component } from 'react'
import { connect } from 'react-redux'
import { empty, loadProjectCSS } from './../utility/Shared'
import HeaderSecondaryAdmin from './../common/HeaderSecondaryAdmin'
import BackButton from './../common/BackButton'
import UserFields from './../common/UserFields'
import LoadingScreen from './../common/LoadingScreen'

class AddUser extends Component {
	constructor(props) {
		super(props);
	}

	state = {
		loading: true,
	}

	async componentWillMount() {
		// if user navigated to this url manually, need to reload licensing data
		if(empty(this.props.licensing) || empty(this.props.licensing.logoImageName)) {
			await this.props.screenProps.getLicensingData(true);
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		// if user navigated to this url manually, need to reload admin data
		if(empty(this.props.adminData) || empty(this.props.adminData.data.staticStats)) {
			await this.props.screenProps.getAdminData();
		}
		
		// redirect read-only admins back to dashboard
		if(this.props.adminData.data.privilegeLevel === 'read') {
			this.props.screenProps.history.push('/admin/dashboard');
		}

		this.setState({loading: false});
	}

  render() {
		// console.log('AddUser.js render() this.props',this.props);

		if(this.state.loading) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText="Loading add user page..."/>
			)
		}


    return (<>
			<div className="container container-admin add-user-page">
				<HeaderSecondaryAdmin history={this.props.screenProps.history} />

				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">

							<div className="back-button-container">
								<BackButton history={this.props.screenProps.history} />
							</div>
							
							<h1>Add User</h1>

							<UserFields ref={(ref) => this.userFields = ref} screenProps={this.props.screenProps} page="add-user" />

						</div/* className="page-content"*/>
					</div/* className="page-content-outer-layer"*/>
				</div/* className="page-content-inner-layer"*/>
			</div>
    </>);
  }
}

const mapStateToProps = (state) => {
	// console.log('AddUser.js mapStateToProps() state',state);
	return {
		licensing: state.licensingReducers,
		adminData: state.adminReducers
	}
}

export default connect(mapStateToProps)(AddUser);
