import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getLoginTokenPacket, empty, parseUrlParams, getBaseUrl } from './../utility/Shared'
import { SESSION_NAME_ADMIN_USER } from './../utility/Constants'
import LoadingScreen from './../common/LoadingScreen'

class AdminLogout extends Component {
	constructor(props) {
		super(props)

		// this.logoutIfNotAlready();
		this.redirectToLogin();
	}

	// NOTE: not doing this anymore since it throws an error with animations...
	logoutIfNotAlready() {
		const loginPacket = getLoginTokenPacket(true,true);
		if(empty(loginPacket)) {
			// user is already logged out
			return false;
		}
		this.props.screenProps.logoutUser(loginPacket,true);
		this.logoutLocalStorageSession();
	}

	logoutLocalStorageSession() {
		// remove login "session"
		localStorage.removeItem(SESSION_NAME_ADMIN_USER);
	}

	redirectToLogin() {
		if(this.props.isAppLoaded) {
			this.props.screenProps.history.push('/admin/login');
		} else {
			// NOTE: hard redirect to login page required due to a mother flippen' mystery noted in the wiki
			let urlParams = parseUrlParams();
			if(urlParams.session === 'inactive') {
				window.location.href = getBaseUrl(true) + '/login?session=inactive';
			} else {
				window.location.href = getBaseUrl(true) + '/login';
			}
		}
	}


  render() {
		// console.log('AdminLogout.js render() this.props',this.props);
		// we should never get here but just in case...
    return (<>
			<LoadingScreen loadingText="Logging you out..."/>
		</>);
  }
}

// TODO: PropTypes doesn't work. I hate them.
AdminLogout.propTypes = {

}

function mapStateToProps(state) {
	// console.log('AdminLogout.js mapStateToProps() state',state);
	return {
	}
}

export default connect(mapStateToProps)(AdminLogout);
