import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { routeUser, getCurrentTimestampStringUTC, empty, loadProjectCSS } from './../utility/Shared'
import LoadingScreen from './../common/LoadingScreen'


class Welcome extends Component {

	state = {
		pageCount: 1,
		currentPage: 1,
		loading: true
	}

	async componentWillMount() {
		// if user navigated to this url manually, need to reload userData
		if(empty(this.props.userData)) {
			await this.props.screenProps.getUserDataFromDB();
        if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		this.setState({loading: false});
	}

	handleWelcomeScreensViewed() {
		// mark welcome screens viewed with the current timestamp
		let updatedUserData = Object.assign({},this.props.userData);
		updatedUserData.welcomeScreensViewedTimestamp = getCurrentTimestampStringUTC();
		updatedUserData.position = {
			category: false,
			item: false,
			timestamp: updatedUserData.welcomeScreensViewedTimestamp
		}
		this.props.screenProps.syncReduxAndDB(updatedUserData, 'syncWelcomePages');

		// route the user to the appropriate page
		routeUser(this.props.screenProps.history, updatedUserData);
	}

	nextPage() {
		this.setState({currentPage:this.state.currentPage+1});
	}

	previousPage() {
		this.setState({currentPage:this.state.currentPage-1});
	}

	renderWelcomePage(currentPage) {
		switch (currentPage) {
			case 1:
				return (
					<div className="utility-content-page">
						<div className="utility-content-col-left">
							<h3>VESAP5 Learning Mode Welcomes You!</h3>
							<p className="bold-text">Please read the following important information before clicking continue. This page will not appear the next time you sign in. Access “Help” from the menu for questions on how to navigation the program.</p>
							<p>VESAP5 Learning Mode is an online self-assessment program which offers over 500 questions and includes detailed discussions and references for each question. While this product does not offer CME credit or MOC self-assessment credit, you will be able to review a rationale for the responses and will access valuable references.</p>
						</div>
						<div className="utility-content-col-right"></div>
						</div>
				)
				break;
			default:

		}
	}

  render() {
		// console.log('Welcome.js render() this.props',this.props);

		if(this.state.loading || empty(this.props.userData)) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText="Almost there..." />
			)
		} else if(this.props.userData.welcomeScreensViewedTimestamp !== false) {
			// redirect user to categories page if welcome screens already viewed
			return (
				<Redirect to="/modules" />
			)
		}

    return (
			<div className="container utility-page">
				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">

							<div className="utility-content">


								{this.renderWelcomePage(this.state.currentPage)}

								<div className="flex-row welcome-page-buttons">
									{this.state.currentPage !== 1 &&
									<button className="bn mgn-right-5"
										 onClick={() => this.previousPage()}>
										Previous
									</button>
									}
									{this.state.currentPage !== this.state.pageCount ? (
										<button className="bn"
											 onClick={() => this.nextPage()}>
											<div>Next</div>
										</button>
									) : (
										<button className="bn"
											 onClick={() => this.handleWelcomeScreensViewed()}>
											<div>Continue</div>
										</button>
									)
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    );
  }
}

const mapStateToProps = (state) => {
	// console.log('Welcome.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		licensing: state.licensingReducers
	}
}

export default connect(mapStateToProps)(Welcome);
