import { SET_SCREEN_DIMENSIONS } from './actionTypes';

// set width/height of screen
export const setScreenDimensions = (screenDimensions) => {
	// console.log('actions/housekeeping.js setScreenDimensions() screenDimensions', screenDimensions);

	return {
		type: SET_SCREEN_DIMENSIONS,
		screenDimensions
	}
}
