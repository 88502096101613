import React, { Component } from 'react'
import { connect } from 'react-redux'
import { empty, isCategoryInTesting, getEnterExamInstructions, getFirstUnansweredQuestionId, isReadyToFinalize, finalizeExam } from './../utility/Shared'
import { ENTER_EXAM_MODE_ALERT_TITLE, ENTER_EXAM_MODE_ALERT_TEXT, LEARNING_MODE_RESTRICTED_ALERT_TITLE, LEARNING_MODE_RESTRICTED_ALERT_TEXT, CONFIRM_FINALIZE_EXAM_ALERT_TITLE, CONFIRM_FINALIZE_EXAM_ALERT_TEXT } from './../utility/Constants'
import { confirmAlert } from 'react-confirm-alert'
import './../css/react-confirm-alert.css'


class EnterMode extends Component {

	state = {
	}

	componentDidMount() {
	}

	// enter learning mode
	enterLearning(e) {
		e.preventDefault();
		e.stopPropagation();

		// first check if entering learning mode is allowed
		if(isCategoryInTesting(this.props.userData,this.props.categoryId)) {
			confirmAlert({
				title: LEARNING_MODE_RESTRICTED_ALERT_TITLE,
				message: LEARNING_MODE_RESTRICTED_ALERT_TEXT,
				buttons: [{label: 'Ok'}]
			});
			return;
		}

		let lastVisitedQuestionId = this.props.userData.categories[this.props.categoryId].modes['Learning'].questionId;
		if(empty(lastVisitedQuestionId)) {
			const userCategory = this.props.userData.categories[this.props.categoryId];
			lastVisitedQuestionId = getFirstUnansweredQuestionId(userCategory,'Learning');
		}
		this.navQuestion(lastVisitedQuestionId,'Learning');
	}

	// handle user clicking to enter exam mode of category
	enterExam(e,btnText) {
		e.preventDefault();
		e.stopPropagation();

		const userCategory = this.props.userData.categories[this.props.categoryId];
		if(btnText === 'BEGIN EXAM') {
			// alert user that Learning mode will be restricted for this category if they enter Exam mode
			confirmAlert({
				title: ENTER_EXAM_MODE_ALERT_TITLE,
				message: ENTER_EXAM_MODE_ALERT_TEXT,
				buttons: [
					{label: 'Cancel'},
					{label: 'Continue', onClick: () => {
						const firstUnansweredQuestionId = getFirstUnansweredQuestionId(userCategory,'Exam');
						this.navQuestion(firstUnansweredQuestionId,'Exam');
					}},
				]
			});
		} else if(btnText === 'CONTINUE EXAM') {
			// nav to first unanswered question
			const firstUnansweredQuestionId = getFirstUnansweredQuestionId(userCategory,'Exam');
			this.navQuestion(firstUnansweredQuestionId,'Exam');

		} else if(btnText === 'REVIEW EXAM') {
			// nav to last item visited
			this.navLastItemVisited();
		}
	}

	confirmFinalize(e) {
		e.preventDefault();
		e.stopPropagation();

		// confirm user wants to finalize
		const contentCategory = this.props.contentCategories[this.props.categoryId-1];
		confirmAlert({
			title: CONFIRM_FINALIZE_EXAM_ALERT_TITLE,
			message: CONFIRM_FINALIZE_EXAM_ALERT_TEXT,
			buttons: [
				{label: 'Return to Exam', onClick: () => this.navLastItemVisited()},
				{label: 'Finalize Exam', onClick: () => finalizeExam(this.props.screenProps,this.props.userData,contentCategory)},
			],
		});
	}

	navLastItemVisited(mode='Exam') {
		let lastVisitedQuestionId = this.props.userData.categories[this.props.categoryId].modes[mode].questionId;
		const userCategory = this.props.userData.categories[this.props.categoryId];
		if(empty(lastVisitedQuestionId)) {
			lastVisitedQuestionId = getFirstUnansweredQuestionId(userCategory,mode);
		}
		this.navQuestion(lastVisitedQuestionId,mode);
	}

	navQuestion(questionId,mode) {
		// switch to correct mode
		this.props.screenProps.setCategoryMode(this.props.userData, this.props.categoryId, mode);

		// navigate to item page
		this.props.screenProps.history.push('/item?cid='+this.props.categoryId+'&qid='+questionId);
	}


  render() {
		// console.log('EnterMode.js render() this.props',this.props);

		const isCeEligible = this.props.userData.userInfo.isCeEligible;
		const isExamInProgress = isCategoryInTesting(this.props.userData,this.props.categoryId);
		const examButtonText = getEnterExamInstructions(this.props.userData,this.props.categoryId).examButtonText;
		const isExamReadyToFinalize = isReadyToFinalize(this.props.userData,this.props.categoryId);

    return (
			<div className={this.props.isLandingPage ? "flex-row flex-center-main enter-mode-landing" : "flex-col"}>
				<div className={"btn5 btn5-learning " + (isExamInProgress ? 'btn5-inactive' : '')} onClick={(e)=>this.enterLearning(e)}>
					<div>{!empty(isCeEligible) ? "Learning Mode" : "Enter Learning"}</div>
				</div>
				{(!empty(isCeEligible) && !isExamReadyToFinalize) &&
					<div className="btn5 btn5-exam"
					onClick={(e)=>this.enterExam(e,examButtonText)}>
						<div>{examButtonText}</div>
					</div>
				}
				{(!empty(isCeEligible) && isExamReadyToFinalize) &&
					<div className="btn5 btn5-exam"
					onClick={(e)=>this.confirmFinalize(e)}>
						<div>Finalize Exam</div>
					</div>
				}
			</div>
    );
  }
}


const mapStateToProps = (state) => {
	// console.log('EnterMode.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		contentCategories: state.contentReducers,
	}
}

export default connect(mapStateToProps)(EnterMode);
