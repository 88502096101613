import React, { Component } from 'react'
import { connect } from 'react-redux'
import HeaderSecondaryAdmin from './../common/HeaderSecondaryAdmin'
import AdminDashboardNonGraphStats from './../common/AdminDashboardNonGraphStats'
import { Line } from 'react-chartjs-2'
import { empty, loadProjectCSS } from './../utility/Shared'
import Tabs from './../common/Tabs'
import LoadingScreen from './../common/LoadingScreen'


class AdminDashboard extends Component {

	state = {
		loading: true,
	}

	async componentWillMount() {
		// if user navigated to this url manually, need to reload licensing data
		if(empty(this.props.licensing) || empty(this.props.licensing.logoImageName)) {
			await this.props.screenProps.getLicensingData(true);
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		// if user navigated to this url manually, need to reload admin data
		if(empty(this.props.adminData) || empty(this.props.adminData.data.staticStats)) {
			await this.props.screenProps.getAdminData();
		}

		// build graph data
		const dayQuestionsData = this.generateGraphData('QuestionsAnswered','Day');
		const weekQuestionsData = this.generateGraphData('QuestionsAnswered','Week');
		const monthQuestionsData = this.generateGraphData('QuestionsAnswered','Month');
		const yearQuestionsData = this.generateGraphData('QuestionsAnswered','Year');
		const allQuestionsData = this.generateGraphData('QuestionsAnswered','All');

		const dayCreditsData = this.generateGraphData('CreditsClaimed','Day');
		const weekCreditsData = this.generateGraphData('CreditsClaimed','Week');
		const monthCreditsData = this.generateGraphData('CreditsClaimed','Month');
		const yearCreditsData = this.generateGraphData('CreditsClaimed','Year');
		const allCreditsData = this.generateGraphData('CreditsClaimed','All');

		const dayUsersData = this.generateGraphData('ActiveUsers','Day');
		const weekUsersData = this.generateGraphData('ActiveUsers','Week');
		const monthUsersData = this.generateGraphData('ActiveUsers','Month');
		const yearUsersData = this.generateGraphData('ActiveUsers','Year');
		const allUsersData = this.generateGraphData('ActiveUsers','All');

// this.setState({dayQuestionsData,weekQuestionsData,monthQuestionsData,yearQuestionsData,loading: false});
		this.setState({dayQuestionsData,weekQuestionsData,monthQuestionsData,yearQuestionsData,allQuestionsData,dayCreditsData,weekCreditsData,monthCreditsData,yearCreditsData,allCreditsData,dayUsersData,weekUsersData,monthUsersData,yearUsersData,allUsersData,loading: false});
	}

	// display the day/week/month/year/all stats requested
	dashboardIntervalClicked(intervalSelected) {
		// if the same intervalSelected is the same as what was already being displayed, return
		if(intervalSelected === this.props.adminData.dashboardInterval) {
			return;
		}

		// update redux store to show appropriate tabs and items
		this.props.screenProps.setDashboardInterval(intervalSelected);
	}

	generateGraphData(metric,interval) {
		let rawDataSet = this.props.adminData.data.intervalStats.graph[metric][interval];
// console.log('rawDataSet',rawDataSet);
		// get labels and data
		let labelsArr = []
		let dataArr = [];
		for (const label of Object.keys(rawDataSet)) {
			const dataValue =  rawDataSet[label];
			if(label != 'Total') {
				labelsArr.push(label);
				dataArr.push(dataValue);
			}
		}
		let graphLabel = metric + ': ' + rawDataSet.Total;
// console.log('graphLabel',graphLabel);
// console.log('labelsArr',labelsArr);
// console.log('dataArr',dataArr);
		return {
		  labels: labelsArr,
		  datasets: [
		    {
		      label: graphLabel,
		      fill: true,
		      lineTension: 0.3,
		      backgroundColor: 'rgba(75,192,192,0.4)',
		      borderColor: 'rgba(75,192,192,1)',
		      // borderCapStyle: 'butt',
		      // borderDash: [],
		      // borderDashOffset: 0.0,
		      // borderJoinStyle: 'miter',
		      pointBorderColor: 'rgba(75,192,192,1)',
		      pointBackgroundColor: '#fff',
		      pointBorderWidth: 1,
		      pointHoverRadius: 5,
		      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
		      pointHoverBorderColor: 'rgba(220,220,220,1)',
		      pointHoverBorderWidth: 2,
		      pointRadius: 1,
		      pointHitRadius: 10,
		      data: dataArr
		    }
		  ]
		};

	}

  render() {
		// console.log('AdminDashboard.js render() this.props',this.props);

		if(this.state.loading) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText="Loading Dashboard..."/>
			)
		}


    return (<>
			<div className="container container-admin dashboard-page">
				<HeaderSecondaryAdmin history={this.props.screenProps.history} />

				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">

							<h1>Admin Dashboard</h1>
							{/*<p className="info-text"></p>*/}

							<div className="flex trivia-monster-container type">
								<div className="status-box-container first">
									<p className="status-box-label">Active Users</p>
									<p className="status-box-value">{this.props.adminData.data.staticStats.usersActiveCount}</p>
								</div>
								<div className="status-box-container second">
									<p className="status-box-label">CME Credits Claimed</p>
									<p className="status-box-value">{this.props.adminData.data.staticStats.creditsClaimed}</p>
								</div>
								<div className="status-box-container third">
									<p className="status-box-label">Logins Last 24 Hours</p>
									<p className="status-box-value">{this.props.adminData.data.staticStats.loginsToday}</p>
								</div>
							</div>
							{/* tabs displaying interval stats */}
							<Tabs activeTab={this.props.adminData.dashboardInterval} onClickFunction={this.dashboardIntervalClicked.bind(this)}>
								<div label='Day'></div>
								<div label='Week'></div>
								<div label='Month'></div>
								<div label='Year'></div>
								<div label='All'></div>
							</Tabs>

							{this.props.adminData.dashboardInterval === 'Day' &&
								<div>
									<AdminDashboardNonGraphStats />
									<Line data={this.state.dayQuestionsData} />
									<div className="flex">
										<div className="flex-item-1"><Line data={this.state.dayCreditsData} /></div>
										<div className="flex-item-1"><Line data={this.state.dayUsersData} /></div>
									</div>
								</div>
							}
							{this.props.adminData.dashboardInterval === 'Week' &&
								<div>
									<AdminDashboardNonGraphStats />
									<Line data={this.state.weekQuestionsData} />
									<div className="flex">
										<div className="flex-item-1"><Line data={this.state.weekCreditsData} /></div>
										<div className="flex-item-1"><Line data={this.state.weekUsersData} /></div>
									</div>
								</div>
							}
							{this.props.adminData.dashboardInterval === 'Month' &&
								<div>
									<AdminDashboardNonGraphStats />
									<Line data={this.state.monthQuestionsData} />
									<div className="flex">
										<div className="flex-item-1"><Line data={this.state.monthCreditsData} /></div>
										<div className="flex-item-1"><Line data={this.state.monthUsersData} /></div>
									</div>
								</div>
							}
							{this.props.adminData.dashboardInterval === 'Year' &&
								<div>
									<AdminDashboardNonGraphStats />
									<Line data={this.state.yearQuestionsData} />
									<div className="flex">
										<div className="flex-item-1"><Line data={this.state.yearCreditsData} /></div>
										<div className="flex-item-1"><Line data={this.state.yearUsersData} /></div>
									</div>
								</div>
							}
							{this.props.adminData.dashboardInterval === 'All' &&
							<div>
								<AdminDashboardNonGraphStats />
								<Line data={this.state.allQuestionsData} />
								<div className="flex">
									<div className="flex-item-1"><Line data={this.state.allCreditsData} /></div>
									<div className="flex-item-1"><Line data={this.state.allUsersData} /></div>
								</div>
							</div>
							}

						</div/* className="page-content"*/>
					</div/* className="page-content-outer-layer"*/>
				</div/* className="page-content-inner-layer"*/>

			</div>
	</>);
  }
}

const mapStateToProps = (state) => {
	// console.log('AdminDashboard.js mapStateToProps() state',state);
	return {
		licensing: state.licensingReducers,
		adminData: state.adminReducers
	}
}

export default connect(mapStateToProps)(AdminDashboard);
