// page for making direct calls to api (like postman), testing returns, etc
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { empty, loadProjectCSS, getLoginTokenPacket, getProjectBasename, checkApiResponseErrors, handleBreakingError } from './../utility/Shared'
import { SESSION_NAME_ADMIN_USER } from './../utility/Constants'
import HeaderSecondaryAdmin from './../common/HeaderSecondaryAdmin'
import LoadingScreen from './../common/LoadingScreen'

class SuperAdmin extends Component {

	state = {
		results: {},
		loading: true,
	}

	async componentDidMount() {
		// if user navigated to this url manually, need to reload licensing data
		if(empty(this.props.licensing) || empty(this.props.licensing.logoImageName)) {
			await this.props.screenProps.getLicensingData(true);
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		// if user navigated to this url manually, need to reload admin data
		if(empty(this.props.adminData) || empty(this.props.adminData.data.staticStats)) {
			await this.props.screenProps.getAdminData();
		}

		if(this.props.adminData.data.privilegeLevel === 'super') {
			this.props.screenProps.history.push('/admin/logout');
		} else {
			this.setState({loading:false});
		}
	}

	async runSpeedTest(serviceToTest) {
		this.setState({loadingMessage:'running speed test',loading:true});
		const runSpeedTestResult = await this.hitApi(serviceToTest);
		this.setState({speedTestResults:runSpeedTestResult.speedInfo,loadingMessage:'',loading:false});
	}

	// generic api call
	// TODO: use/combine with Shared.js hitApi()
	async hitApi(apiAction) {
		// console.log('hitApi() called');

		let apiActionResult;

		// get special auth packet
		let	loginPacket = getLoginTokenPacket(true);
		loginPacket.userInfo.authToken = 'aDm1nUs3r!';

// console.log('loginPacket',loginPacket);
		await fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action='+apiAction, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(loginPacket)
		})
		.then((response) => response.json())
		.then((hitApiResponse) => {
console.log('hitApiResponse',hitApiResponse);

			// check for errors so we don't assign an error message to redux
			checkApiResponseErrors(hitApiResponse);

			apiActionResult = hitApiResponse;

		})
		.catch((error) => {
			console.log('ERROR Shared.js hitApi(): ', error);
			handleBreakingError('hitApi',error,loginPacket,true);
			throw new Error('Stop script execution with this uncaught exception.');
		});

		return apiActionResult;
	}


  render() {
		// console.log('SuperAdmin.js render() this.props',this.props);

		if(this.state.loading) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText={empty(this.state.loadingMessage) ? "Performing retina scan..." : this.state.loadingMessage} />
			)
		}

		let speedTestResults = this.state.speedTestResults;
		let resultsKeys = [];
		let resultsValues = [];
		if(!empty(speedTestResults)) {
			for (const key of Object.keys(speedTestResults)) {
				const result =  speedTestResults[key];
				resultsKeys.push(key);
				resultsValues.push(result);
			}
		}

    return (<>
			<div className="container container-admin super-admin-page">
				<HeaderSecondaryAdmin history={this.props.screenProps.history} />

				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">

							<h1>Welcome Mr. President</h1>
							<div className="btn5 mgn-bottom-15" onClick={()=>this.runSpeedTest('testCustomProcessSpeed')}>Run Custom Speed Test</div>
							<div className="btn5 mgn-bottom-15" onClick={()=>this.runSpeedTest('generateCategoriesJSON')}>Run Speed Test on generateCategoriesJSON</div>
							<div className="btn5 mgn-bottom-15" onClick={()=>this.runSpeedTest('populateDB')}>Run Speed Test on populateDB</div>

							{!empty(speedTestResults) && <>
									<p>And the speed test results are in:</p>
									{resultsKeys.map(function(key,idx) {
										return (
											<div key={idx}>
												<p>{key}: {resultsValues[idx]}</p>
											</div>
										)
									})}
							</>}

						</div/* className="page-content"*/>
					</div/* className="page-content-outer-layer"*/>
				</div/* className="page-content-inner-layer"*/>

			</div>
    </>);
  }
}


const mapStateToProps = (state) => {
	// console.log('SuperAdmin.js mapStateToProps() state',state);
	return {
		licensing: state.licensingReducers,
		adminData: state.adminReducers
	}
}

export default connect(mapStateToProps)(SuperAdmin);
