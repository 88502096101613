import React, { Component } from 'react'
import { connect } from 'react-redux'
import {autop,empty,getLoginTokenPacket,getProjectBasename,hitApi,loadProjectCSS,objToArr,parseUrlParam} from './../utility/Shared'
import HeaderSecondaryAdmin from './../common/HeaderSecondaryAdmin'
import BackButton from './../common/BackButton'
import LoadingScreen from './../common/LoadingScreen'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Evaluations extends Component {

	constructor(props) {
		super(props);

		// set project name to switch between dev and live, see ../utility/Shared.js
		this.projectBasename = getProjectBasename();

		this.payload = {};
		this.loginPacket = getLoginTokenPacket(true);// for admin pages
		//this.loginPacket = getLoginTokenPacket();// for non-admin pages
		this.payload.userInfo = this.loginPacket.userInfo;

		// bind function event handlers =============================
		this.handleExport = this.handleExport.bind(this);
		this.handleModuleSelect = this.handleModuleSelect.bind(this);
		this.handleReportRadio = this.handleReportRadio.bind(this);
		this.handleRunReport = this.handleRunReport.bind(this);
		// /bind function event handlers =============================
	}

	state = {
		report:'score',//score|uservalue
		cid:'',// category id
		dateStart:'',
		dateEnd:'',
		csv:false,// if a csv report should be retured on filter form submit
		EvalReportData:{},// score
		EvalReportUserValue:{},// user entered values, i.e. text fields and textareas
		loadingReport:false,
		loading:true,
	}

	async componentDidMount() {
		// for admin pages
		///*
		// if user navigated to this url manually, need to reload licensing data
		if(empty(this.props.licensing) || empty(this.props.licensing.logoImageName)) {
			await this.props.screenProps.getLicensingData(true);
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		// if user navigated to this url manually, need to reload admin data
		if(empty(this.props.adminData) || empty(this.props.adminData.data.staticStats)) {
			await this.props.screenProps.getAdminData();
		}
		//*/

		// for non-admin pages
		/*
		// if user navigated to this url manually, need to reload userData
		if(empty(this.props.userData)) {
			await this.props.screenProps.getUserDataFromDB();
		if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		*/

		// ajax calls ==========
		await this.getCategoriesEval();// sets category dropdown on page load
//		await this.getEvalReportData();// set score all categories display on page load
		// /ajax calls ==========

		// date formatting
		this.moment = require('moment');

		await this.setState({loading:false});
	}// /async componentDidMount() {

	/**
	* getCategoriesEval()
	* loads Categories that have evaluations "attached"
	*/
	async getCategoriesEval() {
		let apiResponse = await hitApi('getCategoriesEval',this.payload);
		//console.log('apiResponse:',apiResponse);
		this.CategoriesEval = apiResponse.CategoriesEval;
		//console.log('this.CategoriesEval:',this.CategoriesEval);
	}

	/**
	* getEvalReportData()
	* ajax return for both score and uservalue return types
	*/
	async getEvalReportData() {
		this.payload.cid = this.state.cid;
		this.payload.dateStart = this.state.dateStart;
		if (!empty(this.state.dateStart) || typeof(this.state.dateStart) === 'object') {
			this.payload.dateStart = this.moment(this.state.dateStart).format("YYYY-MM-DD");
			if(this.payload.dateStart === 'Invalid date') {
				this.payload.dateStart = '';
			}
		}
		this.payload.dateEnd = this.state.dateEnd;
		if (!empty(this.state.dateEnd) || typeof(this.state.dateEnd) === 'object') {
			this.payload.dateEnd = this.moment(this.state.dateEnd).format("YYYY-MM-DD");
			if(this.payload.dateEnd === 'Invalid date') {
				this.payload.dateEnd = '';
			}
		}
		this.payload.csv = this.payload.csv;

		// console.log('this.payload:',this.payload);
		//console.log('this.state.report:',this.state.report);
		if(this.state.report == 'score') {
			this.setState({loadingReport:true});// show loading screen
			this.setState({EvalReportUserValue:{}});// remove uservalue from view
			let apiResponse = await hitApi('getEvalReportData',this.payload);
			 //('apiResponse:',apiResponse);
			this.setState({loadingReport:false});// hide loading screen
			this.setState({EvalReportData:apiResponse.EvalReportData});// display data
		}
		if(this.state.report == 'uservalue') {
			this.setState({loadingReport:true});// show loading screen
			this.setState({EvalReportData:{}});// remove score from view
			let apiResponse = await hitApi('getEvalReportUserValue',this.payload);//console.log('apiResponse:',apiResponse);
			this.setState({loadingReport:false});// hide loading screen
			this.setState({EvalReportUserValue:apiResponse.EvalReportUserValue});// display data
		}
		//console.log('$flag:',$flag);
	}// /async getEvalReportData() {

	/**
	* handleExport()
	* set state for csv, also, form submits as usual to a php page to return the data as csv
	*/
	async handleExport($e) {
		await this.setState({csv:true});
	}

	/**
	* handleModuleSelect()
	* category dropdown
	*/
	handleModuleSelect($e) {
		this.setState({cid:$e.target.value});
	}

	/**
	* handleReportRadio()
	* set score or user value display
	*/
	handleReportRadio($e) {
		//console.log('$e.target.value:',$e.target.value);
		this.setState({report:$e.target.value});
	}

	/**
	* handleRunReport()
	* filter form, runs the ajax report data return
	*/
	handleRunReport($e) {
		//$e.preventDefault();
		this.getEvalReportData();
	}

	/**
	* setDate()
	* to/from datepickers
	*/
	setDate($date,$se) {
		let $date_set = '';
		if($date != null) {$date_set = this.moment($date).format("YYYY-MM-DD");}
		if ($se == 'start') {this.setState({dateStart:$date,$date_set});}
		if ($se == 'end') {this.setState({dateEnd:$date,$date_set});}
	}

	render() {
		// console.log('Evaluations.js render() this.props',this.props);

		if(this.state.loading) {
			return(
				<LoadingScreen loadingText="Loading your evaluations..." />
			)
		}

		if (this.state.loadingReport) {
			return(
				<LoadingScreen loadingText="Loading your Report..." />
			)
		}

		let _this = this;// set this for use inside .map loops below

		return (<>
			<div className="container evaluations-page">
				<HeaderSecondaryAdmin history={this.props.screenProps.history} />

				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">

            <div className="back-button-container">
              <BackButton history={this.props.screenProps.history} />
            </div>

						<h1>Evaluations</h1>

{/* Filter ============================================ */}
						<div className="report-eval-filter">

						<form onSubmit={this.handleExport} xtarget="_blank" name="reports_eval_form" id="" method="post" action={'https://mycmecredit.com/'+this.projectBasename+'/api/tw.php'}>
						<input type="hidden" name="process" id="" value={this.state.report=='score'?'reports-eval':'reports-eval-uv'} />
						<input type="hidden" name="csv" id="" value={this.state.csv} />

						<div><span>
						<select name="cid" value={this.state.cid} onChange={this.handleModuleSelect}>
							<option value="">-- All Modules --</option>
							{objToArr(this.CategoriesEval).map(function($v,$i){
								//console.log('$v:',$v);
								return(<React.Fragment key={$i}>
									<option value={$v['Category.CategoryID']}>{$v['Category.CategoryNumber']}. {$v['Category.CategoryName']}</option>
								</React.Fragment>)
							})}
						</select>
						</span></div>

						<div>
						<br />
						<label>Evaluation type:</label>
						<br /><br />
						<div style={{display: "flex", flexWrap: "wrap"}}>
						<label style={{marginRight: 30, marginBottom: 10}}><input type="radio" onChange={this.handleReportRadio} checked={this.state.report === 'score'} title="" name="eval_report_type" id="" defaultValue="score" /> Multiple Choice</label>
						<label><input type="radio" onChange={this.handleReportRadio} checked={this.state.report === 'uservalue'} title="" name="eval_report_type" id="" defaultValue="uservalue" /> Open Ended</label>
						</div></div>

						<br />

						<div className="reports_eval-datepickers"><span>
						<DatePicker
							name="dateStart"
							selected={this.state.dateStart}
							onChange={$date => this.setDate($date,'start')}
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							placeholderText="Start Date"
						/>
						<br /><br />
						<DatePicker
							name="dateEnd"
							selected={this.state.dateEnd}
							onChange={$date => this.setDate($date,'end')}
							peekNextMonth
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							placeholderText="End Date"
						/>
						</span></div>
						<br />
						<p><em>(results will include these dates - leave empty for no limit)</em></p>

						<div><span>
						<input type="button" onClick={this.handleRunReport} value="Run Report" />
						&nbsp;<input type="submit" value="Export Data" />
						</span></div>

						</form>

						</div/*  className="report-eval-filter" */>
{/* /Filter ============================================ */}

{/* Score ================================================== */}
						{!empty(this.state.EvalReportData)?(<>
						<div className="report-eval report-eval-score">

	{/* Score | questions_shared ======================= */}
						{!empty(this.state.EvalReportData['questions_shared'])?(<>
						<h2>All Modules</h2>
						<p>Total Evaluations: {this.state.EvalReportData['evals_count_total']}</p>
						<ul>
						{objToArr(this.state.EvalReportData['questions_shared']).map(function($v,$i){
							//console.log('$v:',$v);
							let $question = $v['EvalQuestionsShared.Text'];
							return(<React.Fragment key={$i}>
							<li>
								<p className="report-eval-question" dangerouslySetInnerHTML={{ __html:$question}} />

								<div className="report-eval-answers">
								{objToArr($v['answers_shared']).map(function($v2,$i2){
									//console.log('$v2:',$v2);
									let $answer = $v2['EvalAnswersShared.Text'];
									if ($answer == 'null') {$answer = 'User entered';}
									// check that score is a number
									let $score = 0;
									if (isNaN($v2['score'])) {$score = 0;}
									else {$score = Math.round($v2['score']*100);}
									// /check that score is a number
									//$answer += ' -- '+$score+'%';
									return(<React.Fragment key={$i2}>
										<div>
										<div dangerouslySetInnerHTML={{ __html:$score+'%'}} />
										<div dangerouslySetInnerHTML={{ __html:' ('+$v2['count']+')'}} />
										<div dangerouslySetInnerHTML={{ __html:$answer}} />
										</div>
									</React.Fragment>)
								})}{/*{objToArr($v['answers_shared']).map(function($v2,$i2){*/}
								</div/*  className="report-eval-answers" */>

							</li>
							</React.Fragment>)
						})}{/*{objToArr(this.state.EvalReportData['questions_shared']).map(function($v,$i){*/}
						</ul>
						</>):('')}{/*{!empty(this.state.EvalReportData['questions_shared'])?(<>*/}
	{/* /Score | questions_shared ======================= */}

	{/* Score | Category ======================= */}
						{objToArr(this.state.EvalReportData).map(function($v,$i){
							//console.log('$v:',$v);
							// there are branches that category, skip if not a category branch
							if (typeof $v['Category.CategoryName'] == 'undefined') {return false;}

							let $category = $v['Category.CategoryName'];
							let $category_number = $v['Category.CategoryNumber'];
							let $cat_evals_count = $v['cat_evals_count'];
							return(<React.Fragment key={$i}>
								<h2>{$category_number}. {$category}</h2>
								<p>Module Evaluations: {$cat_evals_count}</p>
								<ul>
								{objToArr($v['EvalQuestions']).map(function($v2,$i2){
									if (
									!empty(_this.state.EvalReportData['questions_shared'])
									&& typeof $v2['EvalQuestionsShared.EvalQuestionsSharedID'] != 'undefined'
									) {
										return false;
									}
									let $question = $v2['EvalQuestions.Text'];
									if (typeof $v2['EvalQuestionsShared.Text'] != 'undefined') {$question = $v2['EvalQuestionsShared.Text'];}
									return(<React.Fragment key={$i2}>
										<li>

										<p className="report-eval-question" dangerouslySetInnerHTML={{ __html:$question}} />

										<div className="report-eval-answers">
										{objToArr($v2['answers']).map(function($v3,$i3){
											let $answer = $v3['EvalAnswers.Text'];
											if (typeof $v3['EvalAnswersShared.Text'] != 'undefined') {$answer = $v3['EvalAnswersShared.Text'];}
											if ($answer == 'null') {$answer = 'User entered';}
											// check that score is a number
											let $score = 0;
											if (isNaN($v3['score'])) {$score = 0;}
											else {$score = Math.round($v3['score']*100);}
											// /check that score is a number
											//$answer += ' -- '+$score+'%';
											return(<React.Fragment key={$i3}>
												<div>
												<div dangerouslySetInnerHTML={{ __html:$score+'%'}} />
												<div dangerouslySetInnerHTML={{ __html:' ('+$v3['count']+')'}} />
												<div dangerouslySetInnerHTML={{ __html:$answer}} />
												</div>
											</React.Fragment>)
										})}{/*{objToArr($v2['answers']).map(function($v3,$i3){*/}
										</div/*  className="report-eval-answers" */>

										</li>
									</React.Fragment>)
								})}{/*{objToArr($v['EvalQuestions']).map(function($v2,$i2){*/}
								</ul>
							</React.Fragment>)
						})}{/*{objToArr(this.state.EvalReportData).map(function($v,$i){*/}
	{/* /Score | Category ======================= */}

						</div/*  className="report-eval report-eval-score" */>
						</>):('')}{/*{!empty(this.state.EvalReportData)?(<>*/}

{/*<pre>{!empty(this.state.EvalReportData)?JSON.stringify(this.state.EvalReportData,'',4):''}</pre>*/}
{/*<pre>{!empty(this.state.EvalReportData['questions_shared'])?JSON.stringify(this.state.EvalReportData['questions_shared'],'',4):''}</pre>*/}
{/* /Score ================================================== */}

{/* User Values ============================================ */}
						{!empty(this.state.EvalReportUserValue)?(<>
						<div className="report-eval report-eval-uv">
						<p>
						Evaluations: {this.state.EvalReportUserValue['evals_count_total']}<br />
						Open Ended Responses: {this.state.EvalReportUserValue['count_responses']}
						</p>

	{/* User Values | questions_shared ======================= */}
						{!empty(this.state.EvalReportUserValue['questions_shared'])?(<>
						<h2>All Modules</h2>
						<ul>
						{objToArr(this.state.EvalReportUserValue['questions_shared']).map(function($v,$i){
							//console.log('$v:',$v);
							let $question_shared = $v['EvalQuestionsShared.Text'];
							return(<React.Fragment key={$i}>
								<li className="report-eval-question" dangerouslySetInnerHTML={{ __html:$question_shared}} />
								{objToArr($v['answers_shared']).map(function($v2,$i2){
									let $answer_shared = $v2['EvalAnswersShared.Text'];
									return(<React.Fragment key={$i2}>
										{!empty($answer_shared)?<p className="report-eval-answer" dangerouslySetInnerHTML={{ __html:$answer_shared}} />:''}
										<ul className="report-eval-answers">
										{objToArr($v2['UserEvalResponses']).map(function($v3,$i3){
											return(<React.Fragment key={$i3}>
												{objToArr($v3).map(function($v4,$i4){
													let $Value = $v4['UserEvalResponses.Value'];
													return(<React.Fragment key={$i4}>
														<li className="autop" dangerouslySetInnerHTML={{ __html:autop($Value)}}></li>
													</React.Fragment>)
												})}{/*{objToArr($v3).map(function($v4,$i4){*/}
											</React.Fragment>)
										})}{/*{objToArr($v2['UserEvalResponses']).map(function($v3,$i3){*/}
										</ul>
									</React.Fragment>)
								})}{/*{objToArr($v['answers_shared']).map(function($v2,$i2){*/}
							</React.Fragment>)
						})}{/*{objToArr(this.state.EvalReportUserValue['questions_shared']).map(function($v,$i){*/}
						</ul>
						</>):('')}{/*{!empty(this.state.EvalReportUserValue['questions_shared'])?(<>*/}
	{/* /User Values | questions_shared ======================= */}

	{/* User Values | Category ======================= */}
						{empty(this.state.EvalReportUserValue['questions_shared'])?(<>
						{objToArr(this.state.EvalReportUserValue).map(function($v,$i){
							// skip if not a category branch
							if (typeof $v['Category.CategoryName'] == 'undefined') {return false;}

							let $category = $v['Category.CategoryName'];
							let $category_number = $v['Category.CategoryNumber'];
							return(<React.Fragment key={$i}>
								<h2>{$category_number}. {$category}</h2>
								{/*<p>Responses Category: {$v['count_responses_cat']}</p>*/}
								<ul>
								{objToArr($v['EvalQuestions']).map(function($v2,$i2){
									let $question = $v2['EvalQuestions.Text'];
									if (typeof $v2['EvalQuestionsShared.Text'] != 'undefined') {$question = $v2['EvalQuestionsShared.Text'];}
									if (
									!empty(_this.state.EvalReportUserValue['questions_shared'])
									&& typeof $v2['EvalQuestionsShared.Text'] != 'undefined'
									) {
										// suppress if showing questions shared
										return false;
									}
									return(<React.Fragment key={$i2}>
										<li className="report-eval-question" dangerouslySetInnerHTML={{ __html:$question}} />
										{/*<p>Responses Question: {$v2['count_responses_question']}</p>*/}
										{objToArr($v2['answers']).map(function($v3,$i3){
											let $answer = $v3['EvalAnswers.Text'];
											if (typeof $v3['EvalAnswersShared.Text'] != 'undefined') {$answer = $v3['EvalAnswersShared.Text'];}
											if (
											!empty(_this.state.EvalReportUserValue['questions_shared'])
											&& typeof $v2['EvalAnswersShared.Text'] != 'undefined'
											) {
												// suppress if showing questions_shared
												return false;
											}
											return(<React.Fragment key={$i3}>
												{!empty($answer)?<p className="report-eval-answer" dangerouslySetInnerHTML={{ __html:$answer}} />:''}
												{/*<p>Responses Answer: {$v3['count_responses_answer']}</p>*/}
												<ul className="report-eval-answers">
												{objToArr($v3['UserEvalResponses']).map(function($v4,$i4){
													let $Value = $v4['UserEvalResponses.Value'];
													return(<React.Fragment key={$i4}>
														<li className="autop" dangerouslySetInnerHTML={{ __html:autop($Value)}}></li>
													</React.Fragment>)
													})}{/*{objToArr($v3['UserEvalResponses']).map(function($v4,$i4){*/}
												</ul>
											</React.Fragment>)
										})}{/*{objToArr($v2['answers']).map(function($v3,$i3){*/}
									</React.Fragment>)
								})}{/*{objToArr($v['EvalQuestions']).map(function($v2,$i2){*/}
								</ul>
							</React.Fragment>)
						})}{/*{objToArr(this.state.EvalReportUserValue).map(function($v,$i){*/}
						</>):('')}{/*{empty(this.state.EvalReportUserValue['questions_shared'])?(<>*/}
	{/* /User Values | Category ======================= */}

						</div/*  className="report-eval report-eval-uv" */>


						</>):('')}{/*{!empty(this.state.EvalReportUserValue)?(<>*/}

{/*<pre>{!empty(this.state.EvalReportUserValue)?JSON.stringify(this.state.EvalReportUserValue,'',4):''}</pre>*/}
{/*<pre>{!empty(this.state.EvalReportUserValue['questions_shared'])?JSON.stringify(this.state.EvalReportUserValue['questions_shared'],'',4):''}</pre>*/}
{/* /User Values ============================================ */}

						</div/* className="page-content-inner-layer"*/>
					</div/* className="page-content-outer-layer"*/>
				</div/* className="page-content"*/>

			</div/*  className="container evaluations-page" */>
		</>);
	}
}


const mapStateToProps = (state) => {
	// console.log('Evaluations.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		licensing: state.licensingReducers,
	}
}

export default connect(mapStateToProps)(Evaluations);
