import React, { Component } from 'react'
import { connect } from 'react-redux'
import {empty,getLoginTokenPacket,hitApi,objToArr,parseUrlParams} from './../../utility/Shared'
import LoadingScreen from './../../common/LoadingScreen.js'

class EvaluationsTest extends Component {
	
	constructor(props) {
		super(props);
	}
	
	state = {
		loading: true,
	}
	
	async componentDidMount() {
		// get userEvalResponses =====
		let payload = {};
		const loginPacket = getLoginTokenPacket();
		payload.userInfo = loginPacket.userInfo;
		const apiResponse = await hitApi('getUserEvalResponses',payload);//console.log('apiResponse:',apiResponse);
		this.UserEvalResponses = apiResponse.UserEvalResponses;
		// /get userEvalResponses =====
		
		await this.setState({loading:false});
	}// /async componentDidMount() {
	
	
	render() {
		// console.log('EvaluationsTest.js render() this.props',this.props);

		if(this.state.loading) {
			return(
				<LoadingScreen loadingText="Loading your evaluation..." />
			)
		}
		
		return (<>
			<div className="container EvaluationsTest-page">
				
				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">
						
						<h1>EvaluationsTest</h1>
						
						<pre>{/* JSON.stringify(this.UserEvalResponses,'',4) */}</pre>
						<ul>
						{objToArr(this.UserEvalResponses).map(function($v,$i){
							//console.log('$v:',$v);
							let $row = '';
							for(let $k in $v) {
								let $v2 = $v[$k];//console.log($k+':',$v2);
								if ($v2 === Object($v2)) {continue;}
								$row += '<div>'+$k+':'+$v2+'</div>';
							}
				
							let $user = $v['UserUUID'];
							let $cat = 'cid-'+$v['CategoryID'];
							let $question = 'qid-'+$v['EvalQuestions']['EvalQuestionsID']+': '+$v['EvalQuestions']['Text'];
							let $question_shared = '';
							if (!empty($v['EvalQuestions']['EvalQuestionsSharedID'])) {
								$question_shared = 'qsid-'+$v['EvalQuestionsShared']['EvalQuestionsSharedID']+': '+$v['EvalQuestionsShared']['Text'];
							}
							let $answer = 'aid-'+$v['EvalAnswers']['EvalAnswersID']+': '+$v['EvalAnswers']['Text'];
							let $answer_shared = '';
							if (!empty($v['EvalAnswers']['EvalAnswersSharedID'])) {
								$answer_shared = 'asid-'+$v['EvalAnswersShared']['EvalAnswersSharedID']+': '+$v['EvalAnswersShared']['Text'];
							}
							let $value = $v['EvalAnswers']['Value'];
							let $value_shared = '';
							if (!empty($v['EvalAnswers']['EvalAnswersSharedID'])) {
								$value_shared = $v['EvalAnswersShared']['Value'];
							}
							let $value_user = '';
							if (!empty($v['Value'])) {$value_user = $v['Value'];}
							return(<React.Fragment key={$i}>
								<pre>{/* JSON.stringify($v,'',4) */}</pre>
								<li>
								<fieldset>
								<legend>UserEvalResponses Row</legend>
								<hp-innnerhtml dangerouslySetInnerHTML={{ __html:$row}} />
								</fieldset>
								<div>User: {$user}</div>
								<div>Category: {$cat}</div>
								<div>Question: {$question}</div>
								<div>Question Shared: {$question_shared}</div>
								<div>Answer: {$answer}</div>
								<div>Answer Shared: {$answer_shared}</div>
								<div>Value: {$value}</div>
								<div>Value Shared: {$value_shared}</div>
								<div>Value User: {$value_user}</div>
								</li>
							</React.Fragment>)
						})}
						</ul>
						
						</div/* className="page-content-inner-layer"*/>
					</div/* className="page-content-outer-layer"*/>
				</div/* className="page-content"*/>
				
			</div/*  className="container EvaluationsTest-page" */>
		</>);
	}
}


const mapStateToProps = (state) => {
	// console.log('EvaluationsTest.js mapStateToProps() state',state);
	return {
		// userData: state.userDataReducers,
	}
}

export default connect(mapStateToProps)(EvaluationsTest);