import { SET_ADMIN_DATA, SET_DASHBOARD_INTERVAL } from './actionTypes'
import { getLoginTokenPacket, empty, handleBreakingError, checkApiResponseErrors, getProjectBasename } from './../utility/Shared'
import { setContentToRedux } from './contentActions'

// gets adminData object from api db and loads into redux
export const getAdminData = () => {
	// console.log('actions/adminDataActions.js getAdminDataFromDB() called');

	// we don't have login creds so use login token stored in localStorage
	const loginPacket = getLoginTokenPacket(true);

	return (dispatch) => {
		return fetch('https://mycmecredit.com/'+getProjectBasename()+'/api/hypix.php?action=getAdminData', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(loginPacket)
		})
		.then((response) => response.json())
		.then((getAdminDataResponse) => {
// console.log('getAdminDataResponse',getAdminDataResponse);
			// check for errors so we don't assign an error message to redux
			checkApiResponseErrors(getAdminDataResponse);

			// update redux with api syncing update
			dispatch(setAdminDataToRedux(getAdminDataResponse.adminData));
			dispatch(setContentToRedux(getAdminDataResponse.contentCategories));

		})
		.catch((error) => {
			console.log('ERROR adminDataActions.js getAdminDataFromDB(): ', error);
			handleBreakingError('getAdminDataFromDB',error,loginPacket,true);
			throw new Error('Stop script execution with this uncaught exception.');
		});
	}
}

// set adminData object in redux store
export const setAdminDataToRedux = (adminData) => {
	// console.log('actions/adminDataActions.js setAdminDataToRedux() adminData',adminData);

	return {
		type: SET_ADMIN_DATA,
		adminData
	}
}

// set interval tab selected on admin pages
export const setDashboardInterval = (dashboardInterval) => {
	// console.log('actions/adminDataActions.js setDashboardInterval() adminData',adminData);

	return {
		type: SET_DASHBOARD_INTERVAL,
		dashboardInterval
	}
}
