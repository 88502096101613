import React, { Component } from 'react'
import { connect } from 'react-redux'
import { empty, loadProjectCSS, kmpSearch, objToArr } from './../utility/Shared'
import HeaderSecondaryAdmin from './../common/HeaderSecondaryAdmin'
import BackButton from './../common/BackButton'
import LoadingScreen from './../common/LoadingScreen'

class UserSearch extends Component {

	constructor(props) {
		super(props)
		this.handleFirstNameTextChange = this.handleFirstNameTextChange.bind(this);
		this.handleLastNameTextChange = this.handleLastNameTextChange.bind(this);
		this.navUser = this.navUser.bind(this);
	}

	state = {
		firstNameSearchString: '',
		lastNameSearchString: '',
		searchResults: [],
		loading: true,
	}

	async componentWillMount() {
		// if user navigated to this url manually, need to reload licensing data
		if(empty(this.props.licensing) || empty(this.props.licensing.logoImageName)) {
			await this.props.screenProps.getLicensingData(true);
			if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}
		// if user navigated to this url manually, need to reload admin data
		if(empty(this.props.adminData) || empty(this.props.adminData.data.staticStats)) {
			await this.props.screenProps.getAdminData();
		}

		this.setState({searchResults:objToArr(this.props.adminData.data.usersList),loading: false});
	}

	handleFirstNameTextChange(e) {
		const searchString = e.target.value;
		let searchResults = JSON.parse(JSON.stringify(this.state.searchResults))

		for (const key of Object.keys(this.props.adminData.data.usersList)) {
			const user =  this.props.adminData.data.usersList[key];
			const indexStartFn = kmpSearch(searchString,user.FirstName);
			const indexStartLn = kmpSearch(this.state.lastNameSearchString,user.LastName);
			if((empty(searchString) || indexStartFn != -1) && (empty(this.state.lastNameSearchString) || indexStartLn!= -1)) {
				// found!
				searchResults[key] = user;
			} else {
				delete searchResults[key];
			}
		} // end for usersList

		// if both search fields are empty, show the entire user list again
		if(empty(searchString) && empty(this.state.lastNameSearchString)) {
			searchResults = objToArr(this.props.adminData.data.usersList);
		}

		this.setState({searchResults,firstNameSearchString:searchString});
	}

	handleLastNameTextChange(e) {
		const searchString = e.target.value;
		let searchResults = JSON.parse(JSON.stringify(this.state.searchResults));
		for (const key of Object.keys(this.props.adminData.data.usersList)) {
			const user =  this.props.adminData.data.usersList[key];
			const indexStartLn = kmpSearch(searchString,user.LastName);
			const indexStartFn = kmpSearch(this.state.firstNameSearchString,user.FirstName);
			if((empty(this.state.firstNameSearchString) || indexStartFn != -1) && (empty(searchString) || indexStartLn != -1)) {
				// found! the first name and last name search strings are found in this user's first/last name
				searchResults[key] = user;
			} else {
				delete searchResults[key];
			}
		} // end for usersList

		// if both search fields are empty, show the entire user list again
		if(empty(searchString) && empty(this.state.firstNameSearchString)) {
			searchResults = objToArr(this.props.adminData.data.usersList);
		}

		this.setState({searchResults,lastNameSearchString:searchString});
	}

	navUser(user) {
		this.props.screenProps.history.push('/admin/user-detail?uuid='+user.UUID);
	}

  render() {
		// console.log('UserSearch.js render() this.props',this.props);

		if(this.state.loading) {
			// show blank screen while loading
			return (
				<LoadingScreen loadingText="Loading user search..."/>
			)
		}

		const navUser = this.navUser;

    return (<>
			<div className="container container-admin user-search-page">
				<HeaderSecondaryAdmin history={this.props.screenProps.history} />

				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="page-content-inner-layer">
              <div className="back-button-container">
                <BackButton history={this.props.screenProps.history} />
              </div>

							<h1>Search for Users</h1>
							<p className="info-text">Please fill in any of the fields below and click "Search." To narrow your search, you may use more than one field at a time.</p>

							<div className="form-container user-search-form">
								<div className="flex-row">
									<input className="input-box" type="text" value={this.state.firstNameSearchString} onChange={this.handleFirstNameTextChange} placeholder="First name"/>
									<input className="input-box" type="text" value={this.state.lastNameSearchString} onChange={this.handleLastNameTextChange} placeholder="Last name"/>
								</div>
							</div>

							{!empty(this.state.searchResults) &&
								<div className="table-wrapper">
								<div className="table data-table">
									<div className="row row-header">
										<div className="cell">Full Name</div>
										<div className="cell">Member #</div>
										<div className="cell">CME Eligible</div>
										<div className="cell">Active</div>
										<div className="cell">Test User</div>
										<div className="cell">Create Date</div>
									</div>
								{this.state.searchResults.map(function(user,idx) {
									return(
										<div key={idx} className="row" title={"View User Detail: "+user.LastName +", "+user.FirstName}>
											<div className="cell link" onClick={()=>navUser(user)}>{user.LastName}, {user.FirstName}</div>
											<div className="cell">{user.LmsID}</div>
											<div className="cell">{user.IsCeEligible === '1' ? 'Y' : 'N'}</div>
											<div className="cell">{user.IsActive === '1' ? 'Y' : 'N'}</div>
											<div className="cell">{user.IsTestUser === '1' ? 'Y' : 'N'}</div>
											<div className="cell">{user.DateCreated}</div>
										</div>
									)
								})}
								</div>
								</div/*  className="table-wrapper" */>
							}

						</div/* className="page-content"*/>
					</div/* className="page-content-outer-layer"*/>
				</div/* className="page-content-inner-layer"*/>

			</div>
    </>);
  }
}

const mapStateToProps = (state) => {
	// console.log('UserSearch.js mapStateToProps() state',state);
	return {
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers,
		adminData: state.adminReducers
	}
}

export default connect(mapStateToProps)(UserSearch);
