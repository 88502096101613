import React, { Component } from 'react'
import { connect } from 'react-redux'
import { empty, popDialogCreditsAlreadyClaimed, getCategoryModeStatus, getCurrentTimestampStringUTC, parseUrlParams, isCategoryPurchased, loadProjectCSS } from './../utility/Shared'
import { CATEGORIES_ALIAS, CATEGORY_ALIAS, PASSING_PERCENTAGE } from './../utility/Constants'
import LoadingScreen from './../common/LoadingScreen'
import BackButton from './../common/BackButton'
import { confirmAlert } from 'react-confirm-alert'


class ClaimCredit extends Component {
	constructor(props) {
		super(props);
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleSuffixChange = this.handleSuffixChange.bind(this);
	}

	state = {
		email: '',
		suffix: '',
		loading: true
	}

	// make sure that userData has loaded properly. first get userData if we don't already have it. then assign the category in user position by getting url params
	async componentWillMount() {
		// if user navigated to this url manually, need to reload userData
		if(empty(this.props.userData)) {
			await this.props.screenProps.getUserDataFromDB();
      if(empty(this.props.licensing)) {return;} // return if double loading for animations
			loadProjectCSS(this.props.licensing.cssFilename);
		}

		// get category to load from url params and set category in user position
		let urlParams = parseUrlParams();
		let cid = '1';
		if(!empty(urlParams.cid)) {
			cid = urlParams.cid;
		} else if(!empty(this.props.userData.position.category)) {
			cid = this.props.userData.position.category.id;
		}
		let catIdx = cid - 1;
		let category = this.props.contentCategories[catIdx];

		// check the claim credit status for this category. redirect user to landing page if not in 'review' mode or if already completed eval
		let redirectWasNecessary = this.redirectIfNecessary(category);
		if(redirectWasNecessary === true) {
			return; // stop component execution
		} else {
			// load updated userData into redux
			this.props.screenProps.setUserPosition(this.props.userData,category);
			// populate email and suffix with what we already have (if any)
			const email = this.props.userData.userInfo.email;
			const suffix = this.props.userData.userInfo.suffix;
			this.setState({email,suffix,loading: false});
		}
	}

	handleEmailChange(e) {
		this.setState({email:e.target.value});
	}

	handleSuffixChange(e) {
		this.setState({suffix:e.target.value});
	}

	// redirect user to landing page if category is not in 'review' mode or if credits have already been submitted. returns true if redirect was necessary and false if not necessary
	redirectIfNecessary(category) {
		// first check if category is empty
		if(empty(category)) {
			this.props.screenProps.history.push('/'+CATEGORIES_ALIAS.toLowerCase());
			return true;
		}

		// make sure user purchased this category. redirect if not.
		let isPurchased = isCategoryPurchased(this.props.userData,category.id);
		if(empty(isPurchased)) {
			this.props.screenProps.history.push('/'+CATEGORIES_ALIAS.toLowerCase());
			return true;
		}

		// check if category is eligible
		let categoryModeStatus = getCategoryModeStatus(this.props.userData,category.id,'Exam');
		let evaluationCategoryId = category.id;
		if(!empty(this.props.userData.position.category.parentCategory)) {
			evaluationCategoryId = this.props.userData.position.category.parentCategory.id;
		}
		let evaluationInfo = this.props.userData.evaluations[evaluationCategoryId];
		if(categoryModeStatus !== 'review') {
			this.props.screenProps.history.push('/landing?cid='+category.id);
			confirmAlert({
				message: 'You must achieve a score of '+PASSING_PERCENTAGE+'% or greater for this '+CATEGORY_ALIAS+' in Exam mode before you can claim credit.',
				buttons: [{label: 'Ok'}]
			});
			return true;
		} else if(categoryModeStatus === 'review' && evaluationInfo.timestamp === '0000-00-00 00:00:00') {
			this.props.screenProps.history.push('/evaluation?cid='+category.id);
			confirmAlert({
				message: 'You must complete the evaluation for this '+CATEGORY_ALIAS+' before you can claim credit.',
				buttons: [{label: 'Ok'}]
			});
			return true;
		}

		// check if credits have already been claimed
		let creditClaimedInfo = this.props.userData.creditsClaimed[category.id];
		if(creditClaimedInfo.claimStatus === 'claimed') {
			this.props.screenProps.history.push('/landing?cid='+category.id);
			popDialogCreditsAlreadyClaimed(creditClaimedInfo);
			return true;
		}

		return false; // redirect was not necessary
	}

	claimCredit(e) {
		e.preventDefault();

		// save credit submission to userData in redux and db
		let updatedUserData = Object.assign({},this.props.userData);
		updatedUserData.userInfo.email = this.state.email;
		updatedUserData.userInfo.suffix = this.state.suffix;
		updatedUserData.creditsClaimed[this.props.userData.position.category.id].claimStatus = 'claimed';
		updatedUserData.creditsClaimed[this.props.userData.position.category.id].timestamp = getCurrentTimestampStringUTC();
		this.props.screenProps.syncReduxAndDB(updatedUserData, 'syncCreditsClaimed');

		// say thank you because we're polite like that
		confirmAlert({
			message: 'Your scores for the '+this.props.userData.position.category.name+' '+CATEGORY_ALIAS+' have successfully been submitted for credit to the Society for Vascular Surgery. Thank you for participating!',
			buttons: [
				{label: "Done", onClick: () => {
					// bring user to categories page
					this.props.screenProps.history.push('/'+CATEGORIES_ALIAS.toLowerCase());
				}},
			]
		});
	}


	render() {
		// console.log('ClaimCredit.js render() this.props',this.props);
		// console.log('ClaimCredit.js render() this.state',this.state);

		if(this.state.loading || empty(this.props.userData)) {
			// show loading screen
			return (
				<LoadingScreen loadingText="Loading claim credits page..." />
			)
		}

		return (
			<div className="container claim-credit-page">
				<div className="page-content">
					<div className="page-content-outer-layer">
						<div className="back-button-container">
							<BackButton history={this.props.screenProps.history} label={CATEGORY_ALIAS} />
						</div>
						<div className="page-content-inner-layer">

							{/* the claim credit page stuff */}
							<div className="error-content">
								<div className="error-content-page">
									<div className="error-content-col-left">
										<div className="header-text">Claim CME Credit</div>
										<p>You are now ready to submit your data to the Society for Vascular Surgery which is accredited by the Accreditation Council for Continuing Medical Education (ACCME) to provide continuing medical education for physicians.</p>
										<p>Please provide an e-mail address where we can send confirmations and other important communications.</p>
										<p>Name: <span className="claim-name">{this.props.userData.userInfo.firstName + ' ' + this.props.userData.userInfo.lastName}</span></p>
										<form onSubmit={(e) => this.claimCredit(e)}>
											<p>
												Email:&nbsp;&nbsp;
												<input className="input-box" type="text" value={this.state.email} onChange={this.handleEmailChange} />
											</p>
											<p>Entering your suffix/credentials is also optional, but is strongly encouraged. It will appear on your certificate.</p>
											<p>
												Suffix (e.g. M.D.):&nbsp;&nbsp;
												<input className="input-box" type="text" value={this.state.suffix} onChange={this.handleSuffixChange} />
											</p>
											<input className="btn5" type="submit" value="Submit" />
										</form>
									</div>
									<div className="error-content-col-right claim-img-container">
										<img src={require("./../images/cme-graphic.png")} width="350" height="300" alt="error icon" className={"error-icon"}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	// console.log('ClaimCredit.js mapStateToProps() state',state);
	return {
		userData: state.userDataReducers,
		contentCategories: state.contentReducers,
		licensing: state.licensingReducers
	}
}

export default connect(mapStateToProps)(ClaimCredit);
